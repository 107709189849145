import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as SwitchSvg } from '../../images/icons/switch.svg';
import { ReactComponent as UserSvg } from '../../images/icons/user.svg';
import { ReactComponent as LumLogo } from '../../images/lum_logo_redesign.svg';
import LogOutButton from './LogoutButton'
import { BLUE_COLOR, MAIN_COLOR, ORANGE_COLOR, SECONDARY_COLOR, SECONDARY_WHITE_COLOR, WHITE_COLOR } from '../../constants/css';
import IconButton from './lib/IconButton';
import Statusbar from '../configurator/StatusBar';
import { withRouter } from 'react-router';

const Header = styled.header`
    position: ${({ isDashboard }) => isDashboard ? 'static' : 'fixed'};
    z-index: 100;
    width: 100%;
    top: 0;
`

const GeneralHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${SECONDARY_COLOR};
    padding: 25px 100px 15px 100px;
`

const Heading = styled.h2`
    color: ${SECONDARY_WHITE_COLOR};
    font-size: 32px;
`

const Navigation = styled.nav`
    display: flex;
    justify-content: space-between;
    width: 200px;
    padding-left: 100px;
`

function ConfiguratorStatusBar(props) {
    const definition = props.productOffers.definition;
    return (<Statusbar
        definition={definition}
        acceptAction={() => props.onSaveHandler(0, true)}
        cancelAction={() => props.history.push('/dashboard')}
        cancelLabel='Änderungen verwerfen'
        acceptLabel='Änderungen speichern'
        buttonWidth='264px'
        background={definition.data.status === 'activated' ? MAIN_COLOR : BLUE_COLOR}
        colorText={definition.data.status === 'activated' ? SECONDARY_COLOR : WHITE_COLOR}
        isConfigurator
    />)
}

function EditorStatusBar(props) {
    const definition = props.productOffers.definition;
    return(
    <Statusbar
        definition={definition}
        acceptAction={() => props.onCloseClick()}
        cancelLabel='abbrechen'
        acceptLabel='schliessen'
        buttonWidth='180px'
        background={ORANGE_COLOR}
        colorText={WHITE_COLOR}
        editorLastUpdate={props.editorLastUpdate}
    />)
}


class Menu extends React.Component {

    renderMenu(items) {
        return items.map((item) => {
            if (item.isLogoutAction) {
                return (
                    <LogOutButton
                        key={item.label}
                        logoutHandler={this.props.logoutDispatcher}
                    />
                )
            }
            const SvgComponent = item.label === "wechseln" ? SwitchSvg : UserSvg;

            return (
                <a key={item.label} href={item.href}>
                    <IconButton
                        width={'20px'}
                        tooltip={item.label}
                        positionTooltip={'bottom'}
                        backgroundTooltip={SECONDARY_WHITE_COLOR}
                        colorTooltip={SECONDARY_COLOR}
                    >
                        <SvgComponent fill={SECONDARY_WHITE_COLOR} />
                    </IconButton>
                </a>
            )
        });
    }

    render() {
        const isDashboard = this.props.component === 'Dashboard';
        const isConfigurator = this.props.component === 'Configurator';
        const isEditor = this.props.component === 'Editor';        

        return (
            <Header isDashboard={isDashboard}>
                <GeneralHeader>
                    <LumLogo width="204px" fill={SECONDARY_WHITE_COLOR} />
                    <Heading>Product Catalog</Heading>
                    <Navigation>
                        {this.renderMenu(this.props.menu)}
                    </Navigation>
                </GeneralHeader>
                {isConfigurator &&
                    <ConfiguratorStatusBar {...this.props}/>
                }
                {isEditor &&
                    <EditorStatusBar {...this.props}/>
                }
            </Header>
        )
    }
}

Menu.propTypes = {
    menu: PropTypes.array
};

export default withRouter(Menu);
