import React from "react";
import { Icon, InputItem, InputWrap, Label, Unit, ColorBox } from "../styles/Input";
import { ErrorMessage, Container } from "../styles/InputStyles";
import { TooltipContainer, TooltipIcon, TooltipText } from "../styles/TooltipIcon";

class Input extends React.Component {
    checkIsError() {
        const { touched, error, serverError} = this.props
        return touched && (error || serverError)
    }

    getValueEntered() {
        // Check for input value 0
        if (this.props.value === 0) {
            return true
        }
         
        // Check for all other input values
        return Boolean(this.props.value)
    }

    getLabelText() {
        if (this.props.placeholder) {
            if(!this.getValueEntered()) {
                return this.props.placeholder
            }
        }

        return this.props.labelText
    }

    render() {
        return (
            <Container
                className={this.props.className}
                width={this.props.width}
            >
                {this.props.icon && <Icon>{this.props.icon}</Icon>}
                <InputWrap colorBox={this.props.colorBox} isColorBox={this.props.isColorBox}>
                    <InputItem
                        data-testid='input-element'
                        type={this.props.type}
                        colorLabel={this.props.colorLabel}
                        opacityLabelSmall={this.props.opacityLabelSmall}
                        colorText={this.props.colorText} 
                        colorBorder={this.props.colorBorder}
                        activeColor={this.props.activeColor} 
                        errorColor={this.props.errorColor}
                        isError={this.checkIsError()}
                        unit={this.props.unit}
                        min={this.props.min}
                        max={this.props.max}
                        value={this.props.value}
                        onClick={this.props.onClick}
                        onChange={this.props.onChange}
                        disabled={this.props.disabled ? "disabled": ""}
                        autoComplete={this.props.autoComplete}
                        pattern={this.props.pattern}
                        required={this.props.required}
                        colorBox={this.props.colorBox}
                        isColorBox={this.props.isColorBox}
                    />
                        <Label 
                            colorLabel={this.props.colorLabel}
                            valueEntered={this.getValueEntered()}
                            isColorBox={this.props.isColorBox}
                        >
                            {this.getLabelText()}
                            {this.props.infoIcon && 
                                <TooltipContainer>
                                    <TooltipIcon tooltipMarginLeft={this.props.tooltipMarginLeft || "10px"}>
                                        {this.props.infoIcon}
                                    </TooltipIcon>
                                    <TooltipText>
                                        {this.props.infoIconTooltip}
                                    </TooltipText>
                                </TooltipContainer>
                            }
                        </Label>
                    <Unit disabled={this.props.disabled}>
                        {this.props.unit}
                    </Unit>
                    {this.props.isColorBox && 
                        <ColorBox 
                        data-testid='input-color-box-element'
                        onClick={this.props.onClick}
                        colorBox={this.props.colorBox}
                        >&nbsp;</ColorBox> 
                    }
                </InputWrap>
                {this.checkIsError() &&
                    <ErrorMessage errorColor={this.props.errorColor}>
                        {this.props.error || this.props.serverError}
                    </ErrorMessage>
                }
            </Container>
        )
    }
}

export default Input
