import styled from "styled-components";
import { APP_FONT } from "../../../constants/css";


export const RadioButtonsContainer = styled.div`
  display: flex;
  margin-top: ${({mt= '0' }) => mt};
`

export const StyledRadioButton = styled.button`
  width: ${({width}) => width};
  height: ${({height}) => height};
  overflow: hidden;
  padding: 0 12px;
  font-size: 14px;
  font-family: ${APP_FONT};
  cursor: pointer;
  background-color: ${({backgroundColor}) => backgroundColor};
  color: ${({color}) => color};
  border-radius: 4px;
  border: none;
  z-index: 1;

  &:first-of-type {
    margin-right: 10px;
  }

  :hover {
    outline: none;
  }

  :focus {
    outline: none;
  }
`

