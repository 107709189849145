import React from 'react';
import moment from 'moment';
import { MAIN_COLOR, SECONDARY_COLOR } from '../../constants/css';
import { ButtonsWrapper } from '../common/styles/ButtonsWrapper';
import Button from '../common/styles/Button';

export default class EditorActionButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentModal: false,
            modalTitle: '',
            modalActionLabel: '',
            modalAction: null,
        };

        this.onClose = this.onClose.bind(this);
    }

    onSave(){
        this.props.onSaveClick();
    }

    onPreview(){
        this.props.onPreviewClick()
    }

    onClose(evt) {
        evt.stopPropagation();
        this.setState({ contentModal: true });
    }

    formatDate(date){
        if(date==null){
            return "-"
        }else{
            return moment(date).format('DD.MM.YYYY hh:mm:ss');
        }
    }

    render(){
        return(
            <ButtonsWrapper justifyContent='end'>
                <Button
                    color={MAIN_COLOR}
                    colorText={SECONDARY_COLOR} 
                    onClick={this.onSave.bind(this)}
                    type='submit'
                >
                    speichern
                </Button>
                <Button
                    color={SECONDARY_COLOR} 
                    colorText={SECONDARY_COLOR} 
                    onClick={() => this.props.onPreviewClick()}
                    className='mr-5'
                >
                    Preview
                </Button>
            </ButtonsWrapper>
        )
    }
}
