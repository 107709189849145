import styled, {css} from "styled-components";
import {GRAY_COLOR, MAIN_COLOR, SECONDARY_COLOR, WHITE_COLOR} from "../../../constants/css";
import {IconButtonContainer} from "./IconButton";

export const DialogContainer = styled.div`
  width: 100%;
  height: 100vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(22, 22, 22, 0.5);
`

const flexibleHeight = css`
  min-height: ${props => props.children.length ? '' : '246px'};
`

export const DialogWindowContainer = styled.div`
  position: relative;
  max-width: 608px;
  width: 100%;
  ${flexibleHeight};
  display: flex;
  flex-direction: column;
`

export const HeaderDialog = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({backgroundHeader}) => backgroundHeader ?? GRAY_COLOR};
  padding: 28px 0 22px 0;

  ${IconButtonContainer} {
    &:hover {
      border: none;
    }
  }

`

export const HeaderText = styled.div`
  margin-top: 8px;
  font-size: 24px;
  color: ${({color}) => color ?? SECONDARY_COLOR};
  text-align: center;
  padding: 0 30px;
`

export const DialogWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${WHITE_COLOR};
  padding-top: 36px;
  width: 100%;
  height: 100%;

  ${flexibleHeight} {
    min-height: ${props => props.children.length ? '' : '246px'};
  }
 
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 20px;
  max-width: 520px;
  margin-bottom: 40px;
  width: 100%;
`

export const Across = styled.span`
  position: absolute;
  right: 15px;
  top: 16px;
  width: 11px;
  height: 11px;
  cursor: pointer;

  &:hover {

  }

  &:before, &:after {
    position: absolute;
    content: '';
    height: 11px;
    width: 2px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  :hover:after, :hover:before {
    background: ${MAIN_COLOR};
  }
`
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 27px 48px 27px;
  width: 100%;
`

export const DialogQuestion = styled.p`
    max-width: 400px;
`;
