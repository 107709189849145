import { 
    FETCH_SECTION_STARTED, 
    FETCH_SECTION_FAILED,
    FETCH_SECTION_SUCCEEDED,
} from '../actions/types';

import errorHandler from '../actions/errorHandler';

export const INITIAL_STATE = {
    data: null,
    error: {}
};

export default function(state=INITIAL_STATE, action){
    switch(action.type){
        case FETCH_SECTION_STARTED:
            return {...state, data: null, error: {} };
        case FETCH_SECTION_SUCCEEDED:
            return {...state, data: action.payload.data, error: {} };
        case FETCH_SECTION_FAILED: {
            const errorObj = errorHandler(action.payload);
            return {...state, error: errorObj, data: null };
        }
        default:
            return state;
    }
}