import React from "react"
import sorryIcon from "../../images/Sorry.svg"
import {ErrorPageStyle, StyledHeading} from "./styles/ErrorPageStyle";

export class ErrorBoundary extends React.Component {
  render() {
    return (
        <ErrorPageStyle>
          <section className="error-page">
            <img src={sorryIcon} alt="man who is sorry" />
            <StyledHeading>Es tut uns leid</StyledHeading>
            <p>
              Diese Seite existiert nicht. Vielleicht existierte diese Seite einmal, wurde aber gelöscht.
                Oder es gibt ein Tippfehler in Ihrer URL.
            </p>
          </section>
        </ErrorPageStyle>
      )
    }
  }
