import styled, { css } from "styled-components";
import {
  MAIN_COLOR, 
  NO_ACTIVE_COLOR, 
  SECONDARY_COLOR,
  NO_ACTIVE_BG_COLOR
} from "../../../constants/css";

export const TextAreaContainer = styled.div`
  width: 100%;
`

export const TextAreaElement = styled.textarea`
  width: 100%;
  resize: none;
  height: ${({height = '200px'}) => height};
  padding: 13px 20px 15px 16px;
  outline: none;
  border: ${({border = 'none'}) => border};
  color: ${({colorText = SECONDARY_COLOR}) => colorText};
  resize: ${({resize = 'none'}) => resize};
  
  ${({disabled}) => disabled && css`
    background:  ${NO_ACTIVE_BG_COLOR};
    opacity: 0.5;
  `}

  ::placeholder {
    color: ${({colorPlaceholder = NO_ACTIVE_COLOR}) => colorPlaceholder};
  }

  :focus {
    border: 2px solid ${({borderColor = MAIN_COLOR}) => borderColor};
  }
`

export const LabelText = styled.div`
  height: 25px;
  font-size: 14px;
  color: ${({colorLabel = NO_ACTIVE_COLOR}) => colorLabel};
  margin-bottom: 7px;
`
