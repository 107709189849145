import React from "react";
import { BLUE_COLOR, PINK_COLOR, SECONDARY_WHITE_COLOR } from "../../../constants/css";

import { Across, DialogContainer, DialogContent, DialogWindowContainer, DialogWrapper, HeaderDialog, HeaderText } from  '../styles/DialogWindow';
import IconButton from "./IconButton";

class ResultModal extends React.Component{
    getColor () {
        if(this.props.isError) {
            return PINK_COLOR
        } else {
            return BLUE_COLOR
        }
    }

    render(){
        return(
            <DialogContainer>
                <DialogWindowContainer>
                <Across onClick={() => this.props.onHide()}/>
                <HeaderDialog>
                     <IconButton 
                        isLarge 
                        isFill 
                        colorFill={this.getColor()}
                        colorBorder={this.getColor()}
                        colorFillHover={this.getColor()}
                        colorFillIcon={SECONDARY_WHITE_COLOR}
                        > 
                        {this.props.modalIcon}
                    </IconButton>
                    <HeaderText color={this.getColor()}>{this.props.getModalTitle()}</HeaderText>
                </HeaderDialog>
                <DialogWrapper>
                    <DialogContent>
                        {this.props.message}
                    </DialogContent>
                </DialogWrapper>
                </DialogWindowContainer>
            </DialogContainer>
        )
    }
}

export default ResultModal;
