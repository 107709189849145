import styled from "styled-components";
import { PINK_COLOR } from "../../../constants/css";

export const ErrorMessage = styled.span`
  // position absolute needed to avoid pushing below content down
  position: absolute;
  color: ${({errorColor = PINK_COLOR}) => errorColor};
  font-size: 10px;
  margin-top: 5px;
`

export const Container = styled.div`
  display: inline-block;
  width: ${({width = "100%"}) => width};
  position: relative;
`
