import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components';
import { toast } from 'react-toastify';
import ProductOfferParser from '../../utils/ProductOfferParser';
import { MODAL_PROCESSES_STATES, MODAL_TITLE_STATES, PRODUCT_OFFER_DEFINITION_STATUS } from '../../constants';
import {getDefinitionAsJson} from "../../api/calls";
import DuplicationModalForm from "./DuplicationModalForm";
import PollerModal from "../common/PollerModal";
import ProductItem from '../common/lib/ProductItem';
import { BoldText, BoldTextWrapper, LeftBlock, ProductItemText, RightBlock, Toggles } from '../common/styles/ProductItem';
import IconButton from '../common/lib/IconButton';
import {ReactComponent as Duplicate} from '../../images/icons/duplicate.svg'
import {ReactComponent as Refresh} from '../../images/icons/refresh.svg'
import {ReactComponent as Pencil} from '../../images/icons/pencil.svg'
import {ReactComponent as Haken} from "../../images/icons/haken.svg";
import {ReactComponent as Download} from '../../images/icons/download.svg'
import { ConsumerItemSpecifics } from './ConsumerItemSpecifics';
import { ProducerItemSpecifics } from './ProducerItemSpecifics';
import moment from 'moment';
import ResultModal from '../common/lib/ResultModal';
import { downloadTxtFile } from '../../utils/commonUtils';
import { MAIN_COLOR, WHITE_COLOR, PINK_COLOR } from '../../constants/css';

export const DisplayIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const ProductItemRow = styled(Row)`
    justify-content: center;
`

const MODAL_ICON_STATES = {
    'activation': <Haken stroke={WHITE_COLOR}/>,
    'synchronization': <Refresh stroke={WHITE_COLOR}/>,
    'duplication': <Duplicate stroke={WHITE_COLOR}/>
}

export class ProductOfferListItem extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showActivateButton: false,
            showDeactivateButton: false,
            showDeleteButton: false,
            showSyncButton: false,
            showMoreInfo: false,
            showModal: false,
            modalQuestion: '',
            modalActionLabel: '',
            modalAction: null,
            startPolling: false,
            pollTaskId: null,
            statusLabel: PRODUCT_OFFER_DEFINITION_STATUS[props.definition.status].label,
            action: null,
            resultModal: false,
            resultMessage: '',
            resultError: false,
        };
        this.handleStatusEditClick = this.handleStatusEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleActivateClick = this.handleActivateClick.bind(this);
        this.handleDeactivateClick = this.handleDeactivateClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleShowMoreInfo = this.handleShowMoreInfo.bind(this);
        this.pollingRequestHandler = this.pollingRequestHandler.bind(this);
        this.handleJsonValuesClick = this.handleJsonValuesClick.bind(this);
        this.handleDuplicateDefinitionClick = this.handleDuplicateDefinitionClick.bind(this);
        this.poObject = this.props.definition;
        this.poParser = new ProductOfferParser(props.definition.product_offer_characteristics);
    }

    showToast(message, type="success"){
        toast(message, {type});
        this.setState({showModal: false})
    }

    handleStatusEditClick(evt) {
        evt.stopPropagation();
        if(this.state.showActivateButton || this.state.showDeactivateButton){
            this.setState({
                showDeleteButton: false,
                showActivateButton: false,
                showDeactivateButton: false,
                showSyncButton: false
            });    
        }else{
            this.setState({
                showDeleteButton: this.props.definition.status === 'draft',
                showActivateButton: this.props.definition.status === 'deactivated' || this.props.definition.status === 'draft',
                showDeactivateButton: this.props.definition.status === 'activated',
                showSyncButton: this.props.definition.status === 'activated',
            });
        }
    }

    handleCancelClick(evt) {
        evt.stopPropagation();
        this.setState({
            showActivateButton: false,
            showDeactivateButton: false,
        });
    }

    handleActivateClick(evt) {
        evt.stopPropagation();
        this.setState({
            showModal: true,
            action: 'activation',
            modalQuestion: 'Sind Sie sicher, dass Sie das Produkt aktivieren möchten?',
            modalActionLabel: 'aktivieren',
            modalAction: () => {this.updateProductOffer(this.poObject.id, 'activate');}
        });
    }

    handleDeactivateClick(evt) {
        evt.stopPropagation();
        this.setState({
            showModal: true,
            action: 'deactivation',
            modalQuestion: 'Sind Sie sicher, dass Sie das Produkt deaktivieren möchten?',
            modalActionLabel: 'deaktivieren',
            modalAction: () => {this.updateProductOffer(this.poObject.id, 'deactivate')}
        });        
    }

    handleSyncClick(evt) {
        evt.stopPropagation();
        this.setState({
            showModal: true,
            action: 'synchronization',
            modalQuestion: 'Sind Sie sicher, dass Sie das aktive Produkt überschreiben möchten?',
            modalActionLabel: 'überschreiben',
            modalAction:  () => {this.synchronizeProductOffer(this.poObject.id,this.props.definition)},
        });
    }

    handleShowMoreInfo(evt) {
        evt.stopPropagation();
        this.setState({
            showMoreInfo: !this.state.showMoreInfo
        });
    }

    handleDeleteClick(evt) {
        evt.stopPropagation();
        this.setState({
            showModal: true,
            action: 'deletion',
            modalQuestion: 'Sind Sie sicher, dass Sie den Produktentwurf löschen möchten?',
            modalActionLabel: 'löschen',
            modalAction: () => {this.props.deleteActionHandler(this.props.token, this.poObject.id, this.showToast.bind(this))}
        });        
    }

    handleDuplicateDefinitionClick(){
        this.setState({
            showModal: false,
            showFormModal:true,
            modalQuestion: '',
            modalAction: null,
            modalActionLabel: '',
            action: 'duplication',
        });
    }

    pollingRequestHandler(taskId){
        this.props.pollTaskHandler(this.props.token, taskId)
    }

    updateProductOffer(pk, status){
        this.props.activateActionHandler(this.props.token, pk, status)
            .then(response => {
                this.setState({
                    startPolling: true,
                    pollTaskId: response.data.task_id
                });
            }).catch(error => {
                const message = error.response.data.message
                this.setState({
                    showModal: false,
                    resultModal: true,
                    resultError: true,
                    resultMessage: `Bei der Aktivierung der Definition ist ein Fehler aufgetreten: ${message}`
                });
        });
    }

    synchronizeProductOffer(pk){
        this.props.updateActionHandler(this.props.token, pk)
            .then(response => {
                this.setState({
                    startPolling: true,
                    pollTaskId: response.data.task_id
                });
            }).catch(error => {
            this.setState({
                showModal: false,
                resultModal: true,
                resultError: true,
                resultMessage: `Bei der Synchronizierung der Definition ist ein Fehler aufgetreten: ${error}`
            });
        });   
    }

    handleJsonValuesClick(){
        const callback = (response) => {
            downloadTxtFile(JSON.stringify(response.data, null, 2), 'pod.json', 'text/plain')
        }
        
        getDefinitionAsJson(this.props.token, this.poObject.id, callback);
    }

    handleDuplicationSuccess() {
        this.setState({
            resultModal: true,
            resultError: false,
            resultMessage: 'Das Duplizieren war erfolgreich.'
        })
        this.resetModalState()
    }

    handleDuplicationError(err) {
        this.setState({
            resultModal: true,
            resultError: true,
            resultMessage: `Bei der Duplizierung der Definition ist ein Fehler aufgetreten: ${err}`
        })
        this.resetModalState()
    }

    handlePollingSuccess() {
        this.setState({
            resultModal: true,
            resultError: false,
            resultMessage: `Das Produkt wurde erfolgreich ${MODAL_PROCESSES_STATES[this.state.action]}.`
        })
        this.resetPollingState()
    }

    handlePollingError(err) {
        this.setState({
            resultModal: true,
            resultError: true,
            resultMessage: `Das Produkt wurde nicht ${MODAL_PROCESSES_STATES[this.state.action]}. Es trat ein Fehler auf: ${err}`
        })
        this.resetPollingState()
    }

    resetModalState(){
        this.setState({
            showModal: false,
            showFormModal: false,
            modalQuestion: '',
            modalAction: null,
            modalActionLabel: ''
        });
    }

    resetPollingState(){
        this.setState({
            showModal: false,
            startPolling: false,
            modalQuestion: '',
            modalAction: null,
            modalActionLabel: '',
            pollTaskId: null,
        });
    }

    getEditedLabels() {
        const editedLabels = [
            'erstellt am', 
            'zuletzt gespeichert',
            'aktiviert am',
            'zuletzt synchronisiert am'
        ]
        return editedLabels.map((el, index) => <ProductItemText key={index}>{el}</ProductItemText>)
    }

    getEditedInfo() {
        const formatDate= (date) => {
            return date == null ? "-" : moment(date).format('DD.MM.YYYY | HH:mm');
        }

        const editedInfo = [
            formatDate(this.props.definition.creation_date), 
            formatDate(this.props.definition.updated_date),
            formatDate(this.props.definition.activation_date),
            formatDate(this.props.definition.last_synchronised)
        ]

        return editedInfo.map((el, index) => <BoldText key={index}>{el}</BoldText>)
    }

    getModalTitle() {
        return `Produkt ${this.poParser.getValue('display_name')} 
            ${MODAL_TITLE_STATES[this.state.action]}`
    }

    render(){
        return(<Col key={this.props.definition.id} lg={12} md={12}>
            {this.state.showModal &&
            <PollerModal  
                onHide={() => this.resetPollingState()}
                acceptLabel={this.state.modalActionLabel}
                acceptAction={this.state.modalAction}
                startsPolling={this.state.startPolling}
                pollAction={this.pollingRequestHandler}
                pollTaskId={this.state.pollTaskId}
                modalIcon={MODAL_ICON_STATES[this.state.action]}
                updateStatusActivationSuccessful={this.props.updateStatusActivationSuccessful}
                modalQuestion={this.state.modalQuestion}
                modalProcess={MODAL_PROCESSES_STATES[this.state.action]}
                getModalTitle={this.getModalTitle.bind(this)}
                handlePollingSuccess={this.handlePollingSuccess.bind(this)}
                handlePollingError={this.handlePollingError.bind(this)}
            />}
            {this.state.showFormModal && 
                <DuplicationModalForm 
                    user={this.props.user}
                    acceptAction={this.props.duplicateActionHandler}
                    modalQuestion={`Produkt "${this.poParser.getValue('display_name')}" duplizieren`}
                    token={this.props.token}
                    itemId={this.props.definition.id}
                    successCallback={this.handleDuplicationSuccess.bind(this)}
                    errorCallback={this.handleDuplicationError.bind(this)}
                    onHide={() => this.resetModalState()}
                />
            }
            {this.state.resultModal && 
                <ResultModal 
                    onHide={() => this.setState({resultModal: false})}
                    message={this.state.resultMessage}
                    modalIcon={MODAL_ICON_STATES[this.state.action]}
                    getModalTitle={this.getModalTitle.bind(this)}
                    isError={this.state.resultError}
                />
            }
            <ProductItemRow key={this.poObject.id} className="mb-3">
                <ProductItem 
                    title={this.poParser.getValue('display_name')} 
                    status={this.props.definition.status} 
                    handleActivateClick={this.handleActivateClick} 
                    productId={this.poObject.id}
                >
                    <Toggles>
                        {this.props.definition.status === "activated" &&
                        <IconButton
                                p='0 12px'
                                isFill
                                tooltip={this.props.definition.has_pending_edits ? 'Synchronisieren ausstehend' : 'Synchronisieren'}
                                colorFill={this.props.definition.has_pending_edits ? PINK_COLOR : MAIN_COLOR }
                                colorBorder={this.props.definition.has_pending_edits ? PINK_COLOR : MAIN_COLOR  }
                                positionTooltip={'top'}
                            > 
                            <Refresh onClick={this.handleSyncClick.bind(this)}/>
                            </IconButton> 
                        }  
                        <Link to={`/configurator/${this.props.definition.id}`}>
                            <IconButton
                                width='20px'
                                height='20px'
                                p='0 12px 4px 12px'
                                tooltip={'bearbeiten'}
                                positionTooltip={'top'}
                            >
                                <Pencil onClick={this.handleStatusEditClick}/>
                            </IconButton>
                        </Link>
                        <IconButton
                            width='20px'
                            height='20px'
                            p='0 12px 4px 12px'
                            tooltip={'duplizieren'}
                            positionTooltip={'top'}
                        >
                            <Duplicate onClick={this.handleDuplicateDefinitionClick}/>
                        </IconButton>
                        {!this.props.user.isSalespartnerUser &&
                            <IconButton
                                width='20px'
                                height='20px'
                                p='0 12px 4px 12px'
                                tooltip={'herunterladen'}
                                positionTooltip={'top'}
                            >
                                <Download onClick={this.handleJsonValuesClick}/>
                            </IconButton>
                        }
                    </Toggles>
                    <LeftBlock>
                        {this.props.isProducer ?
                            <ProducerItemSpecifics definition={this.props.definition} />
                        :
                            <ConsumerItemSpecifics definition={this.props.definition} />
                        }
                    </LeftBlock>
                    <RightBlock>
                        {this.getEditedLabels()}
                    </RightBlock>
                    <BoldTextWrapper>
                        {this.getEditedInfo()}
                    </BoldTextWrapper>
                </ProductItem>
            </ProductItemRow>
        </Col>)
    }
}

export default ProductOfferListItem;
