import React, {useState} from "react";
import { TooltipContainer, TooltipIcon, TooltipText } from "../common/styles/TooltipIcon";
import { SECONDARY_COLOR, MAIN_COLOR } from "../../constants/css";
import { ReactComponent as Info } from "../../images/icons/INfo.svg"
import { ReactComponent as AnchorLink } from "../../images/icons/anchor_link.svg"
import { SideBarContainer} from "../common/styles/SideBar";
import  Button  from "../common/styles/Button.js"
import styled from 'styled-components';

const HtmlTagsList = styled.div`
    flex: 4
    display: flex
    justify-content: flex-start;
    padding: 6px 0px
    gap: 1px
`
const HtmlTagsText = styled.div`
    display: flex
    justify-content: center
    margin: 4px 0px
    padding: 6px 0px
`
const itemStyle = { 
    border: 'none', 
    borderRadius:'0px', 
    hover: {background: MAIN_COLOR},
    transition: '0.3s ease',
    padding: '10px', 
    flex:1, 
    display:'flex', 
    justifyContent:'center', 
    maxWidth: '20%',
};

const tooltipIconStyle = {
    height : '20px', 
    width: '20px', 
    marginRight:'4px'
}

const htmlTagList = [
    { name: 'bold', htmlTag: 'b', toDisplay: 'F', content: '<b> Text in fetter Schriftart </b>' },
    { name: 'italic', htmlTag: 'i', toDisplay: 'K', content: '<i> Text in kursiver Schriftart </i>' },
    { name: 'underline', htmlTag: 'u', toDisplay: 'U', content: '<u> unterstrichener Text </u>' },
    { name: 'link', htmlTag: 'div', toDisplay: <AnchorLink style={{width:'20px'}}/>, content: '<a href="https://www.url.de">Text</a>' },
  ];

const EditorHtmlExplainer = () => {
    const [selectedItem, setSelectedItem] = useState({ name:'', content: ""})

    function handleClick ({name, content} ) {
        setSelectedItem({name, content})
    }

    return (
            <SideBarContainer 
                position='fixed'
                top='370px'
                right='80px'
                padding-left='60px'
                style={{zIndex: 1}}
                >
                    <div className="d-flex">
                    <HtmlTagsList >
                        {htmlTagList.map((item,index)=>{
                                return (
                                        <Button
                                        key={index}
                                        style={{...itemStyle , background: item.name === selectedItem.name && MAIN_COLOR}}
                                        onClick={()=>handleClick(item)}
                                        hoverColor={MAIN_COLOR}
                                        >
                                        <item.htmlTag>{item.toDisplay}</item.htmlTag>
                                    </Button>                            
                                    )
                                })}  
                    </HtmlTagsList>
                        <TooltipContainer>
                            <TooltipIcon
                            style={tooltipIconStyle} 
                            >
                            <Info 
                            fill={SECONDARY_COLOR}/>
                            </TooltipIcon>
                            <TooltipText tooltipPositionLeft='0'>
                                Text kann mittels html Tags formatiert werden. Bei der Verwendung muss auf das korrekte Öffnen und das Schließen eines Tags um den zu formatierenden Text herum geachtet werden (siehe Beispiel Tags).
                            </TooltipText>
                        </TooltipContainer>
                    </div>
                <HtmlTagsText>
                    <p 
                    hidden={selectedItem.content.length <= 1}
                    >{selectedItem.content}</p>
                </HtmlTagsText>
            </SideBarContainer>
    )
} 

export default EditorHtmlExplainer;

// function renderBtnContent(item) {
//     if (item.htmlTag.toString().length>3) return <AnchorLink style={{width:'20px'}}/> 
//     else return <item.htmlTag >{item.toDisplay}</item.htmlTag>
//     return React.createElement(item.htmlTag, null, item.toDisplay)
    
// }
