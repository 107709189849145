import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import {getFileDataFromURL} from '../../../utils/fileHandlingUtils';
import Button from '../styles/Button';
import upload from '../../../images/icons/upload.svg';
import { MAIN_COLOR, SECONDARY_COLOR, NO_ACTIVE_BG_COLOR, NO_ACTIVE_COLOR, GRAY_COLOR , PINK_COLOR, WHITE_COLOR} from '../../../constants/css';
import IconButton from '../lib/IconButton';
import {ReactComponent as Delete} from '../../../images/icons/delete.svg';
import {ReactComponent as ImagePlaceholder} from '../../../images/icons/image.svg';

import UploadDocument from '../lib/UploadDocument';
import styled from 'styled-components';
import ContentModal from '../ContentModal';
import ResultModal from '../lib/ResultModal';
import { isValidASCII } from '../../../utils/validationUtils';

const LogoImage = styled.img`
  width: 256px;
  border: 1px solid ${NO_ACTIVE_BG_COLOR};
`

const LogoPlaceholder = styled.div`
  width: 256px;
  height: 140px;
  background: ${GRAY_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
`

function getFileObject(file, callback){
  let fileObj = null;
  if(file && typeof(file) === 'string'){
    getFileDataFromURL(file).then(value => {fileObj = value; callback(value)});
    return fileObj
  }
  callback(file);
  return file
}

class UploadDocumentWrapper extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      show: false,
      fileObj: '',
      error: '',
      contentModal: false,
      resultModal: false
    }
  }

  getFile() {
    const callback = (value) => {
      this.props.input.onChange(value);
      this.setState({
        show: this.props.show,
        fileObj: value,
        loading: false
      });
    }
    getFileObject(this.props.selectedFile, callback);
  }

  componentDidMount(){
    this.getFile()
  }


  componentDidUpdate(prevProps){
    if(prevProps.show !== this.props.show){
      this.setState({
        show: this.props.show
      })
    }

    if(prevProps.selectedFile !== this.props.selectedFile){
      this.getFile()
    }
  }

  onChange(files) {
    this.setState({ error: '' })

    const {
      input: { onChange }
    } = this.props;
    this.setState({
      fileObj: files[0]
    })
    onChange(files[0]);
  }

  onDropRejected(){
    this.setState({ error: 'Falscher Typ oder Datei zu groß' })
  }

  onDeleteClick(evt){
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({ contentModal: true })
  }

  onDeleteConfirm(){
    this.props.onDeleteFile(this.props.input.name);
    this.setState({ contentModal: false, resultModal: true })
  }

  onHide(){
    this.setState({ contentModal: false })
  }

  getIcon() {
    if (this.state.fileObj && this.props.isImageFile) {
      return <LogoImage src={URL.createObjectURL(this.state.fileObj)} alt="logo"/>
    }

    if (this.props.isImageFile) {
      return (
        <LogoPlaceholder>
          <ImagePlaceholder width="50px" height="40px"/>
        </LogoPlaceholder>
      )
    }
    
    return this.props.icon
  }

  onDropHandler(files) {
      if(files.length > 0){
        const file = files[0];
        if (!isValidASCII(file.name)) {
            this.setState({ error: 'Verwendung von Umlauten und Spezialzeichen nicht erlaubt' });
            return;
        }

        if(this.props.onDropCustomHandler){
          this.props.onDropCustomHandler(files);
        }

        return this.onChange(files);
      }
  }

  render() {
    const { input } = this.props

    return (
      <>
      {this.state.contentModal && 
        <ContentModal
          {...this.props}
          modalQuestion='Sind Sie sicher, dass Sie die Datei löschen möchten?'
          acceptAction={this.onDeleteConfirm.bind(this)}
          cancelAction={this.onHide.bind(this)}
          onHide={this.onHide.bind(this)}
          modalIcon={<Delete />}
          getModalTitle={() => 'Datei löschen'}
          colorFill={PINK_COLOR}
          colorFillIcon={WHITE_COLOR}
          color={PINK_COLOR}
          cancelLabel='Datei behalten'
          acceptLabel='Datei löschen'
          acceptButtonColor={PINK_COLOR}
          acceptButtonColorText={WHITE_COLOR}
          buttonWidth='200px'
        />
      }
      {this.state.resultModal && 
        <ResultModal
          onHide={() => this.setState({resultModal: false})}
          message='Die Datei wurde gelöscht.'
          modalIcon={<Delete />}
          getModalTitle={() => 'Datei löschen'}
        />
      }
      {this.props.show &&
        <Dropzone
          onDrop={this.onDropHandler.bind(this)}
          onDropRejected={this.onDropRejected.bind(this)}
          accept={this.props.acceptedTypes}
          multiple={false}
          maxSize={this.props.maxSize}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: "dropzone" })}>
              <input
                {...getInputProps()}
                name={input.name}
                type="file"
                multiple={false}
                disabled={this.props.disabled}
              />
                <UploadDocument
                    icon={this.getIcon()}
                    text={this.state.fileObj ? this.state.fileObj.name : this.props.text}
                    showSize={this.props.showSize}
                    size={this.state.fileObj ? this.state.fileObj.size : this.props.text}
                    widthFileText={this.props.widthFileText}
                    backgroundColor={this.props.backgroundColor}
                    colorIcon={this.state.fileObj ? SECONDARY_COLOR : NO_ACTIVE_BG_COLOR}
                    isImageFile={this.props.isImageFile}
                    fileUploaded={Boolean(this.state.fileObj)}
                    colorText={this.state.fileObj ? SECONDARY_COLOR : NO_ACTIVE_COLOR}
                    textFontSize={this.props.textFontSize}
                    error={this.state.error}
                >
                    {this.state.fileObj ?
                        <IconButton
                            onClick={this.props.disabled ? () => undefined : this.onDeleteClick.bind(this)}
                            tooltip='löschen'
                            width='20px'
                            disabled={this.props.disabled}
                            fill={NO_ACTIVE_COLOR}
                        >
                            <Delete/>
                        </IconButton>
                    :
                        <Button
                            buttonWidth='190px'
                            color={this.props.disabled ? NO_ACTIVE_BG_COLOR : MAIN_COLOR}
                            colorText={this.props.disabled ? NO_ACTIVE_COLOR :  SECONDARY_COLOR}
                            icon={upload}
                            iconPosition='right'
                            disabled={this.props.disabled}
                            hoverCursor={this.props.disabled ? 'default' : 'pointer'}
                        >
                            hochladen
                        </Button>
                    }
                </UploadDocument>
            </div>
          )}
        </Dropzone>
        }
      </>
    );
  }
}

UploadDocumentWrapper.propTypes = {
    acceptedTypes: PropTypes.array,
    show: PropTypes.bool
};
  
UploadDocumentWrapper.defaultProps ={
    acceptedTypes: ['application/pdf'],
    show: true
};

export default UploadDocumentWrapper;
