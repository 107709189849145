import React from "react";
import PropTypes from 'prop-types';
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "./styles/Button";
import { MAIN_COLOR, SECONDARY_COLOR } from "../../constants/css";
import { downloadTxtFile } from "../../utils/commonUtils";

class PreviewerModalEmail extends React.Component{
  constructor(props) {
    super(props);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
  }

  handleDownloadClick(){
    downloadTxtFile(this.props.renderedHtml, `${this.props.cucoId}.html`, 'text/html')
  }

  render(){
    if (!this.props.show) {
      return null;
    }
    return (
      <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={this.props.onHide}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Container>
            <Row className="show-grid">
              <Col lg={12} md={12}>
                {this.props.children}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>        
          <Button
            color={SECONDARY_COLOR}
            colorText={SECONDARY_COLOR}
            className='mr-5'
            onClick={this.handleDownloadClick}>
            Download
          </Button>
          <Button
            color={MAIN_COLOR}
            colorText={SECONDARY_COLOR}
            onClick={this.props.onHide}
          >
            schliessen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

PreviewerModalEmail.propTypes = {
  renderedHtml: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  cucoId: PropTypes.string,
}

export default PreviewerModalEmail;
