import React from "react";
import _ from "lodash";
import { Field } from "redux-form";
import {
  EDITOR_TYPES_MAP,
} from "../../constants";
import { connect } from "react-redux";
import {
  MAIN_COLOR,
  NO_ACTIVE_BG_COLOR,
  NO_ACTIVE_COLOR,
  SECONDARY_COLOR,
  WHITE_COLOR,
} from "../../constants/css";
import InputWrapper from "../common/forms/InputWrapper";
import TextAreaWrapper from "../common/forms/TextAreaWrapper";
import { RadioButton } from "../common/lib/RadioButtons";
import { RadioButtonsContainer } from "../common/styles/RadioButton";
import Select from "../common/lib/Select";
import { getFileDataFromURL } from "../../utils/fileHandlingUtils";
import { CaseButtonContainer, LeftLogo, LogoHeading, RadioButtonCases, RightLogo, StyledCol, StyledFieldset, StyledHalfCol, StyledLegend } from "../common/styles/EditorSections";
import EmailMain from "./mains/EmailMain";
import DocumentMain from "./mains/DocumentMain";

const DOCUMENT_DEFAULT_VALUES = ["file_name"];
const EMAIL_DEFAULT_VALUES = [];

const SINGULAR_PLURAL_VALUES = [
  {
    label: "Einzahl",
    showButton: false,
    showLabel: true,
    activeVariable: "singular",
    inactiveVariable: ["plural"],
    key: "singular",
  },
  {
    label: "Mehrzal",
    showButton: true,
    showLabel: false,
    activeVariable: "plural",
    inactiveVariable: ["singular"],
    key: "plural",
  },
];
const DEBIT_CREDIT_VALUES = [
  {
    label: "Gutschrift",
    showButton: false,
    showLabel: true,
    activeVariable: "credit_paragraph",
    inactiveVariable: ["debit_paragraph"],
    key: "credit_paragraph",
  },
  {
    label: "Forderung",
    showButton: true,
    showLabel: false,
    activeVariable: "debit_paragraph",
    inactiveVariable: ["credit_paragraph"],
    key: "debit_paragraph",
  },
];
const PAYMENT_METHOD_VALUES = [
  {
    label: "SEPA Einzug",
    showButton: false,
    showLabel: true,
    activeVariable: "direct_debit",
    inactiveVariable: ["bank_transfer", "credit_card_display_only"],
    key: "direct_debit",
  },
  {
    label: "Überweisung",
    showButton: true,
    showLabel: false,
    activeVariable: "bank_transfer",
    inactiveVariable: ["direct_debit", "credit_card_display_only"],
    key: "bank_transfer",
  },
  {
    label: "Kreditkarte",
    showButton: true,
    showLabel: false,
    activeVariable: "credit_card_display_only",
    inactiveVariable: ["direct_debit", "bank_transfer"],
    key: "credit_card_display_only",
  },
];

const BLOCK_CASES = {
  singular_plural: SINGULAR_PLURAL_VALUES,
  payment: DEBIT_CREDIT_VALUES,
  method: PAYMENT_METHOD_VALUES,
};

const CASE_ORDERING = {
  credit_paragraph: 0,
  debit_paragraph: 0,
  direct_debit: 1,
  bank_transfer: 1,
  credit_card_display_only: 1,
  singular: 2,
  plural: 2,
};


/* Creates a list of all the possible variable names that a section can have
  depending the cases that given block has. It receives a list of lists and create the carthesian product
  of the given lists
*/
export function cartesian(args) {
  var r = [],
    max = args.length - 1;
  function helper(arr, i) {
    for (var j = 0, l = args[i].length; j < l; j++) {
      var a = arr.slice(0); // clone arr
      a.push(args[i][j]);
      if (i === max) r.push(a);
      else helper(a, i + 1);
    }
  }
  helper([], 0);
  return r;
}

export function getDefaultValues(cucoType, values) {
  const defaultKeys =
    cucoType === "dt" ? DOCUMENT_DEFAULT_VALUES : EMAIL_DEFAULT_VALUES;
  return _.pickBy(values, (v, k) => {
    return defaultKeys.includes(k);
  });
}


export class EditableItem extends React.Component {
  constructor(props) {
    super(props);
    const { variableNames, item, isSingularPlural, paymentMethods } =
      this.props;
    // Fields default states
    const newState = variableNames.reduce((o, key, idx) => {
      const stateKey = `show_${key.variableName}`;
      return Object.assign(o, { [stateKey]: idx === 0 });
    }, {});

    //Buttons default state
    const cases = _.get(item, "cases");
    const casesArr = cases ? cases.split(",") : [];
    const isPaymentMethods = paymentMethods.length > 1;

    const buttonsStates = casesArr.map((item) => {
      let caseState = {};
      if (item === "singular_plural" && !isSingularPlural) {
        Object.assign(caseState, {});
      } else if (item === "method" && !isPaymentMethods) {
        Object.assign(caseState, {});
      } else {
        BLOCK_CASES[item]
          .filter((blockCase) => {
            return (
              (item === "method" && paymentMethods.includes(blockCase.key)) ||
              item !== "method"
            );
          })
          .reduce((o, key) => {
            const buttonKey = `button_${key.key}`;
            const labelKey = `label_${key.key}`;
            return Object.assign(o, {
              [buttonKey]: key.showButton,
              [labelKey]: key.showLabel,
            });
          }, caseState);
      }
      return caseState;
    });

    this.state = {
      ...newState,
      ...buttonsStates.reduce((o, key) => {
        return Object.assign(o, key);
      }, {}),
    };
  }

  onBadgeClick(activeButton, inactiveButtons, variableNames) {
    let newButtonState = {
      [`button_${activeButton}`]: false,
      [`label_${activeButton}`]: true,
    };

    const inactiveButtonState = inactiveButtons
      .filter((item) => {
        return (
          Object.keys(this.state).includes(`button_${item}`) &&
          Object.keys(this.state).includes(`label_${item}`)
        );
      })
      .reduce((o, item) => {
        return Object.assign(o, {
          [`button_${item}`]: true,
          [`label_${item}`]: false,
        });
      }, {});

    // Look for active buttons
    const activeLabels = Object.keys(
      _.pickBy(this.state, (val, key) => {
        return (
          key.includes("label") &&
          !inactiveButtons.includes(key.substring("label".length + 1)) &&
          val === true
        );
      })
    ).map((item) => {
      return item.substring("label".length + 1);
    });

    // Search for variable with the current case plus the active label cases
    const activateVar = _.find(variableNames, (val) => {
      return [activeButton, ...activeLabels].every((v) => val.includes(v));
    });

    // Create the key to be activated on click
    const activateVarKey = { [`show_${activateVar}`]: true };

    // Search for the difference between the active variable and the variable name list
    // to get the variables to be inactivated
    const inactiveVars = this.props.variableNames
      .filter((v) => {
        return v.variableName !== activateVar;
      })
      .map((v) => {
        return { [`show_${v.variableName}`]: false };
      });
    const keysInactive = inactiveVars.reduce((o, key) => {
      return Object.assign(o, key);
    }, {});

    this.setState({
      ...activateVarKey,
      ...inactiveButtonState,
      ...keysInactive,
      ...newButtonState,
    });
  }

  handleSelectChange(value) {
    const inactiveVariables = PAYMENT_METHOD_VALUES.reduce((acc, cur) => {
      if (cur.activeVariable === value) {
        return cur.inactiveVariable;
      }
      return acc;
    }, []);

    // filter for variable names associated to the current case
    const variableNames = this.props.variableNames
      .filter((x) => {
        return x.cases.includes(value);
      })
      .map((x) => {
        return x.variableName;
      });

    this.onBadgeClick(value, inactiveVariables, variableNames);
  }

  renderRadioBtn(key, passiveKey, title) {
    // filter for variable names associated to the current case
    const variableNames = this.props.variableNames
      .filter((x) => {
        return x.cases.includes(key);
      })
      .map((x) => {
        return x.variableName;
      });

    return (
      <RadioButton
        id={`button_${key}`}
        title={title}
        onClick={
          this.state[`label_${key}`]
            ? () => undefined
            : () => this.onBadgeClick(key, [passiveKey], variableNames)
        }
        width="100px"
        height="30px"
        backgroundColor={
          this.state[`label_${key}`] ? MAIN_COLOR : NO_ACTIVE_BG_COLOR
        }
        color={
          this.state[`label_${key}`] ? SECONDARY_COLOR : NO_ACTIVE_COLOR
        }
      />
    );
  }

  renderCaseButtons(item, isSingularPlural, paymentMethods) {
    const cases = _.get(item, "cases");
    const casesArr = cases ? cases.split(",") : [];

    if (casesArr.length === 0 || this.props.variableNames.length === 1) {
      return null;
    }

    let singularPluralBtnsRendered;
    let paymentBtnsRendered;
    let methodSwitchRendered;

    casesArr.forEach((caseItem) => {
      if (caseItem === "singular_plural" && !isSingularPlural) {
        return null;
      }

      if (caseItem === "singular_plural") {
        singularPluralBtnsRendered = (
          <RadioButtonsContainer mt="5px">
            {this.renderRadioBtn("singular", "plural", "Singular")}
            {this.renderRadioBtn("plural", "singular", "Plural")}
          </RadioButtonsContainer>
        );
      }

      if (caseItem === "payment") {
        paymentBtnsRendered = (
          <RadioButtonsContainer mt="5px">
            {this.renderRadioBtn(
              "debit_paragraph",
              "credit_paragraph",
              "Forderung"
            )}
            {this.renderRadioBtn(
              "credit_paragraph",
              "debit_paragraph",
              "Gutschrift"
            )}
          </RadioButtonsContainer>
        );
      }

      if (caseItem === "method") {
        if(paymentMethods.length <= 1){
          return null
        }

        let methodValue;
        if (this.state.label_credit_card_display_only) {
          methodValue = "credit_card_display_only";
        } else if (this.state.label_bank_transfer) {
          methodValue = "bank_transfer";
        } else if (this.state.label_direct_debit) {
          methodValue = "direct_debit";
        }

        const methodSelectOptions = PAYMENT_METHOD_VALUES.reduce((acc, cur) => {
          if (paymentMethods.includes(cur.activeVariable)) {
            acc.push({
              value: cur.activeVariable,
              label: cur.label,
            });
          }
          return acc;
        }, []);

        methodSwitchRendered = (
          <Select
            width="190px"
            labelText="Zahlungsart"
            optionsList={methodSelectOptions}
            value={methodValue}
            onChange={this.handleSelectChange.bind(this)}
            onBlur={() => undefined}
            touched={undefined}
          />
        );
      }
    });

    return (
      <>
        {methodSwitchRendered}
        <RadioButtonCases>
          {singularPluralBtnsRendered}
          {paymentBtnsRendered}
        </RadioButtonCases>
      </>
    );
  }

  renderFormGroup() {
    const { item, variableNames, paymentMethods } = this.props;

    return (
      <div data-testid='editable-item'>
        <CaseButtonContainer>
          {this.renderCaseButtons(
            item,
            this.props.isSingularPlural,
            paymentMethods
          )}
        </CaseButtonContainer>
        {variableNames.map((variable) => {
          const variableKey = `show_${variable.variableName}`;
          const show = this.state[variableKey];

          return (
            <div key={variable.variableName}>
              {show && !item.multiline && (
                <Field
                  name={variable.variableName}
                  type="text"
                  component={InputWrapper}
                  labelText={item.label}
                />
              )}
              {show && item.multiline && (
                <Field
                  key={variable.variableName}
                  name={variable.variableName}
                  type="text"
                  component={TextAreaWrapper}
                  labelText={item.label}
                  height={item.label === "Betreffzeile" ? "80px" : "200px"}
                  placeholder="Bitte füllen Sie den Textbaustein aus"
                  resize="vertical"
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return <>{this.renderFormGroup()}</>;
  }
}

export class EditorSections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coBrandingImagePath: null,
      salesPartnerImagePath: null
    };
  }
  

  componentDidMount() {
    if (this.props.coBrandingImage) {
      this.getFilePath(this.props.coBrandingImage).then(path => {
        this.setState({coBrandingImagePath: path})
      })
    }

    if (this.props.salesPartnerImage) {
      this.getFilePath(this.props.salesPartnerImage).then(path => {
        this.setState({salesPartnerImagePath: path})
      })
    }
  }

  getFilePath(file) {
    return getFileDataFromURL(file)
      .then((value) => {
        return URL.createObjectURL(value);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static getDefinitionVariableName(item, isSingularPlural, paymentMethods) {
    const cases = _.get(item, "cases");

    if (!cases) {
      return [{ cases: null, variableName: item.context_variable }];
    }
    const parms = cases
      .split(",")
      .filter((caseItem) => {
        return (
          (isSingularPlural && caseItem === "singular_plural") ||
          (caseItem === "method" && paymentMethods.length > 1) ||
          caseItem === "payment"
        );
      })
      .map((caseItem) => {
        return BLOCK_CASES[caseItem].map((x) => x.key);
      });

    let variables = [];
    if (parms.length === 0) {
      return [{ cases: null, variableName: item.context_variable }];
    }
    for (let c of cartesian(parms)) {
      //Create a ordered list from a defined order for every case
      const orderedVars = _.sortBy(
        c.map((item) => {
          return { value: item, order: _.get(CASE_ORDERING, item) };
        }),
        [
          function (o) {
            return o.order;
          },
        ]
      );

      //get initial state of list and joining items on a string
      const variableSuffixes = orderedVars.map((item) => item.value).join("_");

      const variableName = `${item.context_variable}_${variableSuffixes}`;
      variables.push({ cases: c, variableName });
    }
    return variables;
  }

  renderEditableSection(item, value) {
    const variableNames = EditorSections.getDefinitionVariableName(
      item,
      this.props.isSingularPlural,
      this.props.paymentMethods
    );

    return (
      <EditableItem
        key={`editable_item_${item.label}`}
        item={item}
        value={value}
        isSingularPlural={this.props.isSingularPlural}
        paymentMethods={this.props.paymentMethods}
        variableNames={variableNames}
      />
    );
  }

  renderLogoBox(item) {
    return (
      <div key={item.label}>
        <LogoHeading>UNTERNEHMENSLOGO</LogoHeading>
        <StyledCol key={item.label} background={WHITE_COLOR} height="120px">
          {this.props.coBrandingImage && (
            <LeftLogo data-testid='left-logo'
              src={this.state.coBrandingImagePath}
              onError={(ev) => (ev.target.style.display = "none")}
            />
          )}
          {this.props.salesPartnerImage && (
            <RightLogo data-testid='right-logo'
              src={this.state.salesPartnerImagePath}
              onError={(ev) => (ev.target.style.display = "none")}
            />
          )}
        </StyledCol>
      </div>
    );
  }

  renderPlaceholderSection(item) {
    if (item.label.includes("Unternehmenslogo")) {
      return this.renderLogoBox(item);
    } else if (item.label.includes("Ort, Rechnungsdatum")) {
      return (
        <StyledHalfCol data-testid='place-date-text'
          key={item.label}
          md={6} lg={6}
          $alignRight
          className="mt-5"
        >
          {item.label}
        </StyledHalfCol>
      )
    } else if (item.label.includes("Kunden Rechnungsadresse")) {
      return (
        <StyledHalfCol data-testid='customer-invoice-address'
          key={item.label} 
          md={6} lg={6}
          className="mt-5"
        >
          {item.label}
        </StyledHalfCol>
      )
    } else if (item.label.includes("Base Design")) {
        return (
          <StyledCol data-testid='base-design-placeholder'
            key={item.label}
            $textTransform="uppercase"
            className="mt-5"
          >
            {item.label}
          </StyledCol>
        )
    } else {
      return (
        <StyledCol data-testid='standard-design-placeholder'
          key={item.label}
          className="mt-5"
        >
          {item.label}
        </StyledCol>
      )
    }
  }

  renderSections(sections) {
    return sections.map((item) => {
      const defaultValue = _.get(
        this.props.defaultValues,
        item.context_variable,
        null
      );
      if (item.editable) {
        const values = this.props.cucoData;
        const value = _.get(values, item.context_variable);
        return this.renderEditableSection(item, value, defaultValue);
      }
      return this.renderPlaceholderSection(item);
    });
  }

  renderSectionsEmail() {
    const sections = _.sortBy(this.props.sections, ["order"], ["asc"]);
    return this.renderSections(sections);
  }

  renderSectionsDocument() {
    const sections = _.chain(this.props.sections)
      .groupBy("page")
      .toPairs()
      .map((pair) => _.zipObject(["page", "data"], pair))
      .value();

    return sections.map((item) => {
      return (
        <StyledFieldset key={`page${item.page}`}>
          <StyledLegend>{`SEITE ${item.page}`}</StyledLegend>
          {this.renderSections(item.data)}
        </StyledFieldset>
      );
    });
  }

  displayCucoType(inputEngString) {
    return EDITOR_TYPES_MAP[inputEngString];
  }
  
  render() {
    const { cucoType, cucoId, paymentMethods, cucoData } = this.props;
    const isEmail = cucoType === "et" ? true : false;
    const isSend = _.get(this.props, "isSend", true);
    return (
      <>
        {isEmail && (
          <EmailMain
            cucoType={cucoType}
            cucoId={cucoId}
            isSend={isSend}
            paymentMethods={paymentMethods}
            defaultValues={getDefaultValues(cucoType, this.props.defaultValues)}
            values={getDefaultValues(cucoType, this.props.cucoData)}
            dispatch={this.props.dispatch}
            cucoData={cucoData}
          >
            {this.renderSectionsEmail()}
          </EmailMain>
        )}
        {!isEmail && (
          <DocumentMain
            cucoType={cucoType}
            cucoId={cucoId}
            paymentMethods={paymentMethods}
            defaultValues={getDefaultValues(cucoType, this.props.defaultValues)}
            values={getDefaultValues(cucoType, this.props.cucoData)}
            dispatch={this.props.dispatch}
          >
            {this.renderSectionsDocument()}
          </DocumentMain>
        )}
      </>
    );
  }
}


export default connect()(EditorSections);