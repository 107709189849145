import styled from "styled-components";
import { NO_ACTIVE_COLOR, WHITE_COLOR } from "../../../constants/css";
import {ReactComponent as Pencil} from '../../../images/icons/pencil.svg'

export const AreaHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`

export const AreaContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${WHITE_COLOR};
    padding: 0 40px 40px 40px;
`

export const StyledPencil = styled(Pencil)`
    width: 30px;
    height: 30px;
    fill: ${NO_ACTIVE_COLOR}
`
