import React from 'react';
import Row from 'react-bootstrap/Row';
import { Field } from "redux-form";
import { CUSTOMER_ADDRESSING_COMBINATION_OPTIONS } from '../../../../constants';
import InputWrapper from '../../../common/forms/InputWrapper';
import { FlexFormCol } from '../../../common/styles/FormStyles';
import SelectWrapper from '../../../common/forms/SelectWrapper';
import styled from 'styled-components';

const StyledField = styled(Field)`
    margin-left: -15px;
`

export default class CustomerApproachForm extends React.Component{

    render(){
        return(
            <Row>
                <FlexFormCol md={12} lg={12}>
                    <FlexFormCol md={6} lg={6}>
                        <StyledField
                            name="salut_definition_salutation_text_female"
                            type="text"
                            labelText="Anrede weiblich"
                            component={InputWrapper}
                            className="mt-3 mb-3 mr-3"
                        />
                        <Field
                            name="salut_definition_salutation_text_male"
                            type="text"
                            labelText="Anrede männlich"
                            component={InputWrapper}
                            className="mt-3 mb-3 mr-3"
                        />
                        <Field
                            name="salut_definition_salutation_text_divers"
                            type="text"
                            labelText="Anrede divers"
                            component={InputWrapper}
                            className="mt-3 mb-3 mr-3"
                        />
                        <Field
                            name="salut_definition_salutation_text_plural"
                            type="text"
                            labelText="Anrede Eheleute"
                            component={InputWrapper}
                            className="mt-3 mb-3"
                        />
                    </FlexFormCol>
                    <Field
                        name="salut_definition_salutation_text_is_formal"
                        labelText="Kundenansprache"
                        component={SelectWrapper}
                        optionsList={CUSTOMER_ADDRESSING_COMBINATION_OPTIONS}
                        className="mt-3 mb-3"
                    />
                </FlexFormCol>
            </Row>
        )
    }
};
