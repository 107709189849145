import React, { useState } from "react";
import { Field, change, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import { SwitchContainer } from "../../common/styles/EditorSections";
import { Switcher } from "../../Switcher/Switcher";
import InputWrapper from "../../common/forms/InputWrapper";
import AttachmentsUploader from "../../common/forms/AttachmentsUploader";
import { StyledHeadingBox } from "../../common/styles/EditorSections";
import { OuterFormColLeft, OuterFormColRight } from "../../common/styles/FormStyles";
import Accordion from "react-bootstrap/Accordion";
import AccordionItem from "../../common/lib/Accordion";
import { ReactComponent as Attachment } from "../../../images/icons/attachment.svg"
import { ReactComponent as AttachmentWhite } from "../../../images/icons/attachment_white.svg"
import styled from "styled-components";
import { registerExtraFields } from "../../../utils/editorUtils";

const StyledAccordion = styled(Accordion)`
  margin-bottom: 2%;
`

const ACTIVE_TEXT = (
  <>
    <b>Ja</b>, der E-Mail Versand für diesen E-Mail Type soll aktiviert werden.
  </>
);

const INACTIVE_TEXT = (
  <>
    <b>Nein</b>, der E-Mail Versand für diesen E-Mail Type soll deaktiviert werden.
  </>
);

const EMAIL_DEFAULT_VALUES = [];



const validateEmailSubject = (value) => {
    // Regex below only allows brackets in the format {number} --> e.g. {0}
    // For better understanding checkout negative lookbehind and negative lookahead Regex
    const regex = /\{(?!\d\})|(?<!\{\d)\}/
    if(value?.search(regex) !== -1) {
      return 'Im E-Mail Betreff dürfen nur Textvariablen wie z.B. {0} verwendet werden.'
    }
}

const EmailMain = (props) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  
  const onCheckBoxChange = () => {
    props.dispatch(change("editorForm", `is_sent`, !props.isSent));
  }

  const handleToggle = (expanded) =>  {
    setIsAccordionExpanded(expanded);
  }

  registerExtraFields(
    EMAIL_DEFAULT_VALUES,
    props.values,
    props.defaultValues,
    props.dispatch,
  )

    const attachmentIcon = isAccordionExpanded ? <Attachment style={{ verticalAlign: "text-top" }} /> : <AttachmentWhite style={{ verticalAlign: "text-top" }} />;
    const {filesCount} = props 
    const attachmentsTitle = (<><span>ANHANG </span> {attachmentIcon} {filesCount}</>)

    return (
      <>
        <SwitchContainer>
          <Field
            name="is_sent"
            switchId={1}
            component={Switcher}
            handleChange={onCheckBoxChange}
            isChecked={props.isSent}
            className="mb-4"
            activeText={ACTIVE_TEXT}
            inActiveText={INACTIVE_TEXT}
            positionText="left"
          />
        </SwitchContainer>
        <fieldset>
          <StyledHeadingBox>ABSENDER / EMPFÄNGER</StyledHeadingBox>
          <Row>
            <OuterFormColLeft md={6} lg={6}>
              <Field
                name="from"
                type="text"
                labelText="Absender"
                component={InputWrapper}
                className="mt-4 mb-5"
                disabled
              />
            </OuterFormColLeft>
            <OuterFormColRight md={6} lg={6}>
              <Field
                name="bcc"
                type="text"
                labelText="BCC"
                component={InputWrapper}
                className="mt-4 mb-5"
                disabled
              />
            </OuterFormColRight>
          </Row>
        </fieldset>
        <fieldset>
          <StyledAccordion>
              <AccordionItem 
                onAccordionToggle={handleToggle}
                title={attachmentsTitle}
                >
                  <AttachmentsUploader dispatch={props.dispatch}/>
              </AccordionItem>
          </StyledAccordion>
        </fieldset>
        <fieldset>
          <StyledHeadingBox>INHALTE DER E-MAIL</StyledHeadingBox>
          <Field
            name="subject"
            type="text"
            labelText="E-Mail Betreff"
            component={InputWrapper}
            className="mt-4 mb-5"
            validate={validateEmailSubject}
          />
          <Field
            name="definition_title"
            type="text"
            labelText="E-Mail Name"
            component={InputWrapper}
            className="mb-5"
          />
          {props.children}
        </fieldset>
      </>
    );
  }

function mapStateToProps(state) {
  const values = getFormValues('editorForm')(state);  
  const filesCount =  values?.attachments?.filter(item => item !== "").length || 0;
  return {
    filesCount,
    isSent: values?.is_sent
  };
}

export default connect(mapStateToProps)(EmailMain);
