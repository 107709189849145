import _ from "lodash";
import {parseStrToBool} from '../utils/commonUtils';

export default class ProductDefinitionValues {
  product_name = "";
  display_name = "";
  product_description = "";
  is_for_business = "";
  product_category = "";
  is_sonstige_direktvermarktung = "";
  payment_model = "";
  consumption_price_model = "";
  salespartner_id = "";
  salespartner_name = "";
  period_billing_interval = "";
  step_progress = 1;

  setValuesFromForm(formValues, isProducer=false) {
    Object.assign(this, _.pick(formValues, Object.keys(this)));
    if (isProducer) {
      this.is_for_business = false;
      this.product_category = 'producer';
    }
    return this.toJson();
  }

  toJson() {
    this.is_for_business = parseStrToBool(this.is_for_business);
    this.is_sonstige_direktvermarktung = parseStrToBool(this.is_sonstige_direktvermarktung);
    const retObj = _.pickBy(this, (value) => {
      return value !== "" && value !== null;
    });
    return _(retObj).value();
  }

  getKeys() {
    return Object.keys(this);
  }

  setFormValues(podObject) {
    return this.setValuesFromForm(podObject, podObject['is_producer']);
  }
}
