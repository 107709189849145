import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { change, Field, reduxForm, unregisterField } from "redux-form";

import {
  PRODUCT_CATEGORY_FIELD_OPTIONS,
  PRODUCT_IS_BUSINESS_FIELD_OPTIONS,
  PRODUCT_TARIF_TYPE_FIELD_OPTIONS,
  DIRECT_MARKETING_OPTIONS,
  PAYMENT_MODEL_OPTIONS,
  PRODUCT_DEFINTION_FIELD_NAMES
} from '../../../constants';
import FormNavigation from '../../common/FormNavigation';
import { OuterFormColLeft, OuterFormColRight, StyledExplanation } from "../../common/styles/FormStyles";
import { combine, length, required } from 'redux-form-validators'
import TextAreaWrapper from '../../common/forms/TextAreaWrapper';
import SelectWrapper from '../../common/forms/SelectWrapper';
import InputWrapper from '../../common/forms/InputWrapper';
import { compose } from 'redux';
import { ReactComponent as Info } from "../../../images/icons/INfo.svg";

function getSalespartnerList(spObj) {
  const subSp = _.get(spObj, 'subsalespartners', [])
    .map(item => { return { label: item.company_name, value: item.spid } });
  return [{ label: spObj.company_name, value: spObj.spid }, ...subSp]
}

class ProductDefinitionForm extends React.Component {
  getSalespartnerChoices() {
    return getSalespartnerList(this.props.salespartner);
  }

  onPriceModelChange(value) {
    let priceCalculationMethod

    switch (value) {
      case 'spot':
        priceCalculationMethod = `combi&&wp:spot_price&&bf:`
        break
      case 'single_step':
        priceCalculationMethod = `combi&&wp:&&bf:`
        break
      default:
        priceCalculationMethod = `combi&&wp:&&bf:`
    }

    // Should not dispatch pc_method in the process of creating a new product
    if (this.props.isExistingProduct) {
      this.props.dispatch(change('productDefinitionForm', 'pc_method', priceCalculationMethod));
    }
  }

  /*  
      The code in componentWillUnmount is a fix. 
      After loading a product offer definition, there was always 
      sync errors for some fields in ProductDefintitionForm
      This error prevented clicking on "speichern und weiter" on the tabs 
      "Base Design" and "Rechnungen & Dokumente".
  */
  componentWillUnmount() {
    PRODUCT_DEFINTION_FIELD_NAMES.forEach((name) => {
      this.props.dispatch(unregisterField("productDefinitionForm", name))
    })
  }

  render() {
    return (
      // handleSubmit needed for local validation of fields
      <Form onSubmit={this.props.handleSubmit(() => undefined)}>
        <StyledExplanation>
          Im ersten Schritt können Sie die grundlegende Definition des
          Stromproduktes festlegen.
        </StyledExplanation>
        <Row>
          <OuterFormColLeft md={6} lg={6}>
            <Field
              name="owner"
              labelText="Salespartner"
              component={SelectWrapper}
              optionsList={this.getSalespartnerChoices()}
              validate={required()}
              disabled={this.props.disabled}
              className="mt-5"
            />
            <Field
              name="display_name"
              component={InputWrapper}
              type="text"
              labelText="Display-Produktname"
              className="mt-5"
              validate={required()}
              disabled={this.props.disabled}
            />
            <Field
              name="product_description"
              labelText="Produktbeschreibung"
              component={TextAreaWrapper}
              placeholder="Bitte beschreiben Sie das Produkt verständlich für den Kunden"
              maxLength="900"
              disabled={this.props.disabled}
              className="mt-5"
              height="228px"
              infoIcon={<Info />}
              infoIconTooltip="Die Produktbeschreibung dient internen Dokumentationszwecken."
              validate={combine(
                required(),
                length({
                  max: 128,
                  message: "Der Text ist zu lang. Es können maximal 128 Zeichen (inklusive Leerzeichen) verwendet werden.",
                })
              )}
            />
          </OuterFormColLeft>
          <OuterFormColRight md={6} lg={6}>
            {!this.props.isProducer ?
              <>
                <Field
                  name="is_for_business"
                  labelText="Zielgruppe"
                  component={SelectWrapper}
                  optionsList={PRODUCT_IS_BUSINESS_FIELD_OPTIONS}
                  validate={required()}
                  disabled={this.props.disabled}
                  className="mt-5"
                  infoIcon={<Info />}
                  infoIconTooltip="Haushaltskunden schließen ihren Stromvertrag mit brutto Preisen ab. Verträge mit Gewerbekunden basieren auf netto Preisen. Für Gewerbekunden ist die Ausweisung der USt. auf Rechnungsdokumenten speziell zugeschnitten."
                />
                <Field
                  name="product_category"
                  labelText="Art des Stromanschlusses"
                  component={SelectWrapper}
                  optionsList={PRODUCT_CATEGORY_FIELD_OPTIONS}
                  validate={required()}
                  disabled={this.props.disabled}
                  className="mt-5"
                  infoIcon={<Info />}
                  infoIconTooltip="Die Auswahl des Stromverbrauchers beeinflusst die dem Stromtarif zugrundeliegenden Netzentgelte, gesetzliche Umlagen und Erwartungen an Messsgeräte."
                />
                <Field
                  name="consumption_price_model"
                  labelText="Tarifart"
                  component={SelectWrapper}
                  optionsList={PRODUCT_TARIF_TYPE_FIELD_OPTIONS}
                  validate={required()}
                  disabled={this.props.disabled}
                  className="mt-5"
                  onCustomChange={this.onPriceModelChange.bind(this)}
                  infoIcon={<Info />}
                  infoIconTooltip="Die Tarifart bestimmt die Anzahl und Art der Arbeitspreise, die mit dem Stromverbrauch verrechnet werden."
                />
              </> :
              <>
                <Field
                  name="is_sonstige_direktvermarktung"
                  labelText="Art der Direktvermarktung"
                  component={SelectWrapper}
                  optionsList={DIRECT_MARKETING_OPTIONS}
                  validate={required()}
                  disabled={this.props.disabled}
                  className="mt-5"
                />
                <Field
                  name="payment_model"
                  labelText="Vergütungsmodell"
                  component={SelectWrapper}
                  optionsList={PAYMENT_MODEL_OPTIONS}
                  validate={required()}
                  disabled={this.props.disabled}
                  className="mt-5"
                  infoIcon={<Info />}
                  infoIconTooltip="Das Vergütungsmodell definiert, mit welchen Preisen der eingespeiste Strom vergütet wird."
                />
              </>}
          </OuterFormColRight>
        </Row>
        <FormNavigation
          onSaveHandler={this.props.onSaveProductOffer}
          onPreviousPageHandler={this.props.onPreviousPage}
          nextTabKey={this.props.nextTabKey}
          previousTabKey={this.props.previousTabKey}
        />
      </Form>
    );
  }
}

function mapStateToProps(state) {
  const owner = _.get(state.salespartner, 'data.spid');
  const initialValues = { ...state.productOffers.definition.formData, ...{ owner } };

  return {
    initialValues,
    salespartner: state.salespartner.data,
    baseFeeMethod: state.form?.productDefinitionForm?.values?.combi_bf_method
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: "productDefinitionForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    touchOnChange: true
  })
)(ProductDefinitionForm)
