import React, { useEffect, useState } from "react";
import { FormHeading } from "./FormHeading";
import { AreaContent, AreaHeading, StyledPencil } from "../styles/ExpandableArea";

const ExpandableArea = (props) => {
    const {triggerCloseContent, Icon, title, children} = props
    const [expanded, setExpanded] = useState(false)
    const handleEditClick = () => { 
        setExpanded(!expanded)
    }

    useEffect(() => {
        setExpanded(false)
    },[triggerCloseContent])

    return (
        <>
            <AreaHeading
                onClick={handleEditClick}
            >
                <FormHeading
                    Icon={Icon}
                >
                    {title}
                </FormHeading>
                <StyledPencil/>
            </AreaHeading>
            {expanded
              ?
                <AreaContent>
                    {children}
                </AreaContent>
              :
              null
            }
        </>
    )
}

export { ExpandableArea }
