import React from "react";
import { Container, ErrorMessage } from "../styles/InputStyles";
import { TextAreaContainer, LabelText, TextAreaElement } from "../styles/TextArea";
import { TooltipContainer, TooltipIcon, TooltipText } from "../styles/TooltipIcon";

class TextArea extends React.Component {
    checkIsError() {
        const { touched, error , serverError} = this.props
        return touched && (error || serverError)
    }

    render() {
        return (
            <Container>
                <TextAreaContainer className={this.props.className}>
                    <LabelText 
                        colorLabel={this.props.colorLabel}
                    >
                        {this.props.labelText}
                        {this.props.infoIcon && 
                            <TooltipContainer>
                                <TooltipIcon tooltipMarginLeft={this.props.tooltipMarginLeft || "10px"}>
                                    {this.props.infoIcon}
                                </TooltipIcon>
                                <TooltipText>
                                    {this.props.infoIconTooltip}
                                </TooltipText>
                            </TooltipContainer>
                        }
                    </LabelText>
                    <TextAreaElement
                        data-testid='textarea-element'
                        value={this.props.value}
                        onChange={this.props.onChange}
                        height={this.props.height}
                        colorText={this.props.colorText}
                        placeholder={this.props.placeholder}
                        borderColor={this.props.borderColor}
                        maxLength={this.props.maxLength}
                        border={this.props.border}
                        disabled={this.props.disabled}
                        resize={this.props.resize}
                    />
                </TextAreaContainer>
                {this.checkIsError() &&
                    <ErrorMessage errorColor={this.props.errorColor}>
                        {this.props.error || this.props.serverError}
                    </ErrorMessage>
                }
            </Container>
        )
    }
}

export default TextArea
