import React from 'react';
import { connect} from 'react-redux'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Field} from "redux-form";

import {COMPANY_TYPE_OPTIONS } from '../../../../constants';
import InputWrapper from '../../../common/forms/InputWrapper';
import SelectWrapper from '../../../common/forms/SelectWrapper';
import { FlexFormCol, OuterFormColLeft, OuterFormColRight } from '../../../common/styles/FormStyles';
import styled from 'styled-components';

const StyledField = styled(Field)`
    margin-left: -15px;
    margin-right: -15px;
`

export class ContactInformationForm extends React.Component{
    renderCeoValues(){
        return(
            <Row className="mt-5">
                <Col md={6} lg={6}>

                </Col>
            </Row>
        )
    }

    renderCharimanValues(){
        return(
            <Row className="mt-5">
                <Col md={6} lg={6}>

                </Col>
                <Col md={6} lg={6}>

                </Col>
            </Row>
        )
    }

    render(){
        return(
            <Row>
                <OuterFormColLeft md={6} lg={6}>
                    <Field
                        name="prv_provider_name"
                        type="text"
                        labelText="Displayname des Unternehmens"
                        component={InputWrapper}
                        className="mt-3"
                    />
                    <Field
                        name="prv_provider_zipcode"
                        type="text"
                        labelText="PLZ"
                        component={InputWrapper}
                        className="mt-5"
                    />
                    <Field
                        name="prv_provider_commercial_register"
                        type="text"
                        labelText="Handelsregister (HRB)"
                        component={InputWrapper}
                        className="mt-5"
                    />
                    <Field
                        name="prv_provider_vat_number"
                        type="text"
                        labelText="Umsatzsteuer-ID-Nummer"
                        component={InputWrapper}
                        className="mt-5"
                    />
                    {this.props.companyType === "gmbh" &&
                        <Field
                            name="prv_provider_ceo"
                            type="text"
                            labelText="Name Geschäftsführer*innen"
                            component={InputWrapper}
                            className="mt-5"
                        />
                    }
                    {this.props.companyType === "ag" &&
                        <Field
                            name="prv_provider_chairman"
                            type="text"
                            labelText="Name des Vorsitzenden des Aufsichtsrats"
                            component={InputWrapper}
                            className="mt-5"
                        />
                    }
                    <Field
                        name="prv_provider_phone"
                        type="text"
                        labelText="Telefonnummer Kundenservice"
                        component={InputWrapper}
                        className="mt-5"
                    />
                    <Field
                        name="prv_provider_fax"
                        type="text"
                        labelText="Faxnummer Kundenservice"
                        component={InputWrapper}
                        className="mt-5"
                    />
                    <Field
                        name="prv_provider_bank"
                        type="text"
                        labelText="Bank"
                        component={InputWrapper}
                        className="mt-5 mb-5"
                    />
                </OuterFormColLeft>
                <OuterFormColRight md={6} lg={6}>
                    <FlexFormCol md={12} lg={12}>
                        <StyledField
                            name="prv_provider_street"
                            type="text"
                            labelText="Strasse"
                            component={InputWrapper}
                            className="mt-3 mr-4"
                        />
                        <StyledField
                            name="prv_provider_house_no"
                            type="text"
                            labelText="Hausnummer"
                            component={InputWrapper}
                            className="mt-3"
                        />
                    </FlexFormCol>
                    <Field
                        name="prv_provider_city"
                        type="text"
                        labelText="Stadt"
                        component={InputWrapper}
                        className="mt-5"
                    />
                    <Field
                        name="prv_provider_register_court"
                        type="text"
                        labelText="Registergericht"
                        component={InputWrapper}
                        className="mt-5"
                    />
                    <Field
                        name="prv_company_type"
                        labelText="Gesellschaftsform"
                        component={SelectWrapper}
                        optionsList={COMPANY_TYPE_OPTIONS}
                        className="mt-5"
                    />
                    {this.props.companyType === "ag" &&
                        <Field
                            name="prv_provider_board"
                            type="text"
                            labelText="Namen der Vorstandsmitglieder"
                            component={InputWrapper}
                            className="mt-5"
                        />
                    }
                    <Field
                        name="prv_provider_mail"
                        type="text"
                        labelText="E-Mail-Adresse Kundenservice"
                        component={InputWrapper}
                        className="mt-5"
                    />
                    <Field
                        name="prv_provider_url"
                        type="text"
                        labelText="Website des Unternehmens"
                        component={InputWrapper}
                        className="mt-5"
                    />
                    <Field
                        name="prv_provider_iban"
                        type="text"
                        labelText="IBAN"
                        component={InputWrapper}
                        className="mt-5 mb-3"
                    />
                </OuterFormColRight>
            </Row>
        )
    }
}

function mapStateToProps(state) {
    return { 
        companyType: state.form?.productDefinitionForm?.values?.prv_company_type 
    }
}

export default connect(mapStateToProps)(ContactInformationForm);
