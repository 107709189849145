import styled from 'styled-components';
import {MAIN_COLOR, NO_ACTIVE_BG_COLOR, NO_ACTIVE_COLOR, SECONDARY_COLOR, WHITE_COLOR} from "../../../constants/css";


export const Container = styled.div`
  background: ${WHITE_COLOR};
  max-width: 1232px;
  width: 100%;
  padding: 20px 27px;
  display: flex;
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-right: 70px;

  &:last-child {
    margin-right: 0;
  }
`

export const DocumentItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;

  border-bottom-color: ${NO_ACTIVE_BG_COLOR};
  border-bottom-width: 1px;
  border-bottom-style: solid;

  &:last-of-type {
    border: none;
  }

  &:not(:first-child) {
    padding-top: 15px;
  }
`

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${SECONDARY_COLOR};
`


export const Text = styled.span`
  font-size: 18px;
  color: ${({isActive}) => isActive ? SECONDARY_COLOR : NO_ACTIVE_COLOR};
  cursor: pointer;
  
  &:hover{
    color: ${MAIN_COLOR};
    text-decoration: underline;
  }
`

export const WrapperTextIcon = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: ${({mr = '0px'}) => mr};
  align-items: flex-start;
  margin-bottom: 7px;
  max-width: ${({maxWidth = 'none'}) => maxWidth};
`

export const WrapperItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`