import React from "react";
import { ErrorMessage, Container } from "../styles/InputStyles";
import { UploadContainer, DocumentContainer, IconContainer, Text, TextButtonContainer } from "../styles/UploadDocument";

class UploadDocument extends React.Component {
    render() {
        return (
            <Container data-testid='upload-document'>
                <UploadContainer
                    flexDirection={this.props.flexDirection}
                    backgroundColor={this.props.backgroundColor}
                    isImageFile={this.props.isImageFile}
                    fileUploaded={this.props.fileUploaded}
                >
                {this.props.isImageFile && !this.props.fileUploaded &&
                    <>    
                        <IconContainer
                            colorIcon={this.props.colorIcon}
                        >
                            {this.props.icon}
                        </IconContainer>
                        <TextButtonContainer isImageFile={this.props.isImageFile}>
                            <Text
                                colorText={this.props.colorText}
                                textFontSize={this.props.textFontSize}
                                isImageFile={this.props.isImageFile}
                            >
                                {this.props.text}
                            </Text>
                            {this.props.children}
                        </TextButtonContainer>
                    </> 
                }
                {(!this.props.isImageFile || this.props.fileUploaded) &&
                    <>
                        <DocumentContainer isImageFile={this.props.isImageFile}>
                            <IconContainer
                                colorIcon={this.props.colorIcon}
                                isImageFile={this.props.isImageFile}
                            >
                                {this.props.icon}
                            </IconContainer>
                            <Text
                                widthFileText={this.props.widthFileText}
                                colorText={this.props.colorText}
                                textFontSize={this.props.textFontSize}
                            >
                                {this.props.text} 
                                {this.props.showSize && ` (${Math.round(this.props.size / 1000)} KB)`}
                            </Text>
                        </DocumentContainer>
                        {this.props.children}
                    </> 
                }
                </UploadContainer>
                {this.props.error &&
                    <ErrorMessage errorColor={this.props.errorColor}>
                        {this.props.error}
                    </ErrorMessage>
                }
            </Container>
        )
    }
}

export default UploadDocument
