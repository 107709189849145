import React from "react";
import {
    BlockStatus,
    Container,
    ContentWrapper,
    Title, TitleWrap, WrapperSwitcher,
} from "../styles/ProductItem";
import {Switcher} from "../../Switcher/Switcher";
import { BLUE_COLOR, MAIN_COLOR, NO_ACTIVE_COLOR, WHITE_COLOR } from "../../../constants/css";
import {ReactComponent as Haken} from "../../../images/icons/haken.svg";
import {ReactComponent as Entwurf} from "../../../images/icons/entwurf.svg";
import {ReactComponent as Cross} from "../../../images/icons/x.svg";

class ProductItem extends React.Component {
    getStatusColor() {
        if (this.props.status === "activated") {
            return MAIN_COLOR
        } else if (this.props.status === "draft") {
            return BLUE_COLOR
        } else {
            return NO_ACTIVE_COLOR
        }
    }

    getStatusIcon() {
        if (this.props.status === "activated") {
            return <Haken stroke={WHITE_COLOR}/>
        } else if (this.props.status === "draft") {
            return <Entwurf stroke={WHITE_COLOR}/>
        } else {
            return <Cross fill={WHITE_COLOR}/>
        }
    }

    handleSwitchChange(ev) {
        if (this.props.status === "draft") {
            this.props.handleActivateClick(ev)
        }
    }

    render() {
        return (
            <Container backgroundColor={this.props.backgroundColor}>
                <BlockStatus 
                    backgroundColorStatus={this.getStatusColor()} 
                    colorIcon={this.props.colorIcon}
                    data-testid='block-status'
                >
                    {this.getStatusIcon()}
                </BlockStatus>
                <ContentWrapper>
                    <TitleWrap>
                        <Title color={this.props.color}>{this.props.title}</Title>
                        {this.props.children[0]}
                    </TitleWrap>
                    {this.props.children[1]}
                    {this.props.children[2]}
                    {this.props.children[3]}
                    <WrapperSwitcher>
                        <Switcher
                            justifyContent={'flex-end'} 
                            positionText={'left'} 
                            switchId={this.props.productId}
                            handleChange={this.handleSwitchChange.bind(this)}
                            isChecked={this.props.status === "activated" ? true : false}
                            activeDisabled={true}
                            activeText="aktiv"
                            inActiveText="inaktiv"
                        />
                    </WrapperSwitcher>
                </ContentWrapper>
            </Container>
        )
    }
}

export default ProductItem