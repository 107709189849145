import React from 'react';
import {CONSUMER_TEXT_VARIABLES, DEFAULT_TEXT_VARIABLES, MONTHLY_CUCOS, MONTH_TEXT_VARIABLES, PRODUCER_TEXT_VARIABLES} from '../../constants';
import TextVariables from '../common/lib/TextVariables';
import {ReactComponent as TextClipboard} from "../../images/icons/textClipboard.svg";
import {ReactComponent as Info} from "../../images/icons/INfo.svg";
import { SECONDARY_COLOR } from '../../constants/css';

const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export default class SideBar extends React.Component {
    onClick(variable){
        const textVar = `{${variable.variable_name}}`;
        copyToClipboard(textVar);
    }

    sortByLabel(arr) {
        return arr.sort((a, b) => {
            if (a.label < b.label) {
                return -1
            }
            if (a.label > b.label) {
                return 1
            }
            return 0;
        })
    }

    getTextVariables() {
        const isMonthly = MONTHLY_CUCOS.some(el => el === this.props.cucoId)
        let finalTextVariables = []

        if (this.props.consumptionPriceModel === 'spot' && isMonthly) {
            finalTextVariables = [...DEFAULT_TEXT_VARIABLES, ...MONTH_TEXT_VARIABLES]
        } else if (this.props.isProducer) {
            finalTextVariables = [...DEFAULT_TEXT_VARIABLES, ...PRODUCER_TEXT_VARIABLES]
        } else {
            finalTextVariables = [...DEFAULT_TEXT_VARIABLES, ...CONSUMER_TEXT_VARIABLES]
        }
    
        return this.sortByLabel(finalTextVariables)
    }

    render(){
        return(
            <TextVariables
                icon={<TextClipboard fill={SECONDARY_COLOR} width='20px' height='20px'/>}
                iconTitle={<Info fill={SECONDARY_COLOR}/>}
                variables={this.getTextVariables()}
                onClick={this.onClick}
                tooltipForIcon='Die Textvariablen werden per Copy und Paste in die Textbausteine eingefügt. Durch einen linken Mausklick auf eine Textvariable wird diese in der Zwischenablage gespeichert und kann mit Strg+v an gewünschter Stelle eingefügt werden.'
                title='Mögliche Textvariablen'
                top='500px'
                bottom='150px'
                right='80px'
            />
        )
    }
}
