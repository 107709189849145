import React from "react";
import PropTypes from 'prop-types';
import ReactPolling from 'react-polling';

import ContentModal from './ContentModal';
import { TASK_POOLING_ENDPOINT } from "../../constants";
import { DialogContent } from "./styles/DialogWindow";
import { MAIN_COLOR, NO_ACTIVE_COLOR, SECONDARY_COLOR, SECONDARY_WHITE_COLOR } from "../../constants/css";

const POLLING_URL = `${process.env.REACT_APP_BASE_URL}${TASK_POOLING_ENDPOINT}`

export class PollerModal extends React.Component{
  onSuccess(response) {
    if (!response.complete) {
      return true
    } else {
      if(response.success === true) {
        this.props.updateStatusActivationSuccessful(response.result.id);
        this.props.handlePollingSuccess()
      }else{
        this.props.handlePollingError()
      }
    }
    // return true to continue polling
    // return false to stop polling
  }
  
  render(){
    return (
      <ContentModal
        {...this.props}
        acceptAction={this.props.acceptAction}
        cancelAction={this.props.onHide}
        onHide={this.props.onHide}
        modalIcon={this.props.modalIcon}
        getModalTitle={this.props.getModalTitle}
        startsPolling={this.props.startsPolling}
        colorFill={this.props.startsPolling ? NO_ACTIVE_COLOR : SECONDARY_COLOR}
        colorFillIcon={SECONDARY_WHITE_COLOR}
        color={this.props.startsPolling ? NO_ACTIVE_COLOR : SECONDARY_COLOR}
        cancelLabel='abbrechen'
        acceptButtonColor={MAIN_COLOR}
        acceptButtonColorText={SECONDARY_COLOR}
      >        
          {this.props.startsPolling &&
            <ReactPolling
                url={`${POLLING_URL}/${this.props.pollTaskId}`}
                interval= {5000} // in milliseconds(ms)
                onSuccess={this.onSuccess.bind(this)}
                onFailure={(err) => this.props.handlePollingError(err)}
                method={'GET'}
                render={({ isPolling }) => {
                  return (
                      <DialogContent>
                        {isPolling ? 
                        `Bitte warten, das Produkt wird ${this.props.modalProcess}` : "Fertig" }
                      </DialogContent>
                  );
                }}
            />}
      </ContentModal>
    );
  }
}

PollerModal.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool,
  acceptAction: PropTypes.func,
  icon : PropTypes.object,
  modalIcon: PropTypes.element,
  getModalTitle: PropTypes.func,
}

export default PollerModal;
