import React from "react";

import {NO_ACTIVE_COLOR, NO_ACTIVE_BG_COLOR} from "../../../constants/css";
import {StyledRadioButton, RadioButtonsContainer} from "../styles/RadioButton";

export class RadioButtons extends React.Component {
    render() {
        return (
            <RadioButtonsContainer>
                {
                    this.props.titles.map((title) => 
                        <RadioButton
                            key={title}
                            width={this.props.width}
                            height={this.props.height}
                            onClick={() => this.props.onSelectFilter(title)}
                            color={this.props.activeTab === title ? this.props.color : NO_ACTIVE_COLOR}
                            backgroundColor={this.props.activeTab === title ? this.props.backgroundColor : NO_ACTIVE_BG_COLOR}
                            title={title} 
                        />
                    )
                }
            </RadioButtonsContainer>
        )
    }
}

export class RadioButton extends React.Component {
    render() {
        return (
            <StyledRadioButton
                width={this.props.width}
                height={this.props.height}
                onClick={this.props.onClick}
                color={this.props.color}
                backgroundColor={this.props.backgroundColor}
                type='button'
            > 
                {this.props.title} 
            </StyledRadioButton>
        )
    }
}
