import React from 'react';

import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';

import IconButton from '../../../common/lib/IconButton';
import {ReactComponent as Delete} from "../../../../images/icons/delete.svg";
import {ReactComponent as Facebook} from '../../../../images/icons/facebook.svg';
import {ReactComponent as Twitter} from '../../../../images/icons/Twitter.svg';
import {ReactComponent as LinkedIn} from '../../../../images/icons/Linkedin.svg';
import {ReactComponent as Instagram} from '../../../../images/icons/Instagram.svg';
import {ReactComponent as Xing} from '../../../../images/icons/xing.svg';
import {ReactComponent as Youtube} from '../../../../images/icons/youtube.svg';
import { OuterFormColLeft } from '../../../common/styles/FormStyles';
import Button from '../../../common/styles/Button';
import { SOCIAL_MEDIA_OPTIONS } from '../../../../constants';
import { MAIN_COLOR, SECONDARY_COLOR } from '../../../../constants/css';
import InputWrapper from '../../../common/forms/InputWrapper';
import SelectWrapper from '../../../common/forms/SelectWrapper';

const StyledSocialMediaContainer = styled.div`
    display: flex;
    align-items: flex-end;
`

const SocialMediaInputs = (props) => {
    const addSocialMediaItem = () => {
        // alreadyAdded is needed for the if-check: Only if there is an option
        // selected when the user clicks the button, an item should be added
        const addedSocialMedia = props.fields.getAll()
        const alreadyAdded = addedSocialMedia.find(item => {
            return Object.keys(item)[0] === props.selectedSocialMedia
        })

        if (props.selectedSocialMedia && alreadyAdded === undefined) {
            props.fields.push({ [props.selectedSocialMedia]: '' })
        }
    }

    const renderSocialMediaInputs = () => {
        return props.fields.map((socialItem, index) => {
            const socialMediaName = Object.keys(props.fields.get(index))[0]

            const getLabelText = () => {
                return SOCIAL_MEDIA_OPTIONS.reduce((acc, cv) => {
                    if (socialMediaName === cv.value){
                        acc = cv.label
                    }
                    return acc
                }, '')
            }

            const renderIcon = (socialName) => {
                switch(socialName) {
                    case 'facebook': return <Facebook />
                    case 'twitter': return <Twitter />
                    case 'linked_in': return <LinkedIn />
                    case 'instagram': return <Instagram />
                    case 'xing': return <Xing />
                    case 'youtube': return <Youtube />
                    default: return <Facebook />
                }
            }
    
            return (
                    <Col md={6} lg={6} key={socialMediaName} className="mb-3">
                        <StyledSocialMediaContainer>
                            <IconButton
                                tooltip='löschen' 
                                positionTooltip='top'
                                width='20px'
                                m='0 40px 2px 0'
                                onClick={() => props.fields.remove(index)}
                            >
                                <Delete
                                    fill={SECONDARY_COLOR}
                                />
                            </IconButton>
                            <Field
                                labelText={getLabelText()}
                                name={`${socialItem}.${socialMediaName}`}
                                type="text"
                                component={InputWrapper}
                                icon={renderIcon(socialMediaName)}
                                placeholder={`Bitte hier die ${getLabelText()} URL eingeben`}
                                validate={required()}
                                // Below normalize is needed, because otherwise key of this input field would get completely removed 
                                // from redux form state. This only applies to fields which aren't in the initial values.
                                normalize={value => value === '' ? null : value}
                            />
                        </StyledSocialMediaContainer>
                    </Col>
            )
        })
    }

    return (
        <>
            <Row>
                <OuterFormColLeft md={6} lg={6} className="mb-3 mr-3">
                    <Field
                        name="selectSocialMediaField"
                        labelText="Social Media Plattform auswählen"
                        component={SelectWrapper}
                        optionsList={props.socialMediaOptions}
                        disabled={props.disabled}
                    />
                </OuterFormColLeft>
                <Col md={5} lg={5} className="mb-3">
                    <Button
                        type='button'
                        color={MAIN_COLOR}
                        colorText={SECONDARY_COLOR} 
                        onClick={addSocialMediaItem}
                    >
                        Plattform hinzufügen
                    </Button>
                </Col>
            </Row>
            <Row className="mb-5 pb-4" >
                {renderSocialMediaInputs()}
            </Row>
        </>
    )
}

export { SocialMediaInputs }
