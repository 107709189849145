import React from 'react';
import TextArea from '../lib/TextArea';

// To decouple the Textarea component from the Field component
class TextAreaWrapper extends React.Component {
    render(){
        return (
            <TextArea
                {...this.props}
                touched={this.props.meta.touched}
                error={this.props.meta.error}
                value={this.props.input.value}
                onChange={this.props.input.onChange}
            />
        )
    }
}

export default TextAreaWrapper
