import { createActionThunk } from 'redux-thunk-actions';
import APIRequest from '../api/ApiRequest';
import {FETCH_SALESPARTNER} from './types';

const SALESPARTNER_GET_ENDPOINT = '/api/salespartner/';

export const fetchSalespartner = (sp, token, callbackSuccess) => {
    const entryPoint = `${SALESPARTNER_GET_ENDPOINT}${sp.spid}/`;
    return (dispatch) => {
        let fetch = createActionThunk(FETCH_SALESPARTNER, () =>
        new APIRequest(token).get(entryPoint));
        let promise = dispatch(fetch());
        return promise.then((response) => {
            callbackSuccess(response.data);            
        });
    };
}
