import "./index.css"

import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"

import stores from "./stores"
import AlertContainer from "./components/common/AlertContainer"
import App from "./App"
import GlobalStyle from "./globalStyles"

const container = document.getElementById("app")
const root = createRoot(container!)

root.render(
  <Provider store={stores}>
    <BrowserRouter>
      <GlobalStyle />
      <App />
      <AlertContainer />
    </BrowserRouter>
  </Provider>,
)
