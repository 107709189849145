import React from 'react';

import PropTypes from 'prop-types';
import { RadioButtons } from '../common/lib/RadioButtons';
import { MAIN_COLOR, SECONDARY_COLOR } from '../../constants/css';

export default class ConsumerProducerTabs extends React.Component {
    render() {
      return (
        <RadioButtons
          activeTab={this.props.activeTypeTab}
          onSelectFilter={this.props.onSelectFilter}
          width='100px' 
          height='30px' 
          titles={['Verbraucher', 'Produzent']} 
          backgroundColor={MAIN_COLOR}
          color={SECONDARY_COLOR}
        />
      );
    }
  }

  ConsumerProducerTabs.propTypes = {
    activeTab: PropTypes.string,
    onSelectFilter: PropTypes.func
  }