import React from "react";
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";
import styled from 'styled-components';

import Menu from "./Menu";
import { MENU_OPTIONS, MENU_OPTIONS_SP } from '../../constants';

export const FluidContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  min-height: 100vh;
`

class Layout extends React.Component {
  getMenuOptions(user=null){
    if(user === null)
      return [];
    if(user.isSalespartnerUser){
      return MENU_OPTIONS_SP;
    }

    return MENU_OPTIONS;
  }

  render() {
    const {user} = this.props;

    return (
      <>
        <FluidContainer fluid>
            <Menu
              menu={this.getMenuOptions(user)}  
              {...this.props}
            />
            {this.props.children}
          </FluidContainer>
      </>
    );
  }
}

Layout.propTypes = {
  menu: PropTypes.array
};

export default Layout;
