import { Col } from "react-bootstrap";
import styled from "styled-components";
import {SECONDARY_WHITE_COLOR, SECONDARY_COLOR} from "../../../constants/css";
import WindTurbines from "../../../images/background/pc_intro_pic.jpg";

export const ImageCol = styled(Col)`
  background-image: url(${WindTurbines});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
`

export const StartPageHeading = styled.h1`
  color: ${SECONDARY_WHITE_COLOR};
  font-size: 64px;
  margin-bottom: 60px;
`

export const StartPageSubHeading = styled.h3`
  color: ${SECONDARY_WHITE_COLOR};
  font-size: 40px;
  margin-bottom: 70px;
`

export const StartPageContentCol = styled(Col)`
  background: ${SECONDARY_COLOR};
  padding-top: 170px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
`

export const LumenazaLogo = styled.img`
  width: 190px;
  padding-top: 30px;
  padding-left: 30px;
`