import styled from "styled-components";
import {MAIN_COLOR, NO_ACTIVE_BG_COLOR, SECONDARY_COLOR, WHITE_COLOR} from "../../../constants/css";

export const Container = styled.div`
  width: 100%;
  background: ${({backgroundColor = WHITE_COLOR}) => backgroundColor};
  display: flex;
`


export const BlockStatus = styled.div`
  background: ${({backgroundColorStatus = MAIN_COLOR}) => backgroundColorStatus};
  height: inherit;
  max-width: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  fill: ${({colorIcon = WHITE_COLOR}) => colorIcon};

  svg {
    width: 25px;
    height: 25px;
  }
`

export const ContentWrapper = styled.div`
  padding: 5px 20px;
  width: 100%;
  position: relative;
`

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.span`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${({color = SECONDARY_COLOR}) => color};
`

export const Toggles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LeftBlock = styled.div`
  width: 100%;
  max-width: 410px;
  display: inline-block;
  border-right-color: ${NO_ACTIVE_BG_COLOR};
  border-right-width: 1px;
  border-right-style: solid;
  @media screen and (max-width: 1300px) {
    max-width: 250px;
  }
  margin-bottom: 10px;
`

export const ProductItemText = styled.p`
  font-size: 16px;
  color: ${({color = SECONDARY_COLOR}) => color};
  word-break: break-word;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const BoldTextWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
`

export const BoldText = styled.p`
  font-weight: bold;
  margin-bottom: 10px;
  color: ${({color = SECONDARY_COLOR}) => color};

  &:last-child {
    margin-bottom: 0;
  }
`

export const WrapperSwitcher = styled.div`
  position: absolute;
  bottom: 12px;
  right: 20px;
`

export const RightBlock = styled(LeftBlock)`
  border: none;
  padding-left: 20px;
  max-width: 250px;
  margin-right: 15px;
  vertical-align: top;
`