import styled, {css} from "styled-components";
import {MAIN_COLOR, NO_ACTIVE_COLOR, SECONDARY_COLOR, WHITE_COLOR, SECONDARY_WHITE_COLOR} from "../../../constants/css";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({m}) => m ? m.split(' ').join(' ') : null};
  padding: ${({p}) => p ? p.split(' ').join(' ') : null};
`

export const hoverIcon = css`
  transition: .2s ease-in;
  opacity: 1;
  visibility: visible;
`

export const positionTriangle = css`
  ${({positionTooltip}) => positionTooltip === 'top'
          ? css`
            bottom: 0;
            transform: translate(50%, 100%);
            border-width: 5px 5px 0 5px;
            border-color: ${({backgroundTooltip}) => backgroundTooltip ?? SECONDARY_COLOR} transparent transparent transparent;
          `
          : css`
            top: 0;
            transform: translate(50%, -100%);
            border-width: 0 5px 5px 5px;
            border-color: transparent transparent ${({backgroundTooltip}) => backgroundTooltip ?? SECONDARY_COLOR} transparent;
          `
  }
`

export const positionTooltip = css`
  ${({positionTooltip}) => positionTooltip === 'top'
          ? css`
            top: -3px;
            transform: translate(-50%, -100%);
          `
          : css`
            bottom: -3px;
            transform: translate(-50%, 100%);
          `
  }
`

export const Tooltip = styled.div`
  ${({tooltip}) => tooltip && css`
    position: absolute;
    color: ${({colorTooltip}) => colorTooltip ?? SECONDARY_WHITE_COLOR};
    font-size: 12px;
    padding: 3px 10px;
    background-color: ${({backgroundTooltip}) => backgroundTooltip ?? SECONDARY_COLOR};
    visibility: hidden;
    opacity: 0;
    left: 50%;
    white-space: nowrap;

    ${positionTooltip}
      // It is required for animation tooltip
    ${({isHover}) => isHover && hoverIcon}
    :after {
      content: '';
      position: absolute;
      right: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      ${positionTriangle}
    }
  `}
`

export const IconButtonContainer = styled.div`
  position: relative;
  width: ${({isLarge, width = '30px'}) => isLarge ? '40px' : width};
  height: ${({isLarge, height = '30px'}) => isLarge ? '40px' : height};
  cursor: ${({isStaticButton, disabled}) => (isStaticButton || disabled) ? 'default': 'pointer'};
  fill: ${NO_ACTIVE_COLOR};

  ${({isHover}) => isHover && css`
    fill: ${SECONDARY_COLOR};
  `}

  //This is required for icons with backgrounds.
  ${({isFill}) => isFill && css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({colorFill}) => colorFill ?? MAIN_COLOR};
    border-radius: 50%;
    padding: ${({isLarge}) => isLarge ? '10px' : '5px'};
    fill: ${({colorFillIcon}) => colorFillIcon ?? SECONDARY_COLOR};

    ${({isHover}) => isHover && css`
    background-color: ${({colorFillHover}) => colorFillHover ?? WHITE_COLOR};
    fill: ${({colorFill}) => colorFill ?? MAIN_COLOR};
    border: 1px solid ${({colorBorder}) => colorBorder ?? MAIN_COLOR};
    `}
  `}

`

export const Title = styled.div`
  font-size: 18px;
  margin: 0 11px;
  color: ${({colorText}) => colorText ?? SECONDARY_COLOR};
  cursor: pointer;
  white-space: nowrap;

  ${({isHover}) => isHover && css`
    color: ${MAIN_COLOR};
  `}
}
`
