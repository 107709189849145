import React from "react";
import { setSubmitFailed, touch } from "redux-form";
import requireAuth from "../auth/requireAuth";
import { runValidation, getFormData } from "../../utils/wizardDataConverter";
import FormWizard from "./FormWizard";
import stepFormHoc from "./stepFormHoc";
import _ from 'lodash';
import {toast} from 'react-toastify';
import {checkStatus} from "../../utils/commonUtils";
import PropTypes from "prop-types";
import Heading from '../common/lib/Heading';
import {ReactComponent as Entwurf} from '../../images/icons/entwurf.svg';
import {ReactComponent as CheckCircle} from '../../images/icons/check_circle.svg';
import IconButton from '../common/lib/IconButton';
import { PageHeadingWrapper } from "../common/styles/PageHeading";
import { BLUE_COLOR, MAIN_COLOR } from "../../constants/css";
import Layout from "../common/Layout";
import { PRODUCT_OFFER_STEPS } from "../../constants";

const callbackNoReload = () => {
  toast('Die Definition wurde erfolgreich aktualisiert', {type: 'success'});
}

const getValueByKey = (object, value) => {
  const keys = Object.keys(object);
  return keys.find(key => object[key] === value);
}

const updateStepProgress = (tabKey, currentStepProgress) => {
  const currentStep = Number(getValueByKey(PRODUCT_OFFER_STEPS, tabKey));

  if (currentStepProgress > currentStep) {
    return currentStepProgress;
  }

  return currentStep + 1;
}


class ProductOfferEditForm extends React.Component {

  componentDidMount() {
        this.callFetchFunction();
  }

  callFetchFunction() {
    const id = this.props.match.params.pk;
    const token = this.props.token;
    const callback = (data) => {localStorage.setItem("activePod", JSON.stringify(data));}

    this.props.retrieveProductOffer(token, id, callback);
  }

  async validateAndSubmitData(status, callback, errorsToIgnore) {
    const errors = runValidation(
      this.props.formCreateDefinitionWizard.registeredFields,
      _.get(this.props.formCreateDefinitionWizard, 'syncErrors', {}),
      errorsToIgnore
    );
    if (errors.length > 0) {
      this.props.dispatch(touch("productDefinitionForm", ...errors));
      this.props.dispatch(setSubmitFailed("productDefinitionForm", ...errors));
    } else {
      let newStepProgress = this.props.stepProgress;
      const saveAllFieldsAndProceed = errorsToIgnore.length === 0;
      if (saveAllFieldsAndProceed) {
        newStepProgress = updateStepProgress(this.props.selectedTabKey, this.props.stepProgress);
      }

      let form = await getFormData(
        status,
        this.props.formCreateDefinitionWizard,
        this.props.isProducerEdit,
        this.props.definition,
        newStepProgress
      );
      const errCallback  = () =>
          toast('Bei der Aktualisierung der Definition ist ein Fehler aufgetreten', {type: 'error'});


      return this.props.updateProductOffer(
          this.props.token,
          this.props.definition.id,
          form,
          callback,
          errCallback
      );
    }
  }

  onFormSubmit(nextTabKey, redirectToDashboard) {
    let callback
    let errorsToIgnore
    const status = this.props.definition.status

    if (redirectToDashboard) {
      callback = () => {
        toast('Die Definition wurde erfolgreich aktualisiert', {type: 'success'});
        this.props.history.push('/dashboard')
      }
      // When user clicks on "Änderungen speichern", it should be possible to save 
      // the filled out fields. For that we have to ignore the required validation.
      errorsToIgnore = ['is required']
    } else {
      callback = id => {
          toast('Die Definition wurde erfolgreich aktualisiert', {type: 'success'});
          this.props.history.push({ pathname: "/empty" });
          this.props.history.replace({ pathname: `/configurator/${id}` });
          this.props.onNextPage(nextTabKey);
      }
      errorsToIgnore = []
    }
    
    this.validateAndSubmitData(status, callback, errorsToIgnore);
  }

  onActivate() {
    this.validateAndSubmitData("activated");
  }

  onFormSaveUpdate(){
    let status = this.props.definition.status
    this.validateAndSubmitData(status, callbackNoReload)
  }

  render() {
    const displayTitle =_.get(this.props.definition && this.props.definition.product_offer_characteristics,'display_name', null);

    return (
      <Layout
        {...this.props}
        onSaveHandler={this.onFormSubmit.bind(this)}
      >
        <PageHeadingWrapper>
          <Heading title={displayTitle}>
            {this.props.definition.status === 'draft' &&
              <IconButton isStaticButton={true}>
                <Entwurf stroke={BLUE_COLOR}/>
              </IconButton>
            }
            {this.props.definition.status === 'activated' &&
              <IconButton isStaticButton={true}>
                <CheckCircle width="30px" fill={MAIN_COLOR}/>
              </IconButton>
            }
          </Heading>
        </PageHeadingWrapper>
        <FormWizard
          isExistingProduct
          selectedTabKey={this.props.selectedTabKey}
          isProducer={this.props.isProducerEdit}
          page={this.props.page}
          checkTabIsActive={this.props.checkTabIsActive}
          onTabSelected={this.props.onTabSelected}
          onPreviousPage={this.props.onPreviousPage}
          onFormSubmit={this.onFormSubmit.bind(this)}
          onFormSaveUpdate={this.onFormSaveUpdate.bind(this)}
          disabled={checkStatus(this.props.definition.status)}
          token={this.props.auth.authenticate}
        />
      </Layout>
    );
  }
}

ProductOfferEditForm.propTypes = {
  token: PropTypes.string,
}

const ProductOfferEditFormWithSteps = stepFormHoc(ProductOfferEditForm, true);

export default requireAuth(ProductOfferEditFormWithSteps, 'Configurator');
