import React from 'react';
import {ReactComponent as Mail} from "../../../images/icons/mail_inactive.svg";
import {ReactComponent as Pencil} from "../../../images/icons/pencil.svg";
import {Container, DocumentItem, Text, Title, Wrapper, WrapperItems, WrapperTextIcon} from "../styles/DocumentForm";
import IconButton from "./IconButton";


class DocumentForm extends React.Component {
    render() {
        return (
            <Container>

                <Wrapper>

                    <DocumentItem>
                        <WrapperTextIcon mr={'25px'}>
                            <Title> Abschlagsrechnung</Title>
                            <IconButton width={'25px'} height={'25px'} tooltip={'Mail'} positionTooltip={'top'}>
                                <Mail/>
                            </IconButton>
                        </WrapperTextIcon>
                        <WrapperItems>
                            <WrapperTextIcon>
                                <Text>E-Mail</Text>
                                <IconButton width={'25px'} height={'25px'} tooltip={'Pencil'} positionTooltip={'top'}>
                                    <Pencil/>
                                </IconButton>
                            </WrapperTextIcon>

                            <WrapperTextIcon>
                                <Text>PDF</Text>
                                <IconButton width={'25px'} height={'25px'} tooltip={'Pencil'} positionTooltip={'top'}>
                                    <Pencil/>
                                </IconButton>
                            </WrapperTextIcon>

                        </WrapperItems>
                    </DocumentItem>

                    <DocumentItem>
                        <WrapperTextIcon mr={'25px'}>
                            <Title>Jahresrechnung</Title>
                            <IconButton width={'25px'} height={'25px'} tooltip={'Mail'} positionTooltip={'top'}>
                                <Mail/>
                            </IconButton>
                        </WrapperTextIcon>
                        <WrapperItems>
                            <WrapperTextIcon>
                                <Text>E-Mail</Text>
                                <IconButton width={'25px'} height={'25px'} tooltip={'Pencil'} positionTooltip={'top'}>
                                    <Pencil/>
                                </IconButton>
                            </WrapperTextIcon>

                            <WrapperTextIcon>
                                <Text>PDF</Text>
                                <IconButton width={'25px'} height={'25px'} tooltip={'Pencil'} positionTooltip={'top'}>
                                    <Pencil/>
                                </IconButton>
                            </WrapperTextIcon>

                        </WrapperItems>
                    </DocumentItem>

                    <DocumentItem>
                        <WrapperTextIcon mr={'25px'}>
                            <Title> Endabrechnung</Title>
                            <IconButton width={'25px'} height={'25px'} tooltip={'Mail'} positionTooltip={'top'}>
                                <Mail/>
                            </IconButton>
                        </WrapperTextIcon>

                        <WrapperItems>
                            <WrapperTextIcon>
                                <Text>E-Mail</Text>
                                <IconButton width={'25px'} height={'25px'} tooltip={'Pencil'} positionTooltip={'top'}>
                                    <Pencil/>
                                </IconButton>
                            </WrapperTextIcon>

                            <WrapperTextIcon>
                                <Text isActive>PDF erstellen</Text>
                                <IconButton width={'25px'} height={'25px'} tooltip={'Pencil'} positionTooltip={'top'}>
                                    <Pencil/>
                                </IconButton>
                            </WrapperTextIcon>

                        </WrapperItems>
                    </DocumentItem>

                </Wrapper>

                <Wrapper>

                    <DocumentItem>
                        <WrapperTextIcon mr={'25px'}>
                            <Title> Abschlagsrechnung</Title>
                            <IconButton width={'25px'} height={'25px'} tooltip={'Mail'} positionTooltip={'top'}>
                                <Mail/>
                            </IconButton>
                        </WrapperTextIcon>
                        <WrapperItems>
                            <WrapperTextIcon>
                                <Text>E-Mail</Text>
                                <IconButton width={'25px'} height={'25px'} tooltip={'Pencil'} positionTooltip={'top'}>
                                    <Pencil/>
                                </IconButton>
                            </WrapperTextIcon>

                            <WrapperTextIcon>
                                <Text isActive>PDF erstellen</Text>
                                <IconButton width={'25px'} height={'25px'} tooltip={'Pencil'} positionTooltip={'top'}>
                                    <Pencil/>
                                </IconButton>
                            </WrapperTextIcon>

                        </WrapperItems>
                    </DocumentItem>

                    <DocumentItem>
                        <WrapperTextIcon mr={'25px'}>
                            <Title> Storno Jahresrechnung</Title>
                            <IconButton width={'25px'} height={'25px'} tooltip={'Mail'} positionTooltip={'top'}>
                                <Mail/>
                            </IconButton>
                        </WrapperTextIcon>
                        <WrapperItems>
                            <WrapperTextIcon>
                                <Text>E-Mail</Text>
                                <IconButton width={'25px'} height={'25px'} tooltip={'Pencil'} positionTooltip={'top'}>
                                    <Pencil/>
                                </IconButton>
                            </WrapperTextIcon>

                            <WrapperTextIcon>
                                <Text>PDF</Text>
                                <IconButton width={'25px'} height={'25px'} tooltip={'Pencil'} positionTooltip={'top'}>
                                    <Pencil/>
                                </IconButton>
                            </WrapperTextIcon>

                        </WrapperItems>
                    </DocumentItem>

                    <DocumentItem>
                        <WrapperTextIcon mr={'25px'}>
                            <Title> Storno Endabrechnung</Title>
                            <IconButton width={'25px'} height={'25px'} tooltip={'Mail'} positionTooltip={'top'}>
                                <Mail/>
                            </IconButton>
                        </WrapperTextIcon>
                        <WrapperItems>
                            <WrapperTextIcon>
                                <Text>E-Mail</Text>
                                <IconButton width={'25px'} height={'25px'} tooltip={'Pencil'} positionTooltip={'top'}>
                                    <Pencil/>
                                </IconButton>
                            </WrapperTextIcon>

                            <WrapperTextIcon>
                                <Text>PDF</Text>
                                <IconButton width={'25px'} height={'25px'} tooltip={'Pencil'} positionTooltip={'top'}>
                                    <Pencil/>
                                </IconButton>
                            </WrapperTextIcon>

                        </WrapperItems>
                    </DocumentItem>

                </Wrapper>




            </Container>
        )
    }

}

export default DocumentForm;