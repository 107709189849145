import React from "react";
import {Container, IconButtonContainer, Title, Tooltip} from "../styles/IconButton";

class IconButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isHover: false}
    }

    onMouseEnter() {
        this.setState({isHover: true})
    }

    onMouseLeave() {
        this.setState({isHover: false})
    }

    handleOnClick(event) {
        if(!this.props.disabled && this.props.onClick){
            this.props.onClick(event)
        }
    }

    render() {
        return (
            <Container 
                m={this.props.m} 
                p={this.props.p} 
                onClick={this.handleOnClick.bind(this)}
            >
                {this.props.text && this.props.positionText === 'left' &&
                <Title 
                    isHover={this.state.isHover}
                    onMouseLeave={() => this.onMouseLeave()} 
                    onMouseEnter={() => this.onMouseEnter()}
                >
                    {this.props.text}
                </Title>}
                
                <IconButtonContainer
                    width={this.props.width} 
                    height={this.props.height} 
                    className={'icon'} 
                    onMouseLeave={() => this.onMouseLeave()} 
                    onMouseEnter={() => this.onMouseEnter()}
                    isFill={this.props.isFill} 
                    isLarge={this.props.isLarge}
                    colorFill={this.props.colorFill}
                    colorBorder={this.props.colorBorder}
                    colorFillHover={this.props.colorFillHover}
                    colorFillIcon={this.props.colorFillIcon}
                    isHover={this.state.isHover}
                    isStaticButton={this.props.isStaticButton}
                    disabled={this.props.disabled}
                    data-testid='icon-button-container'
                >
                    {this.props.children}
                    {!this.props.disabled &&
                        <Tooltip 
                            backgroundTooltip={this.props.backgroundTooltip}
                            colorTooltip={this.props.colorTooltip}
                            isHover={this.state.isHover}
                            positionTooltip={this.props.positionTooltip}
                            tooltip={this.props.tooltip}
                            data-testid='icon-tooltip'
                        >
                            {this.props.tooltip}
                        </Tooltip>
                    }
                </IconButtonContainer>

                {this.props.text && this.props.positionText === 'right' &&
                <Title 
                    isHover={this.state.isHover}
                    onMouseLeave={() => this.onMouseLeave()} 
                    onMouseEnter={() => this.onMouseEnter()}
                >
                    {this.props.text}
                </Title>}
            </Container>
        )
    }
}

export default IconButton
