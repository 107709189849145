import React from "react";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import PlantTypeForm from "./PlantTypeForm";

export default class PriceCalculationProducerForm extends React.Component {
    render() {
        return (
            <FieldArray name={'supported_plant_types'} component={PlantTypeForm} disabled={this.props.disabled}/>
        );
    }
}

PriceCalculationProducerForm.propTypes = {
    disabled: PropTypes.bool
};
