import styled from "styled-components";
import {MAIN_COLOR, SECONDARY_COLOR } from "../../../constants/css";

export const SideBarContainer = styled.div`
  width: 366px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${({top}) => top};
  right: ${({right}) => right};
  bottom: ${({bottom}) => bottom};
  left: ${({left}) => left};
  padding-left: 60px;
`

export const SideBarTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const SideBarTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({colorTitle = SECONDARY_COLOR}) => colorTitle};
  margin-right: 27px;
  margin-left: 16px;
  margin-bottom: 0;
  white-space: nowrap;
`

export const SideBarItem = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 18px;
  color: ${({colorText = SECONDARY_COLOR}) => colorText};
  transition: 0.3s;
  margin: 13px 0;

  &:hover {
    background-color: ${({hoverColorText = MAIN_COLOR}) => hoverColorText};
  }
`

export const SideBarIcon = styled.div`
  margin-right: 11px;
  margin-left: 16px;
`
