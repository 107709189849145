import React from "react";
import { connect } from 'react-redux'
import {
    Field, 
    FieldArray, 
    formValueSelector, 
    change,
} from "redux-form";
import { Row, Col } from "react-bootstrap";
import { required } from "redux-form-validators";
import { 
    DISTRIBUTION_SERVICE_FEE_TYPE,
    INITIAL_DISTRIBUTION_FEE_VALUES,
    SUPPORTED_SERVICE_FEE_TYPES_OPTIONS, 
    SUPPORTED_SERVICE_FEE_UNIT_TYPES_OPTIONS,
    UNIQUE_SERVICE_FEE_UNIT_TYPE_OPTION,
} from "../../../../../constants";
import ProducerSpotServiceForm from "./ProducerSpotServiceFeeForm";
import { FlexFormCol } from "../../../../common/styles/FormStyles";
import { GRAY_COLOR, MAIN_COLOR, NO_ACTIVE_BG_COLOR, NO_ACTIVE_COLOR, SECONDARY_COLOR } from "../../../../../constants/css";
import Button from "../../../../common/styles/Button";
import AccordionItem from "../../../../common/lib/Accordion";
import styled from "styled-components";
import SelectWrapper from "../../../../common/forms/SelectWrapper";
import InputWrapper from "../../../../common/forms/InputWrapper";
import Select from "../../../../common/lib/Select";
import { getLabelText, parseStrToNumber } from "../../../../../utils/commonUtils";
import { ReactComponent as Info } from "../../../../../images/icons/INfo.svg";



const AccordionWrapper = styled.div`
    padding: 0 34px;
`

class ServiceFeeItemForm extends React.Component{
  constructor(props) {
      super(props);
      this.state = {
          selectedServiceType: null,
          emptyMatrix: false,
          availableUnitType: SUPPORTED_SERVICE_FEE_UNIT_TYPES_OPTIONS
      }
  }

  componentDidMount() {
      this.setState({ selectedServiceType: this.props.selectedServiceType.value})
  }

  componentDidUpdate(prevProps, prevState) {
      if(this.props.index === 0){
          if (this.props.minPriceNetto !== prevProps.minPriceNetto) {
              this.props.dispatch(
                  change("productDefinitionForm", `${this.props.rowName}.minimum_price_netto`, this.props.minPriceNetto)
              );
              this.setState({emptyMatrix:true});
          }
      }
      
      if(this.state.selectedServiceType !== null){
          if (this.state.selectedServiceType !== prevState.selectedServiceType) {
              if(this.state.selectedServiceType === 'matrix'){
                  this.props.dispatch(change("productDefinitionForm", `${this.props.rowName}.price_netto`, null))
                  this.setState({ availableUnitType: SUPPORTED_SERVICE_FEE_UNIT_TYPES_OPTIONS })
              }

              if(this.state.selectedServiceType === 'single_value'){
                  this.props.dispatch(change("productDefinitionForm", `${this.props.rowName}.capacity_value_matrix`, null))
                  this.setState({ availableUnitType: SUPPORTED_SERVICE_FEE_UNIT_TYPES_OPTIONS })
              }

              if(this.state.selectedServiceType === 'unique_fee'){
                this.props.dispatch(change("productDefinitionForm", `${this.props.rowName}.capacity_value_matrix`, null))
                this.setState({ availableUnitType: UNIQUE_SERVICE_FEE_UNIT_TYPE_OPTION }) 
                this.props.dispatch(change("productDefinitionForm", `${this.props.rowName}.unit`, 'ct'))
            }
          }
      }
  }

  onselectedServiceType(value) {
      this.setState({ selectedServiceType: value})
  }

  /* 
      Reason for calculateTitleNumber function: Title number of normal fees 
      should ignore entry of distribution costs. It should be e.g.:
      0. Entgelt
      1. Entgelt
      Vermarktungskosten
      2. Entgelt 
  */
  calculateTitleNumber() {
      if (!this.props.distributionCostsIndex || this.props.distributionCostsIndex > this.props.index) {
          return this.props.index + 1
      }
      return this.props.index
  }
  
  showIcon() {
    return this.state.selectedServiceType === 'unique_fee'
  }

  enableUnitDropdown() {
    return this.state.availableUnitType === UNIQUE_SERVICE_FEE_UNIT_TYPE_OPTION  || this.props.disabled
  }

  render(){
      const isFirstItem = this.props.index === 0
      const isJustCreatedItem = this.props.justCreatedAccordionIndex === this.props.fieldsLength -1
      return(
          <>
          {this.props.selectedUnit === '' ?
              <DistributionCostServiceFeeForm
                  index={this.props.index}
                  deleteHandler={this.props.deleteDistributionHandler}
                  rowName={this.props.rowName}
                  disabled={this.props.disabled}
              />
          :
              <AccordionWrapper>
                  <AccordionItem
                      title={`${this.calculateTitleNumber()}. Entgelt`}
                      withDelete={this.props.index !== 0 ? true: false}
                      index={this.props.index}
                      deleteHandler={this.props.deleteHandler}
                      expanded={isFirstItem || isJustCreatedItem}
                      marginBottom="40px"
                      contentBackground={GRAY_COLOR}
                      barBackgroundClosed={NO_ACTIVE_COLOR}
                      barBackgroundOpen={NO_ACTIVE_BG_COLOR}
                      disabled={this.props.disabled}
                  >
                      <Row >
                          <FlexFormCol md={6} lg={6}>
                              <Select
                                  labelText="Art des Entgelts"
                                  optionsList={SUPPORTED_SERVICE_FEE_TYPES_OPTIONS}
                                  className="mt-4 mr-3"
                                  onCustomChange={this.onselectedServiceType.bind(this)}
                                  defaultVal={this.props.selectedServiceType}
                                  onBlur={() => undefined}
                                  onChange={() => undefined}
                                  disabled={this.props.disabled}
                                  infoIcon={this.showIcon() && <Info/> }
                                  infoIconTooltip="Die Einmalgebühr wird auf der ersten Einspeiserechnung abgerechnet." 
                              />
                              <Field
                                  name={`${this.props.rowName}.unit`}
                                  labelText="Monetäre Einheit"
                                  component={SelectWrapper}
                                  optionsList={this.state.availableUnitType}
                                  validate={required()}
                                  width="300px"
                                  arrowMargin="6px"
                                  className="mt-4"
                                  infoIcon={!this.showIcon() && <Info/> }
                                  infoIconTooltip="Eine prozentuale Gebühr wird in der Einspeiserechnung auf die netto Gutschrift für die eingespeiste Strommenge angewandt."
                                  disabled={this.enableUnitDropdown()}
                              />
                          </FlexFormCol>
                          <Col md={6} lg={6}>
                              <Field
                                  name={`${this.props.rowName}.display_name`}
                                  component={InputWrapper}
                                  type="text"
                                  validate={required()}
                                  labelText="Display Name des Dienstleistungsentgelts"
                                  className="mt-4"
                                  disabled={this.props.disabled}
                              />
                          </Col>
                      </Row>
                      <Row>
                      <Col md={12} lg={12}>
                          {
                              this.state.selectedServiceType===null ? null:
                                  this.state.selectedServiceType === 'single_value' || this.state.selectedServiceType === 'unique_fee'
                                  ?   <SingleValueServiceFeeForm
                                          rowName={this.props.rowName}
                                          unit={getLabelText(SUPPORTED_SERVICE_FEE_UNIT_TYPES_OPTIONS, this.props.selectedUnit)}
                                          disabled={this.props.disabled}
                                      /> 
                                  
                                  :   <FieldArray
                                          withRefs={true}
                                          name={`${this.props.rowName}.capacity_value_matrix`} 
                                          component={ProducerSpotServiceForm} 
                                          props={{
                                              unit: getLabelText(SUPPORTED_SERVICE_FEE_UNIT_TYPES_OPTIONS, this.props.selectedUnit),
                                              emptyMatrix: this.state.emptyMatrix,
                                              disabled: this.props.disabled
                                          }} 
                                      />
                          }
                          </Col>
                      </Row>
                  </AccordionItem>
              </AccordionWrapper>}
          </>
      )
  }
}

const ServiceFeeItemFormConnected = connect(mapStateToProps)(ServiceFeeItemForm)

export class PriceCalculationServiceFeeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            justCreatedAccordionIndex: 0
        }
    }

    deleteServiceFee(index){
        this.props.fields.remove(index);
    }

    componentDidMount() {
        if(this.props.fields.length===0){
            this.props.fields.push()
        }
    }

    renderServiceFeeItems(){
        return this.props.fields.map((fee, index) => {
            return (
                <ServiceFeeItemFormConnected
                    key={fee}
                    minPriceNetto={this.props.minPriceNetto}
                    rowName={fee}
                    index={index}
                    deleteHandler={this.deleteServiceFee.bind(this)}
                    justCreatedAccordionIndex={this.state.justCreatedAccordionIndex}
                    fieldsLength={this.props.fields.length}
                    deleteDistributionHandler={this.deleteDistributionFee.bind(this)}
                    distributionCostsIndex={this.props.distributionCostsIndex}
                    disabled={this.props.disabled}
                />
            )
        });
    }

    addServiceFeeItem() {
        this.setState({ justCreatedAccordionIndex: this.props.fields.length })
        this.props.fields.push()
    }    
    showDistributionCosts() {
        this.props.fields.push(INITIAL_DISTRIBUTION_FEE_VALUES)
    }

    deleteDistributionFee(index) {
        this.props.fields.remove(index)
    }

    render() {
        return (
            <>
                <Row>
                    <Col md={12} lg={12}>
                        {this.renderServiceFeeItems()}
                    </Col>            
                </Row>
                <Row>
                    <Button
                        type="button"
                        color={this.props.disabled ? NO_ACTIVE_BG_COLOR : MAIN_COLOR}
                        colorText={this.props.disabled ? NO_ACTIVE_COLOR :  SECONDARY_COLOR}
                        onClick={this.addServiceFeeItem.bind(this)}
                        className="ml-5"
                        disabled={this.props.disabled}
                        hoverCursor={this.props.disabled ? 'default' : 'pointer'}
                    >
                        Entgelt hinzufügen
                    </Button>
                    {!this.props.distributionCostsIndex && 
                        <Button
                            type="button"
                            color={this.props.disabled ? NO_ACTIVE_BG_COLOR : MAIN_COLOR}
                            colorText={this.props.disabled ? NO_ACTIVE_COLOR :  SECONDARY_COLOR}
                            onClick={() => this.showDistributionCosts()}
                            className="ml-2"
                            disabled={this.props.disabled}
                            hoverCursor={this.props.disabled ? 'default' : 'pointer'}
                        >
                            Vermarktungskosten hinzufügen
                        </Button>
                    }  
                </Row>
            </>
            
        );
    }
}

class DistributionCostServiceFeeForm extends React.Component{
    render(){
        return(
            <AccordionWrapper>
                <AccordionItem
                    title="Vermarktungskosten"
                    withDelete={true}
                    index={this.props.index}
                    deleteHandler={this.props.deleteHandler}
                    expanded={true}
                    marginBottom="40px"
                    contentBackground={GRAY_COLOR}
                    barBackgroundClosed={NO_ACTIVE_COLOR}
                    barBackgroundOpen={NO_ACTIVE_BG_COLOR}
                    infoIcon={<Info/>}
                    infoIconTooltip="Vermarktungskosten werden monatlich pro Anlage durch Lumenaza berechnet. Sie setzen sich aus Rampenkosten, Ausgleichsenergiekosten und Kosten aus dem Continuous-Intra-Day zusammen."
                    tooltipMarginLeft="10px"
                    disabled={this.props.disabled}
                >
                    <Row >
                        <FlexFormCol md={6} lg={6}>
                            <Select
                                defaultVal={{ label:'Vermarktungskosten', value: "distribution_costs" }}
                                labelText="Art des Entgelts"
                                optionsList={DISTRIBUTION_SERVICE_FEE_TYPE}
                                className="mt-4 mr-3"
                                disabled={true}
                                onBlur={() => undefined}
                                onChange={() => undefined}
                            />
                        </FlexFormCol>
                        <Col md={6} lg={6}>
                            <Field
                                name={`${this.props.rowName}.display_name`}
                                validate={required()}
                                component={InputWrapper}
                                type="text"
                                labelText="Display Name des Dienstleistungsentgelts"
                                className="mt-4"
                                disabled={this.props.disabled}
                            />
                        </Col>
                    </Row>
                </AccordionItem>
            </AccordionWrapper>
        )
    }
}


class SingleValueServiceFeeForm extends React.Component{
    render(){
        return(
            <Row>
                <Col md={6} lg={6}>
                    <Field
                        name={`${this.props.rowName}.price_netto`}
                        component={InputWrapper}
                        type="number"
                        labelText="Höhe des Entgeltes"
                        validate={required()}
                        parse={parseStrToNumber}
                        unit={this.props.unit}
                        className="mt-5 mb-4"
                        disabled={this.props.disabled}
                    />
                </Col>
            </Row>
        )
    }
}

const mapOptions =   [
    { 'sign_up_fee' : 'unique_fee' },
    { 'percentage_service_fee_on_feedin_payout' : 'single_value' },
    { 'service_fee_producer' : 'single_value' },
    { 'percentage_service_fee_on_feedin_payout_producer_plant_capacity' : 'matrix' },
    { 'service_fee_producer_plant_capacity' : 'matrix' }
]

function mapStateToProps(state, ownProps){

    let selectedServiceTypeVal = 'single_value'
    const rowName = `${ownProps.rowName}`;
    const fieldName  = `${rowName}.unit`;
    const selectedUnit = selector(state, fieldName);
    const classificationField =`${rowName}.classification`;
    const classificationValue = selector(state, classificationField);
    if (typeof classificationValue === 'string') {
        const matchingOption = mapOptions.find(option => classificationValue in option)
        selectedServiceTypeVal = Object.values(matchingOption || {}).join('');
    }

    const selectedLabel = getLabelText(SUPPORTED_SERVICE_FEE_TYPES_OPTIONS, selectedServiceTypeVal)
    const selectedServiceType = {
        value: selectedServiceTypeVal,
        label: selectedLabel
    }
    
    return {
        selectedUnit,
        selectedServiceType,
    }
}

const selector = formValueSelector('productDefinitionForm');

export { ServiceFeeItemFormConnected }
export default PriceCalculationServiceFeeForm
