import React from "react";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { change, Field, FieldArray, formValueSelector } from "redux-form";
import { combine, length, required } from "redux-form-validators";
import _ from 'lodash';
import {
    ADDITIONAL_PRICE_CLASSIFICATION_OPTIONS,
} from "../../../../../../constants";
import TextAreaWrapper from "../../../../../common/forms/TextAreaWrapper";
import { OuterFormColLeft, OuterFormColRight } from "../../../../../common/styles/FormStyles";
import SelectWrapper from "../../../../../common/forms/SelectWrapper";
import InputWrapper from "../../../../../common/forms/InputWrapper";
import AccordionItem from "../../../../../common/lib/Accordion";
import { GRAY_COLOR, MAIN_COLOR, NO_ACTIVE_BG_COLOR, NO_ACTIVE_COLOR, SECONDARY_COLOR } from "../../../../../../constants/css";
import Button from "../../../../../common/styles/Button";
import { PriceCalculationBaseFeeForm } from "../PriceCalculationBaseFeeForm";
import { parseStrToNumber } from "../../../../../../utils/commonUtils";
import { CostItemInputs } from "../CostItemInputs";
import styled from 'styled-components';
import { ReactComponent as Info } from "../../../../../../images/icons/INfo.svg"

const feeLabels = {
    "distribution_cost_fee_consumer": {
        label: "Vermarktungskosten",
        header: "VERMARKTUNGSKOSTEN",
        options: [{ label: "Vermarktungskosten", value: "distribution_cost_fee_consumer" }],
        infoIconToolTip: "Vermarktungskosten werden monatlich für jeden Verbraucher anteilig durch Lumenaza berechnet. Sie setzen sich aus  Ausgleichsenergiekosten, Strukturierungskosten und Kosten aus dem Continuous-Intra-Day zusammen."

    },
    "service_fee": {
        label: "Gebühr",
        header: "WEITERE GEBÜHR",
        options: ADDITIONAL_PRICE_CLASSIFICATION_OPTIONS,
        infoIconToolTip: null
    },
    "meter_fee": {
        label: "Gebühr",
        header: "WEITERE GEBÜHR",
        options: ADDITIONAL_PRICE_CLASSIFICATION_OPTIONS,
        infoIconToolTip: null
    },
}

const StyledButton = styled(Button)`
    margin-left: 20px;
    margin-right: 30px;
`;

const selector = formValueSelector('productDefinitionForm');

export function SpotBaseFeeItem(props) {
    function showDistributionCostButton() {
        let showButton = true;
        props.fields.forEach((_, index) => {
            if (props.fees[index].classification === 'distribution_cost_fee_consumer')
                showButton = false;
        })
        return showButton
    }
    function renderAdditionalFees() {
        return props.fields.map((fee, index) => {
            const classification = _.get(props.fees[index], 'classification', "service_fee")
            const header = _.get(feeLabels, `${classification}.header`, feeLabels["service_fee"].header);
            const label = _.get(feeLabels, `${classification}.label`, feeLabels["service_fee"].label);
            const options = _.get(feeLabels, `${classification}.options`, feeLabels["service_fee"].options);
            const infoToolTip = _.get(feeLabels, `${classification}.infoIconToolTip`, null);
            return (
                <AccordionItem
                    key={fee}
                    title={header}
                    expanded={true}
                    withDelete={true}
                    index={index}
                    deleteHandler={() => { props.fields.remove(index) }}
                    contentBackground={GRAY_COLOR}
                    barBackgroundClosed={NO_ACTIVE_COLOR}
                    barBackgroundOpen={NO_ACTIVE_BG_COLOR}
                    marginBottom="40px"
                    marginTop="10px"
                    disabled={props.disabled}
                    tooltipMarginLeft="10px"
                    infoIconTooltip={infoToolTip}
                    infoIcon={infoToolTip !== null ? <Info /> : null}
                >
                    <Row>
                        <OuterFormColLeft md={6} lg={6}>
                            <Field
                                name={`${fee}.display_name`}
                                component={InputWrapper}
                                type="text"
                                labelText={`Display Name der ${label}`}
                                validate={required()}
                                disabled={props.disabled}
                                className="mt-3"
                            />
                            <Field
                                name={`${fee}.description`}
                                labelText={`Beschreibung der ${label}`}
                                component={TextAreaWrapper}
                                validate={combine(
                                  required(),
                                  length({
                                    max: 128,
                                    message: "Der Text ist zu lang. Es können maximal 128 Zeichen (inklusive Leerzeichen) verwendet werden.",
                                  })
                                )}
                                placeholder="Bitte beschreiben Sie hier die Zusammensetzung der Gebühr"
                                disabled={props.disabled}
                                className="mt-5"
                            />
                        </OuterFormColLeft>
                        <OuterFormColRight md={6} lg={6}>
                            {classification === 'distribution_cost_fee_consumer' &&
                                <>
                                    <Field
                                        name={`${fee}.classification`}
                                        labelText="Klassifizierung"
                                        component={SelectWrapper}
                                        optionsList={options}
                                        disabled={true}
                                        className="mt-3"
                                        value={classification}
                                    />
                                </>
                            }
                            {classification !== 'distribution_cost_fee_consumer' &&
                                <>
                                    <Field
                                        name={`${fee}.price_netto`}
                                        component={InputWrapper}
                                        unit="ct/Monat (netto)"
                                        type="number"
                                        labelText={`Höhe der ${label}`}
                                        validate={required()}
                                        parse={parseStrToNumber}
                                        disabled={props.disabled}
                                        className="mt-3"
                                    />
                                    <Field
                                        name={`${fee}.classification`}
                                        labelText="Klassifizierung"
                                        validate={required()}
                                        component={SelectWrapper}
                                        optionsList={options}
                                        disabled={props.disabled}
                                        className="mt-5"
                                    /></>}
                        </OuterFormColRight>
                    </Row>
                    {classification !== 'distribution_cost_fee_consumer' &&
                        <FieldArray
                            name={`${fee}.cost_items`}
                            component={CostItemInputs}
                            costItemOptions={props.costItemOptions}
                            selectedCostItem={props.selectedCostItem}
                            fee={fee}
                            feeIndex={index}
                            updateClickIndexStatus={props.updateClickIndexStatus}
                            feeFields={props.fields}
                            validate={props.meterOperatorExists}
                            meterOperatorError={props.meterOperatorError}
                            saveClicked={props.saveClicked}
                            fieldsRefBaseFee={props.fieldsRefBaseFee}
                            fieldsRefMonthlyFees={props.fieldsRefMonthlyFees}
                            disabled={props.disabled}
                        />
                    }
                </AccordionItem>
            )
        })
    }
    return (
        <>
            {renderAdditionalFees()}
            <Row className='mb-2'>
                <StyledButton
                    type='button'
                    color={props.disabled ? NO_ACTIVE_BG_COLOR : MAIN_COLOR}
                    colorText={props.disabled ? NO_ACTIVE_COLOR : SECONDARY_COLOR}
                    hoverCursor={props.disabled ? 'default' : 'pointer'}
                    disabled={props.disabled}
                    onClick={() => props.fields.push({ "cost_items": [] })}
                >
                    Gebühr hinzufügen
                </StyledButton>
                {showDistributionCostButton() &&
                    <Button
                        type='button'
                        color={props.disabled ? NO_ACTIVE_BG_COLOR : MAIN_COLOR}
                        colorText={props.disabled ? NO_ACTIVE_COLOR : SECONDARY_COLOR}
                        hoverCursor={props.disabled ? 'default' : 'pointer'}
                        disabled={props.disabled}
                        onClick={() => { props.fields.push({ "cost_items": [], "classification": "distribution_cost_fee_consumer", "value_netto": 0, "unit": "", "name": "distribution_cost" }) }}
                    >
                        Vermarktungskosten hinzufügen
                    </Button>
                }
            </Row>
        </>
    )
}


export class SpotBaseFee extends React.Component {
    changeBaseFee(baseFee) {
        this.props.dispatch(change(
            'productDefinitionForm',
            'pc_method',
            `combi&&wp:spot_price&&bf:${baseFee}`
        ));
    }

    render() {
        return (
            <>
                <AccordionItem title="KOSTEN PRO MONAT" expanded={true}>
                    <PriceCalculationBaseFeeForm
                        baseFeeMethod={this.props.baseFeeMethod}
                        changeBaseFee={this.changeBaseFee.bind(this)}
                        disabled={this.props.disabled}
                        priceType={this.props.priceType}
                        costItemOptions={this.props.costItemOptions}
                        selectedCostItem={this.props.selectedCostItem}
                        updateClickIndexStatus={this.props.updateClickIndexStatus}
                        meterOperatorExists={this.props.meterOperatorExists}
                        meterOperatorError={this.props.meterOperatorError}
                        saveClicked={this.props.saveClicked}
                        fieldsRefBaseFee={this.props.fieldsRefBaseFee}
                        meterOperatorFeeNotDeletable={this.props.meterOperatorFeeNotDeletable}
                    />
                    <FieldArray name={'monthly_fees'} component={SpotBaseFeeItem} changeBaseFee={this.changeBaseFee.bind(this)} {...this.props} />
                </AccordionItem>
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const fees = selector(state, "monthly_fees") || [];
    return Object.assign({}, ownProps, {
        fees,
    })
}

export default connect(mapStateToProps)(SpotBaseFee)
