import React from "react";
import SpotBaseFee from "./includes/SpotBaseFee";
import SpotWorkingPrice from "./includes/SpotWorkingPrice";

export default function PriceCalculationSpot(props) {
    return (
        <>
            <SpotWorkingPrice
                {...props} 
            />
            < SpotBaseFee
                dispatch={props.dispatch}
                baseFeeMethod={props.baseFeeMethod}
                priceType={props.priceType}
                disabled={props.disabled}
                costItemOptions={props.costItemOptions}
                selectedCostItem={props.selectedCostItem}
                updateClickIndexStatus={props.updateClickIndexStatus}
                saveClicked={props.saveClicked}
                meterOperatorExists={props.meterOperatorExists}
                meterOperatorError={props.meterOperatorError}
                fieldsRefBaseFee={props.fieldsRefBaseFee.bind(this)}
                fieldsRefMonthlyFees={props.fieldsRefMonthlyFees.bind(this)}
                meterOperatorFeeNotDeletable={props.meterOperatorFeeNotDeletable}
            
            />
        </>
    )
}