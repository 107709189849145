import "./App.css";

import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "./components/auth/Login";
import SalespartnerForm from "./components/manager/SalespartnerForm";
import Dashboard from "./components/manager/Dashboard";
import ProductOfferCreateForm from "./components/configurator/ProductOfferCreateForm";
import ProductOfferEditForm from "./components/configurator/ProductOfferEditForm";
import EditorEmailDocument from "./components/editor/EditorEmailDocument";
import { DemoComponents } from "./components/common/lib/DemoComponents";
import PasswordResetForm from "./components/auth/PasswordResetForm";
import { ErrorBoundary } from "./components/common/ErrorBoundary";
import { RedirectToLogin } from "./components/common/RedirectToLogin";

import UserEdition from "./components/manager/UserEdition";


class App extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path="/login"
          render={props => <Login {...props} />}
        />

        <Route
          exact={true}
          path="/demo"
          render={props => <DemoComponents {...props} />}
        />

        <Route
          exact={true}
          path="/salespartner"
          render={props => <SalespartnerForm {...props} />}
        />
        <Route
          exact={true}
          path="/dashboard"
          render={props => <Dashboard {...props} />}
        />
        <Route
          exact={true}
          path="/configurator"
          render={props => <ProductOfferCreateForm {...props} />}
        />

        <Route
          exact={true}
          path="/configurator/:pk/"
          render={props => <ProductOfferEditForm {...props} />}
        >
        </Route>

        <Route
          exact={false}
          path="/configurator/:pk/sections/:cucoid/"
          render={props => <EditorEmailDocument {...props} />}
        />
        <Route
          exact={true}
          path="/user/"
          render={props => <UserEdition {...props} />}
        />

        <Route
          exact={false}
          path="/password_reset/:uid/:token/"
          render={props => <PasswordResetForm {...props} />}
        />

        <Route exact={true} path="/"
          render={() => localStorage.getItem('user') ? <Redirect to="/dashboard" /> : <Redirect to="/login" />} />
        <Route
          exact={true}
          path="/redirect_to_login"
          render={props => <RedirectToLogin {...props} />}
        />
        <Route
          path='*'
          render={props => <ErrorBoundary {...props} />}
        />
      </Switch>
    );
  }
}

export default App;
