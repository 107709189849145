import React from "react";
import { Col, Row } from "react-bootstrap"
import { Field } from "redux-form"
import { required } from "redux-form-validators";
import { PRICE_UNITS_OPTIONS_BF } from "../../../../../constants"
import { parseStrToNumber } from "../../../../../utils/commonUtils";
import InputWrapper from "../../../../common/forms/InputWrapper";
import SelectWrapper from "../../../../common/forms/SelectWrapper";
import { FlexFormCol } from "../../../../common/styles/FormStyles"

export class PriceCalculationBaseFee extends React.Component {
    render() {
        return (
            <>
                {this.props.priceCalculation === 'fix' &&
                    <Field
                        name={`bf_price_${this.props.priceType}`}
                        component={InputWrapper}
                        type="number"
                        labelText="Grundpreis"
                        validate={required()}
                        parse={parseStrToNumber}
                        unit={`ct/Monat (${this.props.priceType})`}
                        disabled={this.props.disabled}
                    />
                }
                {this.props.priceCalculation === 'algorithmic' &&
                    <Row>
                        <FlexFormCol md={6} lg={6} >
                            <Field
                                name="pc_base_fee_margin_min"
                                component={InputWrapper}
                                type="number"
                                labelText="Minimale Marge"
                                className="mr-3"
                                validate={required()}
                                parse={parseStrToNumber}
                                disabled={this.props.disabled}
                            />
                            <Field
                                name="pc_base_fee_margin_min_unit"
                                labelText="Einheit"
                                component={SelectWrapper}
                                optionsList={PRICE_UNITS_OPTIONS_BF}
                                validate={required()}
                                disabled={this.props.disabled}
                                width="170px"
                                arrowMargin="6px"
                            />
                        </FlexFormCol>
                        <Col md={6} lg={6}>
                            <Field
                                name="pc_amount_below_competition_bf"
                                component={InputWrapper}
                                type="number"
                                labelText="Optimales Delta zum Vergleichstarif"
                                unit="ct/Monat"
                                validate={required()}
                                parse={parseStrToNumber}
                                disabled={this.props.disabled}
                            />
                        </Col>
                    </Row>
                }
                {this.props.priceCalculation === 'cost_plus' &&
                    <Row>
                        <FlexFormCol md={6} lg={6}>
                            <Field
                                name="pc_base_fee_margin_min"
                                component={InputWrapper}
                                type="number"
                                labelText="Marge"
                                className="mr-3"
                                validate={required()}
                                parse={parseStrToNumber}
                                disabled={this.props.disabled}
                            />
                            <Field
                                name="pc_base_fee_margin_min_unit"
                                labelText="Einheit"
                                component={SelectWrapper}
                                optionsList={PRICE_UNITS_OPTIONS_BF}
                                validate={required()}
                                disabled={this.props.disabled}
                                width="170px"
                                arrowMargin="6px"
                            />
                        </FlexFormCol>
                    </Row>
                }
            </>
        )
    }
}
