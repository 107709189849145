import React, { useCallback, useEffect } from 'react';
import { getFormValues, FieldArray, Field, change } from "redux-form";
import {toast} from 'react-toastify';
import { connect} from 'react-redux';
import {ReactComponent as PDFIcon} from '../../../images/icons/PDF.svg';
import { GRAY_COLOR } from '../../../constants/css';
import styled from "styled-components"
import UploadDocumentWrapper from './UploadDocumentWrapper';

const StyledParentDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`
const StyledChildrenDiv = styled.div`
  width: 50%;
  padding: 10px 25px;
`

// To have a better understanding of this AttachmentUploader component it is advised to read it in the following order:
// 1. AttachmentsUploader
// 2. renderAttachments
// 3. AttachmentField 

const AttachmentField = ({ dispatch, fields, index, fileUrl }) => { //  3. AttachmentField 

  // useCallback to prevent unnecessary re-renders as the functions are passed as props to every single field
  const handleOnDrop = useCallback((selectedFile) => { 
    fields.splice(fields.length - 1, 1, selectedFile)
    fields.push("")
  }, [fields])

  const handleDeleteFile = useCallback(() => {
    dispatch(change("editorForm", `attachment_${index}`, ""));
    fields.remove(index)
  }, [dispatch, fields, index])

    return (
      <StyledChildrenDiv>
            <Field
                name={`attachment_${index}`}
                component={UploadDocumentWrapper}
                text="Mögliche Formate: .pdf"
                icon={<PDFIcon width="38px" padding="17px 20px"/>}
                selectedFile={fileUrl} 
                backgroundColor={GRAY_COLOR}
                acceptedTypes={[".pdf"]}
                type="file"
                widthText="280px"
                onDropCustomHandler={(selectedFile) => handleOnDrop(selectedFile[0])}
                onDeleteFile={()=> handleDeleteFile(index)}
            /> 
      </StyledChildrenDiv>
    )
  }

const renderAttachments = ({ fields, dispatch }) => { // 2. renderAttachments

    const handleLastField = (fields) => { // ensures that the last field is always empty thus available for a new file 
      if (fields.get(fields.length - 1) !== "") {
        fields.push("")
      }
    }

    const handleDuplicateFiles = (fields, dispatch, newAddition) => { // explicit, check if the file is a duplicate and raise a toast(alert) if so
      const isDuplicate = fields.getAll().filter(url => JSON.stringify(url).includes(newAddition.name)).length > 1;
      if (isDuplicate) {
        toast('Es existiert bereits eine Datei mit demselben Namen.', {type: 'error'});
        dispatch(change("editorForm", `attachment_${fields.length - 2}`, ""));
        fields.remove(fields.length - 2)
      }
    }

    // Opted to place the verification logic here  each field should only be responsible for adding or deleting its file value
    useEffect(() => { 
        handleLastField(fields)
        const newAddition = fields.get(fields.length - 2)
        if (newAddition instanceof File) {
          handleDuplicateFiles(fields, dispatch, newAddition);
        }
    }
    , [fields.length, dispatch])

    return (
    <StyledParentDiv>
      {fields.map((_, index) => (
        <AttachmentField key={index} fields={fields} fileUrl={fields.get(index)} index={index} dispatch={dispatch}/>
        ))}
    </StyledParentDiv>
    )
  }

const AttachmentsUploader = ({ dispatch }) => { // 1. AttachmentsUploader

    // according to the react-redux documentation children components of FieldArray must be wrapped in a parent component
    // hence "renderAttachments" 
    return (
        <div>
            <FieldArray 
                name="attachments"
                component={renderAttachments}
                props={{dispatch: dispatch}}
                />
        </div>
    );
}

function mapStateToProps (state, ownProps) {
    const values = getFormValues('editorForm')(state);
        return Object.assign({}, ownProps, {
            attachments : values.attachments || []
          })
}
export default connect(mapStateToProps)(AttachmentsUploader);