import axios from 'axios';
import _ from 'lodash';

function getFileNameFromURL(file){
    return _.last(file.split('/'));
}

function pathJoin(parts) {
    const separator = '/';
    parts = parts.map((part, index) => {
        part = String(part)
        if (index) { // trim every '/' at the beginning of element except for the first one
            part = part.replace(new RegExp('^' + separator), '');
        }
        if (index !== parts.length - 1) { // trim every '/' at the end of element  except for the last one
            part = part.replace(new RegExp(separator + '$'), '');
        }
        return part; 
    });

    return parts.join(separator);
}

export function getFileDataFromURL(url){
    const fullUrl = pathJoin([process.env.REACT_APP_MEDIA_URL, url]);
    let fileRetrieve = axios.get(fullUrl, {responseType: 'blob'});
    const file = fileRetrieve.then(response => {
        return new File([response.data], getFileNameFromURL(url))
        
    }).catch(function (error) {
        console.log(error);
        return '';
      });
    return file
}