import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Button from "../common/styles/Button";
import { SECONDARY_COLOR, MAIN_COLOR } from "../../constants/css";
import arrowLeft from '../../images/icons/arrow_left.svg';
import arrowRight from '../../images/icons/arrow_right.svg';
import { ButtonsWrapper } from "./styles/ButtonsWrapper";

class FormNavigation extends React.Component {
    clickActions() {
      if (this.props.setSaveClicked) {
          this.props.setSaveClicked(true)
      }
      this.props.onSaveHandler(this.props.nextTabKey)
    }
    
    render() {
        const { previousTabKey, onPreviousPageHandler} = this.props;

        return (
            <ButtonsWrapper
                justifyContent={
                    !this.props.nextTabKey ? 'start' : 'space-between'
                }
            >
                {this.props.nextTabKey && 
                <Button 
                    color={MAIN_COLOR}
                    colorText={SECONDARY_COLOR} 
                    icon={arrowRight}
                    iconPosition='right'
                    iconWidth='15px'
                    onClick={() => this.clickActions()}
                    type="submit"
                >
                    speichern und weiter
                </Button>}
                {previousTabKey && 
                <Button
                    variant='text'
                    color={SECONDARY_COLOR} 
                    colorText={SECONDARY_COLOR} 
                    icon={arrowLeft}
                    iconPosition='left'
                    iconWidth='15px'
                    onClick={() => onPreviousPageHandler(this.props.previousTabKey)}
                >
                    zurück
                </Button>}
            </ButtonsWrapper>
        );
    }
}

FormNavigation.propTypes = {
    onSaveHandler: PropTypes.func,
    onCloseHandler: PropTypes.func,
    onPreviousPageHandler: PropTypes.func,
    previousTabKey: PropTypes.string,
};

export default withRouter(FormNavigation);
