import React from "react";
import Row from "react-bootstrap/Row";
import { Field, FieldArray } from "redux-form";
import { combine, length, required } from "redux-form-validators";

import TextAreaWrapper from "../../../../../common/forms/TextAreaWrapper";

import { OuterFormColLeft, OuterFormColRight } from "../../../../../common/styles/FormStyles";
import InputWrapper from "../../../../../common/forms/InputWrapper";
import AccordionItem from "../../../../../common/lib/Accordion";
import { ReactComponent as Info } from "../../../../../../images/icons/INfo.svg";

import { GRAY_COLOR, NO_ACTIVE_BG_COLOR, NO_ACTIVE_COLOR, SECONDARY_COLOR } from "../../../../../../constants/css";

import ExtraSpotWorkingPrice from "./ExtraSpotWorkingPrice";


export default function SpotWorkingPrice(props) {
    return (
        <>
            <AccordionItem
                title="KOSTEN FÜR DEN VERBRAUCH"
                expanded={true}

                tooltipMarginLeft="10px">
                <AccordionItem
                    title="ENERGIEPREIS - DAY AHEAD"
                    expanded={true}
                    contentBackground={GRAY_COLOR}
                    barBackgroundClosed={NO_ACTIVE_COLOR}
                    barBackgroundOpen={NO_ACTIVE_BG_COLOR}
                    marginBottom="40px"
                    marginTop="10px"
                    tooltipMarginLeft="10px"
                    infoIcon={<Info />}
                    infoIconTooltip="Der Arbeitspreis entspricht den stündlichen Spot Markt Preisen im Day AheadTrading. Es fallen zusätzlich Abgaben, Umlagen & Steuern auf den Strombezug an. Diese werden automatisch aufsummiert und gegenüber dem Endkunden gesondert ausgewiesen."
                    withDelete={false}
                >
                    <Row>
                        <OuterFormColLeft md={6} lg={6}>
                            <Field
                                name="wp_display_name"
                                component={InputWrapper}
                                type="text"
                                validate={required()}
                                labelText="Display Name des Energiepreises"
                                disabled={props.disabled}
                                className="mt-3"
                            />
                            <Field
                                name="wp_description"
                                labelText="Beschreibung des Energiepreises"
                                component={TextAreaWrapper}
                                placeholder="Bitte beschreiben Sie hier die Zusammensetzung des Energiepreises"
                                disabled={props.disabled}
                                border={`1px solid ${SECONDARY_COLOR}`}
                                className="mt-5"
                                infoIcon={<Info />}
                                validate={combine(
                                  required(),
                                  length({
                                    max: 128,
                                    message: "Der Text ist zu lang. Es können maximal 128 Zeichen (inklusive Leerzeichen) verwendet werden.",
                                  })
                                )}
                                infoIconTooltip="Der Beschreibungstext wird in der Begriffserklärung einer Stromabrechnung verwendet."

                            />
                        </OuterFormColLeft>
                        <OuterFormColRight md={6} lg={6}>
                            <Field
                                name="wp_spot_market_display_name"
                                labelText="Name der Strombörse"
                                component={InputWrapper}
                                disabled={props.disabled}
                                validate={required()}
                                className="mt-3"
                            />
                            <Field
                                name="wp_spot_market_price_explanation"
                                labelText="Erklärungstext zur Strombörse"
                                component={TextAreaWrapper}
                                placeholder="Bitte beschreiben Sie hier an welcher Strombörse der Spot Market Preis ermittelt wird."
                                disabled={props.disabled}
                                border={`1px solid ${SECONDARY_COLOR}`}
                                className="mt-5"
                                validate={combine(
                                  required(),
                                  length({
                                    max: 128,
                                    message: "Der Text ist zu lang. Es können maximal 128 Zeichen (inklusive Leerzeichen) verwendet werden.",
                                  })
                                )}
                            />
                        </OuterFormColRight>
                    </Row>
                </AccordionItem>

                <FieldArray name={'working_prices_extra'} component={ExtraSpotWorkingPrice} disabled={props.disabled} />
            </AccordionItem>
        </>
    )
}