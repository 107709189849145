import { createActionThunk } from 'redux-thunk-actions';
import APIRequest from '../api/ApiRequest';
import {FETCH_SECTION} from './types';

const EMAIL_DOC_SECTION_ENDPOINT = '/api/sections/';

export const retrieveSections = (
    token,
    cucoId,
    cucoType,
    productType,
    consumptionPriceModel
) => {
    const entryPointBase = `${EMAIL_DOC_SECTION_ENDPOINT}${cucoId}/?type=${cucoType}&productType=${productType}`;
    const consumptionPriceParameter = consumptionPriceModel === 'null' ? '' : `&consumptionPriceModel=${consumptionPriceModel}`
    const entryPoint = entryPointBase + consumptionPriceParameter
    return (dispatch) => {
        let fetch = createActionThunk(FETCH_SECTION, () =>
        new APIRequest(token).get(entryPoint));
        let promise = dispatch(fetch());
        return promise;
    };
}