import Row from "react-bootstrap/Row";
import { change, Field } from "redux-form";
import { combine, length, required } from "redux-form-validators";
import { PRODUCT_PRICE_CALCULATION_FIELD_OPTIONS } from "../../../../../constants";
import React from "react";
import TextAreaWrapper from "../../../../common/forms/TextAreaWrapper";
import { OuterFormColLeft, OuterFormColRight } from "../../../../common/styles/FormStyles";
import SelectWrapper from "../../../../common/forms/SelectWrapper";
import InputWrapper from "../../../../common/forms/InputWrapper";
import AccordionItem from "../../../../common/lib/Accordion";
import { SECONDARY_COLOR } from "../../../../../constants/css";
import { PriceCalculationWorkingPrice } from "./PriceCalculationWorkingPrice";
import { PriceCalculationBaseFeeForm } from "./PriceCalculationBaseFeeForm";
import { ReactComponent as Info } from "../../../../../images/icons/INfo.svg";

export class PriceCalculationSingleStep extends React.Component {
    changeWorkingPrice(workingPrice) {
        this.props.dispatch(change(
            'productDefinitionForm',
            'pc_method',
            `combi&&wp:${workingPrice}&&bf:${this.props.baseFeeMethod}`
        ));
    }

    changeBaseFee(baseFee) {
        this.props.dispatch(change(
            'productDefinitionForm',
            'pc_method',
            `combi&&wp:${this.props.workingPriceMethod}&&bf:${baseFee}`
        ));
    }
    
    render() {
        return (
            <>
                <AccordionItem title="ARBEITSPREIS" expanded={true}>
                    <Row>
                        <OuterFormColLeft md={6} lg={6}>
                            <Field
                                name="wp_display_name"
                                component={InputWrapper}
                                type="text"
                                labelText="Display Name des Arbeitspreises"
                                validate={required()}
                                disabled={this.props.disabled}
                                className="mt-3"
                            />
                            <Field
                                name="wp_description"
                                labelText="Beschreibung des Arbeitspreises"
                                component={TextAreaWrapper}
                                validate={combine(
                                  required(),
                                  length({
                                    max: 128,
                                    message: "Der Text ist zu lang. Es können maximal 128 Zeichen (inklusive Leerzeichen) verwendet werden.",
                                  })
                                )}
                                placeholder="Bitte beschreiben Sie hier die Zusammensetzung des Arbeitspreises"
                                disabled={this.props.disabled}
                                border={`1px solid ${SECONDARY_COLOR}`}
                                className="mt-5"
                                infoIcon={<Info/>}
                                infoIconTooltip="Der Beschreibungstext wird in der Begriffserklärung einer Stromabrechnung verwendet."
                            />
                        </OuterFormColLeft>
                        <OuterFormColRight md={6} lg={6}>
                            <Field
                                name="combi_wp_method"
                                labelText="Berechnungsart des Arbeitspreises"
                                component={SelectWrapper}
                                optionsList={PRODUCT_PRICE_CALCULATION_FIELD_OPTIONS}
                                validate={required()}
                                disabled={this.props.disabled}
                                onCustomChange={this.changeWorkingPrice.bind(this)}
                                className="mt-3 mb-5"
                            />
                            <PriceCalculationWorkingPrice
                                disabled={this.props.disabled}
                                priceCalculation={this.props.workingPriceMethod}
                                priceType={this.props.priceType}
                            />
                        </OuterFormColRight>
                    </Row>
                </AccordionItem>
                <AccordionItem title="KOSTEN PRO MONAT" expanded={true}>
                    <PriceCalculationBaseFeeForm
                        baseFeeMethod={this.props.baseFeeMethod}
                        changeBaseFee={this.changeBaseFee.bind(this)}
                        disabled={this.props.disabled}
                        priceType={this.props.priceType}
                        costItemOptions={this.props.costItemOptions}
                        selectedCostItem={this.props.selectedCostItem}
                        updateClickIndexStatus={this.props.updateClickIndexStatus}
                        saveClicked={this.props.saveClicked}
                        meterOperatorExists={this.props.meterOperatorExists}
                        meterOperatorError={this.props.meterOperatorError}
                        fieldsRefBaseFee={this.props.fieldsRefBaseFee}
                        fieldsRefMonthlyFees={this.props.fieldsRefMonthlyFees}
                        meterOperatorFeeNotDeletable={this.props.meterOperatorFeeNotDeletable}
                    />
                </AccordionItem>
            </>
        )
    }
}
