import _ from 'lodash';



export default class ProductOfferValues{ 
    owner = '';
    name = '';
    sales_partner_logo_image = '';
    cobranding_logo_image = '';
    primary_font = '';
    primary_font_bold = '';
    secondary_font = '';
    secondary_font_bold = '';
    origination_figure = '';
    is_whitelabel = false;
    is_producer = false;
    

    getFileKeys(){
        return Object.keys(this).filter(k => {return !['owner', 'name', 'is_whitelabel', 'is_producer'].includes(k)})
    }

    setValuesFromForm(formValues){
        Object.assign(this, _.pick(formValues, Object.keys(this)));

        this.is_whitelabel = formValues.sales_partner_logo_image_location !==undefined;
        return this.toJson();
    }

    toJson(){
        const retObj = _.pickBy(this, (value)=>{return (value !== null)});
        return _(retObj).value();
    }

    getKeys(){
        return Object.keys(this);
    }

    setFormValues(podObject){
        const values = this.setValuesFromForm(podObject);
        return values;
    }

}