import React from 'react';
import logout from '../auth/logout';
import { ReactComponent as LogoutIcon } from '../../images/icons/logout.svg';
import {withRouter} from 'react-router-dom';
import IconButton from './lib/IconButton';
import { SECONDARY_COLOR, SECONDARY_WHITE_COLOR } from '../../constants/css';

export class LogOutButton extends React.Component{

    handleClick(evt){
        evt.preventDefault();
        let callback = () => logout(this.props.history);
        const token = localStorage.getItem('authenticated');
        const postData = {token};
        this.props.logoutHandler(postData, token, callback )
    }

    render(){
        return(
            <IconButton 
                tooltip={'ausloggen'}
                positionTooltip={'bottom'}
                backgroundTooltip={SECONDARY_WHITE_COLOR}
                colorTooltip={SECONDARY_COLOR}
                onClick={this.handleClick.bind(this)}
                width="25px"
            >
                <LogoutIcon fill={SECONDARY_WHITE_COLOR} />
            </IconButton>
        )
    }
}

const LogOutButtonWithRouter = withRouter(LogOutButton);

export default LogOutButtonWithRouter;
