import styled, {css} from "styled-components";
import {MAIN_COLOR, NO_ACTIVE_COLOR, PINK_COLOR, SECONDARY_COLOR} from "../../../constants/css";

const getBorderBottomColor = (props) => {
  const { isError, errorColor, disabled, colorBorder } = props

  if (isError) {
    return errorColor || PINK_COLOR
  }

  if (disabled) {
    return NO_ACTIVE_COLOR
  }

  return colorBorder || SECONDARY_COLOR
}

export const Icon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  right: 30px;
  top: 40px;
`

export const InputWrap = styled.div`
  position: relative;
  width: 100%;
  ${({isColorBox}) => isColorBox && css`
  display: flex;
  flex-direction: row-reverse;
`}
`

export const InputItem = styled.input`
  // width: 100%;
  width: ${({isColorBox}) => isColorBox ? '90%' : '100%'};
  height: 46px;

  
  border: 0;
  border-bottom: 1px solid ${props => getBorderBottomColor(props)};
  outline: 0;
  font-size: 18px;
  color: ${({colorText = SECONDARY_COLOR, disabled}) =>
  disabled ? NO_ACTIVE_COLOR : colorText};
  -webkit-text-fill-color: ${({colorText = SECONDARY_COLOR, disabled}) =>
  disabled ? NO_ACTIVE_COLOR : colorText};
  padding: 14px 6px 0 6px;
  background: ${({colorBackground}) => colorBackground ?? 'transparent'};
  transition: border-color 0.2s;

  ${({unit}) => unit && css`
    padding-right: ${({unit}) => unit.length * 20 + 'px'};
  `}

  &:focus + label {
    font-size: 14px;
    color: ${({colorLabel = NO_ACTIVE_COLOR}) => colorLabel};
    top: -16px;
    opacity: ${({opacityLabelSmall = 1}) => opacityLabelSmall};
  }

  &:focus ~ span {
    display: block;
  }

  &:focus {
    border-bottom: 1px solid ${({activeColor = MAIN_COLOR}) => activeColor};
  }

  &:focus ~ :last-child {
    display: block;
  }
`

export const Label = styled.label`
  font-size: 18px;
  color: ${({colorLabel = NO_ACTIVE_COLOR}) => colorLabel};
  padding: 6.5px 0;
  position: absolute;
  top: 8px;
  left: 0;
  transition: 0.2s ease all;
  pointer-events: none;
  ${({valueEntered}) => valueEntered && css`
    font-size: 14px;
    top: -16px;
    opacity: ${({opacityLabelSmall = '1'}) => opacityLabelSmall};
  `}
  ${({isColorBox}) => isColorBox && css`
  font-size: 14px;
  top: -16px;
  opacity: ${({opacityLabelSmall = '1'}) => opacityLabelSmall};
`}
`

export const Unit = styled.span`
  color: ${({colorText = SECONDARY_COLOR, disabled}) =>
  disabled ? NO_ACTIVE_COLOR : colorText};
  position: absolute;
  right: 10px;
  bottom: 4px;
`
export const ColorBox = styled.div`
  width: 30px;
  height: 30px  !important;
  transition: 0.25s ease;
  align-self: end
  margin-right: 5px
  border:  ${({colorBox}) => colorBox ? 'solid 1px black' : "solid 1px #909090"};
  background: ${({colorBox}) => colorBox ? colorBox : 'transparent'};
`