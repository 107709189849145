import React from "react";
import PropTypes from 'prop-types';

import { Across, DialogContainer, DialogContent, DialogQuestion, DialogWindowContainer, DialogWrapper, HeaderDialog, HeaderText } from '../common/styles/DialogWindow';
import Button from '../common/styles/Button';
import { SECONDARY_COLOR } from '../../constants/css';
import IconButton from '../common/lib/IconButton';
import styled from "styled-components";

const ButtonPopUpWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 60px;
    margin-bottom: 25px;
`;

const StyledButton = styled(Button)`
    width: ${({width = '180px'}) => width}
`;

const StyledButtonText = styled.span`
    margin: 0 auto;
`;

class ContentModal extends React.Component{
  render(){
    return (
      <DialogContainer>
        <DialogWindowContainer>
          <Across data-testid='close-element' onClick={() => this.props.onHide()}/>
          <HeaderDialog>
            <IconButton 
              isLarge
              isFill
              colorFill={this.props.colorFill}
              colorFillIcon={this.props.colorFillIcon}
              colorFillHover={this.props.colorFill}
              colorBorder={this.props.colorFill}
              isButton={false}
            > 
              {this.props.modalIcon}
            </IconButton>
            <HeaderText 
              color={this.props.color}
            >
              {this.props.getModalTitle()}
            </HeaderText>
          </HeaderDialog>
          <DialogWrapper>
            {this.props.startsPolling ?
              this.props.children
              :
              <DialogContent>
                <DialogQuestion>
                  {this.props.modalQuestion}
                </DialogQuestion>
                <ButtonPopUpWrapper>
                  <StyledButton
                    onClick={() => this.props.cancelAction()}
                    color={SECONDARY_COLOR}
                    colorText={SECONDARY_COLOR}
                    className="ml-3 mr-3"
                    width={this.props.buttonWidth}
                  >
                    <StyledButtonText>{this.props.cancelLabel}</StyledButtonText>
                  </StyledButton>
                  <StyledButton
                    onClick={() => this.props.acceptAction()}
                    color={this.props.acceptButtonColor}
                    colorText={this.props.acceptButtonColorText}
                    className="ml-3 mr-3"
                    width={this.props.buttonWidth}
                  >
                    <StyledButtonText>{this.props.acceptLabel}</StyledButtonText>
                  </StyledButton>
                </ButtonPopUpWrapper>
              </DialogContent>
            }
          </DialogWrapper>
        </DialogWindowContainer>
      </DialogContainer>
    );
  }
}

ContentModal.propTypes = {
  show: PropTypes.bool,
  acceptAction: PropTypes.func,
  onHide: PropTypes.func,
  modalIcon: PropTypes.element,
  getModalTitle: PropTypes.func,
  acceptLabel: PropTypes.string,
}

export default ContentModal;
