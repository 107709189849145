import React, { useState } from "react";
import { compose } from "redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import { connect } from "react-redux";
import styled from "styled-components";
import { Row, Col, Form } from "react-bootstrap";
import { required, confirmation, format, combine } from "redux-form-validators";
import { toast } from "react-toastify";
import requireAuth from "../auth/requireAuth";
import Layout from "../common/Layout";
import Button from "../common/styles/Button";
import { SECONDARY_COLOR, GRAY_COLOR, MAIN_COLOR, WHITE_COLOR } from "../../constants/css";
import InputWrapper from "../common/forms/InputWrapper";
import PasswordCheckList from "../auth/PasswordChecklist";
import { updatePassword } from "../../api/calls";
import { ReactComponent as UserSvg } from '../../images/icons/user.svg';
import { ReactComponent as SecureSvg } from '../../images/icons/secure.svg';
import arrowLeft from '../../images/icons/arrow_left.svg';
import { FormHeading } from "../common/lib/FormHeading";
import { ExpandableArea } from "../common/lib/ExpandableArea";
import { FIELD_REQUIRED_ERROR, PASSWORDS_DIFFERENT_ERROR, PASSWORD_INVALID_ERROR } from "../../constants";
import {PASSWORD_VALID_REGEX} from "../../utils/validationUtils";


const Title = styled.h1`
  color: ${SECONDARY_COLOR};
  font-size: 40px;
  margin-right: 115px;
`

const FirstRow = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 140px 100px 90px 100px;
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 100px 100px 100px;
  background: ${GRAY_COLOR}
`

const PasswordCheckListWrapper = styled.div`
  margin: 40px 0;
`

const StyledFooter = styled.footer`
    display: flex;
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding: 20px 100px;
    width: 100%;
    background: ${WHITE_COLOR};
    z-index: 100;
`


export function UserEditionView(props) {
  const { handleSubmit, user, logoutDispatcher } = props;
  const [closePasswordEditing, setClosePasswordEditing] = useState(false)

  function onSubmit(formProps) {
    const { token } = props;
    const successCallback = () => {
      toast("Passwort erfolgreich geändert", { type: "success" });
      setClosePasswordEditing(!closePasswordEditing);
      props.dispatch(reset("userEdition"));
    };
    const errCallback = (err) => { 
      toast(`Passwort konnte nicht geändert werden. ${err}`, { type: "error" });
    };
    updatePassword(formProps, token, successCallback, errCallback);
  }

  return (
    <Layout
      logoutDispatcher={logoutDispatcher}
      user={user}

    >
      <FirstRow>
        <Title>Profil</Title>
      </FirstRow>
      <ListWrapper className="mb-5">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12} lg={12}>
              <Row>
                <Col md={6} lg={6} className="pr-5 mr-5">
                  <FormHeading
                    Icon={UserSvg}
                  >
                      PROFILDATEN
                  </FormHeading>
                  <Field
                    name="username"
                    labelText="Benutzername"
                    type="text"
                    component={InputWrapper}
                    disabled={true}
                    className="mt-5"
                  />
                  <Field
                    name="email"
                    component={InputWrapper}
                    type="text"
                    labelText="E-Mail"
                    className="mt-5"
                    disabled={true}
                  />
                  <Field
                    name="first_name"
                    component={InputWrapper}
                    type="text"
                    labelText="Vorname"
                    className="mt-5"
                    disabled={true}
                  />
                  <Field
                    name="last_name"
                    component={InputWrapper}
                    type="text"
                    labelText="Nachname"
                    className="mt-5"
                    disabled={true}
                  />
                </Col>
                <Col>
                  <ExpandableArea
                      Icon={SecureSvg}
                      title="PASSWORT ÄNDERN"
                      triggerCloseContent={closePasswordEditing}
                  >
                    <Field
                      name="old_password"
                      component={InputWrapper}
                      type="password"
                      labelText="Aktuelles Passwort"
                      className="mt-5"
                      validate={required({ msg: FIELD_REQUIRED_ERROR })}
                    />
                    <Field
                      name="new_password"
                      component={InputWrapper}
                      type="password"
                      labelText="Neues Passwort"
                      className="mt-5"
                      validate={combine(required({ msg: FIELD_REQUIRED_ERROR }), format({ with: PASSWORD_VALID_REGEX, msg: PASSWORD_INVALID_ERROR }))}
                    />
                    <Field
                      name="repeat_new_password"
                      component={InputWrapper}
                      type="password"
                      labelText="Neues Password bestätigen"
                      className="mt-5"
                      autoComplete="none"
                      validate={[required({ msg: FIELD_REQUIRED_ERROR }), confirmation({ field: "new_password", fieldLabel: "Passwort", msg: PASSWORDS_DIFFERENT_ERROR })]}
                    />
                    <PasswordCheckListWrapper>
                      <PasswordCheckList
                        value={props.selectedNewPassword}
                        passedColor={MAIN_COLOR}
                        defaultColor={SECONDARY_COLOR}
                      />
                    </PasswordCheckListWrapper>
                    <Button
                      color={MAIN_COLOR}
                      colorText={SECONDARY_COLOR} 
                      type="submit"
                    >
                      Passwort ändern
                    </Button>
                  </ExpandableArea>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </ListWrapper>
      <StyledFooter>
        <Button
            variant='text'
            color={SECONDARY_COLOR} 
            colorText={SECONDARY_COLOR} 
            icon={arrowLeft}
            iconPosition='left'
            iconWidth='15px'
            onClick={() => props.history.push('/dashboard')}
        >
            zurück
        </Button>
      </StyledFooter>
    </Layout>)

};


const selector = formValueSelector("userEdition");

function mapStateToProps(state) {
  const selectedNewPassword = selector(state, "new_password");
  const user = state.auth.user;
  const initialValues = {
    username: user.username,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
  }

  return Object.assign({}, {
    selectedNewPassword,
    initialValues,
    user
  });

}

export default compose(
  connect(mapStateToProps),
  reduxForm({ form: "userEdition" }),
)(requireAuth(UserEditionView, "UserEditionView"))
