import { 
  FETCH_COSTITEMS_STARTED, 
  FETCH_COSTITEMS_FAILED,
  FETCH_COSTITEMS_SUCCEEDED,
  UPDATE_CLICKINDEX_STATUS
} from '../actions/types'

import errorHandler from '../actions/errorHandler'

export const INITIAL_STATE = {
    monthlyFeeClickIndex: 0,
    editableCostItems: JSON.parse(localStorage.getItem('costItems')),
    error: {}
}

export default function(state=INITIAL_STATE, action){
    switch(action.type){
        case FETCH_COSTITEMS_STARTED:
            return {...state, editableCostItems: null, error: {} }
        case FETCH_COSTITEMS_SUCCEEDED:
            return { ...state, editableCostItems: action.payload.data, error: {} }
        case FETCH_COSTITEMS_FAILED: {
            const errorObj = errorHandler(action.payload);
            return {...state, error: errorObj, definitions: null };
        }
        case UPDATE_CLICKINDEX_STATUS:
            return {...state, monthlyFeeClickIndex: action.payload};
        default:
            return state;
    }
}
