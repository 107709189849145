import styled from "styled-components";
import { APP_FONT, NO_ACTIVE_BG_COLOR } from "../../../constants/css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export const EditFormTitle = styled.h3`
  font-family: ${APP_FONT};
  font-size: 20px;
  font-weight: bold;
  color: gray;
`;
export const DescriptionLineOne = styled.p`
  margin-bottom: 1px;
  margin-left: 20px;
`;

export const DescriptionLineTwo = styled.p`
  margin-left: 20px;
`;

export const StyledExplanation = styled.div`
  font-size: 20px;
  margin-bottom: 50px;
`

export const OuterFormColLeft = styled(Col)`
  padding-right: 8px !important;
`

export const OuterFormColRight = styled(Col)`
  padding-left: 8px !important;
`

export const FlexFormCol = styled(Col)`
  display: flex;
  align-items: flex-start;
`

export const FormLegend = styled.legend`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.54px;
  padding-top: 30px;
  padding-bottom: 45px;
`

export const StyledTHead = styled.thead`
  background: ${NO_ACTIVE_BG_COLOR};
  border: none !important;
`

export const StyledTh = styled.th`
  border: none !important;
  
  // Helps that left and right table break on same viewport-width:
  &:last-of-type {
    padding-right: 160px;
    white-space: nowrap;
  }
`

export const StyledTd = styled.td`
  border-top: none !important;
  border-bottom: 1px solid ${NO_ACTIVE_BG_COLOR} !important;
`

export const StyledFormHeading = styled(Row)`
  margin: 30px 0 35px 0;
  font-weight: 700;
`

export const StyledFormIcon = styled.div`
  height: 30px;
`

export const StyledFormTitle = styled.span`
  padding-top: 12px;
  margin-left: 25px;
`
