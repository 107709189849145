import React, { Component } from "react";
import { connect } from "react-redux";

import {
  retrieveProductOffer,
  updateProductOffer,
  createProductOffer,
  resetFormData,
  updateStatusActivationSuccessful,
  updateProductType,
} from "../../actions/productOffersActions";
import { PRODUCT_OFFER_STEPS } from "../../constants";

const mapDispatchToProps = function(dispatch) {
  return {
    retrieveProductOffer: (token, id, callbackSuccess, errCallback) => {
      return dispatch(retrieveProductOffer(token, id, callbackSuccess, errCallback));
    },
    updateProductOffer: (token, id, formData, callbackSuccess, errCallback) => {
      return dispatch(updateProductOffer(token, id, formData, callbackSuccess, errCallback));
    },
    postNewProductOffer: (formData, token, callbackSuccess, errCalback) => {
        return dispatch(createProductOffer(formData, token, callbackSuccess, errCalback));
    },
    resetFormData: () => {return dispatch(resetFormData())},

    updateStatusActivationSuccessful: (id) => {
      return dispatch(updateStatusActivationSuccessful(id))
    },
    updateProductType: (type) => {
      return dispatch(updateProductType(type))
    },
    dispatch
  };
};

function mapStateToProps(state, ownProps) {
  return Object.assign({}, ownProps, {
    formCreateDefinitionWizard: state.form.productDefinitionForm,
    salespartner: state.salespartner.data,
    lastEditedPage: state.productOffers.definition.data.step_progress,
    definition: state.productOffers.definition.data,
    isProducer: state.productOffers.is_producer,
  });
}

export default function stepFormHoc(WrappedComponent, isEdition) {
  class ComposedComponent extends Component {
    constructor(props) {
      super(props);
      this.nextPage = this.nextPage.bind(this);
      this.previousPage = this.previousPage.bind(this);
      this.onTabSelected = this.onTabSelected.bind(this);
      this.checkTabIsActive = this.checkTabIsActive.bind(this);
      this.state = {
        page: 1,
        selectedTabKey: "productDef"
      };

    }

    componentDidUpdate(prevProps) {
      if (this.props.definition !== prevProps.definition && isEdition) {
        this.setState({
          page: this.props.lastEditedPage,
          selectedTabKey: PRODUCT_OFFER_STEPS[this.props.lastEditedPage]
        });
      };
    };

    componentDidMount(){
      if(!isEdition){
          this.props.resetFormData()
      }
    }

    nextPage() {
      const tabKey = PRODUCT_OFFER_STEPS[this.state.page]
      this.setState({ page: this.state.page + 1, selectedTabKey: tabKey });
    }

    previousPage(tabKey) {
      this.setState({ page: this.state.page - 1, selectedTabKey: tabKey });
    }

    checkTabIsActive(page) {
      return page <= this.state.page;
    }

    onTabSelected(tabKey) {
      this.setState({ selectedTabKey: tabKey });
    }

    render() {
      return (
        <WrappedComponent
          isProducerEdit={this.props.definition.is_producer}
          isProducerCreate={this.props.isProducer}
          page={this.state.page}
          selectedTabKey={this.state.selectedTabKey}
          stepProgress={this.props.definition.product_offer_characteristics?.step_progress}
          checkTabIsActive={this.checkTabIsActive}
          onTabSelected={this.onTabSelected}
          onNextPage={this.nextPage}
          onPreviousPage={this.previousPage}
          {...this.props}
        />
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComposedComponent);
}
