import _ from 'lodash';
import React from 'react';
import { connect} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Field, change, formValueSelector} from "redux-form";

import InputWrapper from '../../../common/forms/InputWrapper';
import ColorPickerWrapper from '../../../common/forms/ColorPickerWrapper';
import UploadDocumentWrapper from '../../../common/forms/UploadDocumentWrapper';
import { GRAY_COLOR } from '../../../../constants/css';
import {ReactComponent as FontIcon} from '../../../../images/icons/font_icon.svg';
import { StyledFormHeading } from '../../../common/styles/FormStyles';
import { Tooltip } from '../../../common/lib/Tooltip';

class EmailBaseDesignFontSelectionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayPrimaryFontColor: false,
      displaySecondaryFontColor: false,
      primaryColor: "",
      secondaryColor: "",
    };
  }

  handlePrimaryFontFileSelected(selectedFiles) {
    const fileObj = selectedFiles[0];
    const fontName = _.split(fileObj.name, '.')[0];
    this.props.dispatch(change('productDefinitionForm', 'bt_definition_font_type', _.upperFirst(fontName)));
  }

  handleSecondaryFontFileSelected(selectedFiles) {
    const fileObj = selectedFiles[0];
    const fontName = _.split(fileObj.name, '.')[0];
    this.props.dispatch(change('productDefinitionForm', 'bt_definition_font_type_two', _.upperFirst(fontName)));
  }

  handlePrimaryColorClick = () => {
    this.setState({
      displayPrimaryFontColor: !this.state.displayPrimaryFontColor
    });
  };

  handleSecondaryColorClick = () => {
    this.setState({
      displaySecondaryFontColor: !this.state.displaySecondaryFontColor
    });
  };

  handleClosePrimaryColor = () => {
    this.setState({ displayPrimaryFontColor: false });
  };

  handleCloseSecondaryColor = () => {
    this.setState({ displaySecondaryFontColor: false });
  };

  handlePrimaryColorChange = color => {
    this.setState({ primaryColor: color.hex });
  };

  handleSecondaryColorChange = color => {
    this.setState({ secondaryColor: color.hex });
  };

  handlePrimaryFontFileDeleted(){
    this.props.dispatch(change('productDefinitionForm', 'primary_font', ''));
    this.props.dispatch(change('productDefinitionForm', 'bt_definition_font_type', ''));
  }

  handlePrimaryFontBoldFileDeleted(){
    this.props.dispatch(change('productDefinitionForm', 'primary_font_bold', ''));
  }

  handleSecondaryFontFileDeleted(){
    this.props.dispatch(change('productDefinitionForm', 'secondary_font', ''));
    this.props.dispatch(change('productDefinitionForm', 'bt_definition_font_type_two', ''));
  }

  handleSecondaryFontBoldFileDeleted(){
    this.props.dispatch(change('productDefinitionForm', 'secondary_font_bold', ''));
  }

  render() {
    const selectedFontColor = this.props.selectedFontColor || this.state.primaryColor;
    const selectedFontColorTwo = this.props.selectedFontColorTwo || this.state.secondaryColor;

    return (
      <Row>
        <Col md={6} lg={6}>
          <fieldset>
            <StyledFormHeading>
              SCHRIFTART PRIMÄR
              <Tooltip>
                Die Standardschriftart wird für Fließtexte in PDF Dokumenten verwendet. Die fette Schriftart wird für Betreffzeile, Tabellenüberschriften und Teilabschnitt-Überschriften verwendet.
              </Tooltip>
            </StyledFormHeading>
            <Row>
              <Col md={8} lg={8}>
                  <Field
                    name="bt_definition_font_type"
                    type="text"
                    labelText="Schriftart Name"
                    disabled={true}
                    component={InputWrapper}
                  />
              </Col>
              <Col md={4} lg={4}>
                <Field
                  name="bt_definition_font_color"
                  labelText="Schriftfarbe"
                  component={ColorPickerWrapper}
                  handleClose={this.handleClosePrimaryColor.bind(this)}
                  handleInputClick={this.handlePrimaryColorClick.bind(this)}
                  displayPicker={this.state.displayPrimaryFontColor}
                  isColorBox={true}
                  selectedColor={selectedFontColor}
                  handleInputChange={this.handlePrimaryColorChange.bind(this)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12}>
                  <Form.Label className="mt-5 mb-3">Upload Schriftart: Standard</Form.Label>
                  <Field
                    name="primary_font"
                    component={UploadDocumentWrapper}
                    text="Bitte laden Sie eine Standard Schrift hoch. Mögliche Formate: .woff, .tff"
                    icon={<FontIcon width='38px'/>}
                    backgroundColor={GRAY_COLOR}
                    widthText="280px"
                    selectedFile={this.props.selectedPrimaryFontFile}
                    onDropCustomHandler={this.handlePrimaryFontFileSelected.bind(this)}
                    acceptedTypes={[".woff", ".ttf"]}
                    onDeleteFile={this.handlePrimaryFontFileDeleted.bind(this)}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12}>
                  <Form.Label className="mt-5 mb-3">Upload Schriftart: Fett</Form.Label>
                  <Field
                    name="primary_font_bold"
                    component={UploadDocumentWrapper}
                    text="Bitte laden Sie eine Standard Schrift hoch. Mögliche Formate: .woff, .tff"
                    icon={<FontIcon width='38px'/>}
                    backgroundColor={GRAY_COLOR}
                    widthText="280px"
                    selectedFile={this.props.selectedPrimaryFontBoldFile}
                    acceptedTypes={[".woff", ".ttf"]}
                    onDeleteFile={this.handlePrimaryFontBoldFileDeleted.bind(this)}
                  />
              </Col>
            </Row>
          </fieldset>
        </Col>
        <Col md={6} lg={6}>
          <fieldset>
            <StyledFormHeading>
              SCHRIFTART SEKUNDÄR
              <Tooltip>
                Die Standardschriftart wird für die Fußzeile in PDF Dokumenten verwendet.
              </Tooltip>
            </StyledFormHeading>
            <Row>
              <Col md={6} lg={6}>
                  <Field
                    name="bt_definition_font_type_two"
                    type="text"
                    labelText="Schriftart Name"
                    disabled={true}
                    component={InputWrapper}
                  />
              </Col>
              <Col md={6} lg={6}>
                  <Field
                    name="bt_definition_font_color_two"
                    labelText="Schriftfarbe"
                    component={ColorPickerWrapper}
                    handleClose={this.handleCloseSecondaryColor.bind(this)}
                    handleInputClick={this.handleSecondaryColorClick.bind(this)}
                    displayPicker={this.state.displaySecondaryFontColor}
                    isColorBox={true}
                    selectedColor={selectedFontColorTwo}
                    handleInputChange={this.handleSecondaryColorChange.bind(
                      this
                    )}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12}>
                  <Form.Label className="mt-5 mb-3">Upload Schriftart: Standard</Form.Label>
                  <Field
                    name="secondary_font"
                    component={UploadDocumentWrapper}
                    text="Bitte laden Sie eine Standard Schrift hoch. Mögliche Formate: .woff, .tff"
                    widthText="280px"
                    icon={<FontIcon width='38px'/>}
                    backgroundColor={GRAY_COLOR}
                    selectedFile={this.props.selectedSecondaryFontFile}
                    onDropCustomHandler={this.handleSecondaryFontFileSelected.bind(this)}
                    acceptedTypes={[".woff", ".ttf"]}
                    onDeleteFile={this.handleSecondaryFontFileDeleted.bind(this)}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12}>
                  <Form.Label className="mt-5 mb-3">Upload Schriftart: Fett</Form.Label>
                  <Field
                    name="secondary_font_bold"
                    component={UploadDocumentWrapper}
                    text="Bitte laden Sie eine Standard Schrift hoch. Mögliche Formate: .woff, .tff"
                    widthText="280px"
                    icon={<FontIcon width='38px'/>}
                    backgroundColor={GRAY_COLOR}
                    selectedFile={this.props.selectedSecondaryFontBoldFile}
                    acceptedTypes={[".woff", ".ttf"]}
                    onDeleteFile={this.handleSecondaryFontBoldFileDeleted.bind(this)}
                  />
              </Col>
            </Row>
          </fieldset>
        </Col>
      </Row>
    );
  }
}

const selector = formValueSelector('productDefinitionForm');

function mapStateToProps(state, ownProps) {
  const selectedPrimaryFontFile = selector(state, 'primary_font');
  const selectedPrimaryFontBoldFile = selector(state, 'primary_font_bold');
  const selectedSecondaryFontFile = selector(state, 'secondary_font');
  const selectedSecondaryFontBoldFile = selector(state, 'secondary_font_bold');
  const selectedFontColor = selector(state, 'bt_definition_font_color');
  const selectedFontColorTwo = selector(state, 'bt_definition_font_color_two');
  return Object.assign({}, ownProps, {
    selectedPrimaryFontFile,
    selectedPrimaryFontBoldFile,
    selectedSecondaryFontFile,
    selectedSecondaryFontBoldFile,
    selectedFontColor,
    selectedFontColorTwo
  })
}

export default connect(mapStateToProps)(EmailBaseDesignFontSelectionForm);
 