import styled from 'styled-components';
import {SECONDARY_WHITE_COLOR, MAIN_COLOR, SECONDARY_COLOR, APP_FONT} from "../../../constants/css";
import {css} from 'styled-components'

//Add icon for Button

const settingsIcon = css`
  content: '';
  background-color: ${({colorText}) => colorText};
  -webkit-mask-image: url(${({icon}) => icon});
  mask-image: url(${({icon}) => icon});
  mask-position: center;
  mask-repeat: no-repeat;
  height: ${({iconWidth = '20px'}) => iconWidth};
  width: ${({iconWidth = '20px'}) => iconWidth};
`

// Adjusts depending on props

const settingsCss = css`
  color: ${({colorText}) => colorText};
  border: 1px solid ${({color}) => color};
  background-color: ${({color}) => color};
  border-radius: ${({variant}) => variant === 'outlined' ? '4px' : '30px'};
  position: relative;

  ${({icon, iconPosition, variant}) => icon && iconPosition === 'right'
          ? css` :after {
            margin-left: 12px;
            ${settingsIcon}
          }`
          : css`:before {
            margin-right: 0;
            margin-right: ${() => icon && '12px'};
            margin-right: ${() => variant === 'text' && icon && '6px'};
            ${() => icon ? settingsIcon : null}
          }`
  }
  :hover {
    background-color: transparent;
    color: ${({color}) => color};

    // The after and before pseudo-elments are used for the icon e.g. arrowLeft
    &::after {
      background-color: ${({color}) => color};
      background-color: ${({variant}) => variant === 'text' && MAIN_COLOR}
    }

    &::before {
      background-color: ${({color}) => color};
      background-color: ${({variant}) => variant === 'text' && MAIN_COLOR}
    }
    
    cursor: ${({hoverCursor}) => hoverCursor? hoverCursor: 'pointer'} ;
  }

  ${({variant}) => variant === 'text' && css`
    border: none;
    background-color: transparent;
    :hover {
      color: ${MAIN_COLOR};
    }
  `}
`

// Customizing buttons

const FlexibleButton = styled.button`
  width: ${({buttonWidth = 'auto'}) => buttonWidth};
  ${settingsCss};

  ${({color}) => color === SECONDARY_WHITE_COLOR && css`
    color: ${SECONDARY_WHITE_COLOR};
    padding: 10px 70px !important;
    background-color: transparent;

    ${({variant}) => !variant && css`
      :hover {
        background-color: ${SECONDARY_WHITE_COLOR};
        color: ${SECONDARY_COLOR}
      }
    `}
  `}

  ${({color}) => color === SECONDARY_COLOR && css`
    //It is need for rewrite css ->
    background-color: transparent;

    color: ${SECONDARY_COLOR};

    ${({variant}) => !variant && css`
      :hover {
        background-color: ${({color}) => color};
        color: ${SECONDARY_WHITE_COLOR};

        &:after, &:before {
          background-color: ${SECONDARY_WHITE_COLOR};
        }
      }
    `}
  `}
`

const DefaultButton = css`
  overflow: hidden;
  font-size: 20px;
  padding: 10px 30px;
  font-family: ${APP_FONT};
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid;
  background-color: transparent;
  border-radius: 30px;
  position: relative;

  :hover {
    outline: none;
    background-color:${({hoverColor = SECONDARY_WHITE_COLOR}) => hoverColor};
    color: ${({hoverColorText = SECONDARY_COLOR}) => hoverColorText};
  }

  :focus {
    outline: none;
  }
`

//Common style for Buttons

const Button = styled(FlexibleButton)`
  // If the button has no color props, the default styles are applied
  ${({color}) => !color && css`${DefaultButton}`}

  overflow: hidden;
  ${({m}) => m && css`
    margin: ${m.split(' ').join(' ')}
  `};
  
  padding: ${({p}) => p ? p.split(' ').join(' ') : '10px 30px'};
  font-size: 20px;
  font-family: ${APP_FONT};
  cursor: pointer;
  display: flex;
  align-items: center;

  :hover {
    outline: none;
  }

  :focus {
    outline: none;
  }
`

export default Button
