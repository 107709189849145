import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Accordion } from "react-bootstrap";
import _ from 'lodash';
import PropTypes from "prop-types";
import {
    Field,
    FieldArray,
    formValueSelector,
    isDirty,
  } from "redux-form";  

import { 
    FIXED_REMUNERATION, 
    SUPPORTED_PLANT_TYPES_OPTIONS, 
    SUPPORTED_SERVICE_FEE_UNIT_TYPES_OPTIONS 
    } from "../../../../../constants";
import PriceCalculationServiceFeeForm from "./PriceCalculationServiceFeeForm";
import { connect } from "react-redux";
import { MAIN_COLOR, 
    SECONDARY_COLOR, 
    NO_ACTIVE_BG_COLOR, 
    NO_ACTIVE_COLOR 
    } from "../../../../../constants/css";
import Button from "../../../../common/styles/Button";
import AccordionItem from "../../../../common/lib/Accordion";
import { FlexFormCol, OuterFormColLeft } from "../../../../common/styles/FormStyles";
import SelectWrapper from "../../../../common/forms/SelectWrapper";
import InputWrapper from "../../../../common/forms/InputWrapper";
import { required } from "redux-form-validators";
import { parseStrToNumber, getLabelText } from "../../../../../utils/commonUtils";
import { ReactComponent as Info } from "../../../../../images/icons/INfo.svg";



export function getPlantTypeOptions(options, addedPlantTypes) {
    //displays plant types except already added ones
    if (addedPlantTypes.length === 0) {
        return options
    }

    return _.filter(options, item => {
        return !addedPlantTypes.includes(item.value)
    });
}

class PlantTypeItemForm extends React.Component {
  render() {
      return (
          <>
              <Row>
                  <Col md={6} lg={6}>
                      <Field
                          name={`${this.props.rowName}.display_name`}
                          component={InputWrapper}
                          type="text"
                          labelText="Display Name der Erzeugungsart"
                          className="mt-4 mb-5"
                          infoIcon={<Info/>}
                          infoIconTooltip="Der Display Name wird in allen E-Mails und PDF Dokumenten verwendet."
                          disabled={this.props.disabled}
                      />
                  </Col>
              </Row>               
              <Row>
                  <Col md={12} lg={12}>
                      <FieldArray name={`${this.props.rowName}.service_fees`} 
                          component={PriceCalculationServiceFeeForm} 
                          props={{
                              minPriceNetto: this.props.minPriceNetto,
                              distributionCostsIndex: this.props.distributionCostsIndex,
                              disabled: this.props.disabled
                          }}
                      />
                  </Col>
              </Row>
              <Row>
                  <Col lg={6} md={6}>
                      <Field
                          name={`${this.props.rowName}.minimum_price_netto`}
                          component={InputWrapper}
                          type="number"
                          labelText="Optional: Minimales monatliches Entgelt"
                          unit="ct/Monat"
                          className="mt-5 mb-4"
                          parse={x => parseFloat(x)}
                          disabled={this.props.disabled}
                      />
                  </Col>
              </Row>
          </>
      )
  }
}

const selector = formValueSelector('productDefinitionForm');

const PlantTypeItemFormConnected = connect((state, ownProps) => {
  const rowName = `${ownProps.rowName}`;
  const minPriceNetto = selector(state, `${rowName}.minimum_price_netto`)===undefined?
      null: selector(state, `${rowName}.minimum_price_netto`);
  const serviceFees = selector(state, `${rowName}.service_fees`)
  /* 
      Instead of a simple boolean to indicate wether distribution costs exist or not
      we extract the index of an existing distribution costs
      The reason: We need the index in ServiceFeeItemForm to adjust the title numbers
  */
  let distributionCostsIndex = null
  if (serviceFees) {
      serviceFees.forEach((fee, i) => {
          if (fee?.classification === "distribution_cost_fee_producer"){
              distributionCostsIndex = i
          }
      })
  }
  return {
      minPriceNetto,
      distributionCostsIndex
  }
})(PlantTypeItemForm)

class PlantTypeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plantTypeOptions: [],
            justCreatedAccordionIndex: 0
        }
    }

    componentDidMount() {
        const addedPlantTypes = this.props.fields.map((item, index, fields) => {
            return fields.get(index).plant_type
        });
        const plantTypeOptions = getPlantTypeOptions(SUPPORTED_PLANT_TYPES_OPTIONS, addedPlantTypes);
        this.setState({ plantTypeOptions });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fields !== this.props.fields) {
            const addedPlantTypes = this.props.fields.map((item, index, fields) => {
                return fields.get(index).plant_type
            });
            const plantTypeOptions = getPlantTypeOptions(SUPPORTED_PLANT_TYPES_OPTIONS, addedPlantTypes);
            this.setState({ plantTypeOptions })
        }
    }

    renderEnabledPlantTypes() {
        return this.props.fields.map((plant, index, fields) => {
            const isFirstItem = index === 0
            const isJustCreatedItem = this.state.justCreatedAccordionIndex === fields.length -1 
            return (
                <div data-testid='plant-accordion' key={index}>
                    <AccordionItem
                        title={getLabelText(SUPPORTED_PLANT_TYPES_OPTIONS, fields.get(index).plant_type)}
                        withDelete={true}
                        index={index}
                        deleteHandler={this.deleteEnabledPlantType.bind(this)}
                        expanded={isFirstItem || isJustCreatedItem}
                        key={index}
                        disabled={this.props.disabled}
                    >
                        
                        <PlantTypeItemFormConnected
                            rowName={plant}
                            index={index}
                            disabled={this.props.disabled}
                        />
                    </AccordionItem>
                </div>
            )
        });
    }

    deleteEnabledPlantType(index) {
        this.props.fields.remove(index);
    }

    addPlantTypeItem() {
        const selected = this.props.selectedPlantType
        const alreadyAddedPlantType = _.find(this.props.fields.getAll(),
            function(plant) { return plant.plant_type === selected; })
        this.setState({ justCreatedAccordionIndex: this.props.fields.length })
        if(this.props.selectedPlantType && alreadyAddedPlantType===undefined){
            this.props.fields.push({ plant_type: this.props.selectedPlantType })
        }
    }

    render() {
        return (
            <>
                {
                    this.props.paymentModel === FIXED_REMUNERATION ? 
                        <Row>
                            <Col md={12} lg={12}>
                                <AccordionItem
                                    title="FESTPREISVERGÜTUNG"
                                    index={-2}
                                    expanded={true}
                                    key={-2}
                                >
                                    <Row >
                                        <FlexFormCol md={6} lg={6}>
                                            <Field
                                                name="remuneration_price_netto"
                                                labelText="Höhe des Festpreises"
                                                component={InputWrapper}
                                                validate={required()}
                                                type="number"
                                                parse={parseStrToNumber}
                                                className="mt-4 mr-3"
                                                disabled={this.props.disabled}
                                            />
                                            <Field
                                                defaultVal={SUPPORTED_SERVICE_FEE_UNIT_TYPES_OPTIONS[0]}
                                                name="remuneration_unit"
                                                labelText="Monetäre Einheit"
                                                component={SelectWrapper}
                                                optionsList={SUPPORTED_SERVICE_FEE_UNIT_TYPES_OPTIONS}
                                                width="300px"
                                                arrowMargin="6px"
                                                className="mt-4"
                                                disabled={true}
                                                infoIcon={<Info/>}
                                                infoIconTooltip="Eine prozentuale Gebühr wird in der Einspeiserechnung auf die netto Gutschrift für die eingespeiste Strommenge angewandt."
                                                tooltipMarginLeft="10px"
                                            />
                                        </FlexFormCol>
                                        <Col md={6} lg={6}>
                                            <Field
                                                name="remuneration_display_name"
                                                component={InputWrapper}
                                                type="text"
                                                validate={required()}
                                                labelText="Display Name des Festpreises"
                                                className="mt-4"
                                                disabled={this.props.disabled}
                                            />
                                        </Col>
                                    </Row>
                                </AccordionItem>
                            </Col>
                        </Row>
                    : null
                }
                <Row>
                    <OuterFormColLeft md={6} lg={6} className="mb-5 mr-3">
                        <Field
                            name="selectedPlantTypeField"
                            labelText="Erzeugungsart"
                            component={SelectWrapper}
                            optionsList={this.state.plantTypeOptions}
                            disabled={this.props.disabled}
                        />
                    </OuterFormColLeft>
                    <Col md={5} lg={5} className="mb-5">
                    <Button
                        type='button'
                        color={this.props.disabled ? NO_ACTIVE_BG_COLOR : MAIN_COLOR}
                        colorText={this.props.disabled ? NO_ACTIVE_COLOR :  SECONDARY_COLOR}
                        onClick={this.addPlantTypeItem.bind(this)}
                        disabled={this.props.disabled}
                        hoverCursor={this.props.disabled ? 'default' : 'pointer'}
                    >
                        Erzeugungsart hinzufügen
                    </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                        {this.props.fields.length > 0 &&
                            <Accordion defaultActiveKey={this.props.fields.get(0).plant_type}>
                                {this.renderEnabledPlantTypes()}
                            </Accordion>
                        }
                    </Col>                    
                </Row>
            </>
        )
    }
}

const mapPlantTypeFormStateToProps = state => {
    return {
        initialValues: state.productOffers.definition.formData,
        // This line below is needed in order to enable submit directly after a
        // Social Media input field got removed
        shouldValidate: () => isDirty("productDefinitionForm")(state),
        selectedPlantType: state.form.productDefinitionForm.values.selectedPlantTypeField,
        paymentModel: state.form.productDefinitionForm.values.payment_model
    }
}

const PlantTypeFormConnected = connect(mapPlantTypeFormStateToProps)(PlantTypeForm)

export { PlantTypeItemFormConnected }
export default PlantTypeFormConnected

PlantTypeItemForm.propTypes = {
    rowName: PropTypes.string,
    deleteHandler: PropTypes.func,
    index: PropTypes.number
};


PlantTypeForm.propTypes = {
    rowName: PropTypes.string,
    deleteHandler: PropTypes.func,
    index: PropTypes.number
};
