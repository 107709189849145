import styled, { css } from "styled-components";
import {
  MAIN_COLOR,
  NO_ACTIVE_BG_COLOR,
  NO_ACTIVE_COLOR,
  WHITE_COLOR,
} from "../../../constants/css";

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: ${({ justifyContent = "center" }) => justifyContent};
  align-items: center;
  margin: ${({ m }) => (m ? m.split(" ").join(" ") : null)};
  padding: ${({ p }) => (p ? p.split(" ").join(" ") : null)};
`;

export const Title = styled.span`
  font-size: 16px;
  margin-right: ${({ positionText }) => positionText === "left" ? "10px" : "0"};
  margin-left: ${({ positionText }) => positionText === "right" ? "10px" : "0"};
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ justifyContent }) =>
    justifyContent === "start" &&
    css`
      margin-left: 0;
      padding-top: 16px;
    `}
`;

export const CheckBoxLabel = styled.label`
  top: 0;
  left: 0;
  width: 58px;
  height: 30px;
  border-radius: 15px;
  background: ${({ backgroundNotActiveColor }) =>
    backgroundNotActiveColor ?? NO_ACTIVE_BG_COLOR};
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
  margin-bottom: 0;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin: 3px;
    background: ${({ switchColor }) => switchColor ?? WHITE_COLOR};
    transition: 0.2s;
  }
  &:hover {
    transform: scale(1.05);
  }
`;

export const CheckBox = styled.input`
  display: none;

  ${(props) =>
    props.checked &&
    css`
      &:checked + ${CheckBoxLabel} {
        background: ${({ backgroundActiveColor }) =>
          backgroundActiveColor ?? MAIN_COLOR};
        ${({ activeDisabled }) =>
          activeDisabled &&
          `&:hover {
        transform: none;
        cursor: default;
      }`}
        &::after {
          margin-left: 30px;
        }
      }
    `}
`;

export const CheckBoxText = styled.span`
  position: absolute;
  left: 0;
  color: ${({ colorLabel = NO_ACTIVE_COLOR }) => colorLabel};
  font-size: 14px;
  top: -10px;
  opacity: ${({ opacityLabelSmall = "1" }) => opacityLabelSmall};
`;
