import { createActionThunk } from 'redux-thunk-actions';
import { FETCH_COSTITEMS, FETCH_COSTITEMS_SUCCEEDED, UPDATE_CLICKINDEX_STATUS } from '../actions/types';
import { COST_ITEMS_ENDPOINT } from '../constants';
import APIRequest from '../api/ApiRequest';

export const updateClickIndexStatus = (status) => dispatch => {
    return dispatch({type: UPDATE_CLICKINDEX_STATUS, payload: status})
}

export const fetchCostItems = (token, callback) => {
    return (dispatch) => {
        let action = createActionThunk(FETCH_COSTITEMS, () =>
        new APIRequest(token).get(COST_ITEMS_ENDPOINT))
        let promise = dispatch(action())
        return promise.then((response) => {
            dispatch({type: FETCH_COSTITEMS_SUCCEEDED, payload: response})
            callback(response.data)
        })
    }
}
