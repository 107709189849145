import styled from "styled-components";

const ContainerDemo = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export default ContainerDemo