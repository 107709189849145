import React from "react";
import {CheckBoxContainer, Indicator, Input, Label} from "../styles/CheckBox";

class CheckBox extends React.Component {
    render() {
        return (
            <CheckBoxContainer className={this.props.className}>
                <Label htmlFor={this.props.id}>
                    <Input 
                        type="checkbox"
                        id={this.props.id}
                        checked={this.props.checked}
                        name={this.props.name}
                        backgroundChecked={this.props.backgroundChecked}
                        onChange={this.props.onChange}
                    />
                    <Indicator 
                        background={this.props.background}
                        borderColor={this.props.borderColor}
                        colorIcon={this.props.colorIcon}
                    />
                </Label>
                {this.props.children}
            </CheckBoxContainer>
        )
    }
}

export default CheckBox
