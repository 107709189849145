import styled from 'styled-components';
import {GRAY_COLOR, NO_ACTIVE_BG_COLOR, SECONDARY_COLOR} from "../../../constants/css";

export const UploadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({flexDirection = 'row'}) => flexDirection};
  justify-content: space-between;
  align-items: ${({isImageFile}) => isImageFile ? 'stretch' : 'center'};
  align-items: ${({isImageFile, fileUploaded}) => isImageFile && fileUploaded && 'flex-start'};
  background: ${({backgroundColor = GRAY_COLOR}) => backgroundColor};
  padding: ${({isImageFile}) => isImageFile ? '0' : '15px 21px'};
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  fill: ${({colorIcon = NO_ACTIVE_BG_COLOR}) => colorIcon};
  margin-right: 30px;
  align-items: center;
`

export const Text = styled.div`
  font-size: ${({textFontSize = '14px'}) => textFontSize};
  width: ${({widthFileText = '100%'}) => widthFileText};
  max-width: 400px;
  color: ${({colorText = SECONDARY_COLOR}) => colorText};
  overflow-wrap: break-word;
  padding-right: 15px;
  margin-bottom: ${({isImageFile}) => isImageFile ? '10px' : '0'};
`

export const DocumentContainer = styled.div`
  display: flex;
  align-items: ${({isImageFile}) => isImageFile ? 'flex-start' : 'center'};
`

export const TextButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
