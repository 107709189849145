import styled, {css} from "styled-components";
import {MAIN_COLOR, NO_ACTIVE_COLOR, SECONDARY_COLOR} from "../../../constants/css";


export const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  margin: ${({m}) => m ? m.split(' ').join(' ') : null};
  padding: ${({p}) => p ? p.split(' ').join(' ') : null};
  & > div {
    white-space: nowrap;
    padding-bottom: 7px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 35px;

  ${({isActive}) => isActive && css`
    border-bottom: ${({
      isActive,
      colorIcon = MAIN_COLOR
    }) => (isActive && colorIcon) ? `5px solid ${colorIcon}` : `5px solid ${NO_ACTIVE_COLOR}`};
    padding-top: 5px;
  `}
`

export const Title = styled.span`
  letter-spacing: 0.54px;
  position: relative;
  color: ${({isActive, isDone, doneItemColor = MAIN_COLOR, activeItemColor = SECONDARY_COLOR}) => isActive
          ? activeItemColor
          : isDone ? doneItemColor : NO_ACTIVE_COLOR
  };
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  cursor: ${({isDone}) => isDone ? 'pointer' : 'default'};
  margin-right: 4px;

  ${({twoLines}) => twoLines && css`
    white-space: pre-line;
    width: 148px;
    text-align: center;
  `}
`

export const Wrapper = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const StatusIcon = styled.span`
  & > svg {
    background: ${({colorIcon = MAIN_COLOR}) => colorIcon};
    border-radius: 50%;
  }
`