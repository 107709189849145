import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {DIRECT_MARKETING_OPTIONS, PAYMENT_MODEL_OPTIONS, SUPPORTED_PLANT_TYPES_OPTIONS} from "../../constants";
import { ProductItemText } from '../common/styles/ProductItem';
import { getLabelText } from '../../utils/commonUtils';

export class ProducerItemSpecifics extends React.Component{
    getDisplayPlantTypes(characteristics){
        const plantTypesFromDefinition = _.get(characteristics, 'supported_plant_types', [])
            const plantTypesFromConstants = _.map(plantTypesFromDefinition, function (plant){
                return _.find(SUPPORTED_PLANT_TYPES_OPTIONS, function (o){
                    return o.value === plant.plant_type
                })
            })
            return _.map(plantTypesFromConstants, 'label').join(', ')
    }

    getLabelText(options, specifiedValue) {
        const foundOption = options.find((option => {
            return option.value === specifiedValue
        }))

        return foundOption?.label
    }

    render(){
        const characteristics = this.props.definition.product_offer_characteristics
        const paymentModel = characteristics.payment_model
        const directMarketing = characteristics.is_sonstige_direktvermarktung
        const displayPlantTypes = this.getDisplayPlantTypes(characteristics)

        return(
            <>
                <ProductItemText>
                    {this.props.definition.owner }
                </ProductItemText>
                <ProductItemText>
                    {getLabelText(DIRECT_MARKETING_OPTIONS, directMarketing)}
                </ProductItemText>
                <ProductItemText>
                    {displayPlantTypes}
                </ProductItemText>
                <ProductItemText>
                    {getLabelText(PAYMENT_MODEL_OPTIONS, paymentModel)}
                </ProductItemText>
            </>
        )
    }
}

ProducerItemSpecifics.propTypes = {
    definition: PropTypes.object
}