import styled from "styled-components";
import { SECONDARY_COLOR, SECONDARY_WHITE_COLOR } from "../../../constants/css"

export const TooltipText = styled.p`
    position: absolute;
    bottom: -5px;
    /*Below line centers the tooltip box*/
    left: ${({tooltipPositionLeft = '109px'}) =>
    tooltipPositionLeft};
    transform: translate(-91%, 100%);
    width: 218px;
    padding: 10px 15px;
    text-align: center;
    background: ${SECONDARY_COLOR};
    color: ${SECONDARY_WHITE_COLOR};
    font-size: 16px;
    transition: .3s;
    opacity: 0;
    pointer-events: none;
`

export const TooltipIcon = styled.svg`
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: ${({tooltipMarginLeft = 0}) =>
    tooltipMarginLeft};
`

export const TooltipContainer = styled.div`
    font-size: 16px;
    font-weight: normal;
    text-transform: none;
    display: inline;
    position: relative;
    pointer-events: all;

    &:hover ${TooltipText} {
    opacity: 1;
    pointer-events: auto;
    z-index: 100000;
  }
`
