import {
    AUTH_USER_STARTED,
    AUTH_USER_SUCCEEDED,
    AUTH_USER_FAILED,
    AUTH_LOGOUT_USER_FAILED,
} from '../actions/types';

import errorHandler from '../actions/errorHandler';
import UserModel from '../models/UserModel';

export const INITIAL_STATE = {
    user: new UserModel(JSON.parse(localStorage.getItem('user'))),
    authenticate: localStorage.getItem('authenticated'),
    error: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH_USER_STARTED:
            return { ...state, error: {}, user: null, authenticate: null };
            //return { ...state, authenticate: action.payload.auth_token, user: action.payload.user };
        case AUTH_USER_SUCCEEDED:
            return { ...state, authenticate: action.payload.data.auth_token, user: new UserModel(action.payload.data.user), error: {}};
                    //return { ...state, authenticate: action.payload.auth_token, user: action.payload.user };
        case AUTH_USER_FAILED: {
            const errorObj = errorHandler(action.payload);
            return { ...state, error: errorObj, user: null, authenticate: null };
        }
        case AUTH_LOGOUT_USER_FAILED:
            return { ...state, error: action.payload };
        default:
            return state;
    }
}