import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux';
import {Field, FieldArray, formValueSelector} from "redux-form";

import InputWrapper from '../../../common/forms/InputWrapper';
import { StyledFormHeading } from '../../../common/styles/FormStyles';
import { SOCIAL_MEDIA_OPTIONS } from '../../../../constants';
import { SocialMediaInputs } from './SocialMediaInputs';
import { ReactComponent as Info } from "../../../../images/icons/INfo.svg";
import { Tooltip } from '../../../common/lib/Tooltip';


export class EmailBaseDesignSoMeForm extends React.Component{
    render(){
        return(
            <Row className="mt-5">
                <Col md={12} lg={12}>
                    <StyledFormHeading>
                        SOCIAL MEDIA
                        <Tooltip>
                            Für jede ausgewählte Social Media Plattform erscheint ein Icon mit hinterlegter URL in den Kunden E-Mails.
                        </Tooltip>
                    </StyledFormHeading>
                    <FieldArray name="socialMedia" component={SocialMediaInputs} props={this.props} />
                    <StyledFormHeading>ABSENDER / BCC EMPFÄNGER</StyledFormHeading>
                    <Row>
                        <Col md={6} lg={6}>
                            <Field
                                labelText="Absender"
                                name="email_from"
                                type="text"
                                component={InputWrapper}
                                className="mt-2 mb-4"
                                infoIcon={<Info/>}
                                infoIconTooltip="Für die Absender Domain muss sichergestellt werden, dass für Lumenaza die Rechte für den E-Mail Versand auf Seiten des B2B Partners eingerichtet wurden."
                            />
                        </Col>
                        <Col md={6} lg={6}>
                            <Field
                                labelText="Optional: BCC"
                                name="email_bcc"
                                type="text"
                                component={InputWrapper}
                                className="mt-2 mb-4"
                                infoIcon={<Info/>}
                                infoIconTooltip="Es kann nur ein BCC Empfänger angegeben werden"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

const selector = formValueSelector('productDefinitionForm');

function mapStateToProps(state, ownProps) {
  let socialMediaOnForm = selector(state, 'socialMedia');
  if (socialMediaOnForm === undefined) {
    socialMediaOnForm = [];
  }

  const addedSocialMedia = socialMediaOnForm.reduce((acc, cv) => {
    acc.push(Object.keys(cv)[0])
    return acc
  }, [])

  const socialMediaOptions = SOCIAL_MEDIA_OPTIONS.filter(item => {
    return !addedSocialMedia.includes(item.value)
  })

  const selectedSocialMedia = selector(state, 'selectSocialMediaField')

  return Object.assign({}, ownProps, {
    socialMediaOptions,
    selectedSocialMedia
  })
}

export default connect(mapStateToProps)(EmailBaseDesignSoMeForm);
