import styled from "styled-components";
import { GRAY_COLOR } from "../../../constants/css";
import Stepper from "../../Stepper"

export const StyledStepper = styled(Stepper)`
    margin-top: 30px;
    padding: 0 100px;
`

export const StyledFormWrapper = styled.div`
    background: ${GRAY_COLOR};
    padding: 45px 100px 200px 100px;
    min-height: calc(100vh - 350px);
`
