export const MAIN_COLOR = '#52F0AB';
export const WHITE_COLOR = '#FFFFFF';
export const GRAY_COLOR = '#F2F5FA';
export const PINK_COLOR = '#F05296';
export const BLUE_COLOR = '#5296F0';
export const ORANGE_COLOR= '#F0AB52';
export const SECONDARY_COLOR = '#222222';
export const SECONDARY_WHITE_COLOR = '#FFFDE9';
export const NO_ACTIVE_BG_COLOR = '#D3D3D3';
export const NO_ACTIVE_COLOR = '#909090';

export const APP_FONT = '"Roboto", sans-serif';