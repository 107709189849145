import React, {Component} from "react";
import {connect} from "react-redux";

import {logout} from "../../actions/authActions";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ...ownProps,
    logoutDispatcher: (postData, token, callback) => {
      dispatch(logout(postData, token, callback));
    }
  };
};

function mapStateToProps(state) {
  const props = Object.assign({}, state);
  const user = state.auth.user;
  return { ...props, token: state.auth.authenticate, user};
}

export default function(WrappedComponent, component="") {
  class ComposedComponent extends Component {
    // Our component just got rendered
    componentDidMount() {
      this.shouldNavigateAway();
    }

    // Our component just got updated
    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      if (!this.props.token) {
        this.props.history.push("/redirect_to_login");
      }
      if(component === "SalesPartner"){
        if (this.props.user.isSalespartnerUser) {
          this.props.history.push("/dashboard");
        }
      }
    }

    render() {
      return (
          <div>
            {this.props.token? <WrappedComponent
            {...this.props}
            component={component}
          />: null}
          </div>

      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComposedComponent);
}
