import styled from "styled-components";
import {BLUE_COLOR, SECONDARY_COLOR, SECONDARY_WHITE_COLOR, WHITE_COLOR} from "../../../constants/css";

export const Container = styled.div`
  width: 100%;
  background: ${({background}) => background ?? BLUE_COLOR};
  color: ${({colorText}) => colorText ?? WHITE_COLOR};
  font-size: 16px;
  padding: 10px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export const InformationWrapper = styled.div`
  display: flex;
`

export const Information = styled.span`
  margin-right: 10px;
`

export const InformationData = styled.span`
  font-weight: bold;
`

export const Slash = styled.span`
  margin: 0 20px;
`

export const CloseInformation = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;

  :hover > span {
    color: ${ ({isHover, hoverColor=SECONDARY_COLOR}) => 
    isHover ? hoverColor : SECONDARY_WHITE_COLOR }
  }
`
