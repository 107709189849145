import { createGlobalStyle } from 'styled-components';
import { SECONDARY_COLOR, APP_FONT } from "./constants/css";
 
const GlobalStyle = createGlobalStyle`
body {
    font-family: ${APP_FONT};
    margin: 0;
    padding: 0;
    color: ${SECONDARY_COLOR};
    height: 100%;
  }
`;
 
export default GlobalStyle;