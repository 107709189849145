import React from "react";

import ProductDefinitionForm from "./forms/ProductDefinitionForm";
import ProductPriceCalculationForm from "./forms/ProductPriceCalculationForm";
import ProductContractParameterForm from "./forms/ProductContractParameterForm";
import ProductOfferDefinitionReview from "./forms/ProductOfferDefinitionReview";
import ProductDocumentEmailsForm from "./forms/ProductDocumentsEmailsForm";
import BaseDesignForm from "./forms/BaseDesignForm";
import { PRODUCT_OFFER_STEPS } from "../../constants";
import {activateDeactivateProductOffer, pollTask, syncProductOffer} from "../../api/calls";
import { StyledFormWrapper, StyledStepper } from "../common/styles/FormWizard";

export class FormWizard extends React.Component {
    render(){
        const { selectedTabKey, checkTabIsActive, onTabSelected } = this.props;
        const { onPreviousPage, onFormSubmit, onFormSaveUpdate } = this.props;
        const priceCalcTitle = this.props.isProducer ? 'Entgelte & Vergütung': 'Abrechnung & Preise';

        return (
            <>
                <StyledStepper
                    items={[
                        { title: "Produkt definition", eventKey: PRODUCT_OFFER_STEPS[1] },
                        { title: priceCalcTitle, eventKey: PRODUCT_OFFER_STEPS[2] },
                        { title: "Vertragsparameter", eventKey: PRODUCT_OFFER_STEPS[3] },
                        { title: "Base Design", eventKey: PRODUCT_OFFER_STEPS[4] },
                        { title: "Rechnungen\n& Dokumente", eventKey: PRODUCT_OFFER_STEPS[5], twoLines: true },
                        { title: "Bearbeitung abschliessen", eventKey: PRODUCT_OFFER_STEPS[6], twoLines: true },
                    ]}
                    activeTab={selectedTabKey}
                    onSelect={onTabSelected}
                    checkTabIsActive={checkTabIsActive}
                ></StyledStepper>
                <StyledFormWrapper>
                    {selectedTabKey === "productDef" &&
                    <ProductDefinitionForm
                        isExistingProduct
                        onSaveProductOffer={onFormSubmit}
                        isProducer={this.props.isProducer}
                        disabled={this.props.disabled}
                        nextTabKey="priceCalc"
                    />}

                    {selectedTabKey === "priceCalc" &&
                    <ProductPriceCalculationForm
                        onSaveProductOffer={onFormSubmit}
                        onPreviousPage={onPreviousPage}
                        previousTabKey="productDef"
                        nextTabKey="contactParm"
                        disabled={this.props.disabled}
                        isProducer={this.props.isProducer}
                    />}

                    {selectedTabKey === "contactParm" &&
                    <ProductContractParameterForm
                        onSaveProductOffer={onFormSubmit}
                        onPreviousPage={onPreviousPage}
                        previousTabKey="priceCalc"
                        nextTabKey="baseDesign"
                        disabled={this.props.disabled}
                        isProducer={this.props.isProducer}
                    />}

                    {selectedTabKey === "baseDesign" &&
                    <BaseDesignForm
                        onSaveProductOffer={onFormSubmit}
                        onPreviousPage={onPreviousPage}
                        previousTabKey="contactParm"
                        nextTabKey="invoiceDocs"
                        isProducer={this.props.isProducer}
                    />}
                    
                    {selectedTabKey === "invoiceDocs" &&
                    <ProductDocumentEmailsForm
                        onSaveProductOffer={onFormSubmit}
                        onPreviousPage={onPreviousPage}
                        previousTabKey="baseDesign"
                        nextTabKey="summary"
                        isProducer={this.props.isProducer}
                    />}

                    {selectedTabKey === "summary" &&
                    <ProductOfferDefinitionReview
                        onSaveProductOffer={onFormSubmit}
                        onPreviousPage={onPreviousPage}
                        previousTabKey="invoiceDocs"
                        onFormSaveUpdate={onFormSaveUpdate}
                        disabled={this.props.disabled}
                        token={this.props.token}
                        activateActionHandler={activateDeactivateProductOffer}
                        updateActionHandler={syncProductOffer}
                        pollTaskHandler={pollTask}
                    />}
                </StyledFormWrapper>
            </>
        );
    }
}

export default FormWizard
