import React from 'react';
import { NO_ACTIVE_BG_COLOR } from '../../constants/css';
import { StyledLogoBox, StyledLogoTextFrame, StyledParentBox, StyledTransparentBox } from './styles/LogoPositioningBox';


class LogoPositioningBox extends React.Component {
    render() {
        return(
            <StyledParentBox>
                <StyledLogoBox width="256px" fill={NO_ACTIVE_BG_COLOR}></StyledLogoBox>
                <StyledTransparentBox>
                    <StyledLogoTextFrame logoRight={this.props.logoRight}>
                    <div>Logo</div>
                    </StyledLogoTextFrame>
                </StyledTransparentBox>
            </StyledParentBox>
        )
    }
}

export default LogoPositioningBox
