import React from "react";
import { StyledFormHeading, StyledFormIcon, StyledFormTitle } from "../styles/FormStyles";


const FormHeading = (props) => {
    const {Icon, children} = props

    return (
        <>
            <StyledFormHeading>
            {Icon
                ? 
                    <StyledFormIcon>
                        <Icon/>
                    </StyledFormIcon>
                
                :
                    null
            }
                <StyledFormTitle>{children}</StyledFormTitle>
            </StyledFormHeading>
        </>
    )
}

export { FormHeading }
