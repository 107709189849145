import React from "react";
import ProductOfferListItem from "./ProductOfferListItem";

export default class ProductOffersList extends React.Component {
  renderList() {
    const getDefinitionJsonHandler = this.props.isSalespartnerUser ? () => undefined : this.props.getDefinitionJsonHandler;
    return this.props.definitions.map(item => {
      return (
        <ProductOfferListItem
          isProducer={this.props.isProducer}
          key={item.id}
          definition={item}
          activateActionHandler={this.props.activateActionHandler}
          updateActionHandler={this.props.updateActionHandler}
          deleteActionHandler={this.props.deleteActionHandler}
          pollTaskHandler={this.props.pollTaskHandler}
          getDefinitionJsonHandler = {getDefinitionJsonHandler}
          updateStatusActivationSuccessful={this.props.updateStatusActivationSuccessful}
          token={this.props.token}
          user={this.props.user}
          userRole={this.props.userRole}
          duplicateActionHandler={this.props.duplicateActionHandler}
        />
      );
    });
  }
  
  render() {
    return(this.renderList());
  }
}
