import React from "react";
import { TooltipContainer, TooltipIcon, TooltipText } from "../styles/TooltipIcon";
import { ReactComponent as Info } from "../../../images/icons/INfo.svg";

class Tooltip extends React.Component {
    render() {
        return (
            <TooltipContainer>
                <TooltipIcon tooltipMarginLeft={this.props.tooltipMarginLeft || "10px"}>
                    {this.props.infoIcon || <Info/>}
                </TooltipIcon>
                <TooltipText>
                    {this.props.children}
                </TooltipText>
            </TooltipContainer>
        )
    }
}

export { Tooltip }
