import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import { Field, reduxForm, formValueSelector, isDirty } from "redux-form";
import _ from 'lodash'
import { change } from "redux-form";
import FormNavigation from '../../common/FormNavigation';
import EmailBaseDesignSoMeForm from './includes/EmailBaseDesignSoMeForm';
import EmailBaseDesignFontSelectionForm from './includes/EmailBaseDesignFontSelectionForm';
import ContactInformationForm from './includes/ContactInformationForm';
import CustomerApproachForm from './includes/CustomerApproachForm';
import { OuterFormColLeft, OuterFormColRight, StyledExplanation, StyledFormHeading } from "../../common/styles/FormStyles";
import styled from "styled-components";
import ComparisonGraphColorSelectionForm from "./includes/ComparisonGraphColorSelectionForm";
import PropTypes from 'prop-types';
import AccordionItem from '../../common/lib/Accordion';
import UploadDocumentWrapper from '../../common/forms/UploadDocumentWrapper';
import { MAIN_COLOR, SECONDARY_COLOR, WHITE_COLOR } from '../../../constants/css';
import TextAreaWrapper from '../../common/forms/TextAreaWrapper';
import { RadioButtons } from '../../common/lib/RadioButtons';
import LogoPositioningBox from '../../common/LogoPositioningBox';
import { Tooltip } from '../../common/lib/Tooltip';

const StyledAccordion = styled(Accordion)`
    margin-bottom : 2%;
`;

export const StyledLabel = styled.label`
    font-size: xx-large;
    padding-bottom: 20px;
`;

const StyledLogoPositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-right:40px;
`

const StyledPositioningCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export class BaseDesignForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLogoFileRight: '',
      selectedLogoFileLeft: '',
      isSelectedLogoFileRight: true,
      displayForegroundColor: false,
      foregroundColor: "#FFFFFF",
    }
  }

  componentDidMount() {
    if (this.props.salespartnerType === 'saas' && this.props.logoRight !== undefined) {
      this.setState({
        isSelectedLogoFileRight: true
      })
    } else if (this.props.salespartnerType === 'saas' && this.props.logoLeft !== undefined) {
      this.setState({
        isSelectedLogoFileRight: false
      })
    }
  }

  handleCloseForegroundColor = () => {
    this.setState({ displayForegroundColor: false });
  };

  handleForegroundColorClick = () => {
    this.setState({
      displayForegroundColor: !this.state.displayForegroundColor
    });
  };

  handleForegroundColorChange = color => {
    this.setState({ foregroundColor: color.hex });
  };

  onImageFieldDeleted(id) {
    this.props.change(id, '');
  }

  onCheckBoxChange(titleLogoButtons) {
    const isSelectedLogoFileRight = titleLogoButtons === 'rechts'

    this.props.dispatch(change('productDefinitionForm', `sales_partner_logo_image_location`, isSelectedLogoFileRight));

    this.setState({ isSelectedLogoFileRight: isSelectedLogoFileRight });
  }

  deleteExistingLogo(logoName) {
    this.props.dispatch(change('productDefinitionForm', logoName, ''));
  }

  render() {
    const titleLogoButtons = this.state.isSelectedLogoFileRight ? "rechts" : "links";
    const selectedLogoRight = this.state.selectedLogoFileRight || this.props.logoRight;
    const selectedLogoLeft = this.state.selectedLogoFileLeft || this.props.logoLeft;
    const titleContactData = this.props.isProducer ? "Angaben und Kontaktdaten zum Direktvermarkter" :
        "Angaben und Kontaktdaten zum Stromlieferanten"

    return (
      // handleSubmit needed for local validation of fields
      <Form onSubmit={this.props.handleSubmit(() => undefined)}>
        <StyledExplanation>
            Hier können Sie das Base Design konfigurieren, das in allen Rechnungen und Dokumenten verwendet wird.
        </StyledExplanation>
        <Row>
          <Col md={12} lg={12}>
            <StyledAccordion defaultActiveKey="logo">
              <AccordionItem title="Logo" expanded={true}>
                <Row>
                  <Col md={6} lg={6}>
                      <Field
                        show={this.state.isSelectedLogoFileRight === true}
                        name="sales_partner_logo_image"
                        component={UploadDocumentWrapper}
                        backgroundColor={WHITE_COLOR}
                        text='Bitte laden Sie Ihr Unternehmenslogo hoch. Mögliche Formate jpg, png, svg. Größe: max. 2 MB'
                        textFontSize='16px'
                        widthFileText='240px'
                        selectedFile={selectedLogoRight}
                        acceptedTypes={['image/png', 'image/jpeg', 'image/svg+xml']}
                        isImageFile={true}
                        onDeleteFile={this.onImageFieldDeleted.bind(this)}
                        maxSize={2000000}
                        showSize
                        onDropCustomHandler={() => this.deleteExistingLogo('cobranding_logo_image')}
                      />
                      <Field
                        show={this.state.isSelectedLogoFileRight === false}
                        name="cobranding_logo_image"
                        component={UploadDocumentWrapper}
                        backgroundColor={WHITE_COLOR}
                        text='Bitte laden Sie Ihr Unternehmenslogo hoch. Mögliche Formate jpg, png, svg. Größe: max. 2 MB'
                        textFontSize='16px'
                        widthFileText='240px'
                        selectedFile={selectedLogoLeft}
                        acceptedTypes={['image/png', 'image/jpeg', 'image/svg+xml']}
                        isImageFile={true}
                        onDeleteFile={this.onImageFieldDeleted.bind(this)}
                        maxSize={2000000}
                        showSize
                        onDropCustomHandler={() => this.deleteExistingLogo('sales_partner_logo_image')}
                      />
                  </Col>
                  {this.props.salespartnerType === 'saas' &&
                    (this.props.logoRight || this.props.logoLeft) &&
                    <StyledPositioningCol md={6} lg={6}>
                      <StyledLogoPositionContainer>
                        <p>Soll das Logo links oder rechtsbündig in den Mails und PDFs angezeigt werden?</p>
                        <RadioButtons
                          activeTab={titleLogoButtons}
                          onSelectFilter={this.onCheckBoxChange.bind(this)}
                          width='100px' 
                          height='30px' 
                          titles={['links', 'rechts']} 
                          backgroundColor={MAIN_COLOR}
                          color={SECONDARY_COLOR}
                        />                 
                      </StyledLogoPositionContainer>
                      <LogoPositioningBox logoRight={this.state.isSelectedLogoFileRight}/>
                    </StyledPositioningCol>
                  }
                </Row>
              </AccordionItem>
              <AccordionItem title="Kundenansprache">
                <CustomerApproachForm />
              </AccordionItem>
              <AccordionItem title="Grußformel & Signatur">
                {!this.props.approach && 
                <Row>
                  <OuterFormColLeft md={6} lg={6}>
                    <Field
                      name="bt_definition_signature_plural"
                      labelText="Grußformel & Signatur - Mehrzahl"
                      component={TextAreaWrapper}
                      placeholder="Bitte fügen Sie eine Grußformel und Signatur ein"
                      border={`1px solid ${SECONDARY_COLOR}`}
                      className="mb-3 mt-3"
                    />
                  </OuterFormColLeft>
                  <OuterFormColRight md={6} lg={6}>
                    <Field
                      name="bt_definition_signature_singular"
                      labelText="Grußformel & Signatur - Einzahl"
                      component={TextAreaWrapper}
                      placeholder="Bitte fügen Sie eine Grußformel und Signatur ein"
                      border={`1px solid ${SECONDARY_COLOR}`}
                      className="mb-3 mt-3"
                    /></OuterFormColRight>
                </Row>}
                {this.props.approach &&
                  <Field
                    name="bt_definition_signature"
                    labelText="Grußformel & Signatur"
                    component={TextAreaWrapper}
                    placeholder="Bitte fügen Sie eine Grußformel und Signatur ein"
                    border={`1px solid ${SECONDARY_COLOR}`}
                    className="mb-3 mt-3"
                  />
                }
              </AccordionItem>
              <AccordionItem title={titleContactData}>
                <ContactInformationForm />
              </AccordionItem>

              <AccordionItem title="E-Mail & PDF Dokumente Base Design">
                <EmailBaseDesignFontSelectionForm />
                <EmailBaseDesignSoMeForm />
              </AccordionItem>
              {!this.props.isProducer &&
              <AccordionItem title="Stromkennzeichnung & Farbschema Stromverbrauch im Vergleich">
                <StyledFormHeading>
                  STROMKENNZEICHNUNG HOCHLADEN
                  <Tooltip>
                    Die Stromkennzeichnung ist ein gesetzlich verpflichtendes Element einer Stromabrechnung. 
                  </Tooltip>
                </StyledFormHeading>
                <Row className="mb-5 pb-3">
                  <Col md={6} lg={6}>
                      <Field
                        name="origination_figure"
                        component={UploadDocumentWrapper}
                        backgroundColor={WHITE_COLOR}
                        text="Bitte laden Sie Ihre Stromkennzeichnung hoch. Mögliche Formate: .png, .jpeg. Größe: max. 2 MB"
                        textFontSize='16px'
                        widthFileText='240px'
                        selectedFile={this.props.stromImage}
                        onDeleteFile={this.onImageFieldDeleted.bind(this)}
                        acceptedTypes={['image/png', 'image/jpeg']}
                        isImageFile={true}
                        maxSize={2000000}
                        showSize
                      />
                  </Col>
                </Row>
                <ComparisonGraphColorSelectionForm />
              </AccordionItem>
              }
            </StyledAccordion>
          </Col>
        </Row>
        <FormNavigation
          onSaveHandler={this.props.onSaveProductOffer}
          onPreviousPageHandler={this.props.onPreviousPage}
          nextTabKey={this.props.nextTabKey}
          previousTabKey={this.props.previousTabKey}
        />
      </Form>
    );
  }
}

BaseDesignForm.propTypes = {
  onSaveProductOffer: PropTypes.func.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  previousTabKey: PropTypes.string.isRequired,
  isProducer: PropTypes.bool
};

BaseDesignForm.defaultProps = {
  isProducer: false
}

const selector = formValueSelector('productDefinitionForm');

function mapStateToProps(state, ownProps) {
  const salespartnerTypeConsumer = _.get(state, 'salespartner.data.sp_type', null);
  const salespartnerTypeProducer = _.get(state, 'salespartner.data.sp_type_dm', null);
  const approach = selector(state, 'salut_definition_salutation_text_is_formal');
  const logoRight = selector(state, 'sales_partner_logo_image');
  const logoLeft = selector(state, 'cobranding_logo_image');
  const salespartnerType = ownProps.isProducer ? salespartnerTypeProducer : salespartnerTypeConsumer;
  const stromImage = selector(state, 'origination_figure');
  return Object.assign({}, ownProps, {
    approach,
    logoRight,
    logoLeft,
    stromImage,
    salespartnerType,
    initialValues: state.productOffers.definition.formData,
    // This line below is needed in order to enable submit directly after a Social Media input field got removed
    shouldValidate: () => isDirty("productDefinitionForm")(state)
  })
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: "productDefinitionForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    touchOnChange: true
  })
)(BaseDesignForm)
