import styled, {css} from "styled-components";
import {MAIN_COLOR, NO_ACTIVE_COLOR, SECONDARY_COLOR} from "../../../constants/css";


export const NavbarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 800px;
  height: 100%;
  flex-wrap: nowrap;
  margin: ${({m}) => m ? m.split(' ').join(' ') : null};
  padding: ${({p}) => p ? p.split(' ').join(' ') : null};
  font-weight: bold;
`

export const TextContainer = styled.div`
  display: flex;
  margin-right: 60px;
  justify-content: flex-start;
  align-items: center;
`

export const Title = styled.span`
  letter-spacing: 0.54px;
  position: relative;
  color: ${({isActive, mainColor = SECONDARY_COLOR}) => isActive && mainColor !== '' ? mainColor : NO_ACTIVE_COLOR};
  text-transform: uppercase;
  font-size: 18px;
  margin-right: 8px;
  cursor: pointer;
  
  ${({isActive}) => isActive && css`
    :after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      height: 5px;
      background: ${({
                  isActive,
                  secondaryColor = MAIN_COLOR
                }) => isActive && secondaryColor !== '' ? secondaryColor : NO_ACTIVE_COLOR};
    }
  `}
`

export const Count = styled.span`
  color: ${({
              isActive,
              secondaryColor = MAIN_COLOR
            }) => isActive && secondaryColor !== '' ? secondaryColor : NO_ACTIVE_COLOR};
`

export const Wrapper = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`
