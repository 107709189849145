
import styled from 'styled-components';
import { NO_ACTIVE_BG_COLOR } from '../../../constants/css';
import { ReactComponent as LogoBox } from '../../../images/icons/LogoBox.svg'

export const StyledLogoBox = styled(LogoBox)`
  width: 256px;
  height: 140px;
`

export const StyledParentBox = styled.div`
  position: relative;
  width: 256px;
  height: 140px;
`

export const StyledTransparentBox = styled.div`
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
`

export const StyledLogoTextFrame = styled.div`
  position: absolute;
  width: 49px;
  height: 40px;
  border: 3px solid ${NO_ACTIVE_BG_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${NO_ACTIVE_BG_COLOR};
  top: 19px;
  left: ${({logoRight = true}) => logoRight ? 'auto' : '19px'};
  right: ${({logoRight = true}) => logoRight ? '19px' : 'auto'};
`
