import _ from 'lodash';

export default class ContractParameterValues {
    revocation_period_in_days = '';
    automatic_prolongation = null;
    contract_notice_period = '';
    contract_notice_period_unit = '';
    minimum_contract_term = '';
    fallback_tariff = null;

    setValuesFromForm(formValues) {
        Object.assign(this, _.pick(formValues, this.getKeys()))
        if (formValues.is_automatic_prolongation && formValues.is_automatic_prolongation === true)
            this.automatic_prolongation = {
                automatic_prolongation_time: formValues.automatic_prolongation_time,
                automatic_prolongation_unit: formValues.automatic_prolongation_unit
            };
        else{
            this.automatic_prolongation = null;
        }
        return this.toJson();
    }

    toJson() {
        const retObj = _.pickBy(this, (value) => { return value !== '' && value !== null })
        return _(retObj).value();
    }

    getKeys() {
        return Object.keys(this);
    }

    setFormValues(podObject) {
        let values = {};
        const keys = Object.keys(this).filter(item => item !== 'automatic_prolongation');
        if (_.has(podObject, 'automatic_prolongation')) {
            values['is_automatic_prolongation'] = true;
            values['automatic_prolongation_time'] = _.get(podObject, 'automatic_prolongation.automatic_prolongation_time', 1);
            values['automatic_prolongation_unit'] = _.get(podObject, 'automatic_prolongation.automatic_prolongation_unit', '');
        } else {
            values['is_automatic_prolongation'] = false;
        }
        Object.assign(values, _.pick(podObject, keys))

        return values;
    }

}