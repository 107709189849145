import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { NO_ACTIVE_BG_COLOR, SECONDARY_COLOR, SECONDARY_WHITE_COLOR } from "../../../constants/css";

export const StyledHeadingBox = styled.div`
  background-color: ${SECONDARY_COLOR};
  color: ${SECONDARY_WHITE_COLOR};
  font-size: 18px;
  padding: 8px 11px;
  font-weight: bold;
  letter-spacing: 0.54px;
`;

export const StyledCol = styled(Col)`
  background-color: ${({ background = NO_ACTIVE_BG_COLOR }) => background};
  color: ${({ color = SECONDARY_COLOR }) => color};
  text-transform: ${({ $textTransform }) => $textTransform};
  height: ${({ height = "auto" }) => height};
  padding: 20px 16px;
`;

export const StyledHalfCol = styled(Col)`
  background-color: ${NO_ACTIVE_BG_COLOR};
  color: ${SECONDARY_COLOR};
  padding: 20px 16px;
  display: inline-block;
  width: calc(50% - 16px);
  position: ${({ $alignRight }) => ($alignRight ? "absolute" : "static")};
  right: ${({ $alignRight }) => ($alignRight ? "15px" : "auto")};
`;

export const CaseButtonContainer = styled.div`
  display flex;
  justify-content: flex-end;
  align-items: flex-end;
  position:relative;
  top: 12px;
  margin-top: 20px;
`;

export const SwitchContainer = styled.div`
  display flex;
  justify-content: flex-end;
`;

export const LeftLogo = styled.img`
  height: 80px;
`;

export const RightLogo = styled.img`
  height: 80px;
  position: absolute;
  right: 16px;
  top: 20px;
`;

export const RadioButtonCases = styled.div`
  margin-left: 20px;
  position: relative;
  top: 6px;
`;

export const StyledFieldset = styled.div`
  margin-top: 50px;
`;

export const StyledLegend = styled.div`
  font-weight: 700;
  font-size: 22px;
`;

export const LogoHeading = styled.p`
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 7px;
`;
