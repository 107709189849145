import ProductDefinitionValues from '../models/ProductDefinitionValues';
import PriceCalculationValues from '../models/PriceCalculationValues';
import ContractParameterValues from '../models/ContractParameterValues';
import ProductOfferValues from '../models/ProductOfferValues';
import BaseDesignValues from '../models/BaseDesignValues';
import { getFileDataFromURL } from './fileHandlingUtils';
import ProducerTariffsValues from '../models/ProducerTariffsValues';

export function wizardDataConverter(formData, extraFields = {}, instance=null, isProducer=false) {
    let poValues = new ProductDefinitionValues();
    let pcValues = isProducer ? new ProducerTariffsValues() : new PriceCalculationValues();
    let cpValues = new ContractParameterValues();
    let emailValues = new BaseDesignValues();
    const wizardValues = {
        ...poValues.setValuesFromForm(formData, isProducer), 
        ...pcValues.setValuesFromForm(formData), 
        ...cpValues.setValuesFromForm(formData), 
        ...emailValues.setValuesFromForm(formData, instance),
        ...extraFields
      };
    return wizardValues;

}

export function productOfferConverter(formData, extraFields = {}) {
    const poValues = new ProductOfferValues();
    return poValues.setValuesFromForm({...formData, ...extraFields});
}

export function getWizardData(formData, extraFieldsWizard = {}, extraFieldPo = {}, instance=null){
    const poData = productOfferConverter(
        formData.values,
        extraFieldPo
      );
    
    let wizardData = wizardDataConverter(
        formData.values,
        extraFieldsWizard,
        instance,
        poData['is_producer']
      );


    const poValues = {
        ...poData,
        product_offer_characteristics: JSON.stringify(wizardData)
      };
      return poValues;
}


export function runValidation(registeredFields = [], syncErrors, errorsToIgnore = []) {
    let newSyncErrors
    if (errorsToIgnore.length > 0) {
      const syncErrorsArray = Object.entries(syncErrors)

      newSyncErrors = syncErrorsArray.filter(entry => {
        const [, error] = entry
        const keepErrorEntry = !errorsToIgnore.includes(error)
        return keepErrorEntry
      })
    } else {
      // Create real deepcopy
      newSyncErrors = JSON.parse(JSON.stringify(syncErrors));
    }

    const regFieldsKeys = Object.keys(registeredFields);
    const syncErrorsKeys = Object.keys(newSyncErrors || {});
    const fieldErrors = syncErrorsKeys.filter(
      item => regFieldsKeys.includes(item) && newSyncErrors[item]
    );
    return fieldErrors;
}

async function getFileWithURL(fileKey, fileUrl) {
  const file = await getFileDataFromURL(fileUrl)
  return [fileKey, file]
}

 export async function getFormData(status, formData, isProducer=false, instance=null, stepProgress = 1){
    let form = new FormData();
    form.append("status", status);
    const extraFieldWizard =  {
        is_ht_nt: false,
        step_progress: stepProgress 
      }

    let values = getWizardData(formData, extraFieldWizard, {is_producer:isProducer}, instance);
    const fileKeys = new ProductOfferValues().getFileKeys();

    // For attributes with non-file values
    Object.keys(values).forEach(key => {
      if ((values[key] && values[key] !== "null")) {
        form.append(key, values[key]);
      }
    });

    // For attributes with file values
    await Promise.all(fileKeys.map(async fileKey => {
      if (values[fileKey] && typeof values[fileKey] === 'string') {
        const fileResult = await getFileWithURL(fileKey, values[fileKey])
        form.append(fileResult[0], fileResult[1])
      } else {
        form.append(fileKey, values[fileKey])
      }
    }))

    return form;
  }
