import {combineReducers} from 'redux';
import {reducer as reduxForm } from 'redux-form';

import auth from './auth';
import salespartner from './salesPartner';
import productOffers from './productOffers';
import sections from './emailDocumentsSections'
import costItems from './costItems'

export default combineReducers({
    auth,
    salespartner,
    productOffers,
    sections,
    costItems,
    form: reduxForm
});