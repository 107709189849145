import { createActionThunk } from 'redux-thunk-actions';
import APIRequest from '../api/ApiRequest';
import {
    AUTH_USER,
    AUTH_LOGOUT_USER
} from './types';



export const login = (formProps, callback, errorCallback) => {
    return (dispatch) => {
        let action = createActionThunk(AUTH_USER, () =>
            new APIRequest().post('/auth/login/', formProps));
        let promise = dispatch(action());
        return promise.then((response) => {
            return callback(response.data.auth_token, response.data.user);            
        }).catch(error => {
            console.log(error)
            errorCallback(error);
        });
    };
};

export const logout = (formProps, token, callback) =>{
    return (dispatch) => {
        let fetch = createActionThunk(AUTH_LOGOUT_USER, () =>
            new APIRequest(token).post('/auth/logout/', formProps));
        let promise = dispatch(fetch());
        return promise.then(() => {
            callback();            
        }).catch(() => {
            callback();
        });
    };
};