import React, {Component} from "react";
import sorryIcon from "../../images/Sorry.svg";
import {ErrorPageStyle, StyledParagraph} from "./styles/ErrorPageStyle";
import {BLUE_COLOR, SECONDARY_WHITE_COLOR} from "../../constants/css";
import Button from "./styles/Button";
import styled from 'styled-components';

const StyledButton = styled(Button)`
    margin: 0 auto;
`;

export class RedirectToLogin extends Component {
    handleClick = () => {
        this.props.history.push("/login")
    }
    render() {
        return (
            <ErrorPageStyle>
                <img src={sorryIcon} alt="man who is sorry" />
                <StyledParagraph>Bitte loggen Sie sich ein!</StyledParagraph>
                <StyledButton
                    onClick={this.handleClick}
                    color={BLUE_COLOR}
                    colorText={SECONDARY_WHITE_COLOR}
                     type="button"
                >
                    Anmelden
                </StyledButton>
            </ErrorPageStyle>
        )
    }
}