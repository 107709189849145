import _ from 'lodash';
export default function errorHandler(error) {
    if (error.response) {
        const errorData = error.response.data;
        let message = '';
        if(_.has(errorData, 'message')){
            message = error.response.data.message;
        }else if(_.has(errorData, 'non_field_errors')){
            message = error.response.data.non_field_errors[0];
        }        
        return {message: message, status: error.response.status}
    } else if (error.request) {
        return {message: `Server Error, bitte kontaktieren Sie Ihren Administrator`, status: 500}
    }
    return {message: error.message, status: 500}

}