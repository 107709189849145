import React from "react";
import LumenazaIcon from "../../images/lum_logo_redesign.svg";
import { Container, Row, Col } from "react-bootstrap";
import { SECONDARY_WHITE_COLOR } from "../../constants/css";
import { POD_NAME } from "../../constants";
import Button from "../common/styles/Button";
import { ImageCol, StartPageHeading, StartPageSubHeading, StartPageContentCol, ButtonWrapper, LumenazaLogo} from "../common/styles/StartPages";
import Form from "react-bootstrap/Form";
import {connect} from "react-redux";
import {compose} from "redux";
import {reduxForm, Field } from "redux-form";
import { login } from "../../actions/authActions";
import { required } from "redux-form-validators";
import {toast} from "react-toastify";
import InputWrapper from "../common/forms/InputWrapper";
import UserModel from "../../models/UserModel";
import {getSalespartnerData} from "../../api/calls";
import {FETCH_SALESPARTNER_SUCCEEDED} from "../../actions/types";


export class Login extends React.Component {
  errCallback = () => { toast("Can't login with the given credentials", {type: "error"}); };

  successCallback = (token, user) => {
    localStorage.setItem("authenticated", token);
    localStorage.setItem("user", JSON.stringify(user));
    const userObj = new UserModel(user);
    if (userObj.isSalespartnerUser === true) {
      const cb = (spObj) => {
        localStorage.setItem("salespartner", JSON.stringify(spObj));
        this.props.dispatch({type: FETCH_SALESPARTNER_SUCCEEDED, payload: {data: spObj}});
        this.props.history.push("/dashboard");
      };
      return getSalespartnerData(token, user.salespartner, cb);
     
    } else {
      this.props.history.push("/salespartner");
      return;
    }
  }
  
  onSubmit = formProps => {    
    this.props.loginUser(formProps, this.successCallback, this.errCallback);
  };

  render() {
    const { handleSubmit } = this.props;
    const { error } = this.props.auth;

    return (
      <Container fluid>
        <Row>
          <ImageCol lg={5}>
            <LumenazaLogo src={LumenazaIcon} />
          </ImageCol>
          <StartPageContentCol lg={7}>
            <Container>
              <Row className="justify-content-center">
                <Col lg={9}>
                  <StartPageHeading>{POD_NAME}</StartPageHeading>
                  <StartPageSubHeading>Login</StartPageSubHeading>
                  <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <Field
                      name="username"
                      component={InputWrapper}
                      type="text"
                      labelText="Benutzername"
                      colorLabel={SECONDARY_WHITE_COLOR}
                      opacityLabelSmall="0.8"
                      colorBorder={SECONDARY_WHITE_COLOR}
                      colorText={SECONDARY_WHITE_COLOR}
                      className="mb-4 pb-2"
                      serverError={error.message || ""}
                      validate={required()}
                    />
                    <Field
                      name="password"
                      component={InputWrapper}
                      type="password"
                      labelText="Passwort"
                      colorLabel={SECONDARY_WHITE_COLOR}
                      opacityLabelSmall="0.8"
                      colorBorder={SECONDARY_WHITE_COLOR}
                      colorText={SECONDARY_WHITE_COLOR}
                      className="mb-4 pb-4"
                      autoComplete="none"
                      validate={required()}
                    />
                    <ButtonWrapper>
                      <Button
                        type="submit"
                        color={SECONDARY_WHITE_COLOR}
                        colorText={SECONDARY_WHITE_COLOR}
                      >
                        anmelden
                      </Button>
                    </ButtonWrapper>
                  </Form>
                </Col>
              </Row>
            </Container>
          </StartPageContentCol>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = function(dispatch) {
  return {
    loginUser: (formData, cb, errCb) => {
      return dispatch(login(formData, cb, errCb));
    }
  };
};

function mapStateToProps(state) {
  return Object.assign({}, {auth: state.auth})
}

export default compose(
  reduxForm({form: "login"}),
  connect(mapStateToProps, mapDispatchToProps),
)(Login)
