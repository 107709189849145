import APIRequest from "./ApiRequest";
import {PRODUCT_OFFER_ENDPOINT, TASK_POOLING_ENDPOINT, PREVIEWER_ENDPOINT} from "../constants";


export const activateDeactivateProductOffer = (token, productOffer, action) => {
    const updateEntryPoint = `${PRODUCT_OFFER_ENDPOINT}${productOffer}/${action}/`;
    return new APIRequest(token).put(updateEntryPoint);
}

export const syncProductOffer = (token, productOffer) => {
    const syncEntryPoint = `${PRODUCT_OFFER_ENDPOINT}${productOffer}/synchronize/`;
    return new APIRequest(token).get(syncEntryPoint);
}

export const pollTask = (token, taskId) => {
    const pollingEntryPoint = `${TASK_POOLING_ENDPOINT}/${taskId}/`;

    return new APIRequest(token).get(pollingEntryPoint);
};

export const previewDocumentEmail = (token, payload, callback, errorCallback) => {
    const previewerEndPoint = `${PREVIEWER_ENDPOINT}`;    
    return new APIRequest(token).post(previewerEndPoint, payload).then(response => {
        callback(response)
    }).catch(() => {
        errorCallback()
    })
};

export const getDefinitionAsJson = (token, productOffer, callback) => {
    const retrieveEndPoint = `${PRODUCT_OFFER_ENDPOINT}${productOffer}/json/`;
    return new APIRequest(token).get(retrieveEndPoint).then(response => {
        callback(response)
    })
};

const SALESPARTNER_GET_ENDPOINT = "/api/salespartner/";
export const getSalespartnerData = async (token, spid, callback) => {
    const retrieveEndPoint = `${SALESPARTNER_GET_ENDPOINT}${spid}/`;
    const response = await new APIRequest(token).get(retrieveEndPoint);
    callback(response.data);
};

const PASSWORD_RESET_ENDPOINT = "/auth/reset/";

export const checkUIDTokenData = async(uid, token, errorCallback) => {
    const endpoint = `${PASSWORD_RESET_ENDPOINT}${uid}/${token}/`;
    await new APIRequest().get(endpoint).catch(err=>errorCallback(err));
}


export const resetUserPassword = async(formData, uid, token, successCallback, errorCallback) => {
    const endpoint = `${PASSWORD_RESET_ENDPOINT}${uid}/${token}/`;
    await new APIRequest().post(endpoint, formData).then(response => successCallback(response.data))
    .catch(err=>errorCallback(err));
};


const UPDATE_USER_ENDPOINT = "/auth/user/reset_password/"
export const updatePassword = async(formData, token, successCallback, errorCallback) => {
    const endpoint = `${UPDATE_USER_ENDPOINT}`;
    await new APIRequest(token).post(endpoint, formData).then(response => successCallback(response.data))
    .catch(err=>errorCallback(err));
};