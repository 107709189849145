import styled, {css} from "styled-components";
import {
    MAIN_COLOR,
    NO_ACTIVE_BG_COLOR,
    NO_ACTIVE_COLOR,
    PINK_COLOR,
    SECONDARY_COLOR,
    WHITE_COLOR
} from "../../../constants/css";

const getBorderBottomColor = (props) => {
  const { isError, errorColor, disabled } = props

  if (isError) {
    return errorColor || PINK_COLOR
  }

  if (disabled) {
    return NO_ACTIVE_COLOR
  }

  return SECONDARY_COLOR
}

export const SelectBox = styled.div`
${({optionSelected}) => !optionSelected && css`
  height: 46px;
`}
  border: 0;
  border-bottom: 1px solid ${props => getBorderBottomColor(props)};
  outline: 0;
  font-size: 18px;
  color: ${({selectedItemColor = NO_ACTIVE_COLOR}) => selectedItemColor};
  padding-top: 18px;
  background: transparent;
  transition: border-color 0.2s;
  text-align: left;
  position: relative;
  
  margin-bottom: -6.5px; // needed for right distance to input field below
  cursor: ${({disabled = false}) => disabled ? "default" : "pointer"};

  &::after {
    content: " ";
    position: absolute;
    right: ${({arrowMargin = '18px'}) => arrowMargin};
    bottom: 14px;
    border-right: 2px solid ${({colorIconClose = SECONDARY_COLOR, disabled}) => disabled ? NO_ACTIVE_COLOR : colorIconClose};
    border-top: 2px solid ${({colorIconClose = SECONDARY_COLOR, disabled}) => disabled ? NO_ACTIVE_COLOR : colorIconClose};
    width: 11px;
    height: 11px;
    transform: rotate(-225deg);
    display: block;
    transition: .2s ease-in;
  }

  ${({showOptionList}) => showOptionList && css`
    &::after {
      top: 28px;
      transform: rotate(-45deg);
      border-color: ${({colorIconOpen = MAIN_COLOR}) => colorIconOpen};
    }

    border-bottom: 1px solid ${NO_ACTIVE_BG_COLOR};
  `}
`

export const SelectOptions = styled.ul`
  // position absolute needed to avoid pushing below content down
  position: absolute;
  z-index: 100;
  margin: 0;
  padding: 0 9px;
  text-align: left;
  width: 100%;
  background: ${WHITE_COLOR};
  box-shadow: 0 3px 6px #00000029;
`

export const ItemList = styled.li`
  list-style-type: none;
  padding-top: 13px;
  color: ${({colorItem = SECONDARY_COLOR}) => colorItem};
  font-size: 18px;
  cursor: pointer;

  :first-child {
    padding-top: 8px;
  }

  :last-child {
    padding-bottom: 13px;
  }

  &:hover {
    color: ${({colorIconOpen = MAIN_COLOR}) => colorIconOpen};
  }
`

export const Label = styled.label`
  font-size: 18px;
  transition: 0.2s ease all;
  pointer-events: none;
  position: absolute;
  top: 14px;
  ${({optionSelected}) => optionSelected && css`
    font-size: 14px;
    top: -10px;
  `}
`

export const SelectedText = styled.span`
  ${({optionSelected}) => !optionSelected && css`
    position: absolute;
    left: 5px;
    top: 14px;
  `}
  color: ${({selectedItemColor = SECONDARY_COLOR, disabled}) =>
  disabled ? NO_ACTIVE_COLOR : selectedItemColor};
`
