import _ from 'lodash';

export function filterElementsFromDict(newObject, values, prefix){
    Object.assign(newObject, 
        _.mapKeys(_.pick(values, Object.keys(values)
            .filter(k => { return k.substring(0, prefix.length) === prefix  && (values[k] !== '' && values[k] !== null) })), 
            (v, k) => { return k.substring(prefix.length + 1)
    }));
    return newObject;
}

export function setElementsFromPrefix(values, prefix){
    return _.mapKeys(values, (v, k) => {
        return `${prefix}_${k}` 
    });
    
}