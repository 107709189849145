import React from 'react';
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux';
import {compose} from 'redux';
import { required} from 'redux-form-validators'

import { Across, DialogContainer, DialogContent, DialogWindowContainer, DialogWrapper, HeaderDialog, HeaderText } from '../common/styles/DialogWindow';
import Button from '../common/styles/Button';
import { MAIN_COLOR, SECONDARY_COLOR, SECONDARY_WHITE_COLOR } from '../../constants/css';
import IconButton from '../common/lib/IconButton';
import {ReactComponent as Duplicate} from '../../images/icons/duplicate.svg'
import styled from 'styled-components';
import InputWrapper from '../common/forms/InputWrapper';

function submit(values, dispatch, props){
    return props.acceptAction(
        props.token,
        props.itemId,
        values,
        () => {props.successCallback()},
        (e) => {props.errorCallback(e)},
    )
}

const StyledField = styled(Field)`
    margin-bottom: 30px;
`;
StyledField.displayName = 'StyledField';

const ButtonPopUpWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 60px;
    margin-bottom: 25px;
`;


export class DuplicationModalForm extends React.Component{

    render(){
        const { handleSubmit} = this.props;
       
        return(
            <DialogContainer>
                <DialogWindowContainer>
                    <Across onClick={() => this.props.onHide()}/>
                    <HeaderDialog>
                        <IconButton 
                            isLarge
                            isFill 
                            colorFill={SECONDARY_COLOR}
                            colorBorder={SECONDARY_COLOR}
                            colorFillHover={SECONDARY_COLOR}
                            colorFillIcon={SECONDARY_WHITE_COLOR}
                            isStaticButton={true}
                        > 
                            <Duplicate fill={SECONDARY_WHITE_COLOR}/>
                        </IconButton>
                        <HeaderText>{this.props.modalQuestion}</HeaderText>
                    </HeaderDialog>
                    <DialogWrapper>
                        <DialogContent>
                            <form onSubmit={handleSubmit}>
                                <StyledField 
                                    name='display_name'
                                    component={InputWrapper}
                                    validate={required()}
                                    labelText='Displayname des Duplikats'
                                />
                                {this.props.hiddenSPField &&
                                <Field 
                                    name='salespartner_id'
                                    component={InputWrapper}
                                    validate={required()}
                                    labelText='Salespartner'
                                    hidden={this.props.hiddenSPField}
                                /> }
                                <ButtonPopUpWrapper>
                                    <Button
                                        onClick={() => this.props.onHide()}
                                        color={SECONDARY_COLOR}
                                        colorText={SECONDARY_COLOR}
                                        className="ml-4"
                                    >
                                        abbrechen
                                    </Button>
                                    <Button
                                        type="submit"
                                        color={MAIN_COLOR}
                                        colorText={SECONDARY_COLOR}
                                        className="mr-4"
                                    >
                                        duplizieren
                                    </Button>
                                </ButtonPopUpWrapper>
                            </form>
                        </DialogContent>
                    </DialogWrapper>
                </DialogWindowContainer>
            </DialogContainer>
        )
    }
}

function mapStateToProps(state, ownProps){
    const {user} = ownProps;
    const sp = user.isSalespartnerUser ? user.salespartner : null;
    return Object.assign({}, ownProps, {
        initialValues: {salespartner_id: sp},
        hiddenSPField: !user.isSalespartnerUser
      })
};



export default compose(
    connect(mapStateToProps),
    reduxForm({
      form: 'duplicationForm',
      onSubmit: submit
    }))(DuplicationModalForm);
