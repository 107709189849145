import React from 'react';
import Select from '../lib/Select';

// To decouple the Select component from the Field component
class SelectWrapper extends React.Component {
    render(){
        return (
            <Select
                {...this.props}
                touched={this.props.meta.touched}
                error={this.props.meta.error}
                value={this.props.input.value}
                onChange={this.props.input.onChange}
                onBlur={this.props.input.onBlur}
            />
        )
    }
}

export default SelectWrapper
