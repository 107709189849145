import React from "react";
import {ReactComponent as Done} from "../images/icons/ok.svg";
import {StatusIcon, StepperContainer, TextContainer, Title} from "./common/styles/Stepper";

class Stepper extends React.Component {
    checkIsDone(eventKey, index) {
        return  this.props.checkTabIsActive(index + 1)  && 
                this.props.activeTab !== eventKey
    }

    clickHandler(eventKey, index) {
        if (this.checkIsDone(eventKey, index)) {
            this.props.onSelect(eventKey, index + 1)
        }
    }

    render() {
        return (
            <StepperContainer className={this.props.className}>
                {this.props.items.map((item, index) => (
                    <TextContainer 
                        key={item.eventKey} 
                        onClick={() => this.clickHandler(item.eventKey, index)}
                        isActive={this.props.activeTab === item.eventKey}
                        colorIcon={this.props.colorIcon}
                    >
                        <Title
                            twoLines={item.twoLines}
                            doneItemColor={this.props.doneItemColor}
                            activeItemColor={this.props.activeItemColor}
                            isActive={this.props.activeTab === item.eventKey}
                            isDone={this.checkIsDone(item.eventKey, index)}
                        >
                            {item.title}
                        </Title>
                        { this.checkIsDone(item.eventKey, index) && 
                            <StatusIcon colorIcon={this.props.colorIcon}>
                                <Done width="20px" height="20px"/>
                            </StatusIcon>
                        }
                    </TextContainer>
                ))}
            </StepperContainer>
        )
    }
}

export default Stepper