import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import {
  fetchProductOffers,
  deleteProductOffer,
  duplicateProductOffer,
  updateStatusActivationSuccessful, 
  updateProductType,
} from '../../actions/productOffersActions';
import { fetchCostItems } from '../../actions/costItemActions';
import requireAuth from '../auth/requireAuth';
import ProductOffersList from './ProductOffersList';
import StatusTabs from './StatusTabs';
import ConsumerProducerTabs from './ConsumerProducerTabs';

import { activateDeactivateProductOffer, syncProductOffer, pollTask } from '../../api/calls';
import logout from '../auth/logout';

import { GRAY_COLOR, SECONDARY_COLOR } from '../../constants/css';
import { PRODUCT_OFFER_DEFINITION_STATUS } from '../../constants';
import IconButton from '../common/lib/IconButton';
import {ReactComponent as Plus} from '../../images/icons/plus.svg'
import Layout from '../common/Layout';

const Title = styled.h1`
  color: ${SECONDARY_COLOR};
  font-size: 40px;
  margin-right: 115px;
`

const FirstRow = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 50px 100px;
`

const TabWrapper = styled.div`
  position: relative;
  bottom: 12px;
`
const SecondRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 100px 5px 100px;
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
  background: ${GRAY_COLOR}
`

const PlusWrapper = styled.div`
  position: relative;
  bottom: 5px;
`

export class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTypeTab: 'Verbraucher',
      activeStatusTab: 'Alle'
    }
  }

  componentDidMount() {
    this.callFetchFunction();
  }

  callFetchFunction() {
    const spid = _.get(this.props.salespartner, 'data.spid', '');
    const subSp = _.get(this.props.salespartner, 'data.subsalespartners', [])
    const token = this.props.token;
    this.props.fetchProductOffers(token, spid, subSp);
    this.props.fetchCostItems(token, (data) => {
        localStorage.setItem("costItems",JSON.stringify( data));
    })
  }

  logoutRedirect() {
    const callback = () => logout(this.props.history);
    const token = localStorage.getItem('authenticated');
    const postData = { token };
    this.props.logoutDispatcher(postData, token, callback)
  }

  onSelectTypeFilter(tabTitle) {
    this.setState({ activeTypeTab: tabTitle })
  }

  onSelectStatusFilter(tabTitle) {
    this.setState({ activeStatusTab: tabTitle })
  }

  handlePlusClick(){
    const isProducer = this.state.activeTypeTab === 'Produzent'
    this.props.updateProductType(isProducer)
    this.props.history.push('/configurator');
  }

  renderFilteredDefinitions(defsTypeFiltered, defsFullyFiltered) {
    return(
      <>
        <SecondRowWrapper>
          <StatusTabs 
            definitions={defsTypeFiltered}
            onSelectFilter={this.onSelectStatusFilter.bind(this)}
            activeStatusTab={this.state.activeStatusTab}
          />
          <PlusWrapper>
            <IconButton 
              isFill 
              isLarge 
              text={'Neues Produkt anlegen'} 
              positionText={'right'}
              onClick={this.handlePlusClick.bind(this)}
              className='mb-3'
            >
              <Plus/>
            </IconButton>
          </PlusWrapper>
        </SecondRowWrapper>
        <ListWrapper>
          <ProductOffersList
            isProducer={this.state.activeTypeTab === 'Produzent'}
            definitions={defsFullyFiltered}
            activateActionHandler={activateDeactivateProductOffer}
            updateActionHandler={syncProductOffer}
            deleteActionHandler={this.props.deleteProductOffer}
            updateStatusActivationSuccessful={this.props.updateStatusActivationSuccessful}
            duplicateActionHandler={this.props.duplicateDefinition}
            pollTaskHandler={pollTask}
            token={this.props.auth.authenticate}
            user={this.props.user}
          />
        </ListWrapper>
      </>)
  }

  statusFilterDefinitions(definitions) {
    if (this.state.activeStatusTab === PRODUCT_OFFER_DEFINITION_STATUS['activated'].label) {
      return _.filter(definitions, { 'status': 'activated' })
    } else if (this.state.activeStatusTab === PRODUCT_OFFER_DEFINITION_STATUS['draft'].label) {
      return _.filter(definitions, { 'status': 'draft' })
    } else if (this.state.activeStatusTab === 'Alle') {
      return definitions
    }
  }

  typeFilterDefinitions(definitions) {
    const isProducer = this.state.activeTypeTab === 'Produzent';
    return _.filter(definitions, { 'is_producer': isProducer })
    
  }

  displayList(definitions) {
    const defintionsTypeFiltered = this.typeFilterDefinitions(definitions)
    const definitionsFullyFiltered = this.statusFilterDefinitions(defintionsTypeFiltered)

    return this.renderFilteredDefinitions(defintionsTypeFiltered, definitionsFullyFiltered)
  }

  render() {
    const { definitions } = this.props.productOffers;

    return (
      <Layout
        {...this.props}
      >
        <FirstRow>
          <Title>Strom Produkte</Title>
          <TabWrapper>
            <ConsumerProducerTabs
              activeTypeTab={this.state.activeTypeTab}
              onSelectFilter={this.onSelectTypeFilter.bind(this)}
            />
          </TabWrapper>
        </FirstRow>
        {this.displayList(definitions)}
      </Layout>
    );
  }
}

const mapDispatchToProps = function (dispatch, ownProps) {
  return {
    ...ownProps,
    deleteProductOffer: (token, spid, callback) => {
      return dispatch(deleteProductOffer(token, spid, callback));
    },
    fetchProductOffers: (token, spid, subSp) => {
      return dispatch(fetchProductOffers(token, spid, subSp));
    },
    duplicateDefinition: (token, id, formData, callback, errorCallback) => {
      return dispatch(duplicateProductOffer(token, id, formData, callback, errorCallback));
    },
    updateStatusActivationSuccessful: (id) => {
      return dispatch(updateStatusActivationSuccessful(id))
    },
    updateProductType: (type) => {
      return dispatch(updateProductType(type))
    },
    fetchCostItems: (token, cb) => {
      return dispatch(fetchCostItems(token, cb));
    },
    dispatch
  };
};

export default compose(
  connect(null, mapDispatchToProps),
)(requireAuth(Dashboard, 'Dashboard'));

Dashboard.propTypes = {
  token: PropTypes.string,
  productOffers: PropTypes.object,
  salespartner: PropTypes.object
}