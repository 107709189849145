import React from 'react';
import Input from '../lib/Input';

// To decouple the Input component from the Field component
class InputWrapper extends React.Component {
    render(){
        return (
            <Input
                {...this.props}
                touched={this.props.meta.touched}
                error={this.props.meta.error}
                active={this.props.meta.active}
                value={this.props.input.value}
                onChange={this.props.input.onChange}
                onBlur={this.props.input.onBlur}
            />
        )
    }
}

export default InputWrapper
