import {
    RETRIEVE_DEFINITION_FAILED,
    UPDATE_DEFINITION_FAILED,
    RESET_FORM_DATA,
    FETCH_DEFINITIONS_STARTED,
    FETCH_DEFINITIONS_FAILED,
    FETCH_DEFINITIONS_SUCCEEDED,
    DELETE_DEFINITION_FAILED,
    DELETE_DEFINITION_SUCCEEDED,
    CREATE_DEFINITION_FAILED,
    CREATE_DEFINITION_SUCCEEDED,
    RETRIEVE_DEFINITION_SUCCEEDED,
    UPDATE_DEFINITION_SUCCEEDED,
    UPDATE_DEFINITION_STARTED,
    UPDATE_DEFINITION_CUCO_SUCCEEDED,
    DUPLICATE_DEFINITION_FAILED,
    DUPLICATE_DEFINITION_SUCCEEDED,
    UPDATE_STATUS_ACTIVATION,
    UPDATE_PRODUCTS_TYPE,
} from '../actions/types';

import errorHandler from '../actions/errorHandler';

import ProductOfferParser from '../utils/ProductOfferParser';

export const INITIAL_STATE = {
    definitions: null,
    newDefinition: null,
    definition: {
        data: {},
        formData: {}
    },
    updatedDefinition: null,
    error: {},
};

export default function(state=INITIAL_STATE, action){
    let errorObj;
    let formData;
    switch(action.type){
        case FETCH_DEFINITIONS_STARTED:
            return {...state, definitions: null, error: {} };
        case FETCH_DEFINITIONS_SUCCEEDED:
            return {...state, definitions: action.payload.data, error: {} };
        case FETCH_DEFINITIONS_FAILED:
            errorObj = errorHandler(action.payload);
            return {...state, error: errorObj, definitions: null };
        
        case DELETE_DEFINITION_SUCCEEDED: {
            const defs = state.definitions.filter(item => item.id !== action.payload.data.id)
            return {...state, definitions: defs };
        }    
        case DELETE_DEFINITION_FAILED:
            errorObj = errorHandler(action.payload);
            return {...state, error: action.payload };

        case CREATE_DEFINITION_SUCCEEDED:
            formData = determineWizardStepState(action.payload.data);
            return {
              ...state,
              newDefinition: action.payload.data,
              definition: Object.assign(
                {},
                state.definition,
                {data: action.payload.data, formData: formData}
              )
            };

        case CREATE_DEFINITION_FAILED:
            return {...state, error: action.payload };

        case RETRIEVE_DEFINITION_SUCCEEDED:
            formData = determineWizardStepState(action.payload.data);
            return {
              ...state,
              definition: Object.assign(
                {},
                state.definition,
                {data: action.payload.data, formData: formData}
              )
            };

        case RETRIEVE_DEFINITION_FAILED:
            return {...state, error: action.payload };

        case UPDATE_DEFINITION_STARTED:
            return {...state, updatedDefinition: null, error: {} };

        case UPDATE_DEFINITION_SUCCEEDED:
            formData = determineWizardStepState(action.payload.data);
            return {
              ...state,
              definition: Object.assign({},
                state.definition,
                {data: action.payload.data, formData: formData}
              )
            };

        case UPDATE_DEFINITION_CUCO_SUCCEEDED:
            return {...state, updatedDefinition: action.payload.data };

        case UPDATE_DEFINITION_FAILED:
            errorObj = errorHandler(action.payload);
            return {...state, error: action.payload, updatedDefinition: null };

        case RESET_FORM_DATA:
                return {...state, definition: Object.assign({}, state.definition, {data: {}, formData: {}})};

        case DUPLICATE_DEFINITION_SUCCEEDED: {
            const newState = action.payload.data.spid !== state.definition.spid ? state : getNewStateFromDuplication(state, action.payload.data);
            return newState
        }
        case DUPLICATE_DEFINITION_FAILED:
            return {...state, error: action.payload };

        case UPDATE_STATUS_ACTIVATION: {
            let newDefinition = state.definitions.filter(item => item.id === action.payload)[0]
            newDefinition.status = "activated"
            newDefinition.has_pending_edits = false
            const itemId = newDefinition.id
            const definitions = state.definitions.filter(item => {
                return item.id !== itemId;
            })
            return {...state, definitions: [...definitions, newDefinition]};  
        }

        case UPDATE_PRODUCTS_TYPE:
            return {...state, is_producer: action.payload};

        default:
            return state;

    }
}


function determineWizardStepState(jsonValues){
    const po = new ProductOfferParser(jsonValues);
    return po.determineProductOfferState();
}

function getNewStateFromDuplication(state, newItem){
    return {...state, definitions: [...state.definitions, newItem]}
}
