import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { registerExtraFields } from "../../../utils/editorUtils";
import { StyledHeadingBox } from "../../common/styles/EditorSections";
import InputWrapper from "../../common/forms/InputWrapper";
import { ReactComponent as Info } from "../../../images/icons/INfo.svg";

const DOCUMENT_DEFAULT_VALUES = ["file_name"];

class DocumentMain extends React.Component {
    componentDidMount() {
      registerExtraFields(
        DOCUMENT_DEFAULT_VALUES,
        this.props.values,
        this.props.defaultValues,
        this.props.dispatch
      );
    }
  
    render() {
      return (
        <>
          <fieldset>
            <StyledHeadingBox>PDF-Dokument</StyledHeadingBox>
            <Field
              name="file_name"
              type="text"
              labelText="Dateiname des PDF-Dokument"
              component={InputWrapper}
              className="mt-4"
              disabled
              infoIcon={<Info/>}
              infoIconTooltip="Der Dateiname wird bei Versand des PDF Dokuments erzeugt und enthält kundenspezifische Angaben, die hier in geschweiften Klammern als platzhalter angedeutet sind."
            />
          </fieldset>
          {this.props.children}
        </>
      );
    }
  } 

export default connect()(DocumentMain);