import { applyMiddleware, createStore, compose} from 'redux';
import reducer from '../reducers';
import {INITIAL_STATE as authInitialState} from '../reducers/auth';
import ReduxThunk from 'redux-thunk';

import {createLogger} from 'redux-logger'


const logMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV !== 'production'
});

let middleware = [ReduxThunk, logMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const stores = createStore(
    reducer,
    {auth: authInitialState},
    composeEnhancers(
    applyMiddleware(...middleware)),
)


export default stores;