import React from "react";
import { Field } from 'redux-form'

import Table from "react-bootstrap/Table";
import { 
    MAIN_COLOR, 
    SECONDARY_COLOR, 
    WHITE_COLOR, 
    NO_ACTIVE_BG_COLOR, 
    NO_ACTIVE_COLOR 
    } from "../../../../../constants/css";
import Button from "../../../../common/styles/Button";
import IconButton from "../../../../common/lib/IconButton";
import {ReactComponent as Delete} from "../../../../../images/icons/delete.svg";
import styled from "styled-components";
import { StyledTh } from "../../../../common/styles/FormStyles";
import InputWrapper from "../../../../common/forms/InputWrapper";
import { required } from "redux-form-validators";
import { parseStrToNumber } from "../../../../../utils/commonUtils";
import { Tooltip } from "../../../../common/lib/Tooltip";



const TableContainer = styled.div`
    margin-top: 48px;
    padding: 5px 15px;
    background: ${WHITE_COLOR};
`

const StyledTd = styled.td`
    border: none !important;
`

const StyledTdForDelete = styled(StyledTd)`
    width: 30px;
    vertical-align: bottom !important;
`

const IconWrapper = styled.div`
    padding-bottom: 6px;
`

export default class ProducerSpotServiceFeeForm extends React.Component {
    componentDidMount(){
        if(this.props.emptyMatrix){
            this.props.fields.removeAll();
        }

        this.createAtLeastOneRow()
    }
    
    renderFields() {
        return this.props.fields.map((rowData, index) => (
            <ProducerSpotServiceFeeItemForm
                key={rowData}
                rowName={rowData}
                index={index}
                deleteHandler={this.deleteRow.bind(this)}
                disabled={this.props.disabled}
            />
        ))
    }

    deleteRow(index) {
        this.props.fields.remove(index);
    }

    createAtLeastOneRow() {
        if (this.props.fields.length === 0) {
            this.props.fields.push()
        }
    }

    componentDidUpdate() {
        this.createAtLeastOneRow()
    }

    render() {
        return (
            <TableContainer>
                <Table>
                    <thead>
                        <tr>
                            <StyledTh>
                                Ab installierte Leistung
                                <Tooltip>
                                    Hier können mehrere Stufen installierter Leistung in kWp definiert werden. Für jede Stufe kann ein Entgelt angegeben werden. Bei Vertragsabschluss wird ein Produzent anhand seiner installierten Leistung direkt der richtigen Stufe zugeordnet und mit dem angegeben Entgelt abgerechnet. Die kleinste Stufe sollte mit 0 kWp definiert werden.
                                </Tooltip>
                            </StyledTh>
                            <StyledTh>Entgelt in {this.props.unit}</StyledTh>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderFields()}
                    </tbody>
                </Table>
                <Button
                    type="button"
                    color={this.props.disabled ? NO_ACTIVE_BG_COLOR : MAIN_COLOR}
                    colorText={this.props.disabled ? NO_ACTIVE_COLOR :  SECONDARY_COLOR}
                    onClick={() => {this.props.fields.push()}}
                    className="mt-4 mb-3"
                    disabled={this.props.disabled}
                    hoverCursor={this.props.disabled ? 'default' : 'pointer'}
                >
                    Leistung hinzufügen
                </Button>
            </TableContainer>
        )
    }
}

export class ProducerSpotServiceFeeItemForm extends React.Component {
    render() {
        return (
            <tr >
                <StyledTd>
                    <Field
                        name={`${this.props.rowName}.capacity`}
                        component={InputWrapper}
                        type="number"
                        validate={required()}
                        parse={parseStrToNumber}
                        disabled={this.props.disabled}
                    />
                </StyledTd>
                <StyledTd>
                    <Field
                        name={`${this.props.rowName}.price_netto`}
                        component={InputWrapper}
                        type="number"
                        validate={required()}
                        parse={parseStrToNumber}
                        disabled={this.props.disabled}
                    />
                </StyledTd>
                <StyledTdForDelete>
                    <IconWrapper>
                        <IconButton
                            tooltip='löschen' 
                            positionTooltip='top'
                            width='20px'
                            onClick={() => this.props.deleteHandler(this.props.index)}
                            disabled={this.props.disabled}
                        >
                            <Delete fill={SECONDARY_COLOR} />
                        </IconButton>
                    </IconWrapper>
                </StyledTdForDelete>
            </tr>
        )
    }
}
