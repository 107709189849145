import React from "react";
import styled, { css } from "styled-components";
import {
  NUMBER_REGEX,
  LENGTH_REGEX,
  SPECIAL_CHARS_REGEX
} from "./utils";
import { PASSWORD_HINT_MIN_LENGTH, PASSWORD_HINT_NUMBER_REQUIRED, PASSWORD_HINT_SPECIAL_CHAR_REQUIRED } from '../../constants';

const rules = [
    { label: PASSWORD_HINT_MIN_LENGTH, pattern: LENGTH_REGEX },
    { label: PASSWORD_HINT_NUMBER_REQUIRED, pattern: NUMBER_REGEX },
    { label: PASSWORD_HINT_SPECIAL_CHAR_REQUIRED, pattern: SPECIAL_CHARS_REGEX }
  ];

const CheckList = styled.p`
  color: ${({passed, passedColor, defaultColor}) => passed ? passedColor : defaultColor};
  ${({passed}) => passed && css`

        &::before {
          content: "✔";
          display: inline-block;
          margin-right: 0.4em;
        }
    `}

`
  
  const PasswordCheckList = (props) => {
    return (
      <div>
        {rules.map((rule) => {
          const rulePatterns =  props.value && props.value.match(rule.pattern);
          return <CheckList key={rule.label} passed={rulePatterns} defaultColor={props.defaultColor} passedColor={props.passedColor}>{rule.label}</CheckList>;
        })}
      </div>
    );
  };
  
  export default PasswordCheckList;