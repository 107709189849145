import _ from "lodash";
import ContractParameterValues from "../models/ContractParameterValues";
import BaseDesignValues from "../models/BaseDesignValues";
import ProductDefinitionValues from "../models/ProductDefinitionValues";
import PriceCalculationValues from "../models/PriceCalculationValues";
import ProductOfferValues from "../models/ProductOfferValues";
import ProducerTariffsValues from "../models/ProducerTariffsValues";

export const NUMBER_STEPS = 4;

export const STEP_1 = [
  "owner",
  "display_name",
  "product_description",
  "is_for_business",
  "product_category",
  "period_billing_interval",
];
export const STEP_2_CONSUMER = [
  "working_prices",
  "price_calculation",
  "monthly_fees",
];
export const STEP_2_PRODUCER = ["supported_plant_types", "fixed_remuneration"];
export const STEP_3 = [
  "revocation_period_in_days",
  "automatic_prolongation",
  "contract_notice_period",
  "contract_notice_period_unit",
  "minimum_contract_ter",
];
export const STEP_4 = ["emails", "documents"];

export default class ProductOfferParser {
  constructor(jsonObj) {
    this.jsonObj = jsonObj;
  }

  setSteps(isProducer = false) {
    const cpObj = new ContractParameterValues();
    const pcObj = !isProducer
      ? new PriceCalculationValues()
      : new ProducerTariffsValues();
    const bdObj = new BaseDesignValues();
    const pdObj = new ProductDefinitionValues();
    const steps_2 = !isProducer ? STEP_2_CONSUMER : STEP_2_PRODUCER;
    return {
      1: { values: STEP_1, instance: pdObj },
      2: { values: steps_2, instance: pcObj },
      3: { values: STEP_3, instance: cpObj },
      4: { values: STEP_4, instance: bdObj },
    };
  }

  getValue(key, defaultValue = "") {
    return _.get(this.jsonObj, key, defaultValue);
  }

  getStepValues(keys) {
    let values = {};
    keys.forEach((key) => {
      values[key] = this.getValue(key);
    });
    return values;
  }

  responseValueNotEmpty(value) {
    switch (typeof value) {
      case "object":
        return Object.keys(value).length !== 0;
      default:
        return value !== "";
    }
  }

  determineProductOfferState() {
    const responseKeys = Object.keys(
      this.jsonObj.product_offer_characteristics
    );
    const response = this.jsonObj.product_offer_characteristics;
    const steps = this.setSteps(this.jsonObj.is_producer);
    const poValues = {
      ...this.jsonObj.product_offer_characteristics,
      ..._.pickBy(this.jsonObj, (v, k) => {
        return k !== "product_offer_characteristics";
      }),
    };

    let formData = {};
    for (let i = 1; i <= NUMBER_STEPS; i++) {
      let keys = steps[i].values;
      if (
        keys.some((v) => {
          return (
            responseKeys.includes(v) && this.responseValueNotEmpty(response[v])
          );
        })
      ) {
        let model = steps[i].instance;
        let values = model.setFormValues(poValues);
        Object.assign(formData, values);
      } else {
        break;
      }
    }

    const baseValuesObj = new ProductOfferValues();
    let values = baseValuesObj.setFormValues(poValues);
    Object.assign(formData, values);
    return formData;
  }
}

