import styled, { css } from "styled-components";
import {MAIN_COLOR, SECONDARY_COLOR, SECONDARY_WHITE_COLOR, WHITE_COLOR} from "../../../constants/css";

export const AccordionContainer = styled.div`
    margin-bottom: ${({marginBottom = "60px"}) => marginBottom};
    margin-top: ${({marginTop = "0"}) => marginTop};
`

export const AccordionBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    background: ${({barBackgroundClosed= SECONDARY_COLOR }) => barBackgroundClosed};
    position: relative;
    padding-right: 10px;
    cursor: pointer;

    &::after {
        content: " ";
        position: absolute;
        left: 10px;
        bottom: 12px;
        border-right: 2px solid ${SECONDARY_WHITE_COLOR};
        border-top: 2px solid ${SECONDARY_WHITE_COLOR};
        width: 10px;
        height: 10px;
        transform: rotate(-45deg);
        display: block;
        transition: .2s ease-in;
    }

    ${({expanded}) => expanded && css`
        background: ${({barBackgroundOpen = MAIN_COLOR }) => barBackgroundOpen};

        &::after {
        top: 14px;
        transform: rotate(-225deg);
        border-color: ${SECONDARY_COLOR};
        }
    `}
`

export const AccordionContent = styled.div`
    padding: 20px;
    background: ${({contentBackground = WHITE_COLOR}) => contentBackground};
    margin-bottom: ${({marginBottom = "60px"}) => marginBottom};
`

export const Text = styled.span`
    font-size: 18px;
    color: ${({expanded}) => expanded ? SECONDARY_COLOR : SECONDARY_WHITE_COLOR};
    margin-left: 30px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.54px;
`
