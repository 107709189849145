import React from 'react';
import {
    SideBarContainer,
    SideBarIcon,
    SideBarItem,
    SideBarTitle,
    SideBarTitleContainer
} from "../styles/SideBar";
import { StyledSimpleBar } from '../styles/SimpleBar';
import { TooltipContainer, TooltipIcon, TooltipText } from '../styles/TooltipIcon';

class TextVariables extends React.Component {
    render() {
        return (
            <SideBarContainer
                left={this.props.left}
                right={this.props.right}
                top={this.props.top}
                bottom={this.props.bottom}
            >
                <SideBarTitleContainer>
                    <SideBarTitle colorTitle={this.props.colorTitle}>
                        {this.props.title}
                    </SideBarTitle>
                    <TooltipContainer>
                        <TooltipIcon>
                            {this.props.iconTitle}
                        </TooltipIcon>
                        <TooltipText tooltipPositionLeft='0'>
                            {this.props.tooltipForIcon}
                        </TooltipText>
                    </TooltipContainer>
                </SideBarTitleContainer>
                    <StyledSimpleBar style={{ height: 'calc(100% - 1px)'}} autoHide={false}>
                    {this.props.variables && this.props.variables.map((item, index) => 
                        <SideBarItem
                            key={`${index}_${item.variable_name}`}
                            hoverColorText={this.props.hoverColorText}
                            colorText={this.props.colorText}
                            onClick={() => this.props.onClick(item)}
                        >
                            <SideBarIcon>
                                {this.props.icon}
                            </SideBarIcon>
                            <div>{item.label}</div>
                        </SideBarItem>)}
                    </StyledSimpleBar>
            </SideBarContainer>
        )
    }
}

export default TextVariables;
