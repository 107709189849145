import axios from "axios";

import {BASIC_USER, BASIC_PWD, IS_TEST} from '../constants';


export default class APIRequest {
    constructor(token=null){
        this.token = token;
    }

    get(url, body, headers={}, json=true) {
        return this.request(url, 'get', body, headers, json);
    }

    post(url, body, headers={}, json=true) {
        return this.request(url, 'post', body, headers, json);
    }


    put(url, body, headers={}, json=true) {
        return this.request(url, 'put', body, headers, json);
    }

    delete(url, body, headers={}, json=true) {
        return this.request(url, 'delete', body, headers, json);
    }

    getAuthHeaders(){
        let tokenAuth = `Token ${this.token}`;
        let basicAuth = `Basic ${btoa(BASIC_USER +':'+ BASIC_PWD)}`;
        let authHeader = '';
        
        if(this.token && IS_TEST){
            authHeader = `${tokenAuth}, ${basicAuth}`
        }
        else if(this.token){
            authHeader = tokenAuth
        }else if(IS_TEST){
            authHeader = basicAuth;
        }
        
        return {'Authorization': authHeader};
    }

    request(endpoint, method, body={}, headers={}) {
        const authHeader = this.getAuthHeaders();
        const allHeaders = {...headers, ...authHeader};
        const fullURL = this.buildFinalUrl(endpoint);
        let options = {
            headers: allHeaders,
            responseType: 'json',
            baseUrl: process.env.REACT_APP_BASE_URL,
            method: method,
            url: fullURL,
            qsStringifyOptions: {arrayFormat: 'repeat'}
        };
        switch(method) {
            case 'get':
                options.params = body;
                break;
            default:
                options.data = body;
        }
        return axios(options);
    }

    buildFinalUrl(partialUrl) {
        return process.env.REACT_APP_BASE_URL + partialUrl;
    }

    static getBaseUrl(path) {
        return process.env.REACT_APP_BASE_URL + path;
    }
}
