const PRICE_CHANGE_CONFIRMATION = 'price_change_confirmation_consumer';
const INSTALLMENT_BILL = 'installment_bill';
const INSTALLMENT_BILL_EMAIL = 'installment_bill_email';
export const PAYMENT_METHOD_CHANGE_CONSUMER = 'payment_method_change_consumer'
const MONTHLY_BILL = 'monthly_bill';
const MONTHLY_BILL_EMAIL = 'monthly_bill_email';
const YEAR_BILL = 'year_bill';
const YEAR_BILL_EMAIL = 'year_bill_email';
const FINAL_BILL = 'final_bill';
const FINAL_BILL_EMAIL = 'final_bill_email';
const UPDATE_INSTALLMENT_PLAN = 'update_installment_plan';
const MONTHLY_BILL_STORNO = 'monthly_bill_storno';
const MONTHLY_BILL_EMAIL_STORNO = 'monthly_bill_email_storno';
const YEAR_BILL_STORNO = 'year_bill_storno';
const YEAR_BILL_EMAIL_STORNO = 'year_bill_email_storno';
const FINAL_BILL_STORNO = 'final_bill_storno';
const FINAL_BILL_EMAIL_STORNO = 'final_bill_email_storno';
const FEED_IN_BILL = 'feed_in_bill'
const FEED_IN_BILL_EMAIL = 'feed_in_bill_email'
const FEED_IN_BILL_STORNO = 'feed_in_bill_storno'
const FEED_IN_BILL_STORNO_EMAIL = 'feed_in_bill_email_storno'
const ORDER_CONFIRMATION = 'order_confirmation_email';
const ORDER_CONFIRMATION_PRODUCER = 'order_confirmation_email_producer';
const DISTRIBUTION_COST_CLEARING_BILL = 'dist_cost_clearing_bill'
const DISTRIBUTION_COST_CLEARING_BILL_STORNO = 'dist_cost_clearing_bill_storno'
const DISTRIBUTION_COST_CLEARING_BILL_EMAIL = 'dist_cost_clearing_bill_email'
const DISTRIBUTION_COST_CLEARING_BILL_STORNO_EMAIL = 'dist_cost_clearing_bill_email_storno'
// currently only supports consumers
const DELIVERY_CONFIRMATION = 'delivery_confirmation_consumer';
const DELIVERY_CONFIRMATION_PRODUCER = 'delivery_confirmation_producer';
const PASSWORD_RESET = 'password_reset';
const CONTRACT_REVOCATION_CONFIRMATION = 'contract_revocation_confirmation_email';
const WELCOME_MAIL_CONSUMER = 'welcome_email_consumer';
const WELCOME_MAIL_PRODUCER = 'welcome_email_producer';
const CANCELLATION_CONFIRMATION = 'cancellation_confirmation_email';
const PRODUCT_SWITCH = 'product_switch';
const CONTRACT_START = 'contract_start_email';

export const EMAILS_DOCS_ITEMS_CONSUMER_SPOT = [
    {cuco: MONTHLY_BILL, email: MONTHLY_BILL_EMAIL, document: MONTHLY_BILL, label: 'Monatsrechnung'},
    {cuco: MONTHLY_BILL_STORNO, email: MONTHLY_BILL_EMAIL_STORNO, document: MONTHLY_BILL_STORNO, label: 'Storno Monatsrechnung'},
];

export const EMAILS_DOCS_ITEMS_CONSUMER = [
    {cuco: INSTALLMENT_BILL, email: INSTALLMENT_BILL_EMAIL, document: INSTALLMENT_BILL, label: 'Abschlagsrechnung'},
    {cuco: YEAR_BILL, email: YEAR_BILL_EMAIL, document: YEAR_BILL, label: 'Jahresrechnung'},
    {cuco: FINAL_BILL, email: FINAL_BILL_EMAIL, document: FINAL_BILL, label: 'Endabrechnung'},
    {cuco: UPDATE_INSTALLMENT_PLAN, email: UPDATE_INSTALLMENT_PLAN, document: UPDATE_INSTALLMENT_PLAN, label: 'Abschlagsneuberechnung'},
    {cuco: YEAR_BILL_STORNO, email: YEAR_BILL_EMAIL_STORNO, document: YEAR_BILL_STORNO, label: 'Storno Jahresrechnung'},
    {cuco: FINAL_BILL_STORNO, email: FINAL_BILL_EMAIL_STORNO, document: FINAL_BILL_STORNO, label: 'Storno Endabrechnung'},
];

export const OTHER_CUCOS_CONSUMER = [
    {cuco: ORDER_CONFIRMATION, email: ORDER_CONFIRMATION, label: 'Auftragsbestätigung'},
    {cuco: DELIVERY_CONFIRMATION, email: DELIVERY_CONFIRMATION, document: DELIVERY_CONFIRMATION, label: 'Lieferbestätigung'},
    {cuco: PASSWORD_RESET, email: PASSWORD_RESET, label: 'Passwort zurücksetzen'},
    {cuco: CONTRACT_REVOCATION_CONFIRMATION, email: CONTRACT_REVOCATION_CONFIRMATION, label: 'Widerrufsbestätigung'},
    {cuco: WELCOME_MAIL_CONSUMER, email: WELCOME_MAIL_CONSUMER, label: 'Willkommensmail (+Passwort)'},
    {cuco: CONTRACT_START, email: CONTRACT_START, label: 'Lieferbeginn'},
    {cuco: CANCELLATION_CONFIRMATION, email: CANCELLATION_CONFIRMATION, label: 'Kündigungsbestätigung'},
    {cuco: PRICE_CHANGE_CONFIRMATION, email: PRICE_CHANGE_CONFIRMATION, document: PRICE_CHANGE_CONFIRMATION, label: 'Ankündigung Preisänderung'},
    {cuco: PRODUCT_SWITCH, email: PRODUCT_SWITCH, document: PRODUCT_SWITCH, label: 'Produktwechsel'},
    {cuco: PAYMENT_METHOD_CHANGE_CONSUMER, email: PAYMENT_METHOD_CHANGE_CONSUMER, label: 'Wechsel der Zahlmethode zu SEPA'},    
];

export const OTHER_CUCOS_CONSUMER_SPOT = [
    {cuco: ORDER_CONFIRMATION, email: ORDER_CONFIRMATION, label: 'Auftragsbestätigung'},
    {cuco: DELIVERY_CONFIRMATION, email: DELIVERY_CONFIRMATION, document: DELIVERY_CONFIRMATION, label: 'Lieferbestätigung'},
    {cuco: CONTRACT_REVOCATION_CONFIRMATION, email: CONTRACT_REVOCATION_CONFIRMATION, label: 'Widerrufsbestätigung'},     
    {cuco: WELCOME_MAIL_CONSUMER, email: WELCOME_MAIL_CONSUMER, label: 'Willkommensmail (+Passwort)'},
    {cuco: CONTRACT_START, email: CONTRACT_START, label: 'Lieferbeginn'},
    {cuco: PASSWORD_RESET, email: PASSWORD_RESET, label: 'Passwort zurücksetzen'},
    {cuco: PRICE_CHANGE_CONFIRMATION, email: PRICE_CHANGE_CONFIRMATION, document: PRICE_CHANGE_CONFIRMATION, label: 'Ankündigung Preisänderung'},
    {cuco: CANCELLATION_CONFIRMATION, email: CANCELLATION_CONFIRMATION, label: 'Kündigungsbestätigung'},    
    {cuco: PAYMENT_METHOD_CHANGE_CONSUMER, email: PAYMENT_METHOD_CHANGE_CONSUMER, label: 'Wechsel der Zahlmethode zu SEPA'},
    {cuco: PRODUCT_SWITCH, email: PRODUCT_SWITCH, document: PRODUCT_SWITCH, label: 'Produktwechsel zum Dynamischen Tarif'},
];

export const EMAILS_DOCS_ITEMS_PRODUCER = [
    {cuco: FEED_IN_BILL, email: FEED_IN_BILL_EMAIL, document: FEED_IN_BILL, label: 'Einspeiserechnung'},
    {cuco: FEED_IN_BILL_STORNO, email: FEED_IN_BILL_STORNO_EMAIL, document: FEED_IN_BILL_STORNO, label: 'Storno Einspeiserechnung'},
];

export const EMAILS_DOCS_ITEMS_PRODUCER_DISTRIBUTION = [
    {cuco: FEED_IN_BILL, email: FEED_IN_BILL_EMAIL, document: FEED_IN_BILL, label: 'Einspeiserechnung'},
    {cuco: FEED_IN_BILL_STORNO, email: FEED_IN_BILL_STORNO_EMAIL, document: FEED_IN_BILL_STORNO, label: 'Storno Einspeiserechnung'},
    {cuco: DISTRIBUTION_COST_CLEARING_BILL, email: DISTRIBUTION_COST_CLEARING_BILL_EMAIL, document: DISTRIBUTION_COST_CLEARING_BILL, label: 'Schlussrechnung Vermarktungskosten'},
    {cuco: DISTRIBUTION_COST_CLEARING_BILL_STORNO, email: DISTRIBUTION_COST_CLEARING_BILL_STORNO_EMAIL, document: DISTRIBUTION_COST_CLEARING_BILL_STORNO, label: 'Storno Schlussrechnung Vermarktungskosten'},
];

export const OTHER_CUCOS_PRODUCER = [
    {cuco: ORDER_CONFIRMATION_PRODUCER, email: ORDER_CONFIRMATION_PRODUCER, label: 'Auftragsbestätigung'},
    {cuco: DELIVERY_CONFIRMATION_PRODUCER, email: DELIVERY_CONFIRMATION_PRODUCER, document: DELIVERY_CONFIRMATION_PRODUCER, label: 'Lieferbestätigung'},
    {cuco: PASSWORD_RESET, email: PASSWORD_RESET, label: 'Passwort zurücksetzen'},
    {cuco: CONTRACT_REVOCATION_CONFIRMATION, email: CONTRACT_REVOCATION_CONFIRMATION, label: 'Widerrufsbestätigung'},
    {cuco: WELCOME_MAIL_PRODUCER, email: WELCOME_MAIL_PRODUCER, label: 'Willkommensmail (+Passwort)'},
    {cuco: CONTRACT_START, email: CONTRACT_START, label: 'Lieferbeginn'},
    {cuco: CANCELLATION_CONFIRMATION, email: CANCELLATION_CONFIRMATION, label: 'Kündigungsbestätigung'},
    {cuco: PRODUCT_SWITCH, email: PRODUCT_SWITCH, document: PRODUCT_SWITCH, label: 'Produktwechsel'},    
];
