export function checkStatus(status){
    return status === "activated";
}

export function parseStrToBool(value){
    return typeof(value) === 'boolean' ?  value : value.toLowerCase() === 'true';
}

export function parseStrToNumber(value) {
    return value === '' ? '' : parseFloat(value)
}

export function getLabelText(options, specifiedValue) {
    if (specifiedValue === "true") {
        specifiedValue = true
    }

    if (specifiedValue === "false") {
        specifiedValue = false
    }
    
    const foundOption = options.find((option => {
        return option.value === specifiedValue
    }))

    return foundOption ? foundOption.label : ''
}

export function downloadTxtFile(content, fileName, fileType) {
    const element = document.createElement("a");
    const file = new Blob([content], { type: {fileType} });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
  }
