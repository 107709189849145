import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { updateStatusActivationSuccessful } from '../../actions/productOffersActions';
import {toast} from 'react-toastify';
import styled from "styled-components";
import Button from '../common/styles/Button';
import { MAIN_COLOR, SECONDARY_COLOR, SECONDARY_WHITE_COLOR, PINK_COLOR, WHITE_COLOR } from "../../constants/css";

import {ReactComponent as Refresh} from '../../images/icons/refresh.svg'
import {ReactComponent as Haken} from "../../images/icons/haken.svg";
import {ReactComponent as Attention} from '../../images/icons/warning-sign.svg';
import PollerModal from "./PollerModal";
import ResultModal from "./lib/ResultModal";
import ProductOfferParser from '../../utils/ProductOfferParser';
import ContentModal from "./ContentModal";
import { MODAL_PROCESSES_STATES, MODAL_TITLE_STATES } from '../../constants';

const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
`

const MODAL_ICON_STATES = {
    'activation': <Haken stroke={WHITE_COLOR}/>,
    'synchronization': <Refresh stroke={WHITE_COLOR}/>,
}

export class LastPageButtonPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalActionLabel: '',
            modalAction: null,
            startPolling: false,
            pollTaskId: null,
            action: '',
            contentModal: false,
            resultModal: false,
            resultMessage: '',
            resultError: false,
        };

        this.handleCloseClick = this.handleCloseClick.bind(this);
        this.poObject = this.props.definition;
        this.poParser = new ProductOfferParser(props.definition.formData);
    }

    updateProductOffer(pk, status){
        this.props.activateActionHandler(this.props.token, pk, status)
            .then(response => {
                this.setState({
                    startPolling: true,
                    pollTaskId: response.data.task_id
                });
            }).catch(error => {
                const message = error.response.data.message
                this.setState({
                    showModal: false,
                    resultModal: true,
                    resultError: true,
                    resultMessage: `Bei der Aktivierung der Definition ist ein Fehler aufgetreten: ${message}`
                });
            });
    }

    synchronizeProductOffer(pk){
        this.props.updateActionHandler(this.props.token, pk)
            .then(response => {
                this.setState({
                    startPolling: true,
                    pollTaskId: response.data.task_id
                });
            }).catch(error => {
                this.setState({
                    showModal: false,
                    resultModal: true,
                    resultError: true,
                    resultMessage: `Bei der Synchronizierung der Definition ist ein Fehler aufgetreten: ${error}`
                });
            });   
    }

    handleCloseClick(evt) {
        evt.stopPropagation();
        this.setState({ contentModal: true });
    }

    handleCloseConfirmation() {
        this.props.onFormSaveUpdate(this.props.status);
        this.props.history.push('/dashboard');
    }

    handleActivateClick(evt) {
        evt.stopPropagation();
        this.setState({
            showModal: true,
            action: 'activation',
            modalQuestion: 'Sind Sie sicher, dass Sie das Produkt aktivieren möchten?',
            modalActionLabel: 'aktivieren',
            modalAction: () => {this.updateProductOffer(this.poObject.data.id, 'activate');}
        });
    }

    handleSyncClick(evt) {
        evt.stopPropagation();
        this.setState({
            showModal: true,
            action: 'synchronization',
            modalQuestion: 'Sind Sie sicher, dass Sie das aktive Produkt überschreiben möchten?',
            modalActionLabel: 'überschreiben',
            modalAction:  () => {this.synchronizeProductOffer(this.poObject.data.id, this.props.definition)},
        });
    }

    resetPollingState(){
        this.setState({
            showModal: false,
            startPolling: false,
            modalQuestion: '',
            modalAction: null,
            modalActionLabel: '',
            pollTaskId: null,
        });
    }

    pollingRequestHandler(taskId){
        this.props.pollTaskHandler(this.props.token, taskId)
    }

    handlePollingSuccess() {
        this.setState({
            resultModal: true,
            resultError: false,
            resultMessage: `Das Produkt wurde erfolgreich ${MODAL_PROCESSES_STATES[this.state.action]}.`
        })
        this.resetPollingState();
        toast('Die Definition wurde erfolgreich aktiviert', {type: 'success'});
        this.props.history.push("/dashboard");

    }

    handlePollingError(err) {
        this.setState({
            resultModal: true,
            resultError: true,
            resultMessage: `Das Produkt wurde nicht ${MODAL_PROCESSES_STATES[this.state.action]}. Es trat ein Fehler auf: ${err}`
        })
        this.resetPollingState()
    }

    getModalTitle() {
          return `Produkt ${this.poParser.getValue('display_name')} 
            ${MODAL_TITLE_STATES[this.state.action]}`
    }

    renderNavigation(){
        const {status} = this.props;
        if(status === 'activated'){
            return(
                <StyledRow>
                    <Button
                        onClick={this.handleCloseClick}
                        hoverColor={SECONDARY_COLOR}
                        hoverColorText={SECONDARY_WHITE_COLOR}
                    >
                        speichern und später synchronisieren
                    </Button>
                    <Button
                        onClick={this.handleSyncClick.bind(this)}
                        color={MAIN_COLOR}
                        colorText={SECONDARY_COLOR}
                    >
                        Produkt synchronisieren
                    </Button>
                </StyledRow>
            )
        } else{
            return(
                <StyledRow>
                    <Button
                        onClick={this.handleCloseClick}
                        hoverColor={SECONDARY_COLOR}
                        hoverColorText={SECONDARY_WHITE_COLOR}
                    >
                        speichern und später abschliessen
                    </Button>
                    <Button
                        onClick={this.handleActivateClick.bind(this)}
                        color={MAIN_COLOR}
                        colorText={SECONDARY_COLOR}
                    >
                        Produkt aktivieren
                    </Button>
                </StyledRow>
            )
        }
    }

    render() {
        return (
            <>
                {this.state.contentModal && 
                    <ContentModal
                        {...this.props}
                        modalQuestion='Sind Sie sicher, dass Sie den Produktkonfigurator schliessen möchten?'
                        acceptAction={() => this.handleCloseConfirmation()}
                        cancelAction={() => this.setState({ contentModal: false })}
                        onHide={() => this.setState({ contentModal: false })}
                        modalIcon={<Attention />}
                        getModalTitle={() => 'Wollen Sie wirklich schliessen?'}
                        colorFill={PINK_COLOR}
                        colorFillIcon={WHITE_COLOR}
                        color={PINK_COLOR}
                        cancelLabel='abbrechen'
                        acceptLabel='speichern & schliessen'
                        acceptButtonColor={PINK_COLOR}
                        acceptButtonColorText={WHITE_COLOR}
                        buttonWidth='267px'
                    />
                }
                {this.state.showModal &&
                    <PollerModal 
                        onHide={() => this.resetPollingState()}
                        acceptLabel={this.state.modalActionLabel}
                        acceptAction={this.state.modalAction}
                        startsPolling={this.state.startPolling}
                        pollAction={this.pollingRequestHandler}
                        pollTaskId={this.state.pollTaskId}
                        modalIcon={MODAL_ICON_STATES[this.state.action]}
                        updateStatusActivationSuccessful={updateStatusActivationSuccessful}
                        modalQuestion={this.state.modalQuestion}
                        modalProcess={MODAL_PROCESSES_STATES[this.state.action]}
                        getModalTitle={this.getModalTitle.bind(this)}
                        handlePollingSuccess={this.handlePollingSuccess.bind(this)}
                        handlePollingError={this.handlePollingError.bind(this)}
                    />
                }
                {this.state.resultModal && 
                    <ResultModal 
                        onHide={() => this.setState({resultModal: false})}
                        message={this.state.resultMessage}
                        modalIcon={MODAL_ICON_STATES[this.state.action]}
                        getModalTitle={this.getModalTitle.bind(this)}
                        isError={this.state.resultError}
                    />
                }
                {this.renderNavigation()}
            </>
        );
    }
}

LastPageButtonPanel.propTypes = {
    onSaveHandler: PropTypes.func,
    onCloseHandler: PropTypes.func,
    onPreviousPageHandler: PropTypes.func,
    previousTabKey: PropTypes.string,
    nextTabKey: PropTypes.string
};

export default withRouter(LastPageButtonPanel);
