export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_STARTED = 'AUTH_USER_STARTED';
export const AUTH_USER_SUCCEEDED = 'AUTH_USER_SUCCEEDED';
export const AUTH_USER_FAILED = 'AUTH_USER_FAILED';
export const AUTH_USER_ENDED = 'AUTH_USER_ENDED';

export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';
export const AUTH_LOGOUT_USER_STARTED = 'AUTH_LOGOUT_USER_STARTED';
export const AUTH_LOGOUT_USER_SUCCEEDED = 'AUTH_LOGOUT_USER_SUCCEEDED';
export const AUTH_LOGOUT_USER_FAILED = 'AUTH_LOGOUT_USER_FAILED';
export const AUTH_LOGOUT_ERROR = 'auth_logout_error';

export const FETCH_SALESPARTNER = 'FETCH_SALESPARTNER'
export const FETCH_SALESPARTNER_STARTED = 'FETCH_SALESPARTNER_STARTED';
export const FETCH_SALESPARTNER_SUCCEEDED = 'FETCH_SALESPARTNER_SUCCEEDED';
export const FETCH_SALESPARTNER_FAILED = 'FETCH_SALESPARTNER_FAILED';
export const FETCH_SALESPARTNER_ENDED = 'FETCH_SALESPARTNER_ENDED';
export const FETCH_SALESPARTNER_LOGIN_SUCCEEDED = 'FETCH_SALESPARTNER_LOGIN_SUCCEEDED';

export const FETCH_DEFINITIONS = 'FETCH_DEFINITIONS';
export const FETCH_DEFINITIONS_STARTED = 'FETCH_DEFINITIONS_STARTED';
export const FETCH_DEFINITIONS_SUCCEEDED = 'FETCH_DEFINITIONS_SUCCEEDED';
export const FETCH_DEFINITIONS_FAILED = 'FETCH_DEFINITIONS_FAILED';
export const FETCH_DEFINITIONS_ENDED = 'FETCH_DEFINITIONS_ENDED';

export const DELETE_DEFINITION = 'DELETE_DEFINITION';
export const DELETE_DEFINITION_STARTED = 'DELETE_DEFINITION_STARTED';
export const DELETE_DEFINITION_SUCCEEDED = 'DELETE_DEFINITION_SUCCEEDED';
export const DELETE_DEFINITION_FAILED = 'DELETE_DEFINITION_FAILED';

export const CREATE_DEFINITION = 'CREATE_DEFINITION';
export const CREATE_DEFINITION_STARTED = 'CREATE_DEFINITION_STARTED';
export const CREATE_DEFINITION_SUCCEEDED = 'CREATE_DEFINITION_SUCCEEDED';
export const CREATE_DEFINITION_FAILED = 'CREATE_DEFINITION_FAILED';

export const RETRIEVE_DEFINITION = 'RETRIEVE_DEFINITION';
export const RETRIEVE_DEFINITION_STARTED = 'RETRIEVE_DEFINITION_STARTED';
export const RETRIEVE_DEFINITION_SUCCEEDED = 'RETRIEVE_DEFINITION_SUCCEEDED';
export const RETRIEVE_DEFINITION_FAILED = 'RETRIEVE_DEFINITION_FAILED';

export const UPDATE_DEFINITION = 'UPDATE_DEFINITION';
export const UPDATE_DEFINITION_STARTED = 'UPDATE_DEFINITION_STARTED';
export const UPDATE_DEFINITION_SUCCEEDED = 'UPDATE_DEFINITION_SUCCEEDED';
export const UPDATE_DEFINITION_FAILED = 'UPDATE_DEFINITION_FAILED';

export const FETCH_SECTION = 'FETCH_SECTION'
export const FETCH_SECTION_STARTED = 'FETCH_SECTION_STARTED';
export const FETCH_SECTION_SUCCEEDED = 'FETCH_SECTION_SUCCEEDED';
export const FETCH_SECTION_FAILED = 'FETCH_SECTION_FAILED';
export const FETCH_SECTION_ENDED = 'FETCH_SECTION_ENDED';

export const UPDATE_DEFINITION_CUCO = 'UPDATE_DEFINITION_CUCO';
export const UPDATE_DEFINITION_CUCO_STARTED = 'UPDATE_DEFINITION_CUCO_STARTED';
export const UPDATE_DEFINITION_CUCO_SUCCEEDED = 'UPDATE_DEFINITION_CUCO_SUCCEEDED';
export const UPDATE_DEFINITION_CUCO_FAILED = 'UPDATE_DEFINITION_CUCO_FAILED';

export const DUPLICATE_DEFINITION = 'DUPLICATE_DEFINITION';
export const DUPLICATE_DEFINITION_STARTED = 'DUPLICATE_DEFINITION_STARTED';
export const DUPLICATE_DEFINITION_SUCCEEDED = 'DUPLICATE_DEFINITION_SUCCEEDED';
export const DUPLICATE_DEFINITION_FAILED = 'DUPLICATE_DEFINITION_FAILED';

export const RESET_FORM_DATA = 'reset_form_data';
export const UPDATE_STATUS_ACTIVATION = 'update_status_activation';

export const UPDATE_PRODUCTS_TYPE = 'UPDATE_PRODUCTS_TYPE';

export const FETCH_COSTITEMS = 'FETCH_COSTITEMS';
export const FETCH_COSTITEMS_STARTED = 'FETCH_COSTITEMS_STARTED';
export const FETCH_COSTITEMS_FAILED = 'FETCH_COSTITEMS_FAILED';
export const FETCH_COSTITEMS_SUCCEEDED = 'FETCH_COSTITEMS_SUCCEEDED';

export const UPDATE_CLICKINDEX_STATUS = 'UPDATE_CLICKINDEX_STATUS';
