import React from "react";
import { SketchPicker } from "react-color";
import Input from "../lib/Input";

const popover = {
  position: "absolute",
  zIndex: "2",
  paddingBottom: "100px"
};

const cover = {
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px"
};

class ColorPickerWrapper extends React.Component {
  onChange(color) {
    const {
      input: { onChange }
    } = this.props;
    onChange(color.hex);
  }

  render() {
    const {
      displayPicker,
      selectedColor,
      labelText,
      handleClose,
      handleInputClick,
      handleInputChange,
      isColorBox,
      meta: { touched, error },
    } = this.props;
    return (
        <div>
            <Input
                color={selectedColor}
                type="text"
                value={selectedColor}
                onClick={handleInputClick}
                labelText={labelText}
                touched={touched}
                error={error}
                onChange={this.onChange}
                isColorBox={isColorBox}
                colorBox={selectedColor}
            />
            {displayPicker ? (
            <div style={popover}>
                <div style={cover} onClick={handleClose} />
                <SketchPicker
                    width="95%"
                    onChange={color => {
                        handleInputChange(color);
                        this.onChange(color);
                    }}
                    color={selectedColor}
                />
            </div>
            ) : null}
        </div>
    );
  }
}

export default ColorPickerWrapper;
