import React from "react";
import styled from 'styled-components';
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { Field, change } from "redux-form";
import { numericality, required, length, format, combine } from "redux-form-validators";
import TextAreaWrapper from "../../../../../common/forms/TextAreaWrapper";

import { OuterFormColLeft, OuterFormColRight } from "../../../../../common/styles/FormStyles";
import InputWrapper from "../../../../../common/forms/InputWrapper";
import SelectWrapper from "../../../../../common/forms/SelectWrapper";
import AccordionItem from "../../../../../common/lib/Accordion";
import { ReactComponent as Info } from "../../../../../../images/icons/INfo.svg";
import Button from "../../../../../common/styles/Button";
import { parseStrToNumber } from "../../../../../../utils/commonUtils";
import { GRAY_COLOR, MAIN_COLOR, NO_ACTIVE_BG_COLOR, NO_ACTIVE_COLOR, SECONDARY_COLOR } from "../../../../../../constants/css";
import { FlexFormCol } from "../../../../../common/styles/FormStyles"
import { PRICE_UNITS_OPTIONS_WP } from "../../../../../../constants"

const ButtonWrapper = styled.div`
    padding-left:20px;

`

const ADDITIONAL_CT_KW_PRICE = "additional_ct_kwh_price";

const extraPriceValues = {
    itemTitle: 'ZUSCHLAG AUF DEN ENERGIEPREIS',
    displayNameLabel: 'Display Name des Zuschlags',
    descriptionLabel: 'Beschreibung des Zuschlags',
    priceNettoLabel: 'Höhe des Zuschlags (Netto)',
    descriptionPlaceholderText: 'Bitte beschreiben Sie hier die Zusammensetzung des Zuschlags'
}

const unitToClassification = {
    "1/100": "percentage_service_fee_on_spot_working_price",
    "ct/kWh": ADDITIONAL_CT_KW_PRICE
}

const extraWorkingPriceHint = 'Der interne Name wird auf der Lumenaza Plattform gespeichert und in den REST APIs als "name" zurück gegeben. \
                                Er ist daher besonders für Software Entwickler relevant. Für die Endkunden ist der interne Name nicht sichtbar. \
                                Wir empfehlen einen englische Schreibweise, die selbsterklärend ausdrückt, was abgerechnet wird. \
                                Der interne Name darf nur kleingeschriebene Buchstaben, Zahlen und Unterstriche enthalten. \
                                Er darf nicht länger als 128 Zeichen lang sein.'

const internalNameValidation = 'Der interne Name darf nur kleingeschriebene Buchstaben, Zahlen und Unterstriche enthalten. Er darf nicht länger als 128 Zeichen lang sein.'

function ExtraSpotWorkingPrice(props) {

    function renderExtraItems() {
        return props.fields.map((prefix, index) => {
            return (
                <AccordionItem
                    key={prefix}
                    index={index}
                    title={extraPriceValues.itemTitle}
                    expanded={true}
                    contentBackground={GRAY_COLOR}
                    barBackgroundClosed={NO_ACTIVE_COLOR}
                    barBackgroundOpen={NO_ACTIVE_BG_COLOR}
                    marginBottom="40px"
                    marginTop="10px"
                    withDelete={true}
                    deleteHandler={() => { props.fields.remove(index) }}
                >
                    <Row>
                        <OuterFormColLeft md={6} lg={6}>
                            <Field
                                name={`${prefix}.display_name`}
                                component={InputWrapper}
                                type="text"
                                labelText={extraPriceValues.displayNameLabel}
                                disabled={props.disabled}
                                validate={required()}
                                className="mt-3"
                            />
                            <Field
                                name={`${prefix}.description`}
                                labelText={extraPriceValues.descriptionLabel}
                                component={TextAreaWrapper}
                                placeholder={extraPriceValues.descriptionPlaceholderText}
                                disabled={props.disabled}
                                border={`1px solid ${SECONDARY_COLOR}`}
                                validate={combine(
                                  required(),
                                  length({
                                    max: 128,
                                    message: "Der Text ist zu lang. Es können maximal 128 Zeichen (inklusive Leerzeichen) verwendet werden.",
                                  })
                                )}
                                className="mt-5"
                                infoIcon={<Info />}
                                infoIconTooltip="Der Beschreibungstext wird in der Begriffserklärung einer Stromabrechnung verwendet."

                            />
                        </OuterFormColLeft>
                        <OuterFormColRight md={6} lg={6}>
                            <Row>
                                <FlexFormCol md={12} lg={12}>
                                    <Field
                                        name={`${prefix}.price_netto`}
                                        component={InputWrapper}
                                        type="number"
                                        labelText={extraPriceValues.priceNettoLabel}
                                        disabled={props.disabled}
                                        parse={parseStrToNumber}
                                        validate={[required(), numericality()]}
                                        className="mt-3 mr-3"
                                    />
                                    <Field
                                        name={`${prefix}.unit`}
                                        labelText="Einheit"
                                        component={SelectWrapper}
                                        optionsList={PRICE_UNITS_OPTIONS_WP}
                                        disabled={props.disabled}
                                        width="170px"
                                        arrowMargin="6px"
                                        className="mt-3"
                                        validate={required()}
                                        onCustomChange={(value) => { props.dispatch(change("productDefinitionForm", `${prefix}.classification`, unitToClassification[value])) }}
                                    />
                                </FlexFormCol>
                            </Row>
                            <Row>
                                <FlexFormCol md={12} lg={12}>
                                    <Field
                                        name={`${prefix}.name`}
                                        component={InputWrapper}
                                        type="text"
                                        labelText={"Interner Name"}
                                        disabled={props.disabled}
                                        className="mt-5"
                                        infoIcon={<Info />}
                                        validate={[required(), length({ min: 4, max: 128, message: "Der Name muss mindestens 4 Zeichen haben" }), format({ with: /^[a-zA-Z0-9_]+$/, message: internalNameValidation })]}
                                        infoIconTooltip={extraWorkingPriceHint}
                                    />
                                </FlexFormCol>
                            </Row>
                        </OuterFormColRight>
                    </Row>
                </AccordionItem>)
        })
    }

    return (
        <>

            {renderExtraItems()}
            <Row>
                <ButtonWrapper>
                    <Button
                        color={MAIN_COLOR}
                        colorText={SECONDARY_COLOR}
                        onClick={() => {
                            props.fields.push({ classification: ADDITIONAL_CT_KW_PRICE })
                        }
                        }
                        type='button'
                    >
                        Zuschlag hinzufügen
                    </Button></ButtonWrapper>

            </Row>
        </>);

}

export default connect()(ExtraSpotWorkingPrice);