import React from 'react';
import { connect} from 'react-redux'
import {compose} from 'redux';
import { formValueSelector } from "redux-form";
import Row from 'react-bootstrap/Row';

import styled from "styled-components";
import LastPageButtonPanel from "../../common/LastPageButtonPanel";
import {ReactComponent as CheckCircle} from '../../../images/icons/check_circle.svg'
import FormNavigation from '../../common/FormNavigation';
import { MAIN_COLOR } from '../../../constants/css';

export const Container = styled.div`
    max-width: 700px;
    margin: 0 auto;
`

export const StyledRow = styled(Row)`
    display: flex;
    justify-content: center;
    padding: 0 20px 10px 20px;

    &:first-of-type {
        margin-top: 30px;
    }
`

export const StyledHeading = styled.h2`
    font-size: 24px;
`

export const StyledParagraph = styled.p`
    font-size: 20px;
    text-align: center;
    margin: 30px 0;
`

export class ProductOfferDefinitionReview extends React.Component {
    render(){
        return(
            <Container>
                <StyledRow>
                    <CheckCircle width="40px" fill={MAIN_COLOR}/>
                </StyledRow>
                <StyledRow>
                    {this.props.status === 'activated' &&
                        <StyledHeading>Produkt synchronisieren</StyledHeading>
                    }
                    {this.props.status === 'draft' &&
                        <StyledHeading>Produkt aktivieren</StyledHeading>
                    }
                </StyledRow>
                <StyledRow>
                    {this.props.status === 'activated' &&
                        <StyledParagraph>Bitte prüfen Sie im letzten Schritt Ihre Angaben und Einstellungen. Anschließend können Sie Ihre Änderungen nutzen, um das aktive Produkt zu synchronisieren.</StyledParagraph>
                    }
                    {this.props.status === 'draft' &&
                        <StyledParagraph>Bitte prüfen Sie im letzten Schritt Ihre Angaben und Einstellungen. Anschließend kann das Produkt aktiviert werden, oder die Aktivierung zu einem späteren Zeitpunkt vorgenommen werden.</StyledParagraph>
                    }
                </StyledRow>
                <LastPageButtonPanel
                    onSaveHandler={this.props.onSaveProductOffer}
                    onFormSaveUpdate={this.props.onFormSaveUpdate}
                    onPreviousPageHandler={this.props.onPreviousPage}
                    previousTabKey={this.props.previousTabKey}
                    status={this.props.status}
                    token={this.props.token}
                    definition={this.props.definition}
                    activateActionHandler={this.props.activateActionHandler}
                    updateActionHandler={this.props.updateActionHandler}
                    pollTaskHandler={this.props.pollTaskHandler}
                />
            <FormNavigation
                onPreviousPageHandler={this.props.onPreviousPage}
                previousTabKey={this.props.previousTabKey}
            />
            </Container>
        )
    }
}

const selector = formValueSelector('productDefinitionForm');

function mapStateToProps(state, ownProps) {
    const priceCalculation = selector(state, 'pc_method');
    return Object.assign({}, ownProps, {...state, priceCalculation: priceCalculation,
        status: state.productOffers.definition.data.status,
        definition: state.productOffers.definition,
    })
}

export default compose(
    connect(mapStateToProps),    
)(ProductOfferDefinitionReview)
