import React from "react";
import {Count, NavbarContainer, TextContainer, Title} from "../styles/Navbar";

class Navbar extends React.Component {
    render() {
        return (
            <NavbarContainer>
                {this.props.menuItems.map((el) => (
                    <TextContainer 
                        onClick={() => this.props.onSelectFilter(el.title)}
                        key={el.title}
                    >
                        <Title 
                            mainColor={this.props.mainColor}
                            secondaryColor={this.props.secondaryColor}
                            isActive={this.props.activeTab === el.title}
                        >
                            {el.title}
                        </Title>
                        <Count 
                            secondaryColor={this.props.secondaryColor}
                            isActive={this.props.activeTab === el.title}
                        >
                            {el.count}
                        </Count>
                    </TextContainer>)
                )}
            </NavbarContainer>
        )
    }
}

export default Navbar