import React, { useEffect, useState } from "react"

import { Col, Row } from "react-bootstrap"
import { Field } from "redux-form";
import styled from "styled-components";
import { required } from "redux-form-validators";

import { COST_ITEM_OPTIONS, COST_ITEM_TOOLTIP, } from "../../../../../constants";
import InputWrapper from "../../../../common/forms/InputWrapper";
import { OuterFormColLeft, StyledFormHeading } from "../../../../common/styles/FormStyles";
import SelectWrapper from "../../../../common/forms/SelectWrapper";
import Button from "../../../../common/styles/Button";
import { MAIN_COLOR, NO_ACTIVE_BG_COLOR, NO_ACTIVE_COLOR, SECONDARY_COLOR } from "../../../../../constants/css";
import IconButton from "../../../../common/lib/IconButton";
import {ReactComponent as Delete} from "../../../../../images/icons/delete.svg";
import { Tooltip } from "../../../../common/lib/Tooltip";
import { parseStrToNumber } from "../../../../../utils/commonUtils";
import { biggerThenOrEqual } from "../../../../../utils/validationUtils";
import { ErrorMessage } from "../../../../common/styles/InputStyles";

const StyledCostItemsContainer = styled.div`
    display: flex;
    align-items: flex-end;
`

const StyledFieldDescription = styled.div`
    display: flex;
    position: relative;
    top: 5px;
    margin-right: 20px;
    font-size: 18px;
`

const StyledErrorMessage = styled(ErrorMessage)`
    margin-left: 15px;
    margin-top: 55px;
`

const costItemValidations = [required(), biggerThenOrEqual(0)]

// For a better understanding have a look at this confluence article:
// https://lumenaza.atlassian.net/l/cp/1bebnJ2E
const CostItemInputs = (props) => {
    if (props.isForBaseFee) {
        props.fieldsRefBaseFee(props.fields)
    } else {
        props.fieldsRefMonthlyFees(props.fields)
    }
    
    const [lastIndexClicked, setLastIndexClicked] = useState(null);

    useEffect(() => {
        const costItem = props.selectedCostItem
        // costItemOptions changes depending on what is already added from the User
        // Only if the selected item of the user is still in this options list, it should be added
        const shouldbeAdded = props.costItemOptions.find(option => {
          return option.value === costItem.name
        })

        if (lastIndexClicked !== null && shouldbeAdded) {
            props.fields.push({
                price_netto: '',
                display_name: costItem?.display_name,
                unit: costItem?.unit,
                name: costItem?.name
            });
        }

        // If one add-cost-item-button would be clicked two times in a row,
        // there would be no lastIndexClicked change. 
        // Then the useEffect wouldn't be triggered, but it should. 
        // That's why we reset the index each time to null.
        setLastIndexClicked(null);
    }, [lastIndexClicked]);

    const addCostItem = (index) => {
        props.updateClickIndexStatus(index)
        setLastIndexClicked(index);
    }

    const renderCostItemInputs = () => {
        return props.fields.map((costItem, index) => {
            const costItemObj = props.fields.get(index)
            const costItemName = costItemObj['name']

            const getTexts = () => {
                return COST_ITEM_OPTIONS.reduce((acc, cv) => {
                    if (costItemName === cv.value){
                        acc.label = cv.label
                        acc.helperText = cv.helperText
                    }
                    return acc
                }, {})
            }

            return (
                <Col md={6} lg={6} key={costItemName} className="mb-3" data-testid={costItemName}>
                    <StyledCostItemsContainer>
                        <StyledFieldDescription>
                            {getTexts().label}
                            <Tooltip>
                            {getTexts().helperText}
                            </Tooltip>
                        </StyledFieldDescription>
                        <Field
                            name={`${costItem}.price_netto`}
                            type="number"
                            parse={parseStrToNumber}
                            component={InputWrapper}
                            validate={costItemValidations}
                            // Below normalize is needed, because otherwise key of this input field would get completely removed 
                            // from redux form state. This only applies to fields which aren't in the initial values.
                            normalize={value => value === '' ? null : value}
                            unit='ct/Jahr (netto)'
                            disabled={props.disabled || props.meterOperatorFeeNotDeletable}
                        />
                        {props.meterOperatorFeeNotDeletable 
                            ? 
                                null
                            :
                                <IconButton
                                    tooltip='löschen' 
                                    positionTooltip='top'
                                    width='20px'
                                    m='0 10px 2px 20px'
                                    onClick={props.disabled ? () => undefined : () => props.fields.remove(index)}
                                    disabled={props.disabled}
                                >
                                    <Delete
                                        fill={SECONDARY_COLOR}
                                    />
                                </IconButton>
                            }
                        
                    </StyledCostItemsContainer>
                </Col>
            )
        })
    }

    return (
        <>
            <StyledFormHeading>
                KOSTENBESTANDTEILE DEFINIEREN
                <Tooltip>
                    {COST_ITEM_TOOLTIP}
                </Tooltip>
            </StyledFormHeading>
            <Row>
                <OuterFormColLeft md={6} lg={6} className="mb-3 mr-3">
                    <Field
                        name={props.isForBaseFee ? 'bf_selectCostItemField' : `${props.fee}.selectCostItemField`}
                        labelText="Kostenbestandteil auswählen"
                        component={SelectWrapper}
                        optionsList={props.costItemOptions || []}
                        disabled={props.disabled}
                    />
                </OuterFormColLeft>
                {props.meterOperatorError && props.saveClicked ? <StyledErrorMessage>Messstellengebühr muss definiert werden</StyledErrorMessage> : null}
                <Col md={5} lg={5} className="mb-3">
                    <Button
                        type='button'
                        color={props.disabled ? NO_ACTIVE_BG_COLOR : MAIN_COLOR}
                        colorText={props.disabled ? NO_ACTIVE_COLOR :  SECONDARY_COLOR}
                        onClick={() => addCostItem(props.feeIndex)}
                        disabled={props.disabled}
                        hoverCursor={props.disabled ? 'default' : 'pointer'}
                    >
                        Kosten hinzufügen
                    </Button>
                </Col>
            </Row>
            <Row className="mb-5 pb-4" >
                {renderCostItemInputs()}
            </Row>
        </>
    )
}

export { CostItemInputs }
