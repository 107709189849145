import styled from 'styled-components';

export const ErrorPageStyle = styled.div`
  padding: 70px 0;
  text-align: center;
`;

export const StyledHeading = styled.h3`
    margin-top: 15px;
`

export const StyledParagraph = styled.p`
    margin-top: 15px;
`