import _ from 'lodash';
import { FIXED_REMUNERATION, SERVICE_FEE_MATRIX_PARAMS, SERVICE_FEE_SINGLE_VALUE_PARAMS } from '../constants';

export default class ProducerTariffsValues{
    plantTypePrefix = 'pt';

    setValuesFromForm(formValues){
        const supported_plant_types = _.get(formValues, 'supported_plant_types', [])

        // Add correct description and classifcation to each service fee
        const modified_plant_types = supported_plant_types.map(plant => {
            const modified_service_fees = plant.service_fees.map(fee => {
                const isDistributionCost = fee.classification === "distribution_cost_fee_producer"

                if (fee.capacity_value_matrix && !isDistributionCost) {
                    fee.classification = SERVICE_FEE_MATRIX_PARAMS[fee.unit]?.classification
                    fee.description = SERVICE_FEE_MATRIX_PARAMS[fee.unit]?.description
                }
                if (!fee.capacity_value_matrix && !isDistributionCost){
                    fee.classification = SERVICE_FEE_SINGLE_VALUE_PARAMS[fee.unit]?.classification
                    fee.description = SERVICE_FEE_SINGLE_VALUE_PARAMS[fee.unit]?.description
                }
                /*
                    Below if is needed for distribution costs because single_value is set as a default 
                    in state of component ServiceFeeItemForm. This single_value is then used in componendDidUpate 
                    to set the capacity_value_matrix to null. This should be better refactored, 
                    but it's not an easy change. It would be changes needed at some plPRODUCT_PRICE_CLASSIFICATION_OPTIONSaces in the component.
                */
                if (!fee.capacity_value_matrix || isDistributionCost){
                    delete fee.capacity_value_matrix
                }

                return fee
            })

            return {
                ...plant,
                service_fees: modified_service_fees
            }
        })

        const formObject = {
            supported_plant_types: modified_plant_types,
        }

        if (formValues.payment_model === FIXED_REMUNERATION){
            formObject.fixed_remuneration = [
                {
                    price_netto: formValues.remuneration_price_netto,
                    unit: "ct/kWh",
                    display_name: formValues.remuneration_display_name,
                    description: "Festpreis Vergütung",
                    classification: FIXED_REMUNERATION
                }
            ]
        }

        return formObject    
    }

    setFormValues(podObject){
        const fixed_remuneration = _.get(podObject, 'fixed_remuneration', []);        
        return {
            remuneration_price_netto: fixed_remuneration[0]?.price_netto, 
            remuneration_display_name: fixed_remuneration[0]?.display_name,
            supported_plant_types: podObject.supported_plant_types

        };
    }
}
