import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {Form, getFormValues, reduxForm} from "redux-form";
import {retrieveSections} from '../../actions/emailDocumentsActions';
import {updateProductofferCuco} from '../../actions/productOffersActions';
import requireAuth from '../auth/requireAuth';
import PreviewerModalEmail from "../common/PreviewerModalEmail";
import parse from "html-react-parser"

import {previewDocumentEmail} from '../../api/calls';
import SideBar from './SideBar'
import EditorSections from './EditorSections';
import styled from 'styled-components';
import Layout from '../common/Layout';
import EditorActionButtons from './EditorActionButtons';
import { StyledFormWrapper } from '../common/styles/FormWizard';
import { PageHeadingWrapper } from '../common/styles/PageHeading';
import Heading from '../common/lib/Heading';
import IconButton from '../common/lib/IconButton';
import {ReactComponent as Entwurf} from '../../images/icons/entwurf.svg';
import {ReactComponent as CheckCircle} from '../../images/icons/check_circle.svg';
import {ReactComponent as EmailIcon} from '../../images/icons/mail_editor.svg';
import {ReactComponent as PdfIcon} from '../../images/icons/pdf_editor.svg';
import { BLUE_COLOR, MAIN_COLOR, NO_ACTIVE_BG_COLOR } from '../../constants/css';
import { EDITOR_TYPES_MAP } from '../../constants';
import EditorHtmlExplainer from './EditorHtmlExplainer';

const SubHeadingWrapper = styled.div`
  display: flex;
  font-size: 22px;
  padding: 0 100px;
  margin-top: 30px;
  margin-bottom: 25px;
`

const BoldText = styled.div`
  font-weight: 700;
  margin-right: 20px;
`

const IconWrapper = styled.div`
  margin-top: -5px;
  margin-right: 20px;
`

const ContainerSections = styled(Col)`
  margin-right: 440px;
`

const SeperatorLine = styled.div`
  position: fixed;
  top: 370px;
  right: 446px;
  bottom: 0;
  width: 2px;
  background: ${NO_ACTIVE_BG_COLOR};
  opacity: 0.5;
`
const SideBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed,
  top: 328px,
  right: 80px,
  bottom: 150px,
  padding-left: 60px
  `

  const EMAIL_DOCUMENT_ITEM_FIELDS = ['name', 'subject', "is_sent", "file_name", "attachments"]
const PRODUCER_PRODUCT_CATEGORIES = ['single_value', 'capacity_dependent']

function getDataFromPod(data, type, id){
  const typeIndex = type === 'et' ? 'emails' : 'documents';
  const listIndex = type === 'et' ? 'email_types' : 'document_types';
  const cucoList = _.get(data, `product_offer_characteristics.${typeIndex}.${listIndex}`, []);
  const cucoData = cucoList.filter(item => item.name === id).map(item => {return {...item.context_variables, ..._.omit(item, ['context_variables'])}});
  let retCuco = _.head(cucoData) === undefined ? {} : _.head(cucoData);
  
  if(type === 'et'){
    const from =  _.get(data, `product_offer_characteristics.${typeIndex}.from`);
    const bcc =  _.get(data, `product_offer_characteristics.${typeIndex}.bcc`);
    //const subject =  _.get(data, `product_offer_characteristics.${typeIndex}.base_template.subject`);
    retCuco.from = from;
    retCuco.bcc = bcc;
  }

  
  return retCuco

}


function convertToDefinitionData(values){  
  //should convert form data into {base_values: {value:1, value2: 2}, context_variables: {definition_variable_1:1}}
  let notDefinitionValues = _.pickBy(values, (v, k)=>{return !k.includes('definition') && EMAIL_DOCUMENT_ITEM_FIELDS.includes(k)});
  const definitionValues = _.pickBy(values, (v, k)=>{return k.includes('definition') });

  return {
    base_values: notDefinitionValues,
    context_variables: definitionValues
  };
}

// Takes the base64 pdf data and converts to bytesarray and create Blob from that array.
function base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export class EditorEmailDocument extends React.Component {
  constructor(props) {    
    super(props);
    
    this.state = {
    showModalEmail: false,
    renderedHtml: null,
    };
    this.callbackEmail = this.callbackEmail.bind(this);
  }


  componentDidMount() {
    this.callFetchFunction();
  }

  updateCuco() {
    if (this.props.valid) {

      let newFormData = new FormData();
      const {cucoType, cucoId} = this.props;
      const podId = this.props.match.params.pk;
      const token = this.props.token;
      const values = convertToDefinitionData(this.props.submitedValues);

      for (const [key, val] of Object.entries(this.props.submitedValues)) {
        if (key.startsWith('attachment_') && val instanceof File) {
          newFormData.append(key, val);
        }
      }

      newFormData.append('cuco_data', JSON.stringify(values));
      newFormData.append('cuco_type', cucoType);
      newFormData.append('cuco_id', cucoId);

      const callback = (data) => {toast('Success', {type: 'success'}); localStorage.setItem('activePod', JSON.stringify(data));};
      this.props.updateCuco(token, podId, newFormData, callback);
    } else {
      toast('Bei der Aktualisierung ist ein Fehler aufgetreten', {type: 'error'});
    }
  }
  closePage(){
    this.props.history.go(-2);
  }

  callbackEmail(response){
    const fileContent = response.data.file_contents;
    this.setState({'showModalEmail': true, renderedHtml: fileContent});

  }

  callbackPDF(response){
    const fileContent = response.data.file_contents;
    const file = base64toBlob(fileContent, 'application/pdf');
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');

  }

  previewCuco() {
    const {cucoType, cucoId, consumptionPriceModel} = this.props;
    const podId = this.props.match.params.pk;
    const token = this.props.token;
    const values = convertToDefinitionData(this.props.submitedValues);
    const payload = {
      cuco_data: values,
      cuco_type: cucoType,
      cuco_id: cucoId,
      pod_id: podId,
      cpm: consumptionPriceModel
    };
    const callback = cucoType === 'et'? this.callbackEmail : this.callbackPDF;
    const errorCallback = () => {toast('Error creating PDF', {type: 'error'});};
    return previewDocumentEmail(token, payload, callback, errorCallback);

  }

  callFetchFunction() {
    // When is open in a new tab
    /* const param = this.props.match.params.pk;
    const cucoType = param.substring(0, 2);
    const cucoId = param.substring(3); */
    //When open in same tab
    const {cucoType, cucoId, productType, consumptionPriceModel} = this.props;
    const token = this.props.token;
    if (PRODUCER_PRODUCT_CATEGORIES.includes(productType)){
      this.props.retrieveSections(token, cucoId, cucoType, 'producer', consumptionPriceModel);
    }
    else {
      this.props.retrieveSections(token, cucoId, cucoType, productType, consumptionPriceModel);
    }
  }

  render() {
    const { data} = this.props.sections;
    const sections = _.get(data, 'sections', null);
    const variables = _.get(data, 'text_variables', []);
    const defaultValues = _.get(data, 'default_values');
    const {
      cucoType, 
      initialValues, 
      customerApproach, 
      cucoId, 
      paymentMethods,
      isProducer,
      // attachment,
    } = this.props;
    const displayTitle =_.get(this.props.lsData && this.props.lsData.product_offer_characteristics,'display_name', null);

    return (
      <Form 
      method="post"
      enctype="multipart/form-data"
      onSubmit={this.props.handleSubmit(() => undefined)}
      >
      <Layout
        {...this.props}
        onCloseClick={this.closePage.bind(this)}
      >
        <PageHeadingWrapper>
          <Heading title={displayTitle}>
            {this.props.lsData.status === 'draft' &&
              <IconButton isStaticButton={true}>
                <Entwurf stroke={BLUE_COLOR}/>
              </IconButton>
            }
            {this.props.lsData.status === 'activated' &&
              <IconButton isStaticButton={true}>
                <CheckCircle width="30px" fill={MAIN_COLOR}/>
              </IconButton>
            }
          </Heading>
        </PageHeadingWrapper>
        {cucoType === 'et' &&
          <>
            <SubHeadingWrapper>
              <BoldText>EDITOR:</BoldText>
              <IconWrapper>
                <EmailIcon/>
              </IconWrapper>
              E-Mail | {EDITOR_TYPES_MAP[this.props.cucoId]}
            </SubHeadingWrapper>
            
          </>
        }
        {cucoType === 'dt' &&
          <>
            <SubHeadingWrapper>
              <BoldText>EDITOR:</BoldText>
              <IconWrapper>
                <PdfIcon/>
              </IconWrapper>
              PDF | {EDITOR_TYPES_MAP[this.props.cucoId]}
            </SubHeadingWrapper>
            
          </>
        }
        <StyledFormWrapper>
        <Row>
          {this.state.showModalEmail && 
            <PreviewerModalEmail
              show={this.state.showModalEmail}
              onHide={() => this.setState({'showModalEmail': false})}
              renderedHtml={this.state.renderedHtml}
              cucoId={cucoId}
              >
                {parse(this.state.renderedHtml)}
              </PreviewerModalEmail> 
          }
          {sections && 
          <ContainerSections>
              <EditorSections 
                cucoType={cucoType} 
                sections={sections}
                cucoData={initialValues}
                isSingularPlural={!customerApproach}
                paymentMethods={paymentMethods}
                cucoId={cucoId}
                isSend={this.props.isSend}
                defaultValues={defaultValues}
                dispatch={this.props.dispatch}
                coBrandingImage={this.props.lsData.cobranding_logo_image}
                salesPartnerImage={this.props.lsData.sales_partner_logo_image}
              />
          </ContainerSections>
          }
          <SeperatorLine/>
          <SideBlock >
            <EditorHtmlExplainer />
            <SideBar
              variables={variables}
              isProducer={isProducer}
              cucoId={cucoId}
              consumptionPriceModel={this.props.consumptionPriceModel}
            />
          </SideBlock>
        </Row>
        <EditorActionButtons
          onSaveClick={this.updateCuco.bind(this)}
          onPreviewClick={this.previewCuco.bind(this)}
        />
        </StyledFormWrapper>
      </Layout>
      </Form>
    );
  }
}

const mapDispatchToProps = function(dispatch, ownProps) {
  return {
    ...ownProps,
    retrieveSections: (token, cucoId, cucoType, productType, consumptionPriceModel) => {
      return dispatch(retrieveSections(token, cucoId, cucoType, productType, consumptionPriceModel));
    },
    updateCuco: (token, podId, data, callback) => {
      return dispatch(updateProductofferCuco(token, podId, data, callback));
    },
    dispatch
  };
};

function mapStateToProps(state, ownProps){
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const cucoType = params.get('ct');
  const cucoId = params.get('ci');
  const productType = params.get('pt')
  const consumptionPriceModel = params.get('cpm')
  const lsData = JSON.parse(localStorage.getItem('activePod'));
  const cucoData = getDataFromPod(lsData, cucoType, cucoId) || _.get(ownProps, 'location.state.cucoData', {});
  const customerApproach = _.get(ownProps, 'location.state.customerApproach', false);
  const paymentMethods = _.get(ownProps, 'location.state.paymentMethods', []);
  // values below is where the attachments array is stored 
  const values = getFormValues('editorForm')(state);
  const isProducer = _.get(ownProps, 'location.state.isProducer', false);
  const isSend = cucoData.is_sent
  return Object.assign({}, ownProps, {
      initialValues: cucoData,
      submitedValues: values,
      cucoType,
      cucoId,
      productType,
      consumptionPriceModel,
      lsData,
      customerApproach,
      paymentMethods,
      isProducer,
      isSend,
  })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: 'editorForm',
      touchOnChange: true
    }))(requireAuth(EditorEmailDocument, 'Editor'));
