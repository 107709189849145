import React from 'react';
import {
    PRODUCT_OFFER_DEFINITION_STATUS
} from '../../constants';

import Navbar from '../common/lib/Navbar';

export default class StatusTabs extends React.Component{
    getValueStatus(status, definitions){
        return definitions.filter(item => item.status === status).length;
    }
    
    render(){
        const menuItemAll =  {
            title: 'Alle', 
            count: this.props.definitions.length
        }

        const menuItemActivated = {
            title: PRODUCT_OFFER_DEFINITION_STATUS['activated'].label, 
            count: this.getValueStatus('activated', this.props.definitions)
        }

        const menuItemDraft = {
            title: PRODUCT_OFFER_DEFINITION_STATUS['draft'].label, 
            count: this.getValueStatus('draft', this.props.definitions)
        }

        return(
            <Navbar
                menuItems={[
                    menuItemAll,
                    menuItemActivated,
                    menuItemDraft
                ]}
                onSelectFilter={this.props.onSelectFilter}
                activeTab={this.props.activeStatusTab}
            />
        )
    }
}