import React from "react";
import {
    CloseInformation,
    Container,
    Information,
    InformationData,
    InformationWrapper,
    Slash
} from "../styles/HeaderInfo";
import { ReactComponent as Cross } from '../../../images/icons/x_thin.svg';
import { SECONDARY_COLOR, SECONDARY_WHITE_COLOR } from "../../../constants/css";

class HeaderInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {isHover: false}
    }

    onMouseEnter() {
        this.setState({isHover: true})
    }

    onMouseLeave() {
        this.setState({isHover: false})
    }

    render() {
        return (
            <Container
                data-testid='headerinfo-container'
                className={this.props.className} 
                background={this.props.background} 
                colorText={this.props.colorText}
            >
                <InformationWrapper>

                    {this.props.isConfigurator && !this.props.success && this.props.author && <Information>Autor</Information>}
                    {this.props.isConfigurator && 
                    <InformationData>{this.props.author}</InformationData>}

                    {!this.props.success && this.props.author && 
                    <Slash> | </Slash>}

                    {this.props.isConfigurator && !this.props.success && <Information>Beginn</Information>}
                    {this.props.isConfigurator &&
                    <InformationData> {this.props.begin}</InformationData>}

                    {this.props.isConfigurator && !this.props.success && <Slash> | </Slash>}


                    {!this.props.success && <Information>Zuletzt gespeichert</Information>}
                    <InformationData>{this.props.lastSave}</InformationData>

                </InformationWrapper>

                <CloseInformation
                    onMouseLeave={() => this.onMouseLeave()}
                    onMouseEnter={() => this.onMouseEnter()}
                    isHover={this.state.isHover}
                    hoverColor={this.props.hoverColor}
                    onClick={this.props.onClick}
                >
                    <Information>
                        schliessen
                    </Information>
                    <Cross
                        fill={
                            this.state.isHover
                            ?
                                (this.props.hoverColor || SECONDARY_COLOR)
                            :
                                (this.props.colorText || SECONDARY_WHITE_COLOR)
                        }
                        width="13px"
                    />
                </CloseInformation>

            </Container>
        )
    }
}

export default HeaderInfo