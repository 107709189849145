import React from 'react';
import _ from 'lodash'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { MAIN_COLOR, NO_ACTIVE_COLOR } from '../../constants/css';
import { DocumentItem, Text, Title, WrapperItems, WrapperTextIcon } from './styles/DocumentForm';
import IconButton from './lib/IconButton';
import {ReactComponent as MailInactive} from "../../images/icons/mail_inactive.svg";
import {ReactComponent as MailActive} from "../../images/icons/mail_active.svg";
import {ReactComponent as Pencil} from "../../images/icons/pencil.svg";

export const IconContainer = styled.div`
    padding:0;
    display: inline;
    box-sizing:border-box;
`;

export const StyledIcon = styled(FontAwesomeIcon)`
    margin-right: 10px;
    box-sizing: border-box;
    height: 20px;
    vertical-align: text-bottom;
`;

const EMAIL_TYPE_PREFIX = 'et';
const DOC_TYPE_PREFIX = 'dt';

export class EmailListItem extends React.Component {

    render() {
        const { cucoId, isActive, cucoData } = this.props;

        return (
            <WrapperTextIcon onClick={() => this.props.onAddClick(cucoId, EMAIL_TYPE_PREFIX, cucoData)}>
                {isActive ? <Text isActive >E-Mail</Text> : <Text isActive={false}>E-Mail erstellen</Text>}
                <IconButton
                    width='25px'
                    height='25px'
                    tooltip='bearbeiten'
                    positionTooltip='top'
                >
                    <Pencil/>
                </IconButton>
            </WrapperTextIcon>
        )
    }
}

export class DocumentListItem extends React.Component {
    render() {
        const { cucoId, isActive, cucoData } = this.props;

        return (
            <WrapperTextIcon onClick={() => this.props.onAddClick(cucoId, DOC_TYPE_PREFIX, cucoData)}>
                {isActive ? <Text isActive >PDF</Text> : <Text isActive={false}>PDF erstellen</Text>}
                <IconButton
                    width={'25px'}
                    height={'25px'}
                    tooltip={'bearbeiten'}
                    positionTooltip={'top'}
                >
                    <Pencil/>
                </IconButton>
            </WrapperTextIcon>
        )
    }
}

export function ProductDocumentEmailsItemsContainer(props) {
    const isSend = _.get(props.cucoData, 'is_sent', false);

    return (
        <DocumentItem>
            <WrapperTextIcon mr='25px' maxWidth='350px'>
                <Title>{props.label}</Title>
                {props.isActive && !isSend &&
                    <IconButton
                        width='28px'
                        height='20px'
                        tooltip='E-Mail inaktiv'
                        positionTooltip='top'
                        isStaticButton
                    >
                        <MailInactive  fill={NO_ACTIVE_COLOR} stroke={NO_ACTIVE_COLOR}/>
                </IconButton>
                }
                {props.isActive && isSend &&
                    <IconButton
                        width='28px'
                        height='20px'
                        tooltip='E-Mail aktiv'
                        positionTooltip='top'
                        isStaticButton
                    >
                        <MailActive fill={MAIN_COLOR}/>
                </IconButton>
                }
            </WrapperTextIcon>
                <WrapperItems>
                    {props.children}
                </WrapperItems>
        </DocumentItem>
    )
}

export default ProductDocumentEmailsItemsContainer;
