import React from 'react';
import { setSubmitFailed, touch } from 'redux-form';
import requireAuth from '../auth/requireAuth';
import {runValidation, getFormData} from '../../utils/wizardDataConverter';
import FormWizard from './FormWizard';
import stepFormHoc from './stepFormHoc';
import _ from 'lodash';
import {toast} from 'react-toastify';
import Heading from '../common/lib/Heading';
import {ReactComponent as Entwurf} from '../../images/icons/entwurf.svg';
import IconButton from '../common/lib/IconButton';
import { PageHeadingWrapper } from '../common/styles/PageHeading';
import { BLUE_COLOR } from '../../constants/css';
import Layout from '../common/Layout';

class ProductOfferCreateForm extends React.Component {
  async validateAndSubmitData(status, callback) {
    const errors = runValidation(this.props.formCreateDefinitionWizard.registeredFields, 
      _.get(this.props.formCreateDefinitionWizard, 'syncErrors', {}),
    );
    if (errors.length > 0) {
      this.props.dispatch(touch("productDefinitionForm", ...errors));
      this.props.dispatch(setSubmitFailed("productDefinitionForm", ...errors));
    } else {
      const stepProgress = 2;
      let form = await getFormData(
        status,
        this.props.formCreateDefinitionWizard,
        this.props.isProducerCreate,
        null,
        stepProgress
      );
      const errCallback  = () =>
        toast('Bei der Erstellung der Definition ist ein Fehler aufgetreten', {type: 'error'});
      return this.props.postNewProductOffer(this.props.token, form, callback, errCallback);
    }
  }

  onFormSubmit(nextTabKey, redirectToDashboard) {
    let callback

    if (redirectToDashboard) {
      callback = () => {
        toast('Die Definition wurde erfolgreich erstellt', {type: 'success'});
        this.props.history.push('/dashboard')
      }
    } else {
      callback = id => {
          toast('Die Definition wurde erfolgreich erstellt', {type: 'success'});
          this.props.history.push({ pathname: "/empty" });
          this.props.history.replace({ pathname: `/configurator/${id}` });
          this.props.onNextPage(nextTabKey);
      }
    }
    this.validateAndSubmitData("draft", callback);
  }

  onActivate() {
    this.validateAndSubmitData("activated");
  }

  render() {
    return (
      <Layout
        {...this.props}
        onSaveHandler={this.onFormSubmit.bind(this)}
      >
        <PageHeadingWrapper>
          <Heading title={'Neuer Entwurf'}>
            <IconButton isStaticButton={true}>
              <Entwurf stroke={BLUE_COLOR}/>
            </IconButton>
          </Heading>
        </PageHeadingWrapper>
        <FormWizard
            isProducer={this.props.isProducerCreate}
            selectedTabKey={this.props.selectedTabKey}
            page={1}
            checkTabIsActive={this.props.checkTabIsActive}
            onTabSelected={this.props.onTabSelected}
            onPreviousPage={this.props.onPreviousPage}
            onFormSubmit={this.onFormSubmit.bind(this)}
        />
      </Layout>
    );
  }
}

const ProductOfferCreateFormWithSteps = stepFormHoc(ProductOfferCreateForm, false)

export default requireAuth(ProductOfferCreateFormWithSteps, 'Configurator');
