import React from "react";
import {
    SECONDARY_WHITE_COLOR,
    SECONDARY_COLOR,
    MAIN_COLOR,
    PINK_COLOR,
    BLUE_COLOR,
    NO_ACTIVE_COLOR
} from "../../../constants/css";
import upload from '../../../images/icons/upload.svg';
import arrowLeft from '../../../images/icons/arrow_left.svg';
import arrowRight from '../../../images/icons/arrow_right.svg';
import across from '../../../images/icons/x_thin.svg';
import {ReactComponent as Duplicate} from '../../../images/icons/duplicate.svg'
import {ReactComponent as Refresh} from '../../../images/icons/refresh.svg'
import {ReactComponent as Pencil} from '../../../images/icons/pencil.svg'
import {ReactComponent as Logout} from '../../../images/icons/logout.svg'
import {ReactComponent as Plus} from '../../../images/icons/plus.svg'
import {ReactComponent as Warning} from '../../../images/icons/warning-sign.svg';
import {ReactComponent as Upload} from '../../../images/icons/upload.svg';
import {ReactComponent as Twitter} from '../../../images/icons/Twitter.svg';
import {ReactComponent as Facebook} from '../../../images/icons/facebook.svg';
import {ReactComponent as LinkedIn} from '../../../images/icons/Linkedin.svg';
import {ReactComponent as Instagram} from '../../../images/icons/Instagram.svg';
import {ReactComponent as Xing} from '../../../images/icons/xing.svg';
import {ReactComponent as FontIcon} from '../../../images/icons/font_icon.svg';
import {ReactComponent as Delete} from '../../../images/icons/delete.svg';
import {ReactComponent as Entwurf} from '../../../images/icons/entwurf.svg';
import {RadioButtons} from "./RadioButtons";
import ContainerDemo from "../styles/СontainerDemo";
import Button from "../styles/Button";
import {Switcher} from "../../Switcher/Switcher";
import IconButton from "./IconButton";
import Navbar from "./Navbar";
import HeaderInfo from "./HeaderInfo";
import {
    Across, ButtonGroup, DialogContainer, DialogContent,
    DialogWindowContainer,
    DialogWrapper,
    HeaderDialog,
    HeaderText
} from "../styles/DialogWindow";
import ProductItem from "./ProductItem";
import {BoldText, BoldTextWrapper, LeftBlock, ProductItemText, RightBlock, Toggles} from "../styles/ProductItem";
import {ReactComponent as Haken} from "../../../images/icons/haken.svg";
import UploadDocument from "./UploadDocument";
import CheckBox from "./CheckBox";
import TextVariables from "./TextVariables";
import {ReactComponent as TextClipboard} from "../../../images/icons/textClipboard.svg";
import {ReactComponent as Info} from "../../../images/icons/INfo.svg";
import Stepper from "../../Stepper";
import Heading from "./Heading";

import TextArea from "./TextArea";
import DocumentForm from "./DocumentForm";
import AccordionItem from "./Accordion";
import Select from "./Select";
import Input from "./Input";

const leftInfo = ['ibc_solar', 'Gewerbe', 'Standard Stromverbraucher', 'Eintarif']
const rightInfo = ['Max Muller', '12.07.2021', '12.07.2021', '12.07.2021']

const countryList = [
    {value: "1", label: "Australia"},
    {value: "2", label: "Brazil"},
    {value: "3", label: "China"},
    {value: "4", label: "Denmark"},
    {value: "5", label: "Egypt"},
    {value: "6", label: "Finland"},
    {value: "7", label: "Ghana"},
    {value: "8", label: "Hungary"},
    {value: "9", label: "India"},
    {value: "10", label: "Japan"}
]

const data = ['E-Mail Kundenservice', 'Telefonnr. Kundenservice',
    'Website URL', 'Produktname', 'Kundennummer', 'Vertragsnummer', 'E-Mail Kundenservice']

const sideBarData = [
    {
        "label": "E-Mail Kundenservice",
        "variable_name": "definition_credits_provider_mail"
    },
    {
        "label": "Telefonnr. Kundenservice",
        "variable_name": "definition_credits_provider_phone"
    },
    {
        "label": "Website URL",
        "variable_name": "definition_credits_provider_url"
    },
    {
        "label": "Produktname",
        "variable_name": "tariff_name"
    },
    {
        "label": "Kundennummer",
        "variable_name": "saas_customer_id"
    },
]

const items = [
    { title: "Produkt definition", eventKey: "productDef" },
    { title: "Preisberechnung", eventKey: "priceCalc" },
    { title: "Vertragsparameter", eventKey: "contactParm" },
    { title: "Base Design", eventKey: "baseDesign" },
    { title: "Rechnungen & Dokumente", eventKey: "invoiceDocs" },
    { title: "Bearbeitung abschliessen", eventKey: "summary"},
]

const accordionItems = [
    'Logo',
    'Kundenanrede Set',
    'Grussformel & Signatur',
    'Angaben und Kontaktdated zum Stromlieferanten',
    'E-Mail und PDF Dokumente Base Design'
]

export class DemoComponents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            isOpen: false,
            showList: false,
            defaultSelectText: "Abrechnungszyklus der Kunden",
            countryList,
            activeStatusTab: 'Alle',
            activeTypeTab: 'Verbraucher',
            activeStepperTab: 'productDef',
            page: 1,
            optionSelected: "",
            typedInput: "",
            touched: false,
            inputValue: ""
        };
    }

    handleToggleWindow() {
        this.setState({isOpen: !this.state.isOpen})
    }

    handleSwitchClick() {
        this.setState({isChecked: !this.state.isChecked})
    }
    
    onSelectStatusFilter(tabTitle) {
        this.setState({ activeStatusTab: tabTitle })
    }

    onSelectTypeFilter(tabTitle) {
        this.setState({ activeTypeTab: tabTitle })
    }

    checkTabIsActive(page) {
        return page <= this.state.page;
    }
  
    onTabSelected(tabKey) {
        const currentIndex = items.findIndex(item => item.eventKey === tabKey)
        this.setState({ activeStepperTab: tabKey, page: currentIndex + 1});
    }

    goNextTab() {
        const currentItem = items.find((item, index) => {
            return index  === this.state.page 
        })

        this.setState({ 
            page: this.state.page + 1,
            activeStepperTab: currentItem.eventKey
        })
    }

    goPreviousTab() {
        const currentItem = items.find((item, index) => {
            return index === this.state.page - 2
        })

        if (this.state.page > 1) {
            this.setState({ 
                page: this.state.page - 1,
                activeStepperTab: currentItem.eventKey
            })
        }
    }

    handleSelectChange(value) {
        this.setState({ optionSelected: value })
    }

    componentDidMount() {
        this.handleTouched()
        this.handleBlur()
    }

    handleTouched() {
        const inputs = document.querySelectorAll('input');

        inputs.forEach(el => {
            el.addEventListener('focus', () => {
                this.setState({ touched: true })
            });
        })
    }

    handleBlur() {
        const inputs = document.querySelectorAll('input');

        inputs.forEach(el => {
            el.addEventListener('blur', () => {
                this.setState({ touched: false })
            });
        })
    }

    onInputChange(event) {
        this.setState({inputValue: event.target.value})
    }
 
    render() {
        return (
            <ContainerDemo>
                <div style={{margin: '10px 0'}}/>

                <div style={{width: '1000px'}}>
                    {accordionItems.map(item => (
                        <AccordionItem
                            key={item}
                            title={item}
                        >
                            <div>
                                <h1>Accordion Content Title</h1>
                                <p>Accordion Content description</p>
                            </div>
                        </AccordionItem>
                    ))}
                    
                </div>
                <TextArea 
                    labelText='Textbaustein 1'
                    placeholder='Text hier eingeben und weiterschreiben'
                />

                <div style={{margin: '10px 0'}}/>

                <TextArea 
                    colorLabel='red'
                    labelText='Textbaustein 1'
                    placeholder='Text hier eingeben und weiterschreiben'
                    colorText='pink'
                />

                <div style={{margin: '10px 0'}}/>

                <CheckBox id={'JavaScript'}> JavaScript </CheckBox>

                <DocumentForm>
                    d
                </DocumentForm>

                <div style={{margin: '10px 0'}}/>

                <CheckBox id={'JavaScript'} > JavaScript </CheckBox>


                <div style={{margin: '10px 0'}}/>

                <CheckBox id={'TypeScript'} backgroundChecked={'pink'} background={'yellow'} borderColor={'red'}
                          colorIcon={'red'}> TypeScript </CheckBox>

                <div style={{margin: '10px 0'}}/>

                <CheckBox id={'React'}/>

                <div style={{margin: '10px 0'}}/>

                <div style={{margin: '10px 0'}}/>

                <Stepper
                    items={items}
                    activeTab={this.state.activeStepperTab}
                    onSelect={this.onTabSelected.bind(this)}
                    checkTabIsActive={this.checkTabIsActive.bind(this)}
                />

                <div style={{margin: '10px 0'}}/>

                <div style={{display: 'flex'}}>
                    <Button
                        color={MAIN_COLOR} 
                        colorText={SECONDARY_COLOR}
                        onClick={this.goPreviousTab.bind(this)}
                    >
                    zurück
                    </Button>
                    <Button
                        color={MAIN_COLOR} 
                        colorText={SECONDARY_COLOR}
                        onClick={this.goNextTab.bind(this)}
                    >
                    weiter
                    </Button>
                </div>

                <div style={{margin: '20px 0'}}/>

                <Stepper
                    doneItemColor={'yellow'}
                    activeItemColor={'purple'}
                    colorIcon={'red'}
                    items={items}
                    activeTab={this.state.activeStepperTab}
                    onSelect={this.onTabSelected.bind(this)}
                    checkTabIsActive={this.checkTabIsActive.bind(this)}
                />


                <div style={{margin: '20px 0'}}/>


                <Stepper 
                    doneItemColor={'pink'}
                    activeItemColor={'pink'}
                    colorIcon={'pink'}
                    items={items}
                    activeTab={this.state.activeStepperTab}
                    onSelect={this.onTabSelected.bind(this)}
                    checkTabIsActive={this.checkTabIsActive.bind(this)}
                />


                <div style={{margin: '10px 0'}}/>

                <TextVariables icon={<TextClipboard width="20px" height="20px"/>}
                         left={0}
                         top={0}
                         iconTitle={<Info/>}
                         tooltipForIcon={'Die Textbausteine werden per Copy und Paste in die Textfelder eingefügt.'}
                         variables={sideBarData}
                         title={'Mögliche Textvariablen'}/>


                <div style={{margin: '10px 0'}}/>

                <TextVariables icon={<Upload width="20px" height="20px"/>}
                         right={0}
                         top={0}
                         iconTitle={<Warning/>}
                         tooltipForIcon={'Die Textbausteine werden per Copy und Paste in die Textfelder eingefügt.'}
                         variables={sideBarData}
                         title={'Mögliche Textvariablen'}/>


                <div style={{margin: '10px 0'}}/>

                <Heading icon={<Entwurf/>} title={'Sonnenenergie Haushaltsstrom'}>
                    <IconButton tooltip={'Entwurf'} positionTooltip={'top'}> <Entwurf/>
                    </IconButton> </Heading>

                <div style={{margin: '10px 0'}}/>

                <Heading icon={<Entwurf/>} title={'Some text'} color={'yellow'}>
                    <IconButton tooltip={'Entwurf'} positionTooltip={'top'}> <Entwurf/> </IconButton>
                </Heading>

                <div style={{margin: '10px 0'}}/>

                <Heading icon={<Entwurf/>} title={'Sonnenenergie Haushaltsstrom'} fontSize={'50px'}>
                    <IconButton width={'20px'} height={'20px'} tooltip={'Entwurf'} positionTooltip={'top'}>
                        <Duplicate/>
                    </IconButton>
                </Heading>

                <div style={{margin: '10px 0'}}/>


                <div style={{margin: '10px 0'}}/>

                <div style={{width: '650px'}}>
                    <UploadDocument icon={<FontIcon width='38px'/>}
                                    text={' Bitte laden Sie eine Standard Schrift hoch. Mögliche Formate: .woff, .tff,'}>
                        <Button color={MAIN_COLOR} icon={upload} iconPosition={'right'}
                                colorText={SECONDARY_COLOR}> hochladen </Button>
                    </UploadDocument>

                    <div style={{margin: '10px 0'}}/>

                    <UploadDocument icon={<FontIcon width='38px'/>}
                                    text={`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias amet 
                                    architecto at beatae commodi, consectetur cum deleniti dicta doloremque dolorum
                                    eaque earum eos harum hic id ipsum itaque libero, magni, perferendis quaerat quia 
                                    reiciendis reprehenderit sint soluta unde veniam voluptatibus. Accusantium alias atque
                                    debitis distinctio dolorem facere, incidunt modi quaerat?`}>
                        <Button color={MAIN_COLOR} icon={upload} iconPosition={'right'}
                                colorText={SECONDARY_COLOR}> hochladen </Button>
                    </UploadDocument>

                    <div style={{margin: '10px 0'}}/>

                    <UploadDocument icon={<FontIcon width='38px'/>}
                                    backgroundColor={'#76a3d5'}
                                    flexDirection={'row-reverse'}
                                    text={' Bitte laden Sie eine Standard Schrift hoch. Mögliche Formate: .woff, .tff,'}>
                        <Button color={MAIN_COLOR} icon={upload} iconPosition={'right'}
                                colorText={SECONDARY_COLOR}> hochladen </Button>
                    </UploadDocument>

                    <div style={{margin: '10px 0'}}/>

                    <UploadDocument icon={<FontIcon width='38px'/>}
                                    colorIcon={'red'}
                                    text={' Bitte laden Sie eine Standard Schrift hoch. Mögliche Formate: .woff, .tff,'}>
                        <Button color={MAIN_COLOR} icon={upload} iconPosition={'right'}
                                colorText={SECONDARY_COLOR}> hochladen </Button>
                    </UploadDocument>

                    <div style={{margin: '10px 0'}}/>

                    <UploadDocument icon={<FontIcon width='38px'/>}
                                    colorText={'red'}
                                    text={' Bitte laden Sie eine Standard Schrift hoch. Mögliche Formate: .woff, .tff,'}>
                        <Button color={MAIN_COLOR} icon={upload} iconPosition={'right'}
                                colorText={SECONDARY_COLOR}> hochladen </Button>
                    </UploadDocument>

                    <div style={{margin: '10px 0'}}/>

                    <UploadDocument icon={<FontIcon width='38px'/>}
                                    widthText={'80%'}
                                    backgroundColor={'#76a3d5'}
                                    text={' Bitte laden Sie eine Standard Schrift hoch. Mögliche Formate: .woff, .tff,'}>
                        <IconButton color={MAIN_COLOR}> <Delete/> </IconButton>
                    </UploadDocument>
                </div>

                <div style={{margin: '10px 0'}}/>


                <div style={{margin: '10px 0'}}/>

                <div style={{margin: '10px 0'}}/>

                <ProductItem icon={<Haken/>} title={'efa:fair Gewerbestrom'}>
                    <Toggles>
                        <IconButton  tooltip={'synchronisieren'}
                                    positionTooltip={'top'}><Refresh/></IconButton>
                        <IconButton tooltip={'duplizieren'} positionTooltip={'top'}><Pencil/></IconButton>
                        <IconButton tooltip={'duplizieren'} positionTooltip={'top'}><Duplicate/></IconButton>
                    </Toggles>
                    <LeftBlock>
                        {leftInfo.map((el, index) => <ProductItemText key={index}>{el}</ProductItemText>)}
                    </LeftBlock>
                    <RightBlock>
                        {data.map((el, index) => <ProductItemText key={index}>{el}</ProductItemText>)}
                    </RightBlock>
                    <BoldTextWrapper>
                        {rightInfo.map((el, index) => <BoldText key={index}>{el}</BoldText>)}
                    </BoldTextWrapper>
                </ProductItem>

                <div style={{margin: '10px 0'}}/>


                <ProductItem backgroundColorStatus={BLUE_COLOR} backgroundColor={'black'} colorIcon={'blue'}
                             icon={<Plus/>} color={'purple'}
                             title={'efa:fair Gewerbestrom'}>
                    <Toggles>
                        <IconButton  tooltip={'warning'}
                                     positionTooltip={'top'}><Warning/></IconButton>
                        <IconButton tooltip={'duplizieren'} positionTooltip={'top'}><Upload/></IconButton>
                        <IconButton tooltip={'duplizieren'} positionTooltip={'top'}><Duplicate/></IconButton>
                    </Toggles>
                    <LeftBlock>
                        {leftInfo.map((el, index) => <ProductItemText color={'red'} key={index}>{el}</ProductItemText>)}
                    </LeftBlock>

                    <RightBlock>
                        {data.map((el, index) => <ProductItemText color={'green'} key={index}>{el}</ProductItemText>)}
                    </RightBlock>
                    <BoldTextWrapper>
                        {rightInfo.map((el, index) => <BoldText color={'pink'} key={index}>{el}</BoldText>)}
                    </BoldTextWrapper>
                </ProductItem>

                <div style={{margin: '10px 0'}}/>


                <Select
                    width="500px"
                    name="tarif_type"
                    labelText="Unterstützte Tarifart"
                    optionsList={this.state.countryList}
                    value={this.state.optionSelected}
                    onChange={this.handleSelectChange.bind(this)}
                    onBlur={() => undefined}
                    touched={undefined}
                />

                <div style={{margin: '10px 0'}}/>

                <Select defaultText={this.state.defaultSelectText}
                        width="600px"
                        name="tarif_type"
                        labelText="Other width and colors"
                        optionsList={this.state.countryList}
                        selectedItemColor={'red'}
                        colorIconOpen={'blue'}
                        colorIconClose={'red'}
                        value={this.state.optionSelected}
                        onChange={this.handleSelectChange.bind(this)}
                        onBlur={() => undefined}
                        touched={undefined}
                />

                <div style={{margin: '10px 0'}}/>

                <Input
                    width="500px"
                    labelText={'Display-Produktname'}
                    tooltip={'Tool'}
                    onChange={this.onInputChange.bind(this)}
                    value={this.state.inputValue}
                    touched={undefined}
                    error={undefined}
                    required
                />

                <div style={{margin: '20px 0'}}/>

                <Input
                    width="500px"
                    labelText={'Optional: BCC with Error'}
                    onChange={this.onInputChange.bind(this)}
                    value={this.state.inputValue}
                    touched={this.state.touched}
                    error='Eingabe fehlerhaft'
                    required
                />

                <div style={{margin: '20px 0'}}/>
                <div style={{margin: '20px 0'}}/>

                <Input
                    labelText={'Different width and successColor'}
                    width={'400px'}
                    activeColor={'blue'}
                    onChange={this.onInputChange.bind(this)}
                    value={this.state.inputValue}
                    touched={undefined}
                    error={undefined}
                    required
                />

                <div style={{margin: '20px 0'}}/>

                <Input
                    labelText={'Different width and errorColor'}
                    width={'400px'}
                    errorColor={'brown'}
                    onChange={this.onInputChange.bind(this)}
                    value={this.state.inputValue}
                    touched={this.state.touched}
                    error='Eingabe fehlerhaft'
                    required
                />

                <div style={{margin: '20px 0'}}/>
                <div style={{margin: '20px 0'}}/>

                <Input
                    labelText={'Change color of labelText'}
                    colorLabel={'yellow'} width={'600px'}
                    onChange={this.onInputChange.bind(this)}
                    value={this.state.inputValue}
                    touched={undefined}
                    error={undefined}
                    required
                />

                <div style={{margin: '20px 0'}}/>

                <Input
                    labelText={'Change color of text'}
                    colorText={'red'} width={'600px'}
                    onChange={this.onInputChange.bind(this)}
                    value={this.state.inputValue}
                    touched={undefined}
                    error={undefined}
                    required
                />

                <div style={{margin: '20px 0'}}/>

                <Input
                    labelText={'With icon'}
                    width={'300px'}
                    icon={<Twitter/>}
                    onChange={this.onInputChange.bind(this)}
                    value={this.state.inputValue}
                    touched={undefined}
                    error={undefined}
                    required
                />

                <div style={{margin: '20px 0'}}/>

                <Input
                    labelText={'With icon'}
                    width={'300px'}
                    icon={<Facebook/>}
                    onChange={this.onInputChange.bind(this)}
                    value={this.state.inputValue}
                    touched={undefined}
                    error={undefined}
                    required
                />

                <div style={{margin: '20px 0'}}/>

                <Input
                    labelText={'With icon'}
                    width={'300px'}
                    icon={<LinkedIn/>}
                    onChange={this.onInputChange.bind(this)}
                    value={this.state.inputValue}
                    touched={undefined}
                    error={undefined}
                    required
                />

                <div style={{margin: '20px 0'}}/>

                <Input
                    labelText={'With icon'}
                    width={'300px'}
                    icon={<Instagram/>}
                    onChange={this.onInputChange.bind(this)}
                    value={this.state.inputValue}
                    touched={true}
                    error="Some error"
                    required
                />

                <div style={{margin: '20px 0'}}/>
                
                <Input
                    labelText={'With icon'}
                    width={'300px'}
                    icon={<Xing/>}
                    onChange={this.onInputChange.bind(this)}
                    value={this.state.inputValue}
                    touched={true}
                    error="Some error"
                    required
                />

                <div style={{margin: '20px 0'}}/>

                <HeaderInfo author={'Max Müller'} begin={'02.06.2021'} lastSave={'10.07.2021 | 12:34 Uhr'}/>


                <div style={{margin: '10px 0'}}/>

                <HeaderInfo background={MAIN_COLOR} colorText={PINK_COLOR} author={'Max Müller'} begin={'02.06.2021'}
                            lastSave={'10.07.2021 | 12:34 Uhr'} hoverColor={'yellow'}/>

                <div style={{margin: '10px 0'}}/>

                <div style={{margin: '10px 0'}}/>

                <TextVariables icon={<TextClipboard width="20px" height="20px"/>}
                         right={0}
                         bottom={0}
                         colorTitle={'red'}
                         colorText={'blue'}
                         hoverColorText={'yellow'}
                         iconTitle={<Info/>}
                         tooltipForIcon={'Die Textbausteine werden'}
                         variables={sideBarData}
                         title={'Mögliche Textvariablen'}/>

                <div style={{margin: '10px 0'}}/>
                <HeaderInfo background={PINK_COLOR} colorText={NO_ACTIVE_COLOR} author={'Max Müller'}
                            begin={'02.06.2021'}
                            lastSave={'10.07.2021 | 12:34 Uhr'} hoverColor={'green'}/>

                <div style={{margin: '10px 0'}}/>

                <HeaderInfo success background={PINK_COLOR} author={'Ihre Änderungen wurden gespeichert.'}/>

                <h1>Buttons</h1>
                <Button color={SECONDARY_WHITE_COLOR}> anmelden </Button>

                <div style={{margin: '10px 0'}}/>
                <Button color={MAIN_COLOR} icon={upload} iconPosition={'left'}
                        colorText={SECONDARY_COLOR}> anmelden </Button>

                <div style={{margin: '10px 0'}}/>
                <p>With custom paddings/margins which you can set up</p>
                <Button color={MAIN_COLOR} icon={upload} iconPosition={'right'} p={'20px'} m={'20px 10px 10px 50px'}
                        colorText={SECONDARY_COLOR}> anmelden </Button>
                <div style={{margin: '10px 0'}}/>

                <Button color={MAIN_COLOR} icon={upload} colorText={SECONDARY_COLOR}
                        iconPosition={'right'}> duplizieren </Button>

                <div style={{margin: '10px 0'}}/>
                <Button color={SECONDARY_COLOR} colorText={SECONDARY_COLOR} icon={arrowLeft}
                        iconPosition={'left'}> abbrenchen </Button>

                <div style={{margin: '10px 0'}}/>
                <Button color={SECONDARY_COLOR} colorText={SECONDARY_COLOR} icon={arrowRight}
                        iconPosition={'right'}> abbrenchen </Button>

                <div style={{margin: '10px 0'}}/>
                <Button color={PINK_COLOR} colorText={SECONDARY_WHITE_COLOR} icon={across}
                        iconPosition={'right'}> Änderungen
                    speichern </Button>

                <div style={{margin: '10px 0'}}/>
                <Button color={PINK_COLOR} colorText={SECONDARY_WHITE_COLOR} icon={across}
                        iconPosition={'left'}> Änderungen
                    speichern </Button>

                <div style={{margin: '10px 0'}}/>
                <Button color={MAIN_COLOR}> Änderungen verwerfen</Button>

                <div style={{margin: '10px 0'}}/>
                <RadioButtons 
                    titles={['Verbraucher', 'abbrechen', 'test']} backgroundColor={MAIN_COLOR}
                    color={SECONDARY_COLOR}
                    activeTab={this.state.activeTypeTab}
                    onSelectFilter={this.onSelectTypeFilter.bind(this)}
                />

                <div style={{margin: '20px 0'}}/>
                <h1>Icon buttons</h1>

                <div style={{margin: '10px 0'}}/>

                <IconButton tooltip={'duplizieren'} positionTooltip={'top'}><Duplicate/></IconButton>

                <div style={{margin: '50px 0'}}/>

                <IconButton isFill tooltip={'synchronisieren'}><Refresh/></IconButton>

                <div style={{margin: '50px 0'}}/>

                <IconButton tooltip={'duplizieren'} positionTooltip={'top'}><Pencil/></IconButton>

                <div style={{margin: '50px 0'}}/>

                <IconButton backgroundTooltip={SECONDARY_WHITE_COLOR} positionTooltip={'bottom'}
                            colorTooltip={SECONDARY_COLOR}
                            tooltip={'logout'}><Logout/></IconButton>

                <div style={{margin: '50px 0'}}/>

                <IconButton isFill isLarge text={'Neues Produkt anlegen'} positionText={'right'}><Plus/></IconButton>

                <div style={{margin: '50px 0'}}/>

                <IconButton isFill isLarge text={'Neues Produkt anlegen'} positionText={'left'}><Plus/></IconButton>

                <p>With custom paddings/margins which you can set up</p>
                <IconButton isFill m={'30px'} p={'5px'}><Plus/></IconButton>
                <div style={{margin: '10px 0'}}/>

                <h1>Switcher</h1>

                <div style={{margin: '10px 0'}}/>

                <Switcher 
                    positionText='left'
                    activeText='aktiv'
                    inActiveText='inaktiv'
                    switchId={1234}
                    handleChange={this.handleSwitchClick.bind(this)}
                    isChecked={this.state.isChecked}
                />

                <div style={{margin: '10px 0'}}/>

                <Switcher 
                    positionText={'right'}
                    switchId={12345}
                    handleChange={this.handleSwitchClick.bind(this)}
                    isChecked={this.state.isChecked}
                />

                <div style={{margin: '10px 0'}}/>

                <Switcher 
                    positionText={'right'} 
                    backgroundNotActiveColor={'black'} 
                    switchColor={'yellow'}
                    backgroundActiveColor={'white'}
                    switchId={123456}
                    handleChange={this.handleSwitchClick.bind(this)}
                    isChecked={this.state.isChecked}
                />

                <div style={{margin: '10px 0'}}/>

                <Switcher 
                    positionText='left'
                    activeText='aktiv'
                    inActiveText='inaktiv'
                    backgroundNotActiveColor='red'
                    switchColor='blue'
                    backgroundActiveColor='green'
                    switchId={1234567}
                    handleChange={this.handleSwitchClick.bind(this)}
                    isChecked={this.state.isChecked}
                />


                <div style={{margin: '10px 0'}}/>

                <h1>Dialog window</h1>

                <div style={{margin: '20px 0'}}/>

                <button onClick={() => this.handleToggleWindow()}>Open window</button>

                {this.state.isOpen && <DialogContainer>
                    <DialogWindowContainer>
                        <Across onClick={() => this.handleToggleWindow()}/>
                        <HeaderDialog>
                            <IconButton 
                                isLarge 
                                isFill
                                colorFill={SECONDARY_COLOR}
                                colorFillHover={SECONDARY_COLOR}
                                colorBorder={SECONDARY_COLOR}
                                tooltip={'duplizieren'}
                                positionTooltip={'top'}
                                colorFillIcon={SECONDARY_WHITE_COLOR}> <Duplicate fill={SECONDARY_WHITE_COLOR}/>
                            </IconButton>
                            <HeaderText> Produkt „Displayname“ duplizieren </HeaderText>
                        </HeaderDialog>
                        <DialogWrapper>
                            <DialogContent>T&T eMobil wurde für den Salespartner XY angelegt.</DialogContent>
                        </DialogWrapper>
                    </DialogWindowContainer>
                </DialogContainer>}

                <div style={{margin: '20px 0'}}/>

                <DialogWindowContainer>
                    <Across/>
                    <HeaderDialog>
                        <IconButton 
                        isLarge 
                        isFill 
                        colorFill={PINK_COLOR}
                        colorFillHover={PINK_COLOR}
                        colorBorder={PINK_COLOR}
                        colorFillIcon={SECONDARY_WHITE_COLOR}> <Warning fill={SECONDARY_WHITE_COLOR}/>
                        </IconButton>
                        <HeaderText color={PINK_COLOR}> Wollen Sie wirklich schliessen? </HeaderText>
                    </HeaderDialog>
                    <DialogWrapper>
                        <DialogContent>Sind Sie sicher,
                            daß Sie die Eingabe beenden wollen? Nicht
                            gespeicherte Zwischenstände
                            gehen verloren.</DialogContent>
                    </DialogWrapper>
                </DialogWindowContainer>

                <div style={{margin: '20px 0'}}/>


                <DialogWindowContainer>
                    <Across/>
                    <HeaderDialog>
                        <IconButton 
                        isLarge 
                        isFill 
                        colorFill={PINK_COLOR}
                        colorFillHover={PINK_COLOR}
                        colorBorder={PINK_COLOR}
                        colorFillIcon={SECONDARY_WHITE_COLOR}> <Warning fill={SECONDARY_WHITE_COLOR}/>
                        </IconButton>
                        <HeaderText color={PINK_COLOR}> Wollen Sie wirklich schliessen? </HeaderText>
                    </HeaderDialog>
                    <DialogWrapper>
                        <DialogContent>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque consectetur
                            ducimus eos iste neque porro quae quaerat repudiandae totam! Atque aut facilis id libero
                            maxime omnis quasi quos ullam, ut vel. At commodi, consectetur dolore eius libero placeat
                            quae repudiandae saepe vero? Aliquam aspernatur, corporis delectus exercitationem explicabo
                            maxime modi molestias nemo quae qui sed voluptate! Beatae debitis dolorem ea eaque, fugit
                            iste laborum, magnam maiores nemo nostrum quam quidem tenetur totam? Aspernatur at
                            consequuntur eos est excepturi, explicabo facere facilis iure laudantium magnam, neque non
                            nulla possimus quam rem tempora tempore veniam voluptatem. Aperiam commodi doloribus
                            repudiandae voluptates? Deleniti deserunt doloribus ducimus eos facilis impedit laboriosam
                            qui quibusdam, quisquam, rerum sed voluptate voluptatem? Ducimus facere inventore iste
                            pariatur quisquam rerum velit voluptatibus! Assumenda deleniti dignissimos eligendi esse ex
                            perspiciatis rem reprehenderit, sunt unde voluptatum. Accusamus adipisci beatae cum
                            cupiditate eius eum explicabo fugit iste modi, nam nesciunt nostrum officiis placeat
                            possimus quo, quod reprehenderit tempora tempore ullam vero! Atque dolorum, ducimus? A
                            consequuntur cumque debitis eius expedita illum ipsa laboriosam, laudantium maxime modi,
                            obcaecati placeat possimus reiciendis saepe totam vero voluptates? Accusamus aperiam,
                            assumenda at consequuntur cupiditate delectus dolorem error excepturi modi possimus
                            quibusdam recusandae, sequi sint ut voluptas?</DialogContent>
                    </DialogWrapper>
                </DialogWindowContainer>

                <div style={{margin: '20px 0'}}/>

                <DialogWindowContainer>
                    <Across/>
                    <HeaderDialog>
                        <IconButton 
                        isLarge 
                        isFill 
                        colorFill={PINK_COLOR}
                        colorFillHover={PINK_COLOR}
                        colorBorder={PINK_COLOR}
                        colorFillIcon={SECONDARY_WHITE_COLOR}> <Warning fill={SECONDARY_WHITE_COLOR}/>
                        </IconButton>
                        <HeaderText color={PINK_COLOR}> Wollen Sie wirklich schliessen? </HeaderText>
                    </HeaderDialog>
                    <DialogWrapper>
                        <DialogContent>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque consectetur
                            ducimus eos iste neque porro quae quaerat repudiandae totam! Atque aut facilis id libero
                            maxime omnis quasi quos ullam, ut vel. At commodi, consectetur dolore eius libero placeat
                            quae repudiandae saepe vero? Aliquam aspernatur, corporis delectus exercitationem explicabo
                            maxime modi molestias nemo quae qui sed voluptate! Beatae debitis dolorem ea eaque, fugit
                            iste laborum, magnam maiores nemo nostrum quam quidem tenetur totam? Aspernatur at
                            consequuntur eos est excepturi, explicabo facere facilis iure laudantium magnam, neque non
                            nulla possimus quam rem tempora tempore veniam voluptatem. Aperiam commodi doloribus
                            repudiandae voluptates? Deleniti deserunt doloribus ducimus eos facilis impedit laboriosam
                            qui quibusdam, quisquam, rerum sed voluptate voluptatem? Ducimus facere inventore iste
                            pariatur quisquam rerum velit voluptatibus! Assumenda deleniti dignissimos eligendi esse ex
                            perspiciatis rem reprehenderit, sunt unde voluptatum. Accusamus adipisci beatae cum
                            cupiditate eius eum explicabo fugit iste modi, nam nesciunt nostrum officiis placeat
                            possimus quo, quod reprehenderit tempora tempore ullam vero! Atque dolorum, ducimus? A
                            consequuntur cumque debitis eius expedita illum ipsa laboriosam, laudantium maxime modi,
                            obcaecati placeat possimus reiciendis saepe totam vero voluptates? Accusamus aperiam,
                            assumenda at consequuntur cupiditate delectus dolorem error excepturi modi possimus
                            quibusdam recusandae, sequi sint ut voluptas?</DialogContent>

                        <ButtonGroup>
                            <Button color={SECONDARY_COLOR} colorText={SECONDARY_COLOR}> Änderungen verwerfen </Button>
                            <Button color={PINK_COLOR} colorText={SECONDARY_WHITE_COLOR}> Änderungen speichern </Button>
                        </ButtonGroup>
                    </DialogWrapper>
                </DialogWindowContainer>

                <div style={{margin: '20px 0'}}/>

                <DialogWindowContainer>
                    <Across/>
                    <HeaderDialog>
                        <IconButton 
                        isLarge 
                        isFill 
                        colorFill={BLUE_COLOR}
                        colorFillHover={BLUE_COLOR}
                        colorBorder={BLUE_COLOR}
                        colorFillIcon={SECONDARY_WHITE_COLOR}> <Upload fill={SECONDARY_WHITE_COLOR}/>
                        </IconButton>
                        <HeaderText color={BLUE_COLOR}> Änderungen wurden gespeichert </HeaderText>
                    </HeaderDialog>
                    <DialogWrapper>
                        <DialogContent>Sie können die Bearbeitung zu einem späteren Zeitpunkt
                            fortsetzen.</DialogContent>
                    </DialogWrapper>
                </DialogWindowContainer>

                <div style={{margin: '20px 0'}}/>

                <DialogWindowContainer>
                    <Across/>
                    <HeaderDialog>
                        <IconButton 
                        isLarge 
                        isFill 
                        colorFill={PINK_COLOR}
                        colorFillHover={PINK_COLOR}
                        colorBorder={PINK_COLOR}
                        colorFillIcon={SECONDARY_WHITE_COLOR}> <Warning fill={SECONDARY_WHITE_COLOR}/>
                        </IconButton>
                        <HeaderText color={PINK_COLOR}> Wollen Sie wirklich schliessen? </HeaderText>
                    </HeaderDialog>
                    <DialogWrapper>
                        <DialogContent>Sind Sie sicher, daß Sie die Eingabe beenden wollen? Nicht gespeicherte
                            Zwischenstände gehen verloren.</DialogContent>
                        <ButtonGroup>
                            <Button color={SECONDARY_COLOR} colorText={SECONDARY_COLOR}> Änderungen verwerfen </Button>
                            <Button color={PINK_COLOR} colorText={SECONDARY_WHITE_COLOR}> Änderungen speichern </Button>
                        </ButtonGroup>
                    </DialogWrapper>
                </DialogWindowContainer>

                <div style={{margin: '10px 0'}}/>

                <Navbar 
                    menuItems={[
                        {title: 'Alle', count: 14},
                        {title: 'Aktiviert', count: 8},
                        {title: 'Entwurf', count: 4},
                        {title: 'Deaktiviert', count: 2},
                    ]}
                    activeTab={this.state.activeStatusTab}
                    onSelectFilter={this.onSelectStatusFilter.bind(this)}
                />

                <div style={{margin: '30px 0'}}/>

                <Navbar 
                    mainColor={'blue'} 
                    secondaryColor={'red'} 
                    menuItems={[
                        {title: 'Alle', count: 14},
                        {title: 'Aktiviert', count: 8},
                        {title: 'Entwurf', count: 4},
                        {title: 'Deaktiviert', count: 2},
                    ]}
                    activeTab={this.state.activeStatusTab}
                    onSelectFilter={this.onSelectStatusFilter.bind(this)}
                />

                <div style={{margin: '30px 0'}}/>

                <Navbar 
                    mainColor={'yellow'} 
                    secondaryColor={'yellow'} 
                    menuItems={[
                        {title: 'Alle', count: 14},
                        {title: 'Aktiviert', count: 8},
                        {title: 'Entwurf', count: 4},
                        {title: 'Deaktiviert', count: 2},
                    ]}
                    activeTab={this.state.activeStatusTab}
                    onSelectFilter={this.onSelectStatusFilter.bind(this)}
                />

                <div style={{margin: '30px 0'}}/>


            </ContainerDemo>
        )
    }
}

