import styled from "styled-components";
import {MAIN_COLOR, SECONDARY_COLOR, WHITE_COLOR} from "../../../constants/css";

export const CheckBoxContainer = styled.div`
  display: flex;
`

export const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;

  &:checked + * {
    transition: .2s ease-in;
    background: ${({backgroundChecked = MAIN_COLOR}) => backgroundChecked};
    border-color: ${({backgroundChecked = MAIN_COLOR}) => backgroundChecked};
  }
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 0;
`;

export const Indicator = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  border: 1.5px solid ${({borderColor = SECONDARY_COLOR}) => borderColor};
  background: ${({background = WHITE_COLOR}) => background};
  transition: .2s ease-in;

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0;
    left: 0.35em;
    width: 35%;
    height: 70%;
    border: 2px solid ${({colorIcon = SECONDARY_COLOR}) => colorIcon};
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
`;
