import React from "react";
import {Container, Title} from "../styles/Heading";


class Heading extends React.Component {

    render() {
        return (
            <Container>
              <div style={{width: '30px', height:'30px'}}> {this.props.children} </div>

                <Title color={this.props.color} fontSize={this.props.fontSize}>
                    {this.props.title}
                </Title>
            </Container>
        )
    }
}

export default Heading