import _ from 'lodash';
import { filterElementsFromDict, setElementsFromPrefix } from '../utils/dictionaryFilteringUtils';

//Placeholders objects for different elements of the json definition. Can be deleted I guess
export function EmailItem(){
    this.base_template = {};
    this.email_types = [];
}

export function DocumentItem(){
    this.base_template = {};
    this.document_types = [];
}

export  function ComparisionGraphicItem(){
    this.definition_csgraphic = {};
}


export function EmailBaseTemplateItem(){
  return undefined
}

export function EmailDefinitionSoMeItem(){
  return undefined
}

export function EmailCustomerApproachItem(){
    this.definition_salutation_text_divers = ""
}

export function EmailDefinitionCreditItem(){
  return undefined
}

export default class BaseDesignValues{
    emailPrefix = 'email';
    providerPrefix = 'prv';
    emailSomePrefix = 'some';
    baseTemplatePrefix = 'bt';
    salutationPrefix = 'salut'
    emailTypesPrefix = 'et';
    docTypesPrefix = 'dt';
    comparisonGraphPrefix = 'cg';

    getEmailDocumentsValues(formValues, instance=null){
        let email = new EmailItem();
        let docs = new DocumentItem();
        let csGraph = new ComparisionGraphicItem();
        email = filterElementsFromDict(email, formValues, this.emailPrefix);
        const emailBaseTemplate = this.getEmailBaseTemplate(formValues);
        email.base_template = emailBaseTemplate;
        if(instance !== null){
            email.email_types = _.get(instance, 'product_offer_characteristics.emails.email_types', []);
            docs.document_types = _.get(instance, 'product_offer_characteristics.documents.document_types', []);
        }
        csGraph.definition_csgraphic = this.getComparisonGraphTypes(formValues);
        docs.base_template = {
            ...emailBaseTemplate,
            definition_font_color: _.get(emailBaseTemplate , 'definition_font_color', ''),
            definition_font_color_two: _.get(emailBaseTemplate , 'definition_font_color_two', ''),
            definition_csgraphic: csGraph.definition_csgraphic
        };
        if(!_.isEmpty(emailBaseTemplate)){
            // const csGraph = new ComparisionGraphicItem()
            docs.base_template = {...docs.base_template}
        }
        return {'emails': email, 'documents': docs};
    }    

    getComparisonGraphTypes(formValues){
    const comparisonGraphTypes = filterElementsFromDict({}, formValues, this.comparisonGraphPrefix);
        return {'definition_csgraphic_bg_color'  : comparisonGraphTypes['definition_csgraphic_bg_color'],
                'definition_csgraphic_fg_color': comparisonGraphTypes['definition_csgraphic_fg_color'],
                'definition_csgraphic_font_color_amount': comparisonGraphTypes['definition_csgraphic_font_color_amount'],
                'definition_csgraphic_font_color_amount_label': comparisonGraphTypes['definition_csgraphic_font_color_amount_label'],
         };
    }

    convertSocialMediaArray(soMeArray) {
        return soMeArray.reduce((soMeObject, soMeEntry) => {
            const key = Object.keys(soMeEntry)[0]
            soMeObject[key] = soMeEntry[key]

            return soMeObject
        }, {})
    }

    getEmailBaseTemplate(formValues){
        let baseTemplate = new EmailBaseTemplateItem();
        baseTemplate = filterElementsFromDict(baseTemplate, formValues, this.baseTemplatePrefix);
        const soMeArray = formValues['socialMedia'] || []
        const soMeObject = this.convertSocialMediaArray(soMeArray)
        let creditDefinition = new EmailDefinitionCreditItem()
        creditDefinition = filterElementsFromDict(creditDefinition, formValues, this.providerPrefix);
        let customerApproach = new EmailCustomerApproachItem();
        customerApproach = filterElementsFromDict(customerApproach, formValues, this.salutationPrefix);
        
        if(_.isEmpty(baseTemplate) && _.isEmpty(soMeObject) && _.isEmpty(creditDefinition) && _.isEmpty(baseTemplate) && _.isEmpty(customerApproach)){
            return {};
        }

        return {
            ...baseTemplate, 
            'definition_credits': creditDefinition, 
            'definition_social_media': soMeObject,
            'definition_salutation_text': customerApproach
        }
    }

    setValuesFromForm(formValues, instance=null){
        return this.getEmailDocumentsValues(formValues, instance)
    }

    getKeys(){
        return ['emails', 'documents']
    }

    setEmailDocumentsFormValues(formValues, items, prefix){
        items.forEach(item=>{
            Object.assign(formValues, {[`${prefix}_${item.name}`]: item.context_variables})
        })
    }

    getSocialMediaArray(someValues) {
        const socialMediaArray = []
        
        for (const property in someValues) {
            socialMediaArray.push({[property]: someValues[property]})
        }

        return socialMediaArray
    }

    setFormValues(podObject){
        let formValues = {};
        const emailValues = _.pick(_.get(podObject, 'emails', {}), ['bcc', 'from']);
        Object.assign(formValues, setElementsFromPrefix(emailValues, this.emailPrefix))
        const templateValues = _.pick(_.get(podObject, 'emails.base_template', {}), [
            'definition_font_color', 
            'definition_font_color_two',
            'definition_signature',
            'definition_signature_singular',
            'definition_signature_plural'
        ]);
        Object.assign(formValues, setElementsFromPrefix(templateValues, this.baseTemplatePrefix))
        const csGraphValues = _.pick(_.get(podObject, 'documents.base_template.definition_csgraphic', {}), [
            'definition_csgraphic_bg_color',
            'definition_csgraphic_fg_color',
            'definition_csgraphic_font_color_amount',
            'definition_csgraphic_font_color_amount_label',
        ]);
        Object.assign(formValues, setElementsFromPrefix(csGraphValues, this.comparisonGraphPrefix))
        const creditsValues = _.get(podObject, 'emails.base_template.definition_credits', {});
        Object.assign(formValues, setElementsFromPrefix(creditsValues, this.providerPrefix));
        const someValues = _.get(podObject, 'emails.base_template.definition_social_media', {});
        formValues['socialMedia'] = this.getSocialMediaArray(someValues)
        const salutationValues = _.get(podObject, 'emails.base_template.definition_salutation_text', {});
        Object.assign(formValues, setElementsFromPrefix(salutationValues, this.salutationPrefix));

        const emailTypes = _.get(podObject, 'emails.email_types', []);
        this.setEmailDocumentsFormValues(formValues, emailTypes, this.emailTypesPrefix);
        const documentTypes = _.get(podObject, 'documents.document_types', []);
        this.setEmailDocumentsFormValues(formValues, documentTypes, this.docTypesPrefix);
        return formValues;
    }

}