import React from 'react';
import PropTypes from "prop-types";
import {
    IS_FOR_BUSINESS_LABEL,
    PRODUCT_CATEGORY_OPTIONS,
    PRODUCT_TARIF_TYPE_FIELD_OPTIONS
} from "../../constants";
import { ProductItemText } from '../common/styles/ProductItem';

export class ConsumerItemSpecifics extends React.Component{
    render(){
        const characteristics = this.props.definition.product_offer_characteristics
        const consumptionPriceModel = characteristics.consumption_price_model
        const labelPriceModel = PRODUCT_TARIF_TYPE_FIELD_OPTIONS.reduce((acc, cur) => {
            if (cur.value === consumptionPriceModel) {
                acc = cur.label
            }
            return acc
        }, '')

        return(
            <>
                <ProductItemText>
                    {this.props.definition.owner}
                </ProductItemText>
                <ProductItemText>
                    {IS_FOR_BUSINESS_LABEL[characteristics.is_for_business]}
                </ProductItemText>
                <ProductItemText>
                    {PRODUCT_CATEGORY_OPTIONS[characteristics.product_category]}
                </ProductItemText>
                <ProductItemText>
                    {labelPriceModel}
                </ProductItemText>
            </>
        )
    }
}

ConsumerItemSpecifics.propTypes = {
    definition: PropTypes.object,
}
