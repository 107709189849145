import React from "react";

import Row from "react-bootstrap/Row";
import { Field, FieldArray } from "redux-form";
import { combine, length, required } from "redux-form-validators";
import { PRODUCT_PRICE_CALCULATION_FIELD_OPTIONS, PRODUCT_PRICE_CLASSIFICATION_OPTIONS } from "../../../../../constants";

import TextAreaWrapper from "../../../../common/forms/TextAreaWrapper";
import { OuterFormColLeft, OuterFormColRight } from "../../../../common/styles/FormStyles";
import SelectWrapper from "../../../../common/forms/SelectWrapper";
import InputWrapper from "../../../../common/forms/InputWrapper";
import AccordionItem from "../../../../common/lib/Accordion";
import { GRAY_COLOR, NO_ACTIVE_BG_COLOR, NO_ACTIVE_COLOR } from "../../../../../constants/css";
import { ReactComponent as Info } from "../../../../../images/icons/INfo.svg";
import { PriceCalculationBaseFee } from "./PriceCalculationBaseFee";
import { CostItemInputs } from "./CostItemInputs";

export class PriceCalculationBaseFeeForm extends React.Component {
    render() {
        return (
            <AccordionItem
                title="GRUNDGEBÜHR INKL. NETZENTGELTE"
                expanded={true}
                contentBackground={GRAY_COLOR}
                barBackgroundClosed={NO_ACTIVE_COLOR}
                barBackgroundOpen={NO_ACTIVE_BG_COLOR}
                marginBottom="40px"
                marginTop="10px"
            >
                <Row>
                    <OuterFormColLeft md={6} lg={6}>
                        <Field
                            name="bf_display_name"
                            component={InputWrapper}
                            type="text"
                            labelText="Display Name der Grundgebühr"
                            validate={required()}
                            disabled={this.props.disabled}
                            className="mt-3"
                        />
                        <Field
                            name="bf_description"
                            labelText="Beschreibung der Grundgebühr"
                            component={TextAreaWrapper}
                            validate={combine(
                              required(),
                              length({
                                max: 128,
                                message: "Der Text ist zu lang. Es können maximal 128 Zeichen (inklusive Leerzeichen) verwendet werden.",
                              })
                            )}
                            placeholder="Bitte beschreiben Sie hier die Zusammensetzung der Grundgebühr"
                            disabled={this.props.disabled}
                            className="mt-5"
                            infoIcon={<Info/>}
                            infoIconTooltip="Der Beschreibungstext wird in der Begriffserklärung einer Stromabrechnung verwendet."
                        />
                    </OuterFormColLeft>
                    <OuterFormColRight md={6} lg={6}>
                        <Field
                            name="combi_bf_method"
                            labelText="Berechnungsart der Grundgebühr"
                            component={SelectWrapper}
                            optionsList={PRODUCT_PRICE_CALCULATION_FIELD_OPTIONS}
                            validate={required()}
                            disabled={this.props.disabled}
                            onCustomChange={this.props.changeBaseFee}
                            className="mt-3 mb-5"
                        />
                        <PriceCalculationBaseFee
                            disabled={this.props.disabled}
                            priceCalculation={this.props.baseFeeMethod}
                            priceType={this.props.priceType}
                        />
                        <Field
                            name="bf_classification"
                            labelText="Klassifizierung"
                            component={SelectWrapper}
                            optionsList={PRODUCT_PRICE_CLASSIFICATION_OPTIONS}
                            disabled={true}
                            defaultVal={PRODUCT_PRICE_CLASSIFICATION_OPTIONS[0]}
                            className="mt-5"
                        />
                    </OuterFormColRight>
                </Row>
                {
                    <FieldArray
                        name="bf_cost_items"
                        component={CostItemInputs}
                        costItemOptions={this.props.costItemOptions}
                        selectedCostItem={this.props.selectedCostItem}
                        updateClickIndexStatus={this.props.updateClickIndexStatus}
                        feeIndex={-1}
                        isForBaseFee={true}
                        validate={this.props.meterOperatorExists}
                        meterOperatorError={this.props.meterOperatorError}
                        saveClicked={this.props.saveClicked}
                        fieldsRefBaseFee={this.props.fieldsRefBaseFee}
                        meterOperatorFeeNotDeletable={this.props.meterOperatorFeeNotDeletable}
                        disabled={this.props.disabled}
                    />
                }
            </AccordionItem>
        )
    }
}
