import { createActionThunk } from 'redux-thunk-actions';
import APIRequest from '../api/ApiRequest';
import errorHandler from './errorHandler';

import {
    FETCH_DEFINITIONS,
    DELETE_DEFINITION,
    CREATE_DEFINITION,
    RETRIEVE_DEFINITION,
    UPDATE_DEFINITION,
    RESET_FORM_DATA,
    UPDATE_DEFINITION_CUCO,
    DUPLICATE_DEFINITION,
    UPDATE_STATUS_ACTIVATION,
    UPDATE_PRODUCTS_TYPE,
    // UPDATE_EDIT_STATUS
} from './types';
import {PRODUCT_OFFER_ENDPOINT} from '../constants';


export const fetchProductOffers = (token, salespartner, subSalespartners=[]) => {
    const spQs = `?salespartner=${salespartner}`;
    const subSpQs = subSalespartners.map(item=> {
        return `subSp=${item.spid}`
    }).join("&");
    const fullQs = `${spQs}&${subSpQs}`
    return (dispatch) => {
        let fetch = createActionThunk(FETCH_DEFINITIONS, () =>
            new APIRequest(token).get(`${PRODUCT_OFFER_ENDPOINT}${fullQs}`));
        return dispatch(fetch());
    };
};

export const deleteProductOffer = (token, productOffer, callback) => {
    const deleteEntryPoint = `${PRODUCT_OFFER_ENDPOINT}${productOffer}/`;
    return (dispatch) => {
        let fetch = createActionThunk(DELETE_DEFINITION, () =>
            new APIRequest(token).delete(deleteEntryPoint));
        dispatch(fetch()).then(() =>{
            callback("Deleted successfully")
        }).catch(() => {
            callback("Error", 'error');
        });
    };
};


export const createProductOffer = (token, formData, callback, errCallback) => {
    return (dispatch) => {
        let post = createActionThunk(CREATE_DEFINITION, () =>
            new APIRequest(token).post(PRODUCT_OFFER_ENDPOINT, formData));
        return dispatch(post()).then((response) =>{
                callback(response.data.id);
            }).catch(() => {
                errCallback()
            });
    };
};


export const retrieveProductOffer = (token, productOffer, callback) => {
    const getEntryPoint = `${PRODUCT_OFFER_ENDPOINT}${productOffer}/`;
    return (dispatch) => {
        let fetch = createActionThunk(RETRIEVE_DEFINITION, () =>
            new APIRequest(token).get(getEntryPoint));
        const promise = dispatch(fetch());
        return promise.then(response => {callback(response.data)})
    };
};

export const updateProductOffer = (token, productOffer, formData, callback, errCallback) => {
    const putEntryPoint = `${PRODUCT_OFFER_ENDPOINT}${productOffer}/`;
    return (dispatch) => {
        let put = createActionThunk(UPDATE_DEFINITION, () =>
            new APIRequest(token).put(putEntryPoint, formData));
        return dispatch(put()).then((response) =>{
            callback(response.data.id);
        }).catch(() => {
            errCallback()
        });
    };
};

export const updateProductofferCuco = (token, productOffer, formData, callback) => {

    const putEntryPoint = `/api/product_definitions_ed/${productOffer}/`;

    return (dispatch) => {
        let fetch = createActionThunk(UPDATE_DEFINITION_CUCO, () =>
        new APIRequest(token).put(putEntryPoint, formData));
        const promise = dispatch(fetch());
        return promise.then(response => {callback(response.data)})
    };
};


export const duplicateProductOffer = (token, productOffer, formData, callback, errorCallback) => {
    const postEntryPoint = `${PRODUCT_OFFER_ENDPOINT}${productOffer}/duplicate/`;
    return (dispatch) => {
        let fetch = createActionThunk(DUPLICATE_DEFINITION, () =>
            new APIRequest(token).post(postEntryPoint, formData));
        const promise = dispatch(fetch());
        return promise.then(response => {callback(response.data)}).catch((e)=>{
            const errorObj = errorHandler(e);
            errorCallback(errorObj.message);
        })
    };
};

export const resetFormData = () => dispatch => {
    return dispatch({type: RESET_FORM_DATA, payload: true})
}

export const updateStatusActivationSuccessful = (id) => dispatch => {
    return dispatch({type: UPDATE_STATUS_ACTIVATION, payload: id})
}

export const updateProductType = (is_producer) => dispatch => {
    return dispatch({type: UPDATE_PRODUCTS_TYPE, payload: is_producer})
}
