import styled from "styled-components";
import {APP_FONT, SECONDARY_COLOR} from "../../../constants/css";

export const Container = styled.div`
  display: flex;
  align-items: center;
`

// Needs !important for Editor heading, because E-Mail preview styles override 
export const Title = styled.h1`
  font-size: ${({fontSize = '40px'}) => fontSize} !important;
  font-weight: normal !important;
  font-family: ${APP_FONT} !important;
  color: ${({color = SECONDARY_COLOR}) => color} !important;
  margin-left: 8px !important;
  margin-bottom: 0 !important;
  margin-top: 4px !important;
`