import React from "react";
import { AccordionBar, AccordionContainer, AccordionContent, Text } from "../styles/Accordion";
import {ReactComponent as Delete} from "../../../images/icons/delete.svg";
import IconButton from "./IconButton";
import { SECONDARY_COLOR, SECONDARY_WHITE_COLOR } from "../../../constants/css";
import { TooltipContainer, TooltipIcon, TooltipText } from "../styles/TooltipIcon";

class AccordionItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: this.props.expanded
        }
    }

    handleAccordionItemClick() {
        const newExpandedState = !this.state.expanded;
        this.setState({ expanded: newExpandedState }, () => { // callback function allowing to now if accordion is expanded or not in parent component
            if (this.props.onAccordionToggle) { 
                this.props.onAccordionToggle(this.state.expanded);
            }
        });
    }

    handleDelete() {
        this.props.deleteHandler(this.props.index)
    }

    handleOnDeleteClick(event) {
        event.stopPropagation()
        if (!this.props.disabled && this.props.deleteHandler) {
            this.handleDelete(event)
        }
    }

    render() {
        return (
            <AccordionContainer 
                marginBottom={this.props.marginBottom}
                marginTop={this.props.marginTop}
                width={this.props.width}
            >
                <AccordionBar
                    expanded={this.state.expanded}
                    onClick={this.handleAccordionItemClick.bind(this)}
                    barBackgroundClosed={this.props.barBackgroundClosed}
                    barBackgroundOpen={this.props.barBackgroundOpen}
                >
                    <Text expanded={this.state.expanded}>
                        {this.props.title}
                        {this.props.infoIcon && 
                            <TooltipContainer>
                                <TooltipIcon tooltipMarginLeft={this.props.tooltipMarginLeft}>
                                    {this.props.infoIcon}
                                </TooltipIcon>
                                <TooltipText>
                                    {this.props.infoIconTooltip}
                                </TooltipText>
                            </TooltipContainer>
                        }
                    </Text>
                    {this.props.withDelete && 
                        <IconButton
                            tooltip='löschen' 
                            positionTooltip='top'
                            width='20px'
                            onClick={event => this.handleOnDeleteClick(event)}
                            disabled={this.props.disabled}
                        >
                            <Delete
                                fill={this.state.expanded ? SECONDARY_COLOR : SECONDARY_WHITE_COLOR}
                            />
                        </IconButton>
                    }
                </AccordionBar>
                {this.state.expanded &&
                    <AccordionContent 
                        marginBottom={this.props.marginBottom}
                        contentBackground={this.props.contentBackground}
                    >
                        {this.props.children}
                    </AccordionContent>
                }
            </AccordionContainer>
        )
    }
}

export default AccordionItem
