import styled from "styled-components";
import { WHITE_COLOR } from "../../../constants/css";

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: ${({justifyContent}) => justifyContent};
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px 100px 20px 100px;
    width: 100%;
    background: ${WHITE_COLOR};
    z-index: 100;
`
