import { METER_OPERATOR_COST_ITEM_NAME } from "../constants"

const meterOperatorInCostItems = (costItem, callback) => {
    if (costItem.name === METER_OPERATOR_COST_ITEM_NAME) {
        callback(false)
        return undefined
    }
}


// Check if a meter operator is under base fee or any additional monthly fee
// If yes return undefined, else return an error String
export const meterOperatorExistsOnForm = (callback) => (value, allValues) => {        
    if (allValues.bf_cost_items) {
        for (let i = 0; i < allValues.bf_cost_items.length; i++){
            const costItem = allValues.bf_cost_items[i]
            return meterOperatorInCostItems(costItem, callback)
        }
    }

    if (allValues.monthly_fees) {
        for (let i = 0; i < allValues.monthly_fees.length; i++){
            for (let j = 0; j < allValues.monthly_fees[i].cost_items?.length; j++){
                const costItem = allValues.monthly_fees[i].cost_items[j]
                return meterOperatorInCostItems(costItem, callback)
            }
        }
    }

    callback(true)
    return "Messstellengebühr muss definiert werden"
}

export const biggerThenOrEqual = (compare) => (value) => {
    if (value && (value < compare)) {
        return 'Wert darf nicht negativ sein'
    }
    return undefined
}


export const UPPERCASE_REGEX = new RegExp(/.*[A-Z]/);
export const NUMBER_REGEX = new RegExp(/.*\d/);
export const LENGTH_REGEX = new RegExp(/.{8,}$/);
export const SPECIAL_CHARS_REGEX = new RegExp(
  /.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/
);

export const PASSWORD_VALID_REGEX = new RegExp(
    `^(?=${[
      LENGTH_REGEX.source,
      NUMBER_REGEX.source,
      SPECIAL_CHARS_REGEX.source
    ].join(")(?=")}).*$`
);

// Control Characters not included (warning ESLint)
export const isValidASCII = (text) => {
  return /^[\x20-\x7E]*$/.test(text);
}
