import React from "react";
import {Field, formValueSelector} from "redux-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {connect} from "react-redux";
import { StyledFormHeading } from "../../../common/styles/FormStyles";
import ColorPickerWrapper from "../../../common/forms/ColorPickerWrapper";
import { Tooltip } from "../../../common/lib/Tooltip"

export class ComparisonGraphColorSelectionForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            displayForegroundColor: false,
            foregroundColor: "#FFFFFF",
            displayBackgroundColor: false,
            backgroundColor: "#FFFFFF",
            displayPillarFontColor: false,
            pillarFontColor: "#FFFFFF",
            displayLabelFontColor: false,
            labelFontColor: "#FFFFFF",


        }
    }
    handleCloseForegroundColor = () => {
        this.setState({ displayForegroundColor: false });
    };
    handleForegroundColorClick = () => {
        this.setState({
            displayForegroundColor: !this.state.displayForegroundColor
        });
    };
    handleForegroundColorChange = color => {
        this.setState({ foregroundColor: color.hex });
    };
    handleCloseBackgroundColor = () => {
        this.setState({ displayBackgroundColor: false });
    };
    handleBackgroundColorClick = () => {
        this.setState({
            displayBackgroundColor: !this.state.displayBackgroundColor
        });
    };
    handleBackgroundColorChange = color => {
        this.setState({ backgroundColor: color.hex });
    };
    handleClosePillarFontColor = () => {
        this.setState({ displayPillarFontColor: false });
    };
    handlePillarFontColorClick = () => {
        this.setState({
            displayPillarFontColor: !this.state.displayPillarFontColor
        });
    };
    handlePillarFontColorChange = color => {
        this.setState({ pillarFontColor: color.hex });
    };
    handleCloseLabelFontColor = () => {
        this.setState({ displayLabelFontColor: false });
    };
    handleLabelFontColorClick = () => {
        this.setState({
            displayLabelFontColor: !this.state.displayLabelFontColor
        });
    };
    handleLabelFontColorChange = color => {
        this.setState({ labelFontColor: color.hex });
    };


    render() {
        const selectedForegroundColor = this.props.selectedForegroundColor || this.state.foregroundColor;
        const selectedBackgroundColor = this.props.selectedBackgroundColor || this.state.backgroundColor;
        const selectedPillarFontColor = this.props.selectedPillarFontColor || this.state.pillarFontColor;
        const selectedLabelFontColor = this.props.selectedLabelFontColor || this.state.labelFontColor;

        return (
            <>
                <StyledFormHeading>
                    STROMVERBRAUCH IM VERGLEICH
                    <Tooltip>
                        Der Stromverbrauch im Vergleich wird in einer Stromabrechnung in einem Säulendiagramm dargestellt. Für die Säulen und Beschriftungen kann ein Farbschema hinterlegt werden.
                    </Tooltip>
                </StyledFormHeading>
                <Row className="mb-4">
                    <Col md={3} lg={3}>
                        <Field
                            name="cg_definition_csgraphic_fg_color"
                            labelText="Säulen Vordergrund"
                            component={ColorPickerWrapper}
                            handleClose={this.handleCloseForegroundColor.bind(this)}
                            handleInputClick={this.handleForegroundColorClick.bind(this)}
                            displayPicker={this.state.displayForegroundColor}
                            isColorBox={true}
                            selectedColor={selectedForegroundColor}
                            handleInputChange={this.handleForegroundColorChange.bind(this)}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <Field
                            name="cg_definition_csgraphic_bg_color"
                            labelText="Säulen Hintergrund"
                            component={ColorPickerWrapper}
                            handleClose={this.handleCloseBackgroundColor.bind(this)}
                            handleInputClick={this.handleBackgroundColorClick.bind(this)}
                            displayPicker={this.state.displayBackgroundColor}
                            isColorBox={true}
                            selectedColor={selectedBackgroundColor}
                            handleInputChange={this.handleBackgroundColorChange.bind(this)}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <Field
                            name="cg_definition_csgraphic_font_color_amount"
                            labelText="Schriftfarbe innerhalb Säule"
                            component={ColorPickerWrapper}
                            handleClose={this.handleClosePillarFontColor.bind(this)}
                            handleInputClick={this.handlePillarFontColorClick.bind(this)}
                            displayPicker={this.state.displayPillarFontColor}
                            isColorBox={true}
                            selectedColor={selectedPillarFontColor}
                            handleInputChange={this.handlePillarFontColorChange.bind(this)}
                        />
                    </Col>
                    <Col md={3} lg={3}>
                        <Field
                            name="cg_definition_csgraphic_font_color_amount_label"
                            labelText="Schriftfarbe unter Säulen"
                            component={ColorPickerWrapper}
                            handleClose={this.handleCloseLabelFontColor.bind(this)}
                            handleInputClick={this.handleLabelFontColorClick.bind(this)}
                            displayPicker={this.state.displayLabelFontColor}
                            isColorBox={true}
                            selectedColor={selectedLabelFontColor}
                            handleInputChange={this.handleLabelFontColorChange.bind(this)}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

const selector = formValueSelector('productDefinitionForm');

function mapStateToProps(state, ownProps) {
    const selectedForegroundColor = selector(state, 'cg_definition_csgraphic_fg_color');
    const selectedBackgroundColor = selector(state, 'cg_definition_csgraphic_bg_color');
    const selectedPillarFontColor = selector(state, 'cg_definition_csgraphic_font_color_amount');
    const selectedLabelFontColor = selector(state, 'cg_definition_csgraphic_font_color_amount_label');
    return Object.assign({}, ownProps, {
        selectedForegroundColor,
        selectedBackgroundColor,
        selectedPillarFontColor,
        selectedLabelFontColor
    })
}

export default connect(mapStateToProps)(ComparisonGraphColorSelectionForm);
