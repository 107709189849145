import React from "react";
import { getLabelText } from "../../../utils/commonUtils";
import { Container, ErrorMessage } from "../styles/InputStyles";
import "../styles/Select";
import {
  ItemList,
  Label,
  SelectBox,
  SelectedText,
  SelectOptions,
} from "../styles/Select";
import { TooltipContainer, TooltipIcon, TooltipText } from "../styles/TooltipIcon";

class Select extends React.Component {
  constructor(props) {
    super(props);

    // @showOptionList => Show / Hide List options
    this.state = {
      showOptionList: false,
      optionSelected: false,
      text: ""
    };
  }

  componentDidMount() {
    // Add Event Listner to handle the click that happens outside
    // the Custom Select Container
    document.addEventListener("mousedown", this.handleClickOutside);
    this.isOptionSelected()

    // Below if-else needed when value management different then usual and defaultVal is supplied (Example: PriceCalculationServiceFeeForm for the Form field with label "Art des Entgelts")
    if (this.props.defaultVal && this.props.defaultVal.label) {
      this.setState({
        text: this.props.defaultVal.label, 
        optionSelected: true
      })
    } else {
      this.setText()
    }
  }

  componentDidUpdate(previousProps) {
    // Add Event Listner to handle the click that happens outside
    // the Custom Select Container
    document.addEventListener("mousedown", this.handleClickOutside);

    if (
        previousProps.value !== this.props.value || 
        previousProps.optionsList !== this.props.optionsList
    ) {
      this.isOptionSelected()
      this.setText()
    }
  }

  componentWillUnmount() {
    // Remove the event listner on component unmounting
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // isOptionSelected indicaes if there is an option selected or not. 
  // Needed to display the label correctly.
  isOptionSelected() {
    const valueInOptionList = this.props.optionsList.find(option => {
      return option.value === this.props.value
    })

    // First below condition is e.g. needed when optionList can
    // be empty. 
    if (!valueInOptionList) {
      this.setState({ optionSelected: false })
    } else if (this.props.value === false) {
      this.setState({optionSelected: true})
    } else {
      this.setState({ optionSelected: Boolean(this.props.value) })
    }
  }

  // This method handles the click that happens outside the
  // select text and list area
  handleClickOutside = (e) => {
    if (
      !e.target.classList.contains("custom-select-option") &&
      !e.target.classList.contains("selected-text")
    ) {
      this.setState({ showOptionList: false });
    }
  };

  // This method handles the display of option list
  handleListDisplay() {
    this.setState((prevState) => {
      return {
        showOptionList: !prevState.showOptionList,
      };
    });

    // Needed to change touched to true
    this.props.onBlur()
  }

  // This method handles the setting of name in select text area
  // and list display on selection
  handleOptionClick(e) {
    this.setState({
      showOptionList: false,
    });

    const clickedValue = e.target.getAttribute("data-name");

    if (this.props.onCustomChange){
      this.setState({
        showOptionList: false,
        text: getLabelText(this.props.optionsList, clickedValue),
        optionSelected: true
      });
    }

    const connectedOnChange = (value) => {
      this.props.onCustomChange(value)
      this.props.onChange(value)
    }

    const onChange = this.props.onCustomChange ? connectedOnChange : this.props.onChange

    // Below first checks needed for a Select field, where stored values are false/true
    if (clickedValue === "false") {
      onChange(false)
    } else if (clickedValue === "true") {
      onChange(true)
    } else {
      onChange(clickedValue)
    }
  }

  setText() {
    this.setState({ text: getLabelText(this.props.optionsList, this.props.value) })
  }

  checkIsError() {
    const { touched, error , serverError} = this.props
    return touched && (error || serverError)
  }

  render() {
    return (
        <Container
          width={this.props.width}
          className={this.props.className}
        >
          <SelectBox
            selectedItemColor={this.props.selectedItemColor}
            showOptionList={this.state.showOptionList}
            onClick={() => !this.props.disabled && this.handleListDisplay()}
            colorIconOpen={this.props.colorIconOpen}
            colorIconClose={this.props.colorIconClose}
            disabled={this.props.disabled}
            isError={this.checkIsError()}
            arrowMargin={this.props.arrowMargin}
            optionSelected={this.state.optionSelected}
          >
            <Label
              optionSelected={this.state.optionSelected}
            >
              {this.props.labelText}
              {this.props.infoIcon && 
                <TooltipContainer>
                    <TooltipIcon tooltipMarginLeft={this.props.tooltipMarginLeft || "10px"}>
                        {this.props.infoIcon}
                    </TooltipIcon>
                    <TooltipText>
                        {this.props.infoIconTooltip}
                    </TooltipText>
                </TooltipContainer>
              }
            </Label>
            <SelectedText
              disabled={this.props.disabled}
              optionSelected={this.state.optionSelected}
            >
              {this.state.text}
            </SelectedText>
          </SelectBox>
          {this.state.showOptionList && (
            <SelectOptions>
              {this.props.optionsList.map((option) => {
                return (
                  <ItemList
                    className="custom-select-option"
                    data-name={option.value}
                    key={option.value}
                    onClick={(e) => this.handleOptionClick(e)}
                    colorIconOpen={this.props.colorIconOpen}
                  >
                    {option.label}
                  </ItemList>
                );
              })}
            </SelectOptions>
          )}
          {this.checkIsError() &&
            <ErrorMessage errorColor={this.props.errorColor}>
                {this.props.error || this.props.serverError}
            </ErrorMessage>
          }
        </Container>
    );
  }
}

export default Select;
